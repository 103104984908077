import React, {useEffect, useState} from "react";
import {EnigooSelectMulti} from "../../EnigooSelectMulti";
import {EnigooSelect} from "../../EnigooSelect";
import PropTypes from "prop-types";
import {apiGetDeviceTemplate} from "../../../Admin/pages/DeviceTemplate/Actions";
import {useTranslation} from "react-i18next";

export const DeviceTemplateSelectField = ({
                                      label,
                                      name,
                                      isMulti = false,
                                      outsideClassName = "w-full",
                                      onChange = null
                                  }) => {
    let [data, setData] = useState(null);
    let [loading, setLoading] = useState(false);
    const {t} = useTranslation()
    //data z databaze
    useEffect(() => {
        get()
    }, [])

    const get = () => {
        setLoading(true);
        apiGetDeviceTemplate((data) => {
            setData(data);
            setLoading(false);
        }, (error) => {
            setLoading(false);
        })
    }
    return (isMulti ? <EnigooSelectMulti placeHolder={t("placeHolder.deviceTemplate")} onValueChange={onChange} outsideClassName={outsideClassName} label={label} disabled={loading} name={name} items={data}/> :
        <EnigooSelect placeHolder={t("placeHolder.deviceTemplate")} onValueChange={onChange} outsideClassName={outsideClassName} label={label} name={name} disabled={loading} items={data}/>);

}
DeviceTemplateSelectField.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    isMulti: PropTypes.bool

}
