import React, {useEffect, useRef, useState} from "react";
import {shallowEqual, useSelector} from "react-redux";
import Portal from "../../../components/portal";
import {FiCheck, FiChevronsLeft, FiChevronsRight, FiX} from "react-icons/fi";
import EnigooButton from "../../../MyComponent/EnigooButton";
import {FiImage, FiSave} from "react-icons/all";
import CogoToast from "cogo-toast";
import {apiGetImages} from "./Actions";
import {EnigooTextField} from "../../../MyComponent/EnigooTextField";
import {Form} from "react-final-form";
import {EnigooLoader} from "../../../MyComponent/EnigooLoader";
import {useTranslation} from "react-i18next";
import EnigooModal from "../../../MyComponent/Modal/EnigooModal";

export const ImageModal = ({
                               setImage,
                               imageTypes = 1,
                               label = null
                           }) => {
    let [images, setImages] = useState(null);
    let [loading, setLoading] = useState(false);
    let [selectedImage, setSelectedImage] = useState(null);
    const {t} = useTranslation()
    label = !label ? t("photo.fromGallery") + ":" : label

    let [canNextPage, setCanNextPage] = useState(false);
    let [canPreviousPage, setCanPreviousPage] = useState(false);
    let [maxOfRecords, setMaxOfRecords] = useState(0);
    let [pageSize, setPageSize] = useState(8);
    let [pageOptions, setPageOptions] = useState(0); //poslední stránka
    let [pageIndex, setPageIndex] = useState(0); //poslední stránka

    let [phrase, setPhrase] = useState(null);

    const timer = useRef(null)

    const {palettes} = useSelector(
        (state) => ({
            palettes: state.reducerAdmin.palettes
        }),
        shallowEqual
    )
    let {background} = {
        ...palettes
    }

    const modalRef = useRef(null)
    const [open, setOpen] = useState(false)


    const show = () => {
        setOpen(true)
    }
    const hide = () => {
        setOpen(false)
    }

    const save = () => {
        let result = false;
        result = setImage(selectedImage);
        if (result) {
            hide();
        } else {
            CogoToast.error(t("photo.saveError"));
        }
    }

    const nextPage = () => {
        if (canNextPage) {
            gotoPage(pageIndex + 1);
        }
    }

    const previousPage = () => {
        if (canPreviousPage) {
            gotoPage(pageIndex - 1);
        }
    }

    const gotoPage = (page) => {
        if (page >= 0 && page < pageOptions) {
            setPageIndex(page);
            setCanPreviousPage(pageIndex - 1 >= 0);
            setCanNextPage(pageIndex + 1 < pageOptions);
        }
    }

    useEffect(() => {
        clearTimeout(timer.current);
        timer.current = setTimeout(() => {
            getImage();
        }, 600);

        const handleClickOutside = (event) => {
            if (!modalRef || !modalRef.current) return false
            if (!open || modalRef.current.contains(event.target)) {
                return false
            }
            setOpen(!open)
        }
        document.addEventListener('mousedown', handleClickOutside)

        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [pageIndex, phrase])

    const getImage = () => {
        setLoading(true);
        apiGetImages({page: pageIndex + 1, pageSize: pageSize, type: imageTypes, phrase}, (data) => {
            setImages(data.data);
            setPageOptions(Math.ceil(data.max / pageSize));
            setMaxOfRecords(data.max);
            setCanPreviousPage(pageIndex - 1 >= 0);
            setCanNextPage(pageIndex + 1 < Math.ceil(data.max / pageSize));
            setLoading(false);
        }, (error) => {
            CogoToast.error(t("photo.cannotLoadFromGallery"));
            setLoading(false);
            hide();
        })
    }

    return (

        <>
            <EnigooModal title={t("photo.chooseFromGalleryTitle")} buttonIcon={<FiImage/>}
                         buttonText={t("photo.chooseImage")}
                         onClose={hide} open={open} onOpen={show} onSave={()=>save()}>
                <div className={"p-4"}>
                    <Form onSubmit={(values) => {
                    }} initialValues={{type: 3}}
                          render={({form}) => {
                              return (
                                  <div className={"flex flex-row"}>
                                      <EnigooTextField outsideClassName={"lg:w-64 w-32 mr-1"}
                                                       name={"phrase"}
                                                       label={""}
                                                       placeHolder={t("placeHolder.findingPhrase")}
                                                       onChange={(event) => {
                                                           setPhrase(event.target ? event.target.value : null);
                                                       }
                                                       }/>
                                  </div>
                              );
                          }}
                    />
                </div>
                {loading && <EnigooLoader text={t("photo.loading")}/>}
                {!loading && images &&
                    <>
                        <div className={"p-2 pb-10"}>
                            <div
                                className="h-128 p-4">
                                <div className={"grid lg:grid-cols-4 grid-cols-2 gap-2"}>
                                    {images.map((item, index) => (
                                            <div style={{
                                                backgroundImage: `url(${item.path})`,
                                                backgroundRepeat: "no-repeat",
                                                backgroundSize: "contain",
                                                backgroundPosition: "center",
                                            }}
                                                 className={`border- rounded ${selectedImage && selectedImage.id === item.id ? 'border-4 border-enigoo-700' : 'border'} lg:h-64 h-32 rounded`}
                                            >
                                                <div
                                                    style={{backgroundColor: selectedImage && item.id !== selectedImage.id ? 'rgba(255,255,255,0.4)' : 'rgba(255,255,255,0)'}}
                                                    className={"h-full w-full grid place-content-center"}
                                                    onClick={() => {
                                                        if (selectedImage && selectedImage.id === item.id) setSelectedImage(null);
                                                        else setSelectedImage({
                                                            "id": item.id,
                                                            "data": item.data
                                                        })
                                                    }
                                                    }
                                                    onDoubleClick={() => {
                                                        setSelectedImage({
                                                            "id": item.id,
                                                            "data": item.data
                                                        });
                                                        save();
                                                    }}>
                                                    {selectedImage && item.id === selectedImage.id &&
                                                        <FiCheck className={"text-enigoo-700 font-bold"} size={40}/>}
                                                </div>
                                            </div>
                                        )
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-wrap items-center space-x-2 pagination mt-2 p-4">

                            <button
                                className={'btn btn-circle bg-enigoo-500 hover:bg-enigoo-600 text-white'}
                                onClick={() => previousPage()} disabled={!canPreviousPage}>
                                <FiChevronsLeft/>
                            </button>
                            {' '}
                            <span
                                className={"form-label"}>{t("table.page")}{' '}<strong>{pageIndex + 1} {t("table.of")} {pageOptions}</strong>{' '}</span>
                            <button
                                className={'btn btn-circle bg-enigoo-500 hover:bg-enigoo-600 text-white'}
                                onClick={() => nextPage()} disabled={!canNextPage}>
                                <FiChevronsRight/>
                            </button>
                            {' '}
                            <span className={"form-label"}>| {t("table.goToPage")}:{' '}</span>
                            <input
                                className={"form-input text-sm"}
                                type="number"
                                defaultValue={pageIndex + 1}
                                onChange={e => {
                                    const page = e.target.value ? Number(e.target.value) - 1 : 0
                                    gotoPage(page)
                                }}
                                style={{width: '70px'}}
                            />
                            <span
                                className={"form-label"}> | {t("basics.sumOfImage")}:{' '}{maxOfRecords} </span>
                        </div>
                    </>

                }
            </EnigooModal>

        </>
    )
}
