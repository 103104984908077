import React from "react";
import EnigooButton from "../../../MyComponent/EnigooButton";
import {FiEdit} from "react-icons/fi";
import {useTranslation} from "react-i18next";
import EnigooTablev2 from "../../../MyComponent/EnigooTablev2";


const GroupsTable = ({items}) => {
    const {t,i18n} = useTranslation()
    const columns = React.useMemo(()=>[
        {header:t('basics.name'),id:"name",accessorKey:'name'},
        {header:t('template.single'),id:"templateNamee",
            cell:({row})=>{
             return row.original.adminTemplateName?row.original.adminTemplateName:"-"
            }
        },
        {
            header:t('groups.displayName'),id:"groups",cell:({row})=>{
                if(row.original.displayName){
                    if(row.original.displayName[i18n.language]){
                        return row.original.displayName[i18n.language]
                    }else if(row.original.displayName.cs){
                        return row.original.displayName.cs;
                    }
                    else{
                        return "-"
                    }
                }   else{
                    return "-";
                }
            }
        },
        {header:t('basics.options'),id:"options",
            cell:({row})=>{
            return <EnigooButton icon={<FiEdit/>} link={"groups/detail/"+row.original.id}/>
            }
        }
    ],[i18n.language])

    const tableData = React.useMemo(()=>items.items,[items]);
    return <EnigooTablev2 paginated={true} selectable={false} data={tableData} columns={columns}/>
}

export default GroupsTable;
