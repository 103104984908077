import {useTranslation} from "react-i18next";
import EnigooMultiLanguageField from "../../../../MyComponent/EnigooMultiLanguageField/EnigooMultiLanguageField";
import React from "react";

const TabTextsOnline = () => {
    const {t} = useTranslation()
    return (
        <>
            <EnigooMultiLanguageField type={"html"} name={"textOnline"} label={t("action.alwaysVisibleText") + ":"}
                                      toolTip={t("action.alwaysVisibleTextToolTip")}/>
            <EnigooMultiLanguageField type={"html"} name={"textAfterAdd"}
                                      label={t("action.textAfterAdd") + ":"} toolTip={t("action.textAfterAddToolTip")}/>
        </>
    )
}
export default TabTextsOnline;
