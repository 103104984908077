import React from 'react';
import PropTypes from "prop-types";

const EnigooTableMultiValue = ({
                                   values,
                                    paramName
                               }) => {
    if (values) {
        return <div className={"grid lg:grid-cols-4 grid-cols-2 gap-1"}>{values.map((item, index) => (
            <div className={`py-1 bg-enigoo-100 text-enigoo-900 text-center font-bold rounded border-enigoo-900`}>
                {paramName?item[paramName]:item}
            </div>
        ))}</div>
    }else{
        return<></>
    }

}

EnigooTableMultiValue.propTypes = {
    values: PropTypes.array,
    paramName: PropTypes.string
}
export default EnigooTableMultiValue;
