import React, {useEffect, useState} from 'react'
import '../css/tailwind.css'
import '../css/main.css'
import '../css/animate.css'
import '../css/_components.css'
import {useParams} from "react-router";
import {EnigooLoader} from "../MyComponent/EnigooLoader";
import {apiPreviewGetUser} from "./Actions";
import {
    FiArrowDown,
    FiArrowUp,
    FiChevronsDown, FiChevronsUp,
    FiCreditCard,
    FiInfo,
    GiKnifeFork,
    RiParkingBoxLine
} from "react-icons/all";
import Widget from "../components/widget";
import {shallowEqual, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import EnigooLanguageDropDown from "../MyComponent/EnigooMultiLanguageField/EnigooLanguageDropDown";
import PreviewMeal from "./PreviewMeal";
import PreviewParking from "./PreviewParking";
import {FiChevronUp} from "react-icons/fi";


const PreviewApp = () => {

    let [loading, setLoading] = useState(false)
    let [data, setData] = useState(null);
    let [errorMessage, setErrorMessage] = useState(null);
    const {t, i18n} = useTranslation();
    const {subjectKey} = useParams();
    const {bc} = useParams();

    const {config, palettes} = useSelector(
        (state) => ({
            config: state.reducerAdmin.config,
            palettes: state.reducerAdmin.palettes
        }),
        shallowEqual
    )
    const {layout, collapsed} = {...config}
    let {background, navbar, logo, leftSidebar, rightSidebar} = {
        ...palettes
    }

    useEffect(() => {
        if (subjectKey && bc) {
            setLoading(true);
            apiPreviewGetUser(subjectKey, bc, (data) => {
                if(!data.multiLanguage){
                    i18n.changeLanguage("cs");
                }
                setData(data);
                setLoading(false);
            }, (error) => {
                setData(null);
                setLoading(false);
                setErrorMessage(t("basics.pageNotFound"));
            })
        } else {
            setErrorMessage(t("basics.pageNotFound"));
        }
    }, [])

    const getTitle = (type) => {
        if(type==="main"){
            if(!data){
                return t("basics.accreditationSystem");
            }
            if(data.previewData["title"] && data.previewData["title"].cs){
                if(data.previewData["title"][i18n.language]){
                    return data.previewData["title"][i18n.language];
                }else{
                    return data.previewData["title"].cs;
                }
            }else{
                return t("basics.accreditationSystem")
            }
        }
        if (data.previewData[type] && data.previewData[type].title && data.previewData[type].title.cs) {
            if (data.previewData[type].title[i18n.language]) {
                return data.previewData[type].title[i18n.language];
            } else {
                return data.previewData[type].title.cs;
            }
        } else {
            switch (type) {
                case "custom":
                    return t("action.information");
                case "credit":
                    return t("action.credit");
                case "meal":
                    return t("mealExtension.single");
                case "parking":
                    return t("parkingExtension.single");
                default:
                    return "";
            }
        }
    }

    const _renderContent = (type) => {
        if (data.previewData[type] && data.previewData[type].html?.cs) {
            if (data.previewData[type].html[i18n.language]) {
                return <div className={""}
                            dangerouslySetInnerHTML={{__html: data.previewData[type].html[i18n.language]}}/>;
            } else {
                return <div className={""} dangerouslySetInnerHTML={{__html: data.previewData[type].html.cs}}/>;
            }
        } else {
            return <></>;
        }
    }

    const InvisibleContent = ({isVisible, setIsVisible}) => {

        return <div className={"text-xl rounded-full p-2 border border-enigoo-500 -mt-12 hover:bg-enigoo-500 hover:text-white"} onClick={() => setIsVisible(!isVisible)}>{isVisible ? <FiChevronsUp/> :
            <FiChevronsDown/>}</div>

    }

    const CreditData = () => {
        let [isVisible, setIsVisible] = useState(false)
        if (data.isCredit && data.previewData.credit && data.previewData.credit.show) {
            return (
                <div className={"text-enigoo-500"}>

                    <Widget description={getTitle("credit").toUpperCase()} right={<FiCreditCard size={25}/>} onClick={()=>setIsVisible(!isVisible)}>
                        <div className={"flex flex-col items-center"}>
                            <InvisibleContent isVisible={isVisible}
                                              setIsVisible={setIsVisible}/>
                        </div>
                        {isVisible &&
                            <div className={"text-xl text-grey-900 mt-2"}>
                                <div>{t("preview.actualStateOfCredit")}:<br/>
                                    <div
                                        className={"font-bold text-blue-800"}>{!data.unlimited ? data.credit === null ? "0 Kč" : data.credit + " Kč" : t("preview.unlimited")}</div>
                                </div>
                                {_renderContent("credit")}
                            </div>
                        }
                    </Widget>
                </div>
            )
        } else {
            return <></>
        }

    }

    const CustomData = () => {
        let [isVisible, setIsVisible] = useState(false)
        if (data.previewData.custom && data.previewData.custom.show) {
            return (
                <div className={"text-enigoo-500"}>
                    <Widget description={getTitle("custom").toUpperCase()} right={<FiInfo size={25}/>}>
                        <div className={"flex flex-col items-center"}>
                            <InvisibleContent isVisible={isVisible}
                                              setIsVisible={setIsVisible}/>
                        </div>
                        {isVisible &&
                            <div className={"text-xl text-grey-900 mt-2"}>
                                {_renderContent("custom")}
                            </div>
                        }
                    </Widget>
                </div>
            )
        } else {
            return <></>
        }
    }

    const MealData = () => {
        let [isVisible, setIsVisible] = useState(false);
        if (data.previewData.meal && data.previewData.meal.show) {
            return (
                <div className={"text-enigoo-500"}>
                    <Widget description={getTitle("meal").toUpperCase()} right={<GiKnifeFork size={25}/>}>
                        <div className={"flex flex-col items-center"}>
                            <InvisibleContent isVisible={isVisible}
                                              setIsVisible={setIsVisible}/>
                        </div>
                        {isVisible && <div className={"text-xl text-grey-900 grid grid-cols-1 mt-2"}>
                            {_renderContent("meal")}
                            <PreviewMeal allowSelect={data.allowMealSelect} userMeal={data.userMeal}
                                         mealItems={data.mealItems} userDays={data.userDays}/>
                        </div>}
                    </Widget>
                </div>
            )
        } else {
            return <></>
        }
    }

    const ParkingData = () => {
        let [isVisible, setIsVisible] = useState(false);
        if (data.previewData.parking && data.previewData.parking.show) {
            return (
                <div className={"text-enigoo-500"}>
                    <Widget description={getTitle("parking").toUpperCase()} right={<RiParkingBoxLine size={25}/>}>
                        <div className={"flex flex-col items-center"}>
                            <InvisibleContent isVisible={isVisible}
                                              setIsVisible={setIsVisible}/>
                        </div>
                        {isVisible && <div className={"text-xl text-grey-900 grid grid-cols-1 mt-2"}>
                            {_renderContent("parking")}
                            <PreviewParking allowSelect={data.allowParkingSelect} userParking={data.userParking}
                                            parkingItems={data.parkingItems} userDays={data.userDays}/>
                        </div>}
                    </Widget>
                </div>
            )
        } else {
            return <></>
        }
    }

    return (
        <>
            <div
                data-layout={layout}
                data-background={background}
                className={"default-mode"}>
                {data && data.multiLanguage && <div className={"flex flex-row bg-grey-50"}>
                    <span className="ml-auto"></span>
                    <div className={"relative mr-2 mt-2"}><EnigooLanguageDropDown/></div>
                </div>}
                <div className={"flex flex-col min-h-screen w-full"}>


                    <div className={"bg-grey-50 text-grey-900 flex place-content-center flex-grow"}>

                        <div className={"text-center flex flex-col lg:w-1/2 w-11/12 items-center pt-6"}>


                            <div
                                className={"lg:text-4xl text-2xl text-enigoo-500 font-bold flex flex-row content-center items-center uppercase"}>
                                {getTitle("main")}
                            </div>

                            {loading && <EnigooLoader text={t("basics.loadingData")}/>}
                            {!loading && errorMessage && <div className={"text-red-800 text-xl mt-5"}>
                                {errorMessage}
                            </div>}
                            {!loading && data &&
                                <>
                                {data?.previewData?.image?.data && <div style={{
                                    backgroundImage: `url(${data.previewData.image.data})`,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "center"
                                }} className={`max-w-8xl w-full ${data?.previewData?.image?.data ? 'h-40' : ''} mb-5`}></div>}
                                <div className={"grid grid-cols-1 gap-2 w-full"}>
                                <CustomData/>
                                <CreditData/>
                                <MealData/>
                                <ParkingData/>
                            </div>
                                </>}
                        </div>
                    </div>
                    <footer
                        className={"sticky bottom-0 flex w-full place-content-center bg-grey-200 lg:px-20 px-5 lg:py-5 py-2 text-lg"}>
                        <div
                            className={"flex lg:w-1/2 lg:flex-row flex-col w-full items-center lg:justify-between justify-center"}>
                            <span className={"uppercase"}>{t("online.info.helpLine")}: <a className={"text-blue-700"}
                                                                                          href={"tel:+420461049232"}>+420 461 049 232</a></span>
                            <span>Powered by: <a href={"https://www.enigoo.cz"} target={"_blank"}
                                                 className={"text-enigoo-500 font-bold"}>ENIGOO</a></span>
                            <span><a className={"text-blue-700"}
                                     href={"mailto:info@enigoo.cz"}>info@enigoo.cz</a></span>
                        </div>
                    </footer>
                </div>
            </div>
        </>
    );
}

export default PreviewApp
