import {axios} from "../../../axiosConfig";

export function apiGetCustomField(callback, error){
    axios.get("api/admin/v1/custom-field")
        .then(response =>
            callback(response.data.data))
        .catch(err=>error(err));
}

export function apiGetCustomFieldById(id,callback, error){
    axios.get("api/admin/v1/custom-field/"+id)
        .then(response=>callback(response.data.data))
        .catch(err=>error(err));
}

export function apiCreateCustomField(data,callback, error){
    axios.post("api/admin/v1/custom-field",data)
        .then(response => callback(response.data.data))
        .catch(err => error(err));
}

export function apiUpdateCustomField(id,data,callback, error){
    axios.put("api/admin/v1/custom-field/"+id,data)
        .then(response => callback(response.data.data))
        .catch(err => error(err));
}

export function apiGetCustomFieldType(callback,error){
    axios.get("api/admin/v1/custom-field-type")
        .then(response => callback(response.data.data))
        .catch(err=>error(err));
}
