import {shallowEqual, useSelector} from "react-redux";
import React from 'react'
import Navbar from "./navbar";
import SideBarManage from "../../components/left-sidebar-1/SideBarManage";

const LayoutManage = ({children}) => {
    const {config,palettes} = useSelector(
        (state) => ({
            config: state.reducerAdmin.config,
            palettes: state.reducerAdmin.palettes
        }),
        shallowEqual
    )
    const {layout, collapsed} = {...config}
    let {background, navbar, logo, leftSidebar, rightSidebar} = {
        ...palettes
    }

    return (
        <div
            data-layout={layout}
            data-collapsed={collapsed}
            data-background={background}
            data-navbar={navbar}
            data-logo={logo}
            data-left-sidebar={leftSidebar}
            data-right-sidebar={rightSidebar}
            className={`${background === 'dark' ? 'dark-mode' : 'default-mode'}`}>
            <div className="wrapper">
                <SideBarManage/>
                <div className="main w-full bg-grey-50 text-grey-900 dark:bg-grey-900 dark:text-white">
                    <Navbar />
                    <div className="min-h-screen p-4">{children}</div>
                </div>
            </div>
        </div>

    )
}

export default LayoutManage;
