import React, {useEffect, useState} from "react";
import {apiGetActionById} from "../../Action/Actions";
import {EnigooSelectMulti} from "../../../../MyComponent/EnigooSelectMulti";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";
import MealAssignModal from "../../ActionExtension/MealExtensionUsers/MealAssignModal";
import {useParams} from "react-router-dom";
import ParkingAssignModal from "../../ActionExtension/ParkingExtension/ParkingAssignModal";
import VerifyRights from "../../../VerifyRights";

const ActionDaysSelect = ({
                              actionId = null,
                              toolTip = null,
                              type = "form",
                              items = null,
                              name = "days",
                              label = null,
                              values = null,
                              onKeyDown,
                              inModal = false
                          }) => {
    let [action, setAction] = useState(null);

    const {t, i18n} = useTranslation();
    const {id} = useParams();
    const {bc} = useParams();

    useEffect(() => {
        _getAction(actionId);
    }, [actionId]);

    const haveExtension = (type) => {
        if (action?.extension) {
            return action.extension.some((item) => item.type === type);
        } else {
            return false;
        }

    }

    const getLabel = (item) => {
        return item.name[i18n.language] ? item.name[i18n.language] : item.name["cs"];
    }
    const _getAction = (id) => {
        if (actionId) {
            apiGetActionById(id, (data) => {
                setAction(data);
            }, (err) => {
                setAction(null);
            })
        }
    }

    let daysItems = React.useMemo(() => {
        let options = [];
        if (!action && items) {
            items.forEach((item) => {
                options.push({
                    label: getLabel(item),
                    value: item.id
                })
            })
        } else if (action && action.days && action.days.length > 0) {
            action.days.forEach((item) => {
                options.push({
                    label: getLabel(item),
                    value: item.id
                })
            })
        }
        if (type === "filter") {
            let array = [];
            array.push({
                label: t("action.chooseDays"),
                options: options
            })
            array.push({
                label: t("action.withoutDays"),
                options: [{label: t("action.withoutDays"), value: null}]
            })
            return array;
        } else {
            return options;
        }

    }, [action, i18n.language, items])


    const getLabelForExtSelect = (extType) => {
        switch (extType) {
            case 1:
                return t("mealExtension.single")
            case 2:
                return t("parkingExtension.single")
            default:
                return "";

        }
    }
    const _renderExtensionSelect = (extType) => {
        if ((!action || !action.multiDay) && haveExtension(extType) && id) {
            return <VerifyRights extension={[extType]} page={[]} action={[]}>
                <div className={"flex flex-col space-y-2 mb-2"}>
                    <div className={"form-label"}>{getLabelForExtSelect(extType)}:</div>
                    {extType === 1 && <MealAssignModal days={[0]} actionId={actionId} usersIds={[id]}/>}
                    {extType === 2 && <ParkingAssignModal days={[0]} actionId={actionId} usersIds={[id]}/>}

                </div>
            </VerifyRights>
        } else if ((haveExtension(extType) && values && id && type === "form") && id) {
            return <VerifyRights extension={[extType]} page={[]} action={[]}>
                <div className={"flex flex-col space-y-2"}>
                    <div className={"form-label"}>{getLabelForExtSelect(extType)}:</div>
                    {extType === 1 && <MealAssignModal days={values[name]} actionId={actionId} usersIds={[id]}/>}
                    {extType === 2 && <ParkingAssignModal days={values[name]} actionId={actionId} usersIds={[id]}/>}
                </div>
            </VerifyRights>
        } else {
            return <></>
        }
    }

    const _renderParkingSelect = () => {

    }

    const _renderDaySelect = () => {
        if (action && action.multiDay) {
            return <EnigooSelectMulti inModal={inModal} toolTip={toolTip} label={!label ? t("action.days") + ": " : label} name={name}
                                      items={daysItems} toolTipPosition={"right"}
                                      grouped={type === "filter"} defaultValue={["value", "label"]}
                                      withSelectAll={type !== "filter"} onKeyDown={onKeyDown}/>
        } else {
            return <></>
        }
    }


    if ((!action || !action.extension) && !items) {
        return <></>
    } else {
        return (
            <div className={`grid ${type !== "filter" ? "grid-cols-3 gap-2" : ""}`}>
                {_renderDaySelect()}
                {_renderExtensionSelect(1)}
                {_renderExtensionSelect(2)}
            </div>
        )
    }

}
ActionDaysSelect.propTypes = {
    actionId: PropTypes.number,
    toolTip: PropTypes.string,
    items: PropTypes.array,
    name: PropTypes.string,
    values: PropTypes.any
}
export default ActionDaysSelect;
