import React from 'react'
import {shallowEqual, useSelector} from 'react-redux'
import {useTranslation} from "react-i18next";

const Footer = () => {
  const {config} = useSelector(
    (state) => ({
      config: state.reducerAdmin.config
    }),
    shallowEqual
  )
  let {name} = {...config}
    const {t} = useTranslation()
  return (
    <div className="flex flex-row items-center justify-between w-full text-xs z-10">
      <div className="text-white">&copy; ENIGOO {new Date().getFullYear()}</div>
      <div className="flex flex-row ml-auto space-x-2">
          {/*TODO: Zatím schované, protože není v provozu*/}
        {/*<Link to="/privacy-policy">Ochrana osobních údajů</Link>
        <Link to="/terms-of-service">Podmínky používání</Link>*/}
        <a href={"https://enigoo.cz/kontakt/"} target={"_blank"} className={"underline"}>{t("login.contactUs")}</a>
      </div>
    </div>
  )
}

export default Footer
