import React, {useEffect, useState} from "react";
import EnigooBreadcrumbs from "../../../MyComponent/EnigooBreadcrumbs";
import EnigooButton from "../../../MyComponent/EnigooButton";
import {FiInfo, FiSave} from "react-icons/all";
import Widget from "../../../components/widget";
import {EnigooLoader} from "../../../MyComponent/EnigooLoader";
import {Form} from "react-final-form";
import {IconTabs} from "../../../components/tabs";
import {useParams} from "react-router";
import {apiCreateDeviceTemplate, apiGetDeviceTemplateById, apiUpdateDeviceTemplate} from "./Actions";
import CogoToast from "cogo-toast";
import {useHistory} from "react-router-dom";
import {EnigooTextField} from "../../../MyComponent/EnigooTextField";
import {EnigooSelect} from "../../../MyComponent/EnigooSelect";
import VerifyRights from "../../VerifyRights";
import DeviceTemplateRules from "./DeviceTemplateRules";
import arrayMutators from "final-form-arrays";
import {useTranslation} from "react-i18next";
import {EnigooSwitch} from "../../../MyComponent/EnigooSwitch";
import {ActionSelectField} from "../../../MyComponent/Api/Admin/ActionSelectField";
import {ZoneSelectField} from "../../../MyComponent/Api/Admin/ZoneSelectField";
import EnigooNote from "../../../MyComponent/EnigooNote";
import EnigooFormChangedAlert from "../../../MyComponent/EnigooFormChangedAlert";

const DeviceTemplateForm = () => {
    let [loading, setLoading] = useState(false);
    let [saving, setSaving] = useState(false);
    let [data, setData] = useState(null);
    let [action, setAction] = useState(null);


    let submit = null;
    let formValues = null;

    const {id} = useParams();
    const history = useHistory();
    const {t} = useTranslation()

    useEffect(() => {
        init();
    }, [])

    useEffect(() => {
        const handleKeyDown = (event) => {
            if ((event.ctrlKey || event.metaKey) && event.key === 's') {
                event.preventDefault();
                submit();
                console.log('Ctrl/Command + S was pressed');
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    const init = () => {
        if (id) {
            setLoading(true);
            apiGetDeviceTemplateById(id, (data) => {
                let initData = data;
                initData.mode = data.data ? data.data.mode : null;
                initData.rules = data.data ? data.data.rules : null;
                initData.globalRule = data.data ? data.data.globalRule : null;
                if (data.data && data.data.globalRule) {
                    initData.globalRule = data.data.globalRule;
                    initData.globalRuleEnabled = true;
                } else {
                    initData.globalRule = null;
                    initData.globalRuleEnabled = false;
                }
                setData(initData);
                setLoading(false);
            }, (error) => {
                CogoToast.error(t("deviceTemplate.loadingDataError"));
                setLoading(false);
                history.push("/device/rules");
            })
        }
    }

    const onSubmitSuccess = (data, values) => {
        setData(values)
        CogoToast.success(t("deviceTemplate.saved"))
        setSaving(false);
        history.push("/device/rules/detail/" + data.id);
    }

    const onSubmitError = (err) => {
        CogoToast.error(t("deviceTemplate.saveError"));
        setSaving(false);
    }

    const onSubmit = (values) => {
        let globalRule = null;
        if (values.mode === 3) {
            globalRule = {info: values.globalRule.info, daily: false, level: 0, entrance: 0};
        } else if (values.mode === 1 && values.globalRuleEnabled) {
            globalRule = values.globalRule;
        }
        values.data = {mode: values.mode, rules: values.rules, globalRule: globalRule};
        if (id) {
            setSaving(true);
            apiUpdateDeviceTemplate(id, values, (data)=>onSubmitSuccess(data,values), onSubmitError)
        } else {
            setSaving(true);
            apiCreateDeviceTemplate(values, (data)=>onSubmitSuccess(data,values), onSubmitError)
        }
    }

    const TabBasicsInfo = ({doPush, values}) => (
        <div className={"w-full"}>
            <EnigooTextField label={t("basics.name") + ":"} name={"name"} placeHolder={t("placeHolder.name")}/>
            <EnigooNote>
                <div className={"flex flex-col space-y-2"}>
                    <div><span className={"text-enigoo-500 font-bold"}>{t("deviceTemplate.plural")}: </span></div>
                    <div className={"flex flex-col space-y-2"}
                         dangerouslySetInnerHTML={{__html: t("deviceTemplate.noteForTemplate")}}></div>
                    <div><span className={"text-enigoo-500 font-bold"}>{t("deviceTemplate.mode")}: </span></div>
                    <div className={"flex flex-col space-y-2"}
                         dangerouslySetInnerHTML={{__html: t("deviceTemplate.modeNote")}}></div>
                </div>
            </EnigooNote>
            <EnigooSelect placeHolder={t("placeHolder.select")} label={t("deviceTemplate.mode") + ": *"} name={"mode"}
                          items={[{value: 1, label: t("deviceTemplate.entry")}, {
                              value: 2,
                              label: t("deviceTemplate.credit")
                          }, {
                              value: 3,
                              label: t("deviceTemplate.zoneCheck")
                          },
                              {
                                  value: 4,
                                  label: t("deviceTemplate.food")
                              },
                              {
                                  value: 5,
                                  label: t("deviceTemplate.parking")
                              }
                          ]} defaultValue={["value", "label"]}/>
            <EnigooNote>
                <div className={"flex flex-col space-y-2"}>
                    <div><span className={"text-enigoo-500 font-bold"}>{t("action.single")}: </span></div>
                    <div className={"flex flex-col space-y-2"}
                         dangerouslySetInnerHTML={{__html: t("deviceTemplate.actionNote")}}></div>
                </div>
            </EnigooNote>
            <ActionSelectField isMulti={false} name={"actionId"} label={t("action.single") + ": "}
                               onChange={(event) => {
                                   setAction(event?.value);
                               }}/>
            {values.mode === 3 && values.actionId &&
                <ZoneSelectField name={"zone"} label={t("zones.plural") + ": "} selectedAction={values.actionId}/>}
            {values.mode === 1 && <>
                <EnigooNote>
                    <div className={"flex flex-col space-y-2"}>
                        <div><span className={"text-enigoo-500 font-bold"}>{t("device.rules")}: </span></div>
                        <div className={"flex flex-col space-y-2"}
                             dangerouslySetInnerHTML={{__html: t("deviceTemplate.rulesNote")}}></div>
                    </div>
                </EnigooNote>
                <EnigooSwitch name={"globalRuleEnabled"} label={"Využít obecné pravidlo: "}/>
                {values.globalRuleEnabled && <div className={"form-element border rounded p-2"}>
                    <div className={"form-label"}>{t("deviceTemplate.globalRule")}:</div>
                    <div className={"grid grid-cols-4 gap-2"}>
                        <EnigooTextField name={`globalRule.entrances`} label={t("deviceTemplate.numOfEntrance") + ": "}
                                         type={"number"}
                                         placeHolder={t("placeHolder.numOfEntrance")}/>
                        <EnigooSwitch name={`globalRule.daily`} label={t("deviceTemplate.daily") + ": "}/>
                        <EnigooTextField name={`globalRule.levelId`} label={t("deviceTemplate.level") + ": "}
                                         toolTip={t("deviceTemplate.levelToolTip")} type={"number"}
                                         placeHolder={t("placeHolder.level")}/>
                        <EnigooSwitch name={`globalRule.info`} label={t("deviceTemplate.accreditationInfo") + ": "}/>
                    </div>
                </div>}
                <DeviceTemplateRules doPush={doPush} values={values}/></>}
            {values.mode === 3 &&
                <EnigooSwitch name={`globalRule.info`} label={t("deviceTemplate.accreditationInfo") + ": "}/>}
        </div>
    )

    return (
        <Form onSubmit={onSubmit} initialValues={data}
              validate={values => {
                  let errors = {};
                  if (!values.mode) {
                      errors.mode = t("basics.requireField")
                  }
                  if (values.mode === 1 && values.rules) {
                      errors.rules = [];
                      values.rules.forEach((item, index) => {
                          errors.rules[index] = {};
                          if (!item.groupId) {
                              errors.rules[index].groupId = t("basics.requireField");
                          }
                      })
                  }
                  return errors;
              }
              }
              mutators={{...arrayMutators}}
              render={({
                           values,
                           handleSubmit,
                           form,
                           dirty,
                           form: {
                               mutators: {push, pop, unshift}
                           }
                       }) => {
                  formValues = values;
                  submit = handleSubmit;
                  return (
                      <>
                          <EnigooBreadcrumbs
                              right={<VerifyRights page={"deviceTemplate"}
                                                   action={id ? "updateDeviceTemplate" : "createDeviceTemplate"}>
                                  <EnigooButton onClick={() => submit()} icon={<FiSave/>} text={t("basics.save")}
                                                loading={saving} disabled={saving || loading}/></VerifyRights>}/>
                          <EnigooFormChangedAlert onReset={() => form.reset(data)} onSave={handleSubmit}
                                                  dirty={dirty}/>
                          <Widget>
                              {loading ? <EnigooLoader text={t("deviceTemplate.loadingData")}/> :
                                  <div className={"flex flex-col"}>

                                      <IconTabs tabs={[
                                          {
                                              index: 0,
                                              content: <TabBasicsInfo doPush={push} values={values}/>,
                                              title: (<><FiInfo size={18} className={"stroke-current"}/><span
                                                  className={"ml-2"}>{t("basics.info")}</span> </>)
                                          }
                                      ]}/>

                                  </div>}
                          </Widget>
                      </>
                  );
              }
              }/>
    )

}
export default DeviceTemplateForm;
