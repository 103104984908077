import React, {useEffect, useState} from "react";
import {apiGetDeviceKey} from "./Actions";
import CogoToast from "cogo-toast";
import {EnigooLoader} from "../../../MyComponent/EnigooLoader";
import EnigooBreadcrumbs from "../../../MyComponent/EnigooBreadcrumbs";
import Widget from "../../../components/widget";
import {useTranslation} from "react-i18next";

const DeviceSettings = ()=>{
    let [loading,setLoading] = useState(false);
    let[data,setData] = useState(null);
    const {t} = useTranslation()

    useEffect(()=>{
        getDeviceKey();
    },[]);

    const getDeviceKey=()=>{
        setLoading(true);
        apiGetDeviceKey((data)=>{
            setData(data);
            setLoading(false);
        },(err)=>{
            CogoToast.error("Nepodařilo se načíst kód zařízení...");
            setLoading(false);
        })
    }

    const _renderBody = ()=>{
        if(loading) return <Widget><EnigooLoader text={t("device.setting.loading")}/></Widget>
        if(!data) return <Widget><>{t("device.setting.loadingError")}</></Widget>
        if(data) return (
            <div className={"lg:flex lg:flex-row"}>

                <div className={"lg:w-1/4 w-full"}>
                    <Widget><span className={"text-xl"}>{t("device.setting.setDevice")}:</span><img width={"60%"} src={data.bc}/></Widget>
                </div>
                <div className={"lg:w-2/4 w-full ml-2"}>
                    <Widget></Widget>
                </div>
                <div className={"lg:w-1/4 w-full ml-2"}>
                    <Widget><span className={"text-xl"}>{t("device.setting.clearDevice")}:</span><img width={"60%"} src={data.bcClear}/></Widget>
                </div>
            </div>
        )
    }

    return (
        <>
            <>
                <EnigooBreadcrumbs/>
                {_renderBody()}
            </>
        </>
    );
}
export default DeviceSettings;
