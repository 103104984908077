import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import PropTypes from "prop-types";
import EnigooRadioField from "./EnigooRadioField";

const YesNoRadioField = ({disabled=false,name,label,columns=2}) =>{
    const {t,i18n} = useTranslation();

    let [radioFieldData,setRadioFieldData] = useState([]);

    useEffect(() => {
        setRadioFieldData([
            {
                value:true,
                name:t("basics.yes"),
                label:null,
                active:true
            },
            {
                value:false,
                name:t("basics.no"),
                label:null,
                active:true
            }
        ])
    }, [i18n.language]);

    return <EnigooRadioField items={radioFieldData} name={name} label={label} disabled={disabled} columns={columns} defaultValues={["value","name","label","active"]}/>
}
YesNoRadioField.propTypes = {
    disabled:PropTypes.bool,
    name: PropTypes.string.isRequired,
    label: PropTypes.string
}
export default YesNoRadioField;
