import axiosLib from "axios";
import CogoToast from "cogo-toast";

export const axios = axiosLib.create({
    baseURL: process.env.NODE_ENV === "development" ? "http://ak.local.cz/" : "/",
    headers: {
        'Content-Type': 'application/json',
    },
});


axios.interceptors.response.use(undefined, function (error) {
    if (error.response.status === 401) {
        localStorage.removeItem("ath-acc-en");
        localStorage.removeItem("ath-acc-en-mn");
        localStorage.removeItem("ath-acc-en-on");
        CogoToast.warn("Nejste autorizovaný. Prosím přihlašte se znovu.")
        window.location.reload()
    }
    if (error.response.status === 404) {
        CogoToast.warn("Neznámá stránka.")
        window.location.replace('/');
    }
    return Promise.reject(error);
})
