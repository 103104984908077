// store.js
import {combineReducers, configureStore} from "@reduxjs/toolkit";
import rootReducer from "./reducers";
import {persistReducer} from "redux-persist";
import storage from "redux-persist/lib/storage";
import {FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE} from "redux-persist/es/constants";
import onlineConfig from "./reducers/onlineConfig";

const persistConfig = {
    key: "root",
    version: 1,
    storage,
    whitelist: ['config','leftSidebar','palettes','actualAction','filterUser','adminRights','image','checkUserCam']
    // Další nastavení
};
const mainReducer = combineReducers(
    {
        reducerAdmin: persistReducer(persistConfig, rootReducer),
        reducerOnline: combineReducers({onlineConfig})
    }
)

const store = configureStore({
    reducer: mainReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        })
    // Další konfigurace store
});

export default store;
