import React from 'react';
import PropTypes from "prop-types";
import ReactTooltip from 'react-tooltip'
import ReactDOM from "react-dom";

const EnigooToolTip = ({
                           content,
                           id,
                            place="right"

                       }) => {

    return ReactDOM.createPortal(<>
        <ReactTooltip id={id} effect={"solid"} place={place} class={"enigoo-tooltip"}>
            <div
                dangerouslySetInnerHTML={{__html: content}}/>
        </ReactTooltip>
    </>, document.getElementById("portal"));



}
EnigooToolTip.propTypes = {
    content: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    place: PropTypes.string,
}
export default EnigooToolTip;
