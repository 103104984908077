import {axios} from "../../../axiosConfig";

const url = "api/admin/v1/device";

export function apiGetDevice(callback,error){
    axios.get(url)
        .then(response=>callback(response.data.data))
        .catch(err=>error(err));
}

export function apiGetDeviceById(id,callback,error){
    axios.get(url+"/"+id)
        .then(response=>callback(response.data.data))
        .catch(err=>error(err));
}

export function apiUpdateDevice(id,data,callback,error){
    axios.put(url+"/"+id,data)
        .then(response=>callback(response.data.data))
        .catch(err=>error(err));
}

export function apiGetDeviceKey(callback,error){
    axios.get(url+"/key")
        .then(response=>callback(response.data.data))
        .catch(err=>error(err));
}
