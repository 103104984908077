import {useTranslation} from "react-i18next";
import GroupsSelectField from "../../../../MyComponent/Api/Admin/GroupsSelectField";
import {EnigooSwitch} from "../../../../MyComponent/EnigooSwitch";
import React from "react";
import PropTypes from "prop-types";

const TabZone = ({zones, onSelectChange}) => {
    const {t} = useTranslation()
    return (
        <>
            <div className={"flex flex-row"}>
                <GroupsSelectField label={t("groups.single") + ": "} name={"group"} isMulti={false}
                                   outsideClassName={"w-full"}
                                   onChange={onSelectChange}/>
            </div>
            <div className={"grid lg:grid-cols-6 grid-cols-3"}>
                {zones && zones.map((item, index) => (
                    <EnigooSwitch name={`z-${item.id}`} label={item.name + ":"}/>
                ))}
            </div>
        </>
    )
}
TabZone.propTypes={
    zones:PropTypes.array,
    onSelectChange:PropTypes.func
}
export default TabZone;
