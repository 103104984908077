import React, {useEffect, useState} from "react";
import Widget from "../../../components/widget";
import EnigooButton from "../../../MyComponent/EnigooButton";
import {FiEdit, FiPlus} from 'react-icons/fi'
import {apiGetUserAdmin} from "./Actions";
import {EnigooLoader} from "../../../MyComponent/EnigooLoader";
import EnigooBreadcrumbs from "../../../MyComponent/EnigooBreadcrumbs";
import VerifyRights from "../../VerifyRights";
import {useTranslation} from "react-i18next";
import EnigooTablev2 from "../../../MyComponent/EnigooTablev2";
import EnigooActiveField from "../../../MyComponent/EnigooActiveField";

const AdminUser = () => {
    let [data, setData] = useState([])
    let [loading, setLoading] = useState(false)
    let [errorMessage, setErrorMessage] = useState(null);

    const {t,i18n} = useTranslation()

    useEffect(() => {
        getAdmin()
    }, []);

    const getAdmin = () => {
        setLoading(true)
        apiGetUserAdmin((data) => {
            setData(data);
            setLoading(false);
            setErrorMessage(null);
        }, error => {
            setLoading(false);
            setErrorMessage(t("adminUser.loadingError"))
        })
    }

    const columns = React.useMemo(() => [
        {header: t('adminUser.login'), id: "login", accessorKey: 'login'},
        {header: t('basics.firstname') + " " + t("basics.lastname"), id: "name", accessorKey: 'name'},
        {header: t('adminUser.lastAction'), id: "lastAction", accessorKey: 'lastAction'},
        {
            header: t('adminUser.active'), id: "active",
            cell: ({row}) => {
                return <EnigooActiveField value={row.original.active}/>
            }
        },
        {
            header: t('basics.options'), id: "options",
            cell: ({row}) => {
                return <EnigooButton icon={<FiEdit/>} link={"admin-user/detail/" + row.original.id}/>
            }
        }
    ], [i18n.language]);

    const tableData = React.useMemo(() => data, [data]);

    const _renderBody = () => {
        if (loading) return <EnigooLoader text={t("adminUser.loading")}/>
        if (errorMessage) return <div>{errorMessage}</div>
        if (!data) return null;
        if (data) return <EnigooTablev2 data={tableData} columns={columns} paginated={false} selectable={false}/>
    }

    return (
        <>
            <EnigooBreadcrumbs
                right={<VerifyRights page={"adminUser"} action={"createAdminUser"}> <EnigooButton
                    link={"/admin-user/create"} icon={<FiPlus/>} text={t("basics.add")}/></VerifyRights>}/>
            <Widget>
                {_renderBody()}
            </Widget>
        </>
    )
}

export default AdminUser;
