import React from "react";
import {usePagination, useRowSelect, useTable} from "react-table";
import {FiChevronsLeft, FiChevronsRight} from "react-icons/fi";
import {useTranslation} from "react-i18next";

/**
 * @deprecated Nová komponenta EnigooTablev2.
 */
const EnigooTable = ({columns, data, selectable, setSelectedRows = null}) => {


    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        selectedFlatRows,
        state: {pageIndex, pageSize, selectedRowIds},
    } = useTable({
        columns,
        data,
        initialState: {pageIndex: 0, hideOnSinglePage: true},
    }, usePagination, useRowSelect, hooks => {
        selectable &&
        hooks.visibleColumns.push(columns => [
            {
                id: 'selection',
                Header: ({getToggleAllRowsSelectedProps}) => (
                    <div>
                        <TableCheckBox {...getToggleAllRowsSelectedProps()} setSelectedRows={setSelectedRows}
                                       selectedFlatRows={selectedFlatRows}/>
                    </div>
                ),
                Cell: ({row}) => (
                    <div>
                        <TableCheckBox {...row.getToggleRowSelectedProps()} setSelectedRows={setSelectedRows}
                                       selectedFlatRows={selectedFlatRows}/>
                    </div>
                ),
                width: 2
            },
            ...columns
        ])
    });

    const {t} = useTranslation()

    const selectedRows = React.useMemo(() => {
        const array = [];
        if (setSelectedRows) {
            selectedFlatRows.map(d => array.push(d.original.id))
            setSelectedRows(array);
        }
        return array;
    }, [selectedFlatRows]);


    return (
        <>
        <div className={"overflow-x-auto "}>
            <table className={"table striped"} {...getTableProps()}>
                <thead className={"table-header-group"}>
                {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                            <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                        ))}
                    </tr>
                ))}
                </thead>
                <tbody {...getTableBodyProps()} className={""}>
                {data.length===0?<tr><td colSpan={columns.length}>{t("table.noData")}</td></tr>:
                page.map((row, i) => {
                    prepareRow(row)
                    return (
                        <tr {...row.getRowProps()} className={"table-row"}>
                            {row.cells.map(cell => {
                                return <td {...cell.getCellProps()}
                                           className={"table-cell "}>{cell.render('Cell')}</td>
                            })}
                        </tr>
                    )
                })}
                </tbody>
            </table>
        </div>
            {10 <= data.length ?
                <div className="flex flex-wrap items-center justify-center space-x-2 pagination mt-2">
                    <button className={'btn btn-circle bg-enigoo-500 hover:bg-enigoo-600 text-white'}
                            onClick={() => previousPage()} disabled={!canPreviousPage}>
                        <FiChevronsLeft/>
                    </button>
                    {' '}
                    <span className={"form-label"}>
                    {t("table.page")}{' '}
                        <strong>{pageIndex + 1} z {pageOptions.length}</strong>{' '}
                </span>
                    <button className={'btn btn-circle bg-enigoo-500 hover:bg-enigoo-600 text-white'}
                            onClick={() => nextPage()} disabled={!canNextPage}>
                        <FiChevronsRight/>
                    </button>
                    {' '}
                    <span className={"form-label"}>
          | {t("table.goToPage")}:{' '}
                        <input
                            className={"form-input text-sm"}
                            type="number"
                            defaultValue={pageIndex + 1}
                            onChange={e => {
                                const page = e.target.value ? Number(e.target.value) - 1 : 0
                                gotoPage(page)
                            }}
                            style={{width: '70px'}}
                        />
        </span>{' '}
                    <select
                        className={"form-select text-sm"}
                        value={pageSize}
                        onChange={e => {
                            setPageSize(Number(e.target.value))
                        }}
                    >
                        {[10, 20, 30, 40, 50].map(pageSize => (
                            <option key={pageSize} value={pageSize}>
                                {pageSize} {t("table.records")}
                            </option>
                        ))}
                    </select>
                </div> : <></>}
        </>
    )

}
const TableCheckBox = React.forwardRef(
    ({indeterminate, ...rest}, ref) => {
        const defaultRef = React.useRef()
        const resolvedRef = ref || defaultRef

        React.useEffect(() => {
            resolvedRef.current.indeterminate = indeterminate
        }, [resolvedRef, indeterminate])

        return (
            <>
                <input className={"form-check"} type="checkbox" ref={resolvedRef} {...rest} />
            </>
        )
    }
)


export default EnigooTable;
