import React, {useEffect, useState} from "react";
import Dropdown from "../../../components/dropdowns/dropdown-1";
import {apiGetAction} from "../../../Admin/pages/Action/Actions";
import {shallowEqual, useDispatch, useSelector} from "react-redux";

const EnigooActionDropDown = ({}) => {

    const {actualAction} = useSelector(
        state => (state.reducerAdmin.actualAction),
        shallowEqual
    )

    const dispatch = useDispatch();

    let [loading, setLoading] = useState(false);
    let [actions, setActions] = useState(null);

    let path = window.location.pathname;
    let disabled = path.substring(0,path.lastIndexOf("/"))==="/accreditation/detail" || path==="/accreditation/import";

    useEffect(() => {
        getActions();
    }, []);

    const getActions = () => {
        setLoading(true);
        apiGetAction((data) => {
            setActions(data);
            if(!actualAction) {
                dispatch({
                    type: "SET_ACTION",
                    actualAction: data[0].id,
                    customField:data[0].customField
                });
            }

            setLoading(false);
        }, (error) => {
            setLoading(false);
        })
    }

    const getActionName = () => {
        let name = null;
        if(actualAction && actions) {
            actions.map((item, index) => {
                if (item.id === actualAction) {
                    name = item.name;
                }
            })
        }
        return name;
    }

    const {palettes} = useSelector(
        (state) => ({
            palettes: state.reducerAdmin.palettes
        }),
        shallowEqual
    )

    const {background,leftSidebar,navbar} = palettes;

    let isDark = React.useMemo(()=>{
        return background==="dark" && leftSidebar==="dark" && navbar==="dark";
    },[background,leftSidebar,navbar])

    const getCustomField = (id) =>{
        let customFields = [];
        actions.map((item,index)=>{
            if(item.id === id){
                customFields = item.customField;
            }
        })
        return customFields;
    }

    if (loading) return <></>;
    if (!actions) return <></>;
    if (actions && actions.length === 0) return <></>;
    if (actions) return (
        <>
            <div className="flex flex-row">
                <div className={"min-w-xxs"}>
                    <Dropdown
                        disabled={disabled}
                        width="w-64"
                        placement={`bottom-end`}
                        title={getActionName()}
                        dropdownToggleClass={`w-full btn btn-default border btn-rounded ${isDark?"bg-grey-800 text-grey-50 border-grey-50":"bg-white text-enigoo-500 border-enigoo-500"} btn-icon hover:bg-enigoo-600 bg-enigoo-500 hover:text-white space-x-1 mt-1 mr`}>
                        <>
                            <div className="flex flex-col text-center">
                                {actions.map((item, i) => (
                                    <div
                                        key={i}
                                        className="items-center flex flex-wrap justify-center h-8 space-y-1 dropdown-item">
                                        <button disabled={disabled} onClick={() => {
                                            dispatch({
                                                type: 'CHANGE_PAGE_INDEX',
                                                pageIndex: 0,
                                            })
                                            dispatch({
                                                type: "CHANGE_ACTION",
                                                actualAction: item.id
                                            });
                                            dispatch({
                                                type: "CHANGE_CUSTOM_FIELD",
                                                customField: getCustomField(item.id)
                                            });

                                        }} className="btn text-xs w-full btn-rounded h-full hover:bg-enigoo-500 hover:text-white">{item.name}</button>
                                    </div>
                                ))}
                            </div>
                        </>
                    </Dropdown>
                </div>
            </div>
        </>
    );
    else return <></>;
}

export default EnigooActionDropDown;
