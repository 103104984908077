import React, {useEffect, useState} from 'react'
import {EnigooLoader} from "../MyComponent/EnigooLoader";
import {axios} from "../axiosConfig";
import {ManageLoginForm} from "./pages/Login/ManageLoginForm";
import {apiManageAuthorizeManageUser} from "./pages/Login/Actions";
import LayoutManage from "../layouts/manage";
import Routes from "./Routes";
import {useHistory} from "react-router-dom";

const AppManage = () => {
    let [loading,setLoading] = useState(true);
    let [token,setToken] = useState(null);

    let history = useHistory();

    useEffect(()=>{
        authorize();
    },[])

    const authorize=()=>{
        let token = localStorage.getItem("ath-acc-en-mn");
        if(!token){
            setLoading(false);
            history.push("/manage/login")
        }else{
            axios.defaults.headers.common['authorization-manage']=token;
            apiManageAuthorizeManageUser((data)=>{
                setToken(data.token);
                setLoading(false);
            },(error)=>{
                localStorage.removeItem('ath-acc-en-mn');
                setLoading(false);
            })
        }
    }

    if(loading) return <div className="min-h-screen w-full p-4"><EnigooLoader text={"Připravuji stránku..."}/></div>
    if(!token) return <LayoutManage><ManageLoginForm setToken={setToken}/></LayoutManage>;
    if(token) return<LayoutManage><Routes/></LayoutManage>;

}
export default AppManage;