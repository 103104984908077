import React from 'react'
import {shallowEqual, useSelector} from 'react-redux'
import {Link} from 'react-router-dom'
import {useTranslation} from "react-i18next";

const Logo = () => {
  const {config} = useSelector(
    (state) => ({
      config: state.reducerAdmin.config
    }),
    shallowEqual
  )
    const {t} = useTranslation()
  const {name} = {...config}
  return (
    <div className="uppercase font-bold text-base tracking-wider flex flex-row items-center justify-start w-full whitespace-no-wrap text-white">
      <Link
        to="/"
        className="flex flex-row items-center justify-start space-x-2">
          <img src='logo-E-white.png' alt={""} width={"30"}/>
        <span>{t("basics.accreditationSystem")}</span>
      </Link>
    </div>
  )
}

export default Logo
