import React from "react";
import PropTypes from "prop-types";
import {shallowEqual, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import MealChooseForm from "../../../MyComponent/MealChooseForm";
import ParkingChooseForm from "../../../Admin/pages/ActionExtension/ParkingExtension/ParkingChooseForm";

const OnlineExtensionSelect = ({type=1,values})=>{
    const {onlineConfig} = useSelector(
        (state) => ({
            onlineConfig: state.reducerOnline.onlineConfig
        }),
        shallowEqual
    )

    const {t, i18n} = useTranslation()

    let days = React.useMemo(() => {
        if (values && values.days && values.days.length>0) {
            return values.days;
        } else {
            return [0];
        }
    }, [values?.days])

    switch (type){
        case 1:
            if (onlineConfig.viewMealSelect && onlineConfig.mealSelectData && onlineConfig.mealSelectData.items) {
                return <div className={"form-element"}>
                    <div className={"form-label"}>{t("mealExtension.single")}:</div>
                    <MealChooseForm  name={"mealItems"} days={days} newForm={false}
                                     extensionItems={onlineConfig.mealSelectData.items} initData={null} tabs={false}/>
                </div>
            } else {
                return <></>
            }
            break;
        case 2:
            if (onlineConfig.viewParkingSelect && onlineConfig.parkingSelectData && onlineConfig.parkingSelectData.items) {
                return <div className={"form-element"}>
                    <div className={"form-label"}>{t("parkingExtension.single")}:</div>
                    <ParkingChooseForm  name={"parkingItems"} days={days} newForm={false}
                                     extensionItems={onlineConfig.parkingSelectData.items} initData={null} tabs={false} parentFormValues={values}/>
                </div>
            } else {
                return <></>
            }
            break;
        default:
            return <></>
    }


}
OnlineExtensionSelect.propTypes={
    type: PropTypes.number,
    values:PropTypes.object
}
export default OnlineExtensionSelect;
