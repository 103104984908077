import React from "react";
import EnigooButton from "../../../MyComponent/EnigooButton";
import {FiEdit} from "react-icons/fi";
import {useTranslation} from "react-i18next";
import EnigooTablev2 from "../../../MyComponent/EnigooTablev2";

const MailTemplateTable = ({items}) => {
    const {t, i18n} = useTranslation()
    const columns = React.useMemo(() => [
        {header: t('basics.name'), id: "name", accessorKey: 'name'},
        {
            header: t('basics.type'), id: "type",
            cell: ({row}) => {
                if (row.original.type === 1) return t("mailTemplate.mailAfterAdd");
                else if (row.original.type === 2) return t("mailTemplate.mailFromAdmin");
            }
        },
        {header: t('mailTemplate.fromWho'), id: "subjectFrom", accessorKey: 'subjectFrom'},
        {
            header: t('basics.options'), id: "options",
            cell: ({row}) => {
                return <EnigooButton icon={<FiEdit/>} link={"mail-template/detail/" + row.original.id}/>
            }
        }
    ], [i18n.language])
    const tableData = React.useMemo(() => items.items, [items]);
    return <EnigooTablev2 columns={columns} data={tableData} selectable={false} paginated={true}/>
}

export default MailTemplateTable;
