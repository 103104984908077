import React from "react";
import EnigooButton from "../../../../MyComponent/EnigooButton";
import {Trans, useTranslation} from "react-i18next";
import {FieldArray} from "react-final-form-arrays";
import {FiCopy, FiPlus} from "react-icons/fi";
import {EnigooTextField} from "../../../../MyComponent/EnigooTextField";
import EnigooMultiLanguageField from "../../../../MyComponent/EnigooMultiLanguageField/EnigooMultiLanguageField";
import ParkingOptionModal from "./ParkingOptionModal";
import {Field} from "react-final-form";
import GenerateNumberRowModal from "./GenerateNumberRowModal";
import CogoToast from "cogo-toast";

export const _validateParking = (data,values, t) => {
    let errors = {};
    if (values.extension.items) {
        errors.extension = {};
        errors.extension.items = [];


        values.extension.items.forEach((item1, index1) => {
            errors.extension.items[index1] = {};
            if (item1.items) {
                errors.extension.items[index1].items = [];
                item1.items.forEach((item2, index2) => {
                    errors.extension.items[index1].items[index2] = {};
                    if (!item2.label?.cs) {
                        errors.extension.items[index1].items[index2].label = {}
                        errors.extension.items[index1].items[index2].label.cs = t("basics.requireField")
                    }
                    if (item2.options) {
                        errors.extension.items[index1].items[index2].options = [];
                        item2.options.forEach((item3, index3) => {
                            if (!item3?.name?.cs) {
                                errors.extension.items[index1].items[index2].options[index3] = {};
                                errors.extension.items[index1].items[index2].options[index3].name = {cs: t("basics.requireField")};
                            }
                        })
                    }
                })
            }
        })
    }
    return errors;
}


const _renderOption = (name, index, fields) => {
    return <div key={index}>
        <Field name={`${name}.name.cs`}>
            {({input, meta}) => (
                <div className={"flex flex-col space-y-1"}>
                    <div className={"grid grid-cols-10 place-items-center"}>
                        <div>{`#${index + 1}`}</div>
                        <div className={"col-span-8 justify-self-stretch"}>
                            <ParkingOptionModal fieldName={name} index={index} fields={fields} remove={fields.remove} error={meta.error}/>
                        </div>
                        <div
                            className={"text-right w-1/8"}
                            style={{cursor: "pointer"}}>
                            <div onClick={() => {
                                fields.remove(index)
                            }}> ❌
                            </div>
                        </div>

                    </div>
                    {meta.error ? <div className={"text-xs text-red-500 whitespace-no-wrap text-center"}><Trans
                        i18nKey={"parkingExtension.parkPlaceNameRequired"}>Vyplňte název jídla</Trans></div> : ""}
                </div>
            )}
        </Field>
    </div>
}

export const ParkingItem = ({name, fields, index, data, remove}) => {
    const {t} = useTranslation();
    let optionsFields = null;
    return (
        <div className={`text-sm form-input`} key={name}>
            <div className={"grid grid-cols-2"}>
                <div className={"form-label"}>{t("parkingExtension.parkArray")} #{index + 1}:</div>
                <div className={"flex flex-row space-x-2 justify-end w-full"}>
                    <div
                        className={"text-right"}
                        style={{cursor: "pointer"}}>
                        <div onClick={() => {
                            remove(index)
                        }}> ❌
                        </div>
                    </div>
                </div>
            </div>
            <div className={"flex flex-col space-y-2"}>
                <div className={"hidden"}><EnigooTextField label={""} name={`${name}.id`}/></div>
                <EnigooMultiLanguageField name={`${name}.label`} label={t("basics.label") + ":*"}/>
                <div className={`${optionsFields && optionsFields.value.length>0?"hidden":""}`}>
                    <EnigooTextField label={t("parkingExtension.capacityOfArray") + ":"} toolTip={t("parkingExtension.capacityOfArrayToolTip") } name={`${name}.capacity`}
                                     type={"number"}/></div>
                {/*<FieldArray name={`${name}.options`}>
                    {({fields}) => {
                        optionsFields = fields;
                        return (
                            <div className={"flex flex-col space-y-2"}>
                                <div className={"form-label"}>{t("parkingExtension.optionsForSelectParking")}:</div>

                                {fields.map((name, index) => _renderOption(name, index, fields))}
                                <EnigooButton toolTip={t("parkingExtension.createNewOptionForSelectParking")}
                                              icon={<FiPlus/>} text={t("basics.add")}
                                              onClick={() => {
                                                  fields.push({name: {cs: ""}, open: true})
                                              }}/>
                                <GenerateNumberRowModal onSave={(data) => {
                                    data.forEach((item) => {
                                        fields.push({name: {cs: item}, open: false});
                                    })
                                }}/>
                            </div>
                        )
                    }}
                </FieldArray>*/}
            </div>
        </div>
    )
}

export const ParkingDay = ({day, fieldName, data,push,values,daysFields}) => {
    const {t} = useTranslation();
    return (

        <FieldArray name={`${fieldName}.items`}>
            {({fields}) => {
                return (
                    <>
                        <div className={"flex flex-row space-x-2"}>
                        <EnigooButton _key={fieldName+"_add"} toolTip={t("parkingExtension.createNewParkArray")} icon={<FiPlus/>}
                                      onClick={() => {
                                          fields.push({options: [], date: day})
                                      }}/>
                            {push && <EnigooButton toolTip={t("actionExtension.copyToOtherDays")} _key={"action_meal_extension_duplicate"} icon={<FiCopy/>} onClick={()=>{
                                let itemsToCopy = [];
                                values.extension.items.forEach(item => {
                                    if(item.day===day){
                                        itemsToCopy = item.items
                                    }
                                })
                                let newItemsToCopy = [];
                                itemsToCopy.forEach(item=>{
                                    newItemsToCopy.push({
                                        capacity:item.capacity,
                                        label: item.label,
                                        options: item.options,
                                        timeFrom:item.timeFrom,
                                        timeTo:item.timeTo
                                    })
                                })
                                daysFields.forEach((item,index)=>{
                                    if(item!==fieldName){
                                        let newDayItems = [];
                                        newItemsToCopy.forEach((nItem)=>{
                                            push(item+".items",{
                                                capacity:nItem.capacity,
                                                label:nItem.label,
                                                options:nItem.options,
                                                timeFrom:nItem.timeFrom,
                                                timeTo:nItem.timeTo,
                                                day:daysFields.value[index].day,
                                            })
                                        })
                                    }
                                })
                                CogoToast.success(t("actionExtension.copyToOtherDaysSuccess"));
                            }}/>}
                        </div>
                        <div
                            className={`grid text-sm mt-5 lg:grid-cols-4 grid-cols-1 gap-2`}>
                            {fields.map((name, index) => <ParkingItem name={name} fields={fields} index={index}
                                                                      data={data} remove={fields.remove}/>)}
                        </div>
                    </>
                )
            }
            }
        </FieldArray>
    )
}
