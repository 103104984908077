export default function onlineConfig(
    state = {
        online: null,
        afterAdd: null,
        afterAddAction:null,
        added: false,
        onlineLogo: null,
        withAuthorization: false,
        byPassword: false,
        byCode: false,
        authorizationText: null,
        withPhoto: false,
        requirePhoto: false,
        error: null
    },
    action
) {
    switch (action.type) {
        case 'SET_ONLINE':
            return {...state, ...action.onlineConfig}
        case 'SET_TEXT_ONLINE':
            return {...state, ['online']: action.value};
        case 'SET_TEXT_AFTER_ADD':
            return {...state, ['afterAdd']: action.value};
        case 'SET_ADDED':
            return {...state, ['added']: action.value};
        case 'SET_ONLINE_LOGO':
            return {...state, ['logo']: action.value};
        case 'SET_ONLINE_WITH_AUTH':
            return {...state, ['withAuthorization']: action.value};
        case 'SET_ONLINE_BY_PASS':
            return {...state, ['byPassword']: action.value};
        case 'SET_ONLINE_ERROR':
            return {...state, ['error']: action.value};
        case "RESET_ONLINE": {
            return {
                online: null,
                afterAdd: null,
                afterAddAction:null,
                added: false,
                onlineLogo: null,
                withAuthorization: false,
                byPassword: false,
                byCode: false,
                authorizationText: null,
                withPhoto: false,
                requirePhoto: false,
                error: null
            }
        }
        default:
            return state;
    }
}
