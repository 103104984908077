import React from 'react'
import PropTypes from "prop-types"
import {Link} from "react-router-dom";
import EnigooToolTip from "./EnigooToolTip";

const EnigooButton = ({
                          loading,
                          onClick = null,
                          disabled = false,
                          text,
                          icon,
                          link,
                          visible = true,
                          color = "enigoo",
                          textColor = "white",
                          newTab = false,
                          extraStyle = "",
                          toolTip = null,
                          _key,
                        toolTipPosition="right"
                      }) => {

    if (!visible) return (<></>);
    let style = "btn btn-default rounded btn-icon hover:bg-" + color + "-600 bg-" + color + "-500 text-" + textColor + " space-x-1 mt-1 " + extraStyle;
    let button = (
        <>
        <button data-tip key={_key} data-for={"_tooltip_"+_key}
            className={style}
            onClick={() => onClick && !loading && onClick()} disabled={disabled}>
            {icon} {text && <span>{loading ? 'Pracuji...' : text}</span>}
        </button>
            {toolTip&&
                <EnigooToolTip content={toolTip} id={"_tooltip_" + _key} place={toolTipPosition}/>}
        </>
    );
    if (link && newTab) return <Link to={link} target={"_blank"}>{button}</Link>
    if (link) return <Link to={link}>{button}</Link>
    if (!link) return button
}

EnigooButton.propTypes = {
    loading: PropTypes.bool,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    text: PropTypes.string,
    icon: PropTypes.element,
    link: PropTypes.string,
    visible: PropTypes.bool,
    color: PropTypes.string,
    newTab: PropTypes.bool,
    extraStyle: PropTypes.string
}

export default EnigooButton
