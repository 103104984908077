import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";
import EnigooRadioField from "../../../../MyComponent/EnigooRadioField";

const UserMealExtensionRadioField = ({options,name,label,disabled=false,initValue})=>{
    let [radioFieldData,setRadioFieldData] = useState([]);
    const {t, i18n} = useTranslation();

    const getTranslate = (item) => {
        if (item &&item[i18n.language]) {
            return item[i18n.language];
        } else if(item && item["cs"]) {
            return item["cs"];
        }else{
            return "";
        }
    }


    useEffect(() => {
        let validData = [];
        options.forEach((opt,index)=>{
            let note = getTranslate(opt.note);
            validData.push({
                value:opt.id,
                name: getTranslate(opt.name),
                label:note!==""?"("+note+")":"",
                active: opt.active || opt.id===initValue,
                toolTip: !(opt.active || opt.id===initValue)?t("mealExtension.capacityIsFull"):null
            })
        })
        setRadioFieldData(validData);

    }, [i18n.language]);

    return <EnigooRadioField items={radioFieldData} defaultValues={["value","name","label","active","toolTip"]} name={name} label={label} disabled={disabled} isMulti={false} columns={4}/>
}
UserMealExtensionRadioField.propTypes={
    options: PropTypes.array.isRequired,
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    disabled: PropTypes.bool
}
export default UserMealExtensionRadioField;
