import React, {useEffect, useState} from 'react';
import {Field, Form} from "react-final-form";
import {useHistory, useParams} from "react-router";
import CogoToast from "cogo-toast";
import EnigooButton from "../../../MyComponent/EnigooButton";
import {FiInfo, FiSave} from "react-icons/all";
import Widget from "../../../components/widget";
import {EnigooLoader} from "../../../MyComponent/EnigooLoader";
import {apiCreateCustomField, apiGetCustomFieldById, apiUpdateCustomField} from "./Actions";
import {IconTabs} from "../../../components/tabs";
import {EnigooTextField} from "../../../MyComponent/EnigooTextField";
import {CustomFieldTypeSelectField} from "../../../MyComponent/Api/Admin/CustomFieldTypeSelectField";
import {FieldArray} from "react-final-form-arrays";
import arrayMutators from "final-form-arrays";
import {FiPlus} from "react-icons/fi";
import EnigooBreadcrumbs from "../../../MyComponent/EnigooBreadcrumbs";
import VerifyRights from "../../VerifyRights";
import EnigooMultiLanguageField from "../../../MyComponent/EnigooMultiLanguageField/EnigooMultiLanguageField";
import {Trans, useTranslation} from "react-i18next";
import EnigooFormChangedAlert from "../../../MyComponent/EnigooFormChangedAlert";

const CustomFieldForm = () => {
    let [data, setData] = useState(null);
    let [loading, setLoading] = useState(false);
    let [saving, setSaving] = useState(false);
    let [showOptions, setShowOptions] = useState(false);
    let submit;
    const history = useHistory();
    const {t} = useTranslation()
    const {id} = useParams();

    useEffect(() => {
        init();
    }, []);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if ((event.ctrlKey || event.metaKey) && event.key === 's') {
                event.preventDefault();
                submit();
                console.log('Ctrl/Command + S was pressed');
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    const init = () => {
        if (id) {
            setLoading(true);
            apiGetCustomFieldById(id, (data) => {
                data.customFieldType = data.customFieldType.id;
                data.label.language = "cs";
                setShowOptions(data.customFieldType === 3 || data.customFieldType === 4);
                setData(data);
                setLoading(false);
            }, (error) => {
                setSaving(false);
                CogoToast.error(t("userFields.loadingDataError"))
            })
        }
    }

    const onSubmitSuccess = (data,values) => {
        setData(values)
        CogoToast.success(t("userFields.saveSuccess"));
        setSaving(false);
        history.push("/custom-field/detail/" + data.id);
    }
    const onSubmitError = (error) => {
        CogoToast.error(t("userFields.saveError"));
        setSaving(false);
    }
    const onSubmit = (values) => {
        if (!showOptions && values.options) {
            values.options = null;
        }
        setSaving(true);
        if (id) {
            apiUpdateCustomField(id, values, (data)=>onSubmitSuccess(data,values), onSubmitError)
        } else {
            apiCreateCustomField(values, (data)=>onSubmitSuccess(data,values), onSubmitError)
        }
    }

    const onTypeChange = (event) => {
        setShowOptions(event.value === 3 || event.value === 4);
    }

    const TabBasicsInfo = (props) => (
        <div className={"w-full flex flex-col"}>
            <EnigooTextField label={t("basics.name") + ": *"} name={"name"} placeHolder={t("placeHolder.name")}
                             outsideClassName={"w-full"} key={"0"}/>
            <EnigooMultiLanguageField label={t("basics.label") + ": *"} name={"label"}
                                      placeHolder={t("placeHolder.label")}
                                      outsideClassName={"w-full"} key={"1"}
                                      toolTip={t("placeHolder.multiLangToolTip")}/>
            <CustomFieldTypeSelectField disabled={!!id} onValueChange={onTypeChange} label={t("basics.type") + ": *"}
                                        name={"customFieldType"}
                                        outsideClassName={"w-full"} isMulti={false} key={"3"}/>
            {showOptions &&
                <>

                    <div className={"form-label"}>{t("basics.options")}:</div>
                    <div className={"w-3/4"}><EnigooButton onClick={() => props.push("options", undefined)}
                                                           text={t("basics.add")} icon={<FiPlus size={10}/>}/></div>
                    <div className={"form-element"}>
                        <FieldArray name={"options"}>
                            {({fields}) =>
                                fields.map((name, index) => (
                                    <div key={name} className={"w-full lg:w-1/4"}>
                                        <div className={'flex flex-row mt-2'}>
                                            <div className={"form-label pt-3 mr-3 w-1/12"}>#{index + 1}</div>
                                            <div className={"form-input flex-1"}>
                                                <Field
                                                    name={`${name}.value`}
                                                    component={"input"}
                                                    placeholder={t("placeHolder.option")}
                                                />
                                            </div>
                                            <Field
                                                name={`${name}.id`}
                                                defaultValue={index + 1}
                                            >
                                                {props => (
                                                    <input {...props.input} hidden={true}/>
                                                )}
                                            </Field>

                                            <div
                                                className={"ml-2 pt-3"}
                                                onClick={() => fields.remove(index)}
                                                style={{cursor: "pointer"}}>❌
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </FieldArray>
                    </div>
                </>}
        </div>
    );

    return (
        <Form onSubmit={onSubmit} initialValues={data}
              validate={values => {
                  let errors = {};
                  if (!values.name) {
                      errors.name = t("basics.requireField");
                  }
                  if (!values.label || !values.label.cs) {
                      errors.label = {cs: t("basics.requireField")};
                  }
                  if (!values.customFieldType) {
                      errors.customFieldType = t("basics.requireField");
                  }
                  return errors;
              }
              }
              mutators={{...arrayMutators}}
              render={({
                           handleSubmit,
                  form,
                  dirty,
                           form: {
                               mutators: {push, pop, unshift}
                           },
                       }) => {
                  submit = handleSubmit;
                  return (
                      <>
                          <EnigooBreadcrumbs
                              right={<VerifyRights page={"customField"}
                                                   action={id ? "updateCustomField" : "createCustomField"}>
                                  <EnigooButton onClick={() => submit()} icon={<FiSave/>} text={t("basics.save")}
                                                loading={saving} disabled={saving || loading}/></VerifyRights>}/>
                          <EnigooFormChangedAlert onReset={() => form.reset(data)} onSave={handleSubmit}
                                                  dirty={dirty}/>
                          <Widget>
                              {loading ? <EnigooLoader text={t("userFields.loadingData")}/> :
                                  <div className={"flex flex-col"}>

                                      <IconTabs tabs={[
                                          {
                                              index: 0,
                                              content: <TabBasicsInfo push={push}/>,
                                              title: (<><FiInfo size={18} className={"stroke-current"}/><span
                                                  className={"ml-2"}><Trans
                                                  i18nKey={"basics.info"}>Zakladni info</Trans></span> </>)
                                          }
                                      ]}/>

                                  </div>}
                          </Widget>
                      </>
                  );
              }
              }/>
    )
}
export default CustomFieldForm;



