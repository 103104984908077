import React, {useEffect, useState} from "react";
import {apiGetDeviceTemplate} from "./Actions";
import CogoToast from "cogo-toast";
import {EnigooLoader} from "../../../MyComponent/EnigooLoader";
import EnigooBreadcrumbs from "../../../MyComponent/EnigooBreadcrumbs";
import EnigooButton from "../../../MyComponent/EnigooButton";
import {FiEdit, FiPlus} from "react-icons/fi";
import Widget from "../../../components/widget";
import VerifyRights from "../../VerifyRights";
import {useTranslation} from "react-i18next";
import EnigooTablev2 from "../../../MyComponent/EnigooTablev2";

const DeviceTemplate = () => {
    let [loading, setLoading] = useState(false);
    let [data, setData] = useState(null);
    const {t} = useTranslation()

    useEffect(() => {
        getDeviceTemplate();
    }, [])

    const columns = React.useMemo(() => [
        {header: t('basics.name'), id: "name", accessorKey: 'name'},
        {
            header: t('deviceTemplate.mode'), id: "mode", cell: ({row}) => {
                if (row.original.data.mode) {
                    switch (row.original.data.mode) {
                        case 1:
                            return t("deviceTemplate.entry");
                        case 2:
                            return t("deviceTemplate.credit");
                        case 3:
                            return t("deviceTemplate.zoneCheck");
                        case 4:
                            return t("deviceTemplate.food");
                        case 5:
                            return t("deviceTemplate.parking");

                        default:
                            return "";
                    }
                }
            }
        },
        {
            header: t('basics.options'), id: "options",
            cell: ({row}) => (<EnigooButton icon={<FiEdit/>} link={"/device/rules/detail/" + row.original.id}/>)
        }
    ], []);

    const getDeviceTemplate = () => {
        setLoading(true);
        apiGetDeviceTemplate((data) => {
            setData(data);
            setLoading(false);
        }, (err) => {
            CogoToast.error(t("deviceTemplate.loadingError"));
            setLoading(false);
        })
    }

    const _renderBody = () => {
        if (loading) return <EnigooLoader text={t("deviceTemplate.loading")}/>
        if (!data) return <EnigooTablev2 columns={columns} data={[]} selectable={false}/>
        if (data) return <EnigooTablev2 columns={columns} data={data} selectable={false}/>
    }

    return (
        <>
            <>
                <EnigooBreadcrumbs
                    right={<VerifyRights page={"deviceTemplate"} action={"createDeviceTemplate"}> <EnigooButton
                        link={"/device/rules/create"} icon={<FiPlus/>} text={t("basics.add")}/></VerifyRights>}/>
                <Widget>
                    {_renderBody()}
                </Widget>
            </>
        </>
    );
}

export default DeviceTemplate;
