import React, {useEffect, useRef, useState} from "react";
import EnigooBreadcrumbs from "../../../MyComponent/EnigooBreadcrumbs";
import Widget from "../../../components/widget";
import {EnigooTextField} from "../../../MyComponent/EnigooTextField";
import {Form} from "react-final-form";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router";
import QrReader from "react-qr-scanner";
import {EnigooSwitch} from "../../../MyComponent/EnigooSwitch";
import {shallowEqual, useDispatch, useSelector} from "react-redux";

const UserCheck = () => {

    let [saving, setSaving] = useState(false)
    let [loading, setLoading] = useState(false);

    let submit = null;
    let formRef = null;

    const history = useHistory()
    const dispatch = useDispatch();

    const {camEnable} = useSelector(
        state => ({camEnable: state.reducerAdmin.checkUserCam.camEnable}),
        shallowEqual
    )

    const bcRef = useRef(null)
    const {t} = useTranslation()

    useEffect(() => {
        if (bcRef.current) {
            bcRef.current.focus()
            bcRef.current.select()
        }
    }, []);

    const onSubmit = (values) => {
        let bc = values.bc;
        const match = bc.match(/\/cid\/(.*)/); // hledá vše za /cid/
        bc = match ? match[1] : bc; // získá shodu, pokud existuje

        history.push("/accreditation/check/" + bc);
    }

    const onKeyDown = (event) => {
        if (submit && event.key === 'Enter') {
            submit();
        }
    }

    const _renderFinder = () => {
        return <>
            <Form onSubmit={onSubmit} initialValues={{camEnable:camEnable}}
                  render={({form, handleSubmit}) => {
                      submit = handleSubmit;
                      formRef = form;
                      return (<>
                          <Widget title={t("accreditation.checkTitle")}>
                              <div className={"w-full"}>
                                  <EnigooTextField placeHolder={t("placeHolder.bc")} reference={bcRef} name={"bc"}
                                                   label={t("placeHolder.bc")} toolTip={t("accreditation.checkToolTip")} onKeyDown={onKeyDown}/>
                              </div>
                          </Widget>

                          <div className={"grid grid-cols-3"}>
                              <Widget zIndex={0}>
                                  <EnigooSwitch name={"camEnable"} label={t("basics.camEnable")} toolTip={t("accreditation.checkCameraToolTip")} setChecked={(data) => {
                                      dispatch({
                                          type: "CHANGE_CAM_STATE",
                                          camEnable: data
                                      });
                                  }}/>
                                  {camEnable && <QrReader onScan={(data) => {
                                      if (data) {
                                          console.log(data?.text);
                                          form.change("bc", data.text);
                                          form.submit();
                                      }
                                  }} onError={(error) => {
                                      //CogoToast.error(error);
                                  }} style={{
                                      width: '100%'
                                  }} delay={100}/>}
                              </Widget>
                          </div>
                      </>)
                  }}/>
        </>
    }

    return <div>
        <EnigooBreadcrumbs/>
        {_renderFinder()}
    </div>

}
export default UserCheck
