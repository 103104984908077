import {axios} from "../../../axiosConfig";


export function apiUserLogin(data, callback, error) {
    axios.post("api/admin/v1/login", data)
        .then(response => callback(response.data.data))
        .catch((err) => error(err))
}

export function apiUserAuthoritate(data, callback, error) {
    axios.get("api/admin/v1/authorization")
        .then(response => callback(response.data.data))
        .catch((err) => error(err))
}

export function apiUserLogout(callback, error) {
    axios.post("api/admin/v1/logout")
        .then(response => callback(response.data.data))
        .catch(err => error(err));
}


