import {useTranslation} from "react-i18next";
import {EnigooTextField} from "../../../../MyComponent/EnigooTextField";
import {TemplateSelectField} from "../../../../MyComponent/Api/Admin/TemplateSelectField";
import {EnigooSwitch} from "../../../../MyComponent/EnigooSwitch";
import {CustomFieldsAssign} from "../../../../MyComponent/Api/Admin/CustomFieldsAssign";
import React from "react";
import PropTypes from "prop-types";

const TabBasicInfo = ({actionData, doPush,values}) => {
    const {t} = useTranslation();

    return (
        <>
            <div className={"grid grid-cols-2 gap-2"}>
                <EnigooTextField label={t("basics.name") + ": *"} name={"name"} placeHolder={t("placeHolder.name")}/>
                <TemplateSelectField label={t("action.mailAfterAdd") + ":"} name={"afterMailTemplateId"} type={0}
                                     mailTemplateType={1} isMulti={false}/>

            </div>
            <div className={"grid lg:grid-cols-6 grid-cols-2 gap-2"}>
                <EnigooSwitch name={"isCredit"} label={t("action.credit") + ": *"} toolTip={t("action.creditToolTip")}/>
                <EnigooSwitch name={"withPhoto"} label={t("action.withPhoto") + ": *"} toolTip={t("action.withPhotoToolTip")}/>
                <EnigooSwitch name={"requirePhoto"} label={t("action.requirePhoto") + ": *"} toolTip={t("action.requirePhotoToolTip")}/>
                <EnigooSwitch name={"smartBc"} label={t("action.smartBc") + ": "} toolTip={t("action.smartBcToolTip")}/>
            </div>

            <CustomFieldsAssign
                defaultSelectValues={!actionData || !actionData.customField ? [] : actionData.customField}
                fieldName={"customField"} label={t("userFields.single") + ":"} doPush={doPush}/>
        </>
    )
}

TabBasicInfo.propTypes={
    actionData:PropTypes.any,
    doPush:PropTypes.func.isRequired,
    values:PropTypes.object.isRequired
}
export default TabBasicInfo;
