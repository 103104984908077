import React, {useEffect, useRef, useState} from "react";
import {shallowEqual, useSelector} from "react-redux";
import Portal from "../../../components/portal";
import {FiDelete, FiEdit, FiPlus, FiX} from "react-icons/fi";
import PropTypes from "prop-types";
import EnigooButton from "../../../MyComponent/EnigooButton";
import {FiSave} from "react-icons/all";
import {Form} from "react-final-form";
import Widget from "../../../components/widget";
import {EnigooTextField} from "../../../MyComponent/EnigooTextField";
import CogoToast from "cogo-toast";
import {useTranslation} from "react-i18next";

export const WebExternalCodeModal = ({item, saveCode, removeCode, index = null}) => {

    const {palettes} = useSelector(
        (state) => ({
            palettes: state.reducerAdmin.palettes
        }),
        shallowEqual
    )
    let {background} = {
        ...palettes
    }
    const {t} = useTranslation()

    const modalRef = useRef(null)
    const [open, setOpen] = useState(false)

    let submit;

    const show = () => {
        setOpen(true)
    }
    const hide = () => {
        setOpen(false)
    }

    const save = (data) => {
        let result = false;
        if (item) {
            result = saveCode(index, data);
        } else {
            result = saveCode(data);
        }
        if (result) {
            hide();
            CogoToast.success( t("externalCode.saved"));
        } else {
            CogoToast.error(t("externalCode.saveError"));
        }
    }

    const remove = () => {
        if (index !== null && removeCode !== null) {
            if(removeCode(index)){
                hide();
                CogoToast.success(t("externalCode.removeFromChannel"))
            }else{
                CogoToast.error(t("externalCode.cannotRemove"))
            }

        }
    }

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (!modalRef || !modalRef.current) return false
            if (!open || modalRef.current.contains(event.target)) {
                return false
            }
            setOpen(!open)
        }
        document.addEventListener('mousedown', handleClickOutside)

        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [open, modalRef])

    return (
        <>
            {!item ? <div className={"form-element mr-1"}>
                <EnigooButton onClick={show}
                              text={t("basics.add")}
                              icon={<FiPlus/>}/>
            </div> : <EnigooButton onClick={show}
                                   icon={<FiEdit/>}/>}
            {open && (
                <Portal selector="#portal">
                    <div className="modal-backdrop fade-in"></div>
                    <div className={`modal show ${background === 'dark' ? 'dark-mode' : ''}`}
                         data-background={background}>
                        <div className="relative min-w-sm w-auto mx-auto lg:max-w-5xl" ref={modalRef}>
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h3 className="text-xl font-semibold">{item ? t("externalCode.editExternalCode") : t("externalCode.newExternalCode")}</h3>
                                    <button
                                        className="modal-close btn btn-transparent"
                                        onClick={hide}>
                                        <FiX size={18} className="stroke-current"/>
                                    </button>
                                </div>
                                <div className="relative p-4 flex-auto">
                                    <div className={"max-h-sm overflow-y-scroll p-2 pb-10"}>
                                    <Widget>
                                        <div className="flex flex-col">
                                            <Form onSubmit={save} initialValues={item}
                                                  validate={values => {
                                                      let errors = {};
                                                      if (!values.code) {
                                                          errors.code = t("basics.requireField");
                                                      }
                                                      if (values.numberOfUse && values.numberOfUse < 0) {
                                                          errors.numberOfUse = t("externalCode.enterPositiveNumber")
                                                      }
                                                      return errors;
                                                  }
                                                  } render={({handleSubmit}) => {
                                                submit = handleSubmit;
                                                return (
                                                    <>
                                                        <EnigooTextField label={t("externalCode.code")+": *"}
                                                                         name={"code"}
                                                                         placeHolder={t("placeHolder.text")}
                                                                         outsideClassName={"w-full"}
                                                                         key={"1"}/>
                                                        <EnigooTextField label={t("externalCode.numberOfUse")+":"} type={"number"}
                                                                         name={"numberOfUse"}
                                                                         placeHolder={t("placeHolder.number")}
                                                                         outsideClassName={"w-full"}
                                                                         key={"1"}/>
                                                    </>
                                                );
                                            }}
                                            />
                                        </div>

                                    </Widget>
                                    </div>
                                </div>
                                <div className="modal-footer space-x-2">
                                    <EnigooButton onClick={remove} text={t("basics.delete")} icon={<FiDelete/>}
                                                  visible={index !== null}/>
                                    <EnigooButton onClick={hide} text={t("basics.close")} icon={<FiX/>}/>
                                    <EnigooButton onClick={() => submit()} text={t("basics.save")} icon={<FiSave/>}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </Portal>
            )}
        </>
    )
}
WebExternalCodeModal.propTypes = {
    item: PropTypes.object
}
