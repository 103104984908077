import React, {useEffect, useState} from "react";
import EnigooBreadcrumbs from "../../../MyComponent/EnigooBreadcrumbs";
import EnigooButton from "../../../MyComponent/EnigooButton";
import {FiInfo, FiSave} from "react-icons/all";
import Widget from "../../../components/widget";
import {EnigooLoader} from "../../../MyComponent/EnigooLoader";
import {Form} from "react-final-form";
import {IconTabs} from "../../../components/tabs";
import {EnigooTextField} from "../../../MyComponent/EnigooTextField";
import {apiGetDeviceById, apiUpdateDevice} from "./Actions";
import {useParams} from "react-router";
import {useHistory} from "react-router-dom";
import CogoToast from "cogo-toast";
import {DeviceTemplateSelectField} from "../../../MyComponent/Api/Admin/DeviceTemplateSelectField";
import VerifyRights from "../../VerifyRights";
import {useTranslation} from "react-i18next";
import EnigooFormChangedAlert from "../../../MyComponent/EnigooFormChangedAlert";

const DeviceForm = () => {
    let [loading, setLoading] = useState(false);
    let [saving, setSaving] = useState(false);
    let [data, setData] = useState(null);
    const {t} = useTranslation()

    let submit = null;

    const {id} = useParams();

    const history = useHistory();

    useEffect(() => {
        init();
    }, [])

    useEffect(() => {
        const handleKeyDown = (event) => {
            if ((event.ctrlKey || event.metaKey) && event.key === 's') {
                event.preventDefault();
                submit();
                console.log('Ctrl/Command + S was pressed');
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    const init = () => {
        if (id) {
            setLoading(true);
            apiGetDeviceById(id, (data) => {
                setData(data);
                setLoading(false);
            }, (error) => {
                CogoToast.error(t("device.detailLoadingError"));
                setLoading(false);
                history.push("/device");
            })
        } else {
            CogoToast.error(t("device.detailLoadingError"));
            history.push("/device");
        }
    }

    const onSubmitSuccess = (data,values) => {
        setData(values);
        CogoToast.success(t("device.saveSuccess"));
        setSaving(false);
        history.push("/device/detail/" + id);
    }

    const onSubmitError = (err) => {
        CogoToast.error(t("device.saveError"));
        setSaving(false);
        history.push("/device/detail/" + id);
    }

    const onSubmit = (values) => {
        if (id) {
            setSaving(true);
            apiUpdateDevice(id, values,(data)=> onSubmitSuccess(data,values), onSubmitError)
        }
    }

    const TabBasicsInfo = () => (
        <div className={"w-full"}>
            <EnigooTextField label={t("basics.name") + ":"} name={"name"} placeHolder={t("placeHolder.name")}/>
            <EnigooTextField name={"uid"} label={"UID: *"} disabled={true}/>
            <DeviceTemplateSelectField name={"deviceTemplate"} label={t("device.rule") + ":"} isMulti={false}/>
        </div>
    )

    return (
        <Form onSubmit={onSubmit} initialValues={data}
              validate={values => {
                  let errors = {};
                  if (!values.uid) {
                      errors.uid = t("basics.requireField");
                  }
                  return errors;
              }
              }
              render={({handleSubmit,form,dirty}) => {
                  submit = handleSubmit;
                  return (
                      <>
                          <EnigooBreadcrumbs
                              right={<VerifyRights page={"device"} action={"updateDevice"}> <EnigooButton
                                  onClick={() => submit()} icon={<FiSave/>} text={t("basics.save")}
                                  loading={saving} disabled={saving || loading}/></VerifyRights>}/>
                          <EnigooFormChangedAlert onReset={() => form.reset(data)} onSave={handleSubmit}
                                                  dirty={dirty}/>
                          <Widget>
                              {loading ? <EnigooLoader text={t("device.detailLoading")}/> :
                                  <div className={"flex flex-col"}>

                                      <IconTabs tabs={[
                                          {
                                              index: 0,
                                              content: <TabBasicsInfo/>,
                                              title: (<><FiInfo size={18} className={"stroke-current"}/><span
                                                  className={"ml-2"}>{t("basics.info")}</span> </>)
                                          }
                                      ]}/>

                                  </div>}
                          </Widget>
                      </>
                  );
              }
              }/>
    )

}
export default DeviceForm;
