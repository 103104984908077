import React, {useEffect, useState} from "react";
import {EnigooSelectMulti} from "../../EnigooSelectMulti";
import {EnigooSelect} from "../../EnigooSelect";
import {apiGetZone} from "../../../Admin/pages/Zone/Actions";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";

export const ZoneSelectField = ({
                                    label,
                                    name,
                                    isMulti = true,
                                    outsideClassName = "w-full",
                                    selectedAction = null,
                                    inModal = false
                                }) => {
    let [data, setData] = useState(null);
    let [loading, setLoading] = useState(false);
    let [selectData, setSelectData] = useState([])

    const {t} = useTranslation()
    useEffect(() => {
        get();
    }, [selectedAction]);

    const get = () => {
        setLoading(true);
        apiGetZone((data) => {
            setData(data);
            setLoading(false);
            setSelectData(parseData(data));
        }, (error) => {
            setLoading(false);
        });
    }


    const parseData = (data) => {
        let items = [];
        data && data.map((item, index) => {
            if (item.id === selectedAction || item.id === null) {
                let options = [];
                item.items.map((it, ind) => {
                    options.push({
                        label: it.name,
                        value: it.id
                    })
                })
                items.push({
                    label: item.actionName ? item.actionName : t("groups.notAssign"),
                    options: options
                })
            }
        })
        return items;
    }


    return (
        isMulti ?
            <EnigooSelectMulti inModal={true} placeHolder={t("placeHolder.zone")} grouped={true} label={label} name={name}
                               items={selectData} disabled={loading} outsideClassName={outsideClassName}/> :
            <EnigooSelect inModal={true} placeHolder={t("placeHolder.zone")} grouped={true} label={label} name={name}
                          items={selectData} disabled={loading} outsideClassName={outsideClassName}/>
    )
}

ZoneSelectField.propTypes = {
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    isMulti: PropTypes.bool,
    outsideClassName: PropTypes.string
}
