import React, {useEffect, useRef, useState} from "react";
import Autocomplete from "react-autocomplete"
import axios from "axios";
import PropTypes from "prop-types"
import {Field} from "react-final-form"
import EnigooButton from "./EnigooButton";
import {FiHelpCircle, FiX} from "react-icons/fi";
import EnigooToolTip from "./EnigooToolTip";

const getMapyApi = (phrase, success, error) => {
    const mapyApi = axios.create({
        baseURL: "https://api.mapy.cz/v1/suggest/?count=5&lang=cs&type=regional.address&query=",
        headers: {
            'X-Mapy-Api-Key': 'TLctlMoqkS7rFeN-A4SthpUuD4Mdk6sS5Y90ER4JUrM',
        },
    });

    mapyApi.get(phrase)
        .then(response => success(response.data))
        .catch(err => error(err.response))

}

const getMapyApi2 = (phrase, callback) => {
    var suggest = new window.SMap.SuggestProvider({});
    suggest.get(phrase).then((response) => {
        let data = JSON.parse(suggest._responseData.data)
        callback(data)
    })

}

const EnigooAddressField = ({name, label, isMandatory,toolTipPosition="right"}) => {

    let [address, setAddress] = useState([])
    let [valueActual, setValueActual] = useState()
    let[mapyApiLoaaded,setMapyApiLoaded]=useState(false);

    const timer = useRef(null)

    useEffect(()=>{
        if(!mapyApiLoaaded){
            window.Loader.load(null, {suggest: true}, () => {
                setMapyApiLoaded(true)
            });
        }

    },[])

    let addressValue = React.useMemo(()=>{
        clearTimeout(timer.current)
        if (valueActual) {
            timer.current = setTimeout(() => {
                getMapyApi2(valueActual, (data) => {
                    let array = [];
                    data.result.map((item, key) => {
                        array.push({
                            id: key,
                            city: item.userData.municipality ? item.userData.municipality : item.userData.district,
                            zip: item.userData.zipCode,
                            street: item.userData.suggestFirstRow,
                            gps: item.userData.latitude + ", " + item.userData.longitude
                        })
                    })
                    setAddress(array);
                    return array;
                }, err => {
                    return [];
                })
            }, 500)
        }else{
            return [];
        }

    },[valueActual])

    useEffect(() => {

    }, [valueActual])

    const val = (value) => {
        if (isMandatory && !value) return "Adresa je povinná."
        if (value && (!value.zip || value.zip === "")) return "Vyberte adresu přesně včetně ČP A PSČ."
        if (value && (!value.street || value.street === "")) return "Vyberte adresu přesně včetně ČP A PSČ."
    }

    const parseValue = (value) => {
        return value.street + ", " + value.city + " - " + value.zip
    }

    const toolTip =
        "<p><b>Adresu je nutné vybrat z našeptávače</b></p>" +
        "<p><b>a musí obsahovat: </b></p>" +
        "<p>ulice čp, město - psč </p>" +
        "<p><i>Příklad: </i>Hlaváčova 207, Pardubice - 53002</p>";

    return (
        <Field name={name} validate={val}>
            {({input, meta}) => (
                <div className={"form-element"}>
                    <span className={"form-label flex flex-row"}>{label}
                        <FiHelpCircle className={"ml-2 text-red-900"} size={18}
                                                                  data-tip data-for={"_tooltip_"+name} key={name}/>
                        <EnigooToolTip content={toolTip} id={"_tooltip_" + name} place={toolTipPosition}/></span>
                    <Autocomplete
                        items={address}
                        inputProps={{
                            className: meta.error && meta.touched ? "form-input form-input-invalid" : "form-input",
                            disabled: !!input.value
                        }}
                        menuStyle={{
                            borderRadius: '3px',
                            boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
                            background: 'rgba(255, 255, 255, 1)',
                            padding: '2px 0',
                            fontSize: '90%',
                            position: 'fixed',
                            overflow: 'auto',
                            maxHeight: '50%',
                            zIndex: 999
                        }}
                        wrapperStyle={{display: 'block'}}
                        getItemValue={item => {
                            return item.street
                        }}
                        onChange={(e) => {
                            setValueActual(e.target.value)
                        }
                        }
                        onSelect={(value, item) => {
                            input.onChange(item)
                        }}
                        renderItem={(item, highlighted) => (
                            <div key={item.id} style={{padding: 5, cursor: 'pointer'}}>
                                <div>
                                    <span style={{fontSize: 13, fontWeight: 'bold'}}>{item.street} </span>
                                </div>
                                <div style={{marginTop: 3}}>
                                        <span
                                            style={{fontSize: 11}}>{item.city} {item.zip ? ("- " + item.zip) : ""}</span>
                                </div>
                            </div>
                        )}
                        value={input.value ? parseValue(input.value) : valueActual}
                    />
                    <EnigooButton onClick={() => {
                        setValueActual("");
                        input.onChange(null);
                    }} color={"white"} textColor={"enigoo-500"}
                                  extraStyle={"border border-enigoo-500"}
                                  text={"Zadat znovu"} icon={<FiX className={"mr-2"}/>}/>
                    {meta.error && meta.touched && <div className={"form-error"}>{meta.error}</div>}
                </div>
            )}
        </Field>
    )
}

EnigooAddressField.propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
}


export default EnigooAddressField;
