import React, {useEffect, useState} from 'react';
import {Form} from "react-final-form";
import {Prompt, useHistory, useParams} from "react-router";
import {apiCreateGroup, apiGetGroupById, apiUpdateGroup} from "./Actions";
import CogoToast from "cogo-toast";
import EnigooButton from "../../../MyComponent/EnigooButton";
import {FiInfo, FiSave} from "react-icons/all";
import Widget from "../../../components/widget";
import {EnigooLoader} from "../../../MyComponent/EnigooLoader";
import {EnigooFieldsGroup} from "../../../MyComponent/EnigooFieldsGroup";
import {EnigooTextField} from "../../../MyComponent/EnigooTextField";
import {ActionSelectField} from "../../../MyComponent/Api/Admin/ActionSelectField";
import {IconTabs} from "../../../components/tabs";
import {EnigooHtmlTextArray} from "../../../MyComponent/EnigooHtmlTextArray";
import {TemplateSelectField} from "../../../MyComponent/Api/Admin/TemplateSelectField";
import {ZoneSelectField} from "../../../MyComponent/Api/Admin/ZoneSelectField";
import EnigooBreadcrumbs from "../../../MyComponent/EnigooBreadcrumbs";
import VerifyRights from "../../VerifyRights";
import {Trans, useTranslation} from "react-i18next";
import MealAssignModal from "../ActionExtension/MealExtensionUsers/MealAssignModal";
import {EnigooSwitch} from "../../../MyComponent/EnigooSwitch";
import ParkingAssignModal from "../ActionExtension/ParkingExtension/ParkingAssignModal";
import EnigooFormChangedAlert from "../../../MyComponent/EnigooFormChangedAlert";
import EnigooMultiLanguageField from "../../../MyComponent/EnigooMultiLanguageField/EnigooMultiLanguageField";

const GroupsForm = () => {
    let [groupData, setGroupData] = useState(null);
    let [loading, setLoading] = useState(false);
    let [saving, setSaving] = useState(false);
    let [selectedAction, setSelectedAction] = useState(null);
    const {t} = useTranslation();
    let submit;
    const history = useHistory();

    const {id} = useParams();

    useEffect(() => {
        const handleKeyDown = (event) => {
            if ((event.ctrlKey || event.metaKey) && event.key === 's') {
                event.preventDefault();
                submit();
                console.log('Ctrl/Command + S was pressed');
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    useEffect(() => {
        init();
    }, []);

    const init = () => {
        if (id) {
            setLoading(true);
            apiGetGroupById(id, (data) => {
                const group = data;
                let array = [];
                data.zones.map((item, index) => {
                    array.push(item.id);
                })
                group.zoneId = array;
                setGroupData(group);
                setSelectedAction(data.actionId);
                setLoading(false);
            }, (err) => CogoToast.error(t("basics.noDataError")))
        }
    }
    const afterSubmitSuccess = (data,values) => {
        setGroupData(values)
        CogoToast.success(t("groups.successSave"));
        setSaving(false);
        history.push("/groups/detail/" + data.id);
    }
    const afterSubmitError = (error) => {
        setSaving(false);
        CogoToast.error(t("groups.errorSave"));
    }

    const onSubmit = (values) => {
        setSaving(true);
        if (id) {
            apiUpdateGroup(id, values, (data)=>afterSubmitSuccess(data,values), afterSubmitError);
        } else {
            apiCreateGroup(values, (data)=>afterSubmitSuccess(data,values), afterSubmitError);
        }
    }

    return (
        <Form onSubmit={onSubmit} initialValues={groupData}
              validate={values => {
                  let errors = {};
                  if (!values.name) {
                      errors.name = t("basics.requireField");
                  }
                  return errors;
              }
              } render={({handleSubmit, values,form,dirty}) => {
            submit = handleSubmit;
            return (
                <>
                    <EnigooBreadcrumbs
                        right={<VerifyRights page={"groups"} action={id ? "updateGroups" : "createGroups"}>
                            <EnigooButton
                                onClick={() => submit()} icon={<FiSave/>} text={t("basics.save")}
                                loading={saving} disabled={saving || loading}/></VerifyRights>}/>
                    <EnigooFormChangedAlert onReset={() => form.reset(groupData)} onSave={handleSubmit}
                                            dirty={dirty}/>
                    <Widget>
                        {loading ? <EnigooLoader text={t("groups.loadingData")}/> :
                            <div className="flex flex-col">

                                <div className={"w-full"}>
                                    <IconTabs tabs={tabs(selectedAction, setSelectedAction, values)}/>
                                </div>

                            </div>
                        }
                    </Widget>

                </>
            );
        }}
        />
    )
}

const TabBasicsInfo = ({selectedAction, setSelectedAction, values}) => {
    const {t} = useTranslation()
    const {id} = useParams();
    return (
        <>
            <div className={"grid grid-cols-2 gap-2"}>
                <EnigooTextField label={t("basics.name") + ": *"} name={"name"}
                                 placeHolder={t("placeHolder.name")} key={"1"}/>
                <ActionSelectField onChange={(event) => {
                    setSelectedAction(event ? event.value : null)
                }} label={t("action.single") + ":"} name={"actionId"}
                                   isMulti={false}
                                   key={"2"}/>
                {id && <>
                    <VerifyRights page={[]} action={[]} extension={[1]}> <EnigooSwitch name={"assignDefaultMeal"}
                                                                                       label={t("groups.assignDefaultMeal") + ": "}
                                                                                       toolTip={t("groups.assignDefaultMealToolTip")}/>
                        <div className={"flex flex-col space-y-2"}>
                            {values && values.assignDefaultMeal &&
                                <div className={"form-label"}>{t("mealExtension.single")}:</div>}
                            <MealAssignModal actionId={selectedAction} usersIds={null} type={"groups"} values={values}/>
                        </div>
                    </VerifyRights>
                    <VerifyRights page={[]} action={[]} extension={[2]}>
                        <EnigooSwitch name={"assignDefaultParking"} label={t("groups.assignDefaultParking") + ": "}
                                      toolTip={t("groups.assignDefaultParkingToolTip")}/>
                        <div className={"flex flex-col space-y-2"}>
                            {values && values.assignDefaultParking &&
                                <div className={"form-label"}>{t("parkingExtension.single")}:</div>}
                            <ParkingAssignModal actionId={selectedAction} usersIds={null} type={"groups"}
                                                values={values}/>
                        </div>
                    </VerifyRights>
                </>}
            </div>
            <EnigooMultiLanguageField type={"text"} name={"displayName"} label={t("groups.displayName")+":"} toolTip={t("groups.displayNameToolTip")}/>
            <EnigooHtmlTextArray label={t("groups.htmlData") + ":"} name={"htmlData"}
                                 placeHolder={t("placeHolder.html")}/>
            <EnigooFieldsGroup items={[
                <TemplateSelectField name={"adminTemplateId"} label={t("groups.accreditationTemplate") + ":"}
                                     outsideClassName={"w-full lg:w-1/2"} isMulti={false} key={"1"}/>,
                <ZoneSelectField selectedAction={selectedAction} label={t("zones.plural") + ":"} name={"zoneId"}
                                 outsideClassName={"w-full lg:w-1/2"} isMulti={true}
                                 key={"2"}/>
            ]}/>
        </>
    )
}

const tabs = (selectedAction, setSelectedAction, values) => [
    {
        index: 0,
        content: <TabBasicsInfo selectedAction={selectedAction} setSelectedAction={setSelectedAction} values={values}/>,
        title: (<><FiInfo size={18} className={"stroke-current"}/><span
            className={"ml-2"}><Trans i18nKey={"basics.info"}>Základní informace</Trans></span> </>)
    }
];

export default GroupsForm;
