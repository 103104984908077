import React from "react";
import EnigooButton from "../../../MyComponent/EnigooButton";
import {FiCopy, FiEdit} from "react-icons/fi";
import {useTranslation} from "react-i18next";
import CogoToast from "cogo-toast";
import EnigooTablev2 from "../../../MyComponent/EnigooTablev2";
import EnigooTableMultiValue from "../../../MyComponent/EnigooTableMultiValue";
import EnigooToolTip from "../../../MyComponent/EnigooToolTip";

const CustomFieldTable = ({
                              data
                          }) => {
    const {t, i18n} = useTranslation();

    const columns = React.useMemo(() => [
        {
            header: t('basics.name'),id:"name",
            cell:({row})=>{
                return (
                    <div className={"flex flex-row space-x-2 justify-items-start"}><span
                        className={"basis-1/4"}>{row.original.name}</span>
                    </div>
                )
            }
        },
        {
            header: t('userFields.shownName'),id:"shownName",
            cell:({row})=>{
                if (row.original.label[i18n.language]) {
                    return row.original.label[i18n.language];
                } else {
                    return row.original.label.cs
                }
            }
        },
        {
            header: t('basics.type'), id:"type",
            cell:({row})=>{
                return row.original.customFieldType.name[i18n.language]
            }
        },
        {
            header: t('userFields.values'),
            id:"values",
            cell:({row})=>{
                return <EnigooTableMultiValue values={row.original.options} paramName={"value"}/>
            }
        },
        {
            header: t('basics.options'),id:"options",
            cell:({row})=>{
                return(<div className={"flex flex-row space-x-2"}>
                    <EnigooButton icon={<FiEdit/>}
                                  link={"custom-field/detail/" + row.original.id}/>
                    <div data-tip data-for={"_tooltip_user_fields_copy_"+row.original.id}><EnigooButton icon={<FiCopy/>} onClick={() => {
                        navigator.clipboard.writeText(`{$custom_field[${row.original.id}]}`)
                            .then(() => {
                                CogoToast.success(t("userFields.nameToTemplateCopied"))
                            })
                    }}/>
                        <EnigooToolTip id={"_tooltip_user_fields_copy_"+row.original.id} content={t("userFields.copyNameToTemplate")} place={"right"}/>
                    </div>

                </div>)
            }
        }

    ], [i18n.language]);

    return <EnigooTablev2 columns={columns} data={data} selectable={false} paginated={true}/>

}
export default CustomFieldTable;
