import React, {useEffect, useState} from "react";
import SectionTitle from "../../../components/section-title";
import EnigooButton from "../../../MyComponent/EnigooButton";
import Widget1 from "../../../components/dashboard/widget-1";
import {FiActivity, FiCalendar, FiCheck, FiList, FiPlus, FiSend, FiUser, FiUsers} from "react-icons/fi";
import Section from "../../../components/dashboard/section";
import {CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import {random} from "../../../functions/numbers";
import {getColor} from "../../../functions/colors";
import {apiGetDashboardData} from "./Actions";
import {EnigooLoader} from "../../../MyComponent/EnigooLoader";
import {Trans, useTranslation} from "react-i18next";
import EnigooBreadcrumbs from "../../../MyComponent/EnigooBreadcrumbs";
import {FiInfo, MdError} from "react-icons/all";
import {Link, useHistory} from "react-router-dom";
import Widget from "../../../components/widget";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import EnigooToolTip from "../../../MyComponent/EnigooToolTip";
import {IconTabs} from "../../../components/tabs";
import TabBasicInfo from "../Action/Form/TabBasicInfo";
import {AccreditationLineChart} from "./AccreditationLineChart";
import {AccreditationPieChart} from "./AccreditationPieChart";
import Dropdown from "../../../components/dropdowns/dropdown-1";
import AccreditationLineChartByRegisterType from "./AccreditationLineChartByRegisterType";
import AccreditationBarChart from "./AccreditationBarChart";


const Dashboard = () => {
    let [loading, setLoading] = useState(false);
    let [data, setData] = useState();

    const {adminRights} = useSelector(
        state => ({adminRights: state.reducerAdmin.adminRights}),
        shallowEqual
    )

    const {t} = useTranslation();

    useEffect(() => {
        getData();
    }, []);

    useEffect(() => {
        getData()
    }, [adminRights.subject]);


    const getData = () => {
        setLoading(true);
        apiGetDashboardData((values) => {
            setData(values);
            setLoading(false);
        }, (err) => {
            setData(null);
            setLoading(false);
        })
    }

    const getParamFromData = (type, param) => {
        if (data[type] && data[type][param]) {
            return data[type][param];
        } else {
            return "";
        }
    }
    return (
        <>
            <EnigooBreadcrumbs/>
            {loading && <EnigooLoader text={t("basics.loadingData")}/>}
            {!loading && <>

                <div className={"grid grid-cols-1 lg:grid-cols-1 gap-2"}>
                    <Widget title={t("accreditation.plural")} description={t("accreditation.overviewOfRegister")} zIndex={0}>
                        <IconTabs tabs={[
                            {
                                index: 0,
                                content: <AccreditationLineChartByRegisterType
                                    data={data ? data.accreditationChartData : []}/>,
                                title: <span className={"ml-2"}><Trans i18nKey={"dashboard.chartFull"}></Trans></span>

                            },
                            {
                                index: 1,
                                content: <AccreditationLineChart
                                    data={data ? data.accreditationByActionChartData : []}/>,
                                title: <span className={"ml-2"}><Trans
                                    i18nKey={"dashboard.chartByAction"}></Trans></span>

                            },
                            {
                                index: 2,
                                content: <AccreditationPieChart data={data ? data.actionPieChartData : []}/>,
                                title: <span className={"ml-2"}><Trans
                                    i18nKey={"dashboard.chartByActionFull"}></Trans></span>
                            }
                        ]} disableNonActiveTab={true}/>

                    </Widget>

                    {/*<Widget title={t("accreditation.plural")} description={t("accreditation.overviewOfRegister")} zIndex={0}>
                        <IconTabs tabs={[
                            {
                                index: 0,
                                content: <AccreditationBarChart/>,
                                title: <span className={"ml-2"}><Trans i18nKey={"dashboard.chartFull"}></Trans></span>

                            }
                        ]}/>
                    </Widget>*/}
                </div>


                <div className={"grid grid-cols-2 lg:grid-cols-4 gap-2 mb-2 lg:mb-4"}>

                    <Link to={"/action"}>
                        <Widget1 title={t("action.plural")}
                                 description={(data?.basicData?.actionCount)}
                                 right={<FiCalendar size={24}
                                                    className={"stroke-current text-enigoo-500"}/>}/>
                    </Link>

                    <Link to={"/web-channel"}>
                        <Widget1 title={t("webChannels.plural")}
                                 description={(data?.basicData?.webChannelCount)}
                                 right={<FiCheck size={24}
                                                 className={"stroke-current text-enigoo-500"}/>}/>
                    </Link>
                    <Link to={"/accreditation"}>
                        <Widget1 title={t("accreditation.plural")}
                                 description={(data?.basicData?.userCount)}
                                 right={<FiUsers size={24}
                                                 className={"stroke-current text-enigoo-500"}/>}/>
                    </Link>
                    <Widget1 title={t("email.sended")} description={(data?.basicData?.emailSentCount)}
                             right={<FiSend size={24} className={"stroke-current text-enigoo-500"}/>}/>
                    <Widget1 title={t("email.waiting")} description={(data?.basicData?.emailInQueue)}
                             right={<FiList size={24} className={"stroke-current text-enigoo-500"}/>}/>
                    <Widget1 title={t("email.withError")}
                             description={(data?.basicData?.emailErrorCount)}
                             right={<MdError size={24} className={"stroke-current text-enigoo-500"}/>}/>
                </div>

                <div className="flex flex-col lg:flex-row w-full lg:space-x-2 space-y-2 lg:space-y-0 mb-2 lg:mb-4">
                    <div className="w-full">
                        <Section title={t("accreditation.plural")}
                                 description={t("accreditation.lastRegistrations")}
                                 right={<FiUsers size={24}/>}>
                            <ListOfAcreditations data={data ? data.listOfAccreditation : []}/>
                        </Section>
                    </div>
                </div>
            </>
            }
        </>
    )


}

export default Dashboard;

const ListOfAcreditations = (data) => {

    const history = useHistory();
    const dispatch = useDispatch();
    const {t} = useTranslation()

    const goToDetail = (id, actionId) => {
        dispatch({
            type: "CHANGE_ACTION",
            actualAction: actionId
        });
        history.push(`/accreditation/detail/${id}`);
    }

    return (
        <div className={"grid grid-cols-2 lg:grid-cols-4 gap-2 mb-2 lg:mb-4"}>
            {data?.data && data.data.map((item, i) => (
                <div className="flex items-center justify-start p-2 space-x-4"
                     key={i}>

                    <div className="flex-shrink-0">
                        <button onClick={() => {
                            goToDetail(item.id, item.action.id)
                        }} data-tip data-for={"_tooltip_dashboard_usr_detail_" + i}
                                key={"_tooltip_dashboard_usr_detail_" + i}>
                            {!item.img ? <FiUser className="h-20 w-full rounded-full border"/> :
                                <div className={"h-20 w-20 rounded-full border"} style={{
                                    backgroundImage: `url(${item.img})`,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "center"
                                }}
                                ></div>}
                        </button>
                        <EnigooToolTip id={"_tooltip_dashboard_usr_detail_" + i}
                                       content={t("accreditation.goToDetail")}/>
                    </div>

                    <div className="flex flex-col w-full">
                        <div className="text-sm font-bold">{item.firstname} {item.lastname}</div>
                        <div className="text-sm">{item.groups?.name ? item.groups.name : "-"}</div>
                        <div className="text-sm">{item.dateCreate}</div>
                        <div className="text-sm">{item.action.name}</div>

                    </div>
                </div>
            ))}
        </div>
    )
}


