import {apiExportUser} from "./Actions";
import CogoToast from "cogo-toast";


export const userExport = (userIds, setPrinting, actualAction, setError, filteredData, all,errorNoData,errorPrint,errorExport) => {
    setPrinting(true);
    if ((userIds === null || userIds.length === 0) && !all) {
        CogoToast.error(errorNoData);
        setPrinting(false);
        return 0;
    }
    let data = {};
    data.userIds = userIds;
    data.actionId = actualAction.actualAction;
    data.filterData = filteredData;
    data.all = all;
    data.type = "xls";
    apiExportUser(data, (data) => {
        if (data.error && data.error.length > 0) setError(data.error);
        exportData(data, setPrinting);
    }, (error) => {
        CogoToast.error(errorExport);
        setPrinting(false);
    })

}

const exportData = (data, setPrinting) => {
    const url = window.URL.createObjectURL(new Blob([data.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', data.fileName); //or any other extension
    document.body.appendChild(link);
    link.click();
    setPrinting(false);
}
