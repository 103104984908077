import React, {useEffect, useRef, useState} from "react";
import {shallowEqual, useSelector} from "react-redux";
import Portal from "../../../../components/portal";
import {FiCheck, FiX} from "react-icons/fi";
import Widget from "../../../../components/widget";
import EnigooButton from "../../../../MyComponent/EnigooButton";
import {Trans, useTranslation} from "react-i18next";
import {Link} from "react-router-dom";

export const UserPrintErrorModal = ({error, setError}) => {
    const {palettes} = useSelector(
        (state) => ({
            palettes: state.reducerAdmin.palettes
        }),
        shallowEqual
    )
    let {background} = {
        ...palettes
    }

    const modalRef = useRef(null)
    const [open, setOpen] = useState(false)
    const {t} = useTranslation()

    const show = () => {
        setOpen(true)
    }
    const hide = () => {
        setOpen(false)
        setError(null);
    }

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (!modalRef || !modalRef.current) return false
            if (!open || modalRef.current.contains(event.target)) {
                return false
            }
            setOpen(!open)
        }
        document.addEventListener('mousedown', handleClickOutside)
        if (error) setOpen(true);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [open, modalRef, error])
    if (open && error) {
        return (
            <Portal selector="#portal">
                <div className="modal-backdrop fade-in"></div>
                <div className={`modal show ${background === 'dark' ? 'dark-mode' : ''}`}
                     data-background={background}>
                    <div className="relative min-w-sm w-auto mx-auto lg:max-w-5xl" ref={modalRef}>
                        <div className="modal-content">
                            <div className="modal-header">
                                <h3 className="text-xl font-semibold">{t("accreditation.printErrorTitle")}</h3>
                                <button
                                    className="modal-close btn btn-transparent"
                                    onClick={hide}>
                                    <FiX size={18} className="stroke-current"/>
                                </button>
                            </div>
                            <div className="relative p-4 flex-auto">
                                <div className={"max-h-sm overflow-y-scroll p-2 pb-10"}>
                                    <Widget>
                                        <div className="flex flex-col">
                                            <div
                                                className={"text-enigoo-500 font-bold"}>{t("accreditation.printError")}:
                                            </div>
                                            {error.map((item, index) => (
                                                <div className={"hover:underline hover:font-bold"}>
                                                    <Link to={"/accreditation/detail/"+item.user} target={"_blank"}>
                                                        <Trans i18nKey={"accreditation.mailSendUserError"}
                                                               values={{id: item.user, error: item.error}}>
                                                            <div className={"text-sm"}>Uživatel s
                                                                id {item.user}: {item.error}</div>
                                                        </Trans>
                                                    </Link>
                                                </div>
                                            ))}
                                        </div>

                                    </Widget>
                                </div>
                            </div>
                            <div className="modal-footer space-x-2">
                                <EnigooButton onClick={hide} text={t("basics.close")} icon={<FiX/>}/>
                                <EnigooButton onClick={hide} icon={<FiCheck/>} text={t("basics.ok")}/>
                            </div>
                        </div>
                    </div>
                </div>
            </Portal>
        )
    } else {
        return <></>;
    }
}
