import React from "react";
import EnigooButton from "../../../MyComponent/EnigooButton";
import {FiEdit} from "react-icons/fi";
import {useTranslation} from "react-i18next";
import EnigooTablev2 from "../../../MyComponent/EnigooTablev2";

const TemplateTable = ({items}) => {
    const {t, i18n} = useTranslation()
    const columns = React.useMemo(() => [
        {
            header: t('basics.name'), accessorKey: 'name', id: "namee"
        },
        {
            header: t('basics.type'), accessorKey: 'type', id: "type"
        },
        {
            header: t('basics.options'),
            cell: ({row}) => (<EnigooButton icon={<FiEdit/>} link={"template/detail/" + row.original.id}/>)
        }

    ], [i18n.language])
    const tableData = React.useMemo(() => items.items, [items]);
    return <EnigooTablev2 columns={columns} data={tableData} paginated={true} selectable={false}/>
}
export default TemplateTable;
