import React, {useEffect, useState} from "react";
import {useHistory, useParams} from "react-router";
import CogoToast from "cogo-toast";
import {EnigooTextField} from "../../../MyComponent/EnigooTextField";
import {FiInfo, FiSave} from "react-icons/all";
import {Form} from "react-final-form";
import SectionTitle from "../../../components/section-title";
import EnigooButton from "../../../MyComponent/EnigooButton";
import Widget from "../../../components/widget";
import {EnigooLoader} from "../../../MyComponent/EnigooLoader";
import {IconTabs} from "../../../components/tabs";
import {
    apiManageCreateSubject,
    apiManageGetSubjectById,
    apiManageGetWebChannelForSubject,
    apiManageUpdateSubject
} from "./Actions";
import WebChannelTable from "./WebChannelTable";
import {EnigooSelectMulti} from "../../../MyComponent/EnigooSelectMulti";

const SubjectForm = (props) =>{
    let [subjectData, setSubjectData] = useState(null);
    let [webChannelData, setWebChannelData] = useState(null);
    let [loading, setLoading] = useState(false);
    let [saving, setSaving] = useState(false);

    const history = useHistory();

    const {id} = useParams();

    useEffect(()=>{
        init();
        getWebChannelData();
    },[]);

    const init = ()=>{

        if(id){
            setLoading(true);
            apiManageGetSubjectById(id,(data)=>{
                setSubjectData(data);
                setLoading(false);
            },(error)=>{
                setSubjectData(null);
                CogoToast.error("Nepodařilo se načíst data pro editaci subjektu...");
                setLoading(false);
            })
        }
    }

    const getWebChannelData=()=>{
        apiManageGetWebChannelForSubject(id,(data)=>{
            setWebChannelData(data);
        },error=>{
            setWebChannelData([]);
        })
    }

    const onSubmit = (values) => {
        setSaving(true);
        if (id) {
            apiManageUpdateSubject(values,id,(data)=>{
                setSaving(false);
                CogoToast.success("Subjekt byl úšěšně upraven.")
                history.push("/manage/subject/detail/"+data.id);
            },(error)=>{
                setSaving(false);
                CogoToast.error("Subjekt se nepodařilo upravit\n"+error.message);
            })
        } else {
            apiManageCreateSubject(values,(data)=>{
                setSaving(false);
                CogoToast.success("Subjekt byl úspěšně vytvořen");
                history.push("/manage/subject/detail/"+data.id);
            },(error)=>{
                setSaving(false);
                CogoToast.error("Subjekt se nepodařilo vytvořit\n"+error.message);
            })
        }
    }

    const TabBasicInfo = () => (
        <>
            <EnigooTextField name={"name"} label={"Název: *"} placeHolder={"Zadejte název..."} />
            <EnigooTextField name={"path"} label={"Cesta k souborům: *"} placeHolder={"Zadejte cestu k souborům..."} disabled={!!id}/>
            <EnigooSelectMulti label={"Rozšíření akcí: "} name={"extensions"} items={[
                {
                    id:1,
                    label:"Občerstvení"
                },
                {
                    id:2,
                    label:"Parkování"
                }
            ]} defaultValue={["id","label"]}/>
        </>
    )

    const tabs = [{
        index: 0,
        content: <TabBasicInfo/>,
        title: (<><FiInfo size={18} className={"stroke-current"}/><span
            className={"ml-2"}>Základní informace</span> </>)

    }
    ];

    return(
        <div className="flex flex-col">
            <Form onSubmit={onSubmit} initialValues={subjectData}
                  validate={values => {
                      let errors = {};
                      if (!values.name) {
                          errors.name = "Název je povinný"
                      }if(!values.path){
                          errors.path = "Cesta k souborům je povinná"
                      }
                      return errors;
                  }}
                  render={({handleSubmit}) => (
                      <>
                          <SectionTitle title="Subjekt" subtitle={id ? "Editace" : "Vytvoření"}
                                        right={<EnigooButton disabled={saving||loading} icon={<FiSave size={15}/>}
                                                             loading={saving}
                                                             onClick={handleSubmit}
                                                             text={"Uložit"}/>}/>
                          <Widget zIndex={1}>
                              {loading ? <EnigooLoader text={"Načítám data pro editaci subjektu..."}/> :
                                  <div className="w-full">
                                      <IconTabs tabs={tabs}/>
                                  </div>
                              }
                          </Widget>
                          {id?<Widget title={"Webové kanály"}>
                                <WebChannelTable subjectId={id}/>
                          </Widget>:<></>}
                      </>)}
            />
        </div>
    )
}

export default SubjectForm;
