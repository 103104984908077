import React from "react";
import {shallowEqual, useSelector} from "react-redux";
import ErrorPage from "../Error/ErrorPage";
import {isArray} from "@craco/craco/lib/utils";


const VerifyRights = ({page,action,children,type="content",extension=null,onlyForManageUser,onNoRights=null,onlyDisable=false}) =>{
    const {adminRights} = useSelector(
        state => ({adminRights:state.reducerAdmin.adminRights}),
        shallowEqual
    )

    const verifyExtensions=()=>{
        if(extension && isArray(extension)){
            let corrects = 0;
            extension.forEach((item,index)=>{
                if(adminRights?.subject?.extensions && adminRights.subject.extensions.includes(item)){
                    corrects++;
                }
            })

            if(corrects>0){
                return children;
            }else if(type==="route"){
                return onNoRights?onNoRights: <ErrorPage/>
            }else{
                return onNoRights?onNoRights: null;
            }
        }else{
            return children;
        }
    }
    if(onlyForManageUser){
        if(adminRights.manageuser){
            return children;
        }else{
            return <></>
        }
    }
    if(adminRights.manageuser) return verifyExtensions();
    if(adminRights.superuser) return verifyExtensions();
    if(!adminRights.rights) return type==="route"?<ErrorPage/>:null;
    if(Array.isArray(page) && Array.isArray(action) && page.length===action.length){
        let corrects = 0;
        page.forEach((item,index)=>{
            if(adminRights.rights[item] && adminRights.rights[item][action[index]] && adminRights.rights[item][action[index]].is===true){
                corrects++;
            }
        })
        if(corrects>0){
            return verifyExtensions();
        }else if(type==="route"){
            return onNoRights?onNoRights: <ErrorPage/>
        }else{
            return null;
        }
    }
    else if(Array.isArray(action)){
        let corrects = 0;
        action.map((item,index)=>{
            if(adminRights.rights[page] && adminRights.rights[page][item] && adminRights.rights[page][item].is===true){
                corrects++;
            }
        })
        if(corrects>0){
            return verifyExtensions();
        }else if(type==="route"){
            return onNoRights?onNoRights: <ErrorPage/>
        }else{
            return onlyDisable?<div className={"disabled"}>{children}</div>:null;

        }
    }
    else if(adminRights.rights[page] && adminRights.rights[page][action] && adminRights.rights[page][action].is===true){
        return verifyExtensions();
    }else{
        return type==="route"? onNoRights?onNoRights: <ErrorPage/>:null;
    }
}
export default VerifyRights;
