import {axios} from "../../../axiosConfig";

const url = "api/manage/v1";

export function apiManageLoginUser(data,callback, error){
    axios.post(url+"/login",data)
        .then(response=>callback(response.data.data))
        .catch(err=>error(err));
}

export function apiManageAuthorizeManageUser(callback,error){
    axios.post(url+"/authorize")
        .then(response=>callback(response.data.data))
        .catch(err=>error(err))
}

export function apiManageLogoutUser(callback,error){
    axios.post(url+"/logout")
        .then(response=>callback(response.data.data))
        .catch(err=>error(err));
}