import React from 'react';
import {Route, Switch} from 'react-router-dom';
import Subject from "./pages/Subject/Subject";
import SubjectForm from "./pages/Subject/SubjectForm";
import AdminUserForm from "./pages/AdminUser/AdminUserForm";
import AdminUser from "./pages/AdminUser/AdminUser";


const Routes = () => {
    return (
        <Switch>
            <Route exact path={"/manage"} component={Subject}/>
            <Route exact path={"/manage/subject"} component={Subject}/>
            <Route exact path={"/manage/subject/create"} component={SubjectForm}/>
            <Route exact path={"/manage/subject/detail/:id"} component={SubjectForm}/>
            <Route exact path={"/manage/admin-user"} component={AdminUser}/>
            <Route exact path={"/manage/admin-user/detail/:id"} component={AdminUserForm}/>
            <Route exact path={"/manage/admin-user/create"} component={AdminUserForm}/>

        </Switch>
    )
}
export default Routes
