import React from 'react'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {FiMenu} from 'react-icons/fi'
import LoggedInUser from './loggedInUser'
import '../../css/components/navbar.css'
import EnigooActionDropDown from "../../MyComponent/Api/Admin/EnigooActionDropDown";
import EnigooSubjectDropDown from "../../MyComponent/Api/Admin/EnigooSubjectDropDown";
import EnigooLanguageDropDown from "../../MyComponent/EnigooMultiLanguageField/EnigooLanguageDropDown";
import EnigooDarkModeSwitch from "../../MyComponent/EnigooDarkModeSwitch";
import {RiMenuFoldFill, RiMenuUnfoldFill} from "react-icons/all";

const Navbar = () => {
    const {config} = useSelector(
        state => ({
            config: state.reducerAdmin.config
        }),
        shallowEqual
    )
    let {rightSidebar, collapsed} = {...config}
    const dispatch = useDispatch()

    let path = window.location.pathname;
    path = path.substring(0, path.lastIndexOf("/")) === "/accreditation/detail" ? path.substring(0, path.lastIndexOf("/")) : path;

    return (
        <div className={`navbar navbar-1 border-b fixed top-0 right-0 left-0 z-10 md:${!collapsed?"ml-64":"ml-16"} lg:${!collapsed?"ml-64":"ml-16"}`}>
            <div className="navbar-inner w-full flex items-center justify-start">
                <button
                    onClick={() =>
                        dispatch({
                            type: 'SET_CONFIG_KEY',
                            key: 'collapsed',
                            value: !collapsed
                        })
                    }
                    className="mx-4 text-enigoo-500 md:flex lg:flex hidden">
                    {!collapsed?<RiMenuFoldFill size={20}/>:<RiMenuUnfoldFill size={20}/>}
                </button>
                <button
                    onClick={() =>
                        dispatch({
                            type: 'SET_CONFIG_KEY',
                            key: 'collapsed',
                            value: !collapsed
                        })
                    }
                    className="mx-4 text-enigoo-500 md:hidden lg:hidden flex">
                    {collapsed?<RiMenuFoldFill size={20}/>:<RiMenuUnfoldFill size={20}/>}
                </button>
                {/*TODO: Zatím schované, protože není v provozu*/}
                {/*<Search />*/}
                <div><EnigooSubjectDropDown/></div>
                <span className="ml-auto"></span>

                <div className={"flex flex-row space-x-2 mr-2 items-center"}>
                {path === "/accreditation" || path === "/accreditation/create" || path === "/accreditation/detail" || path === "/accreditation/import" ?
                     <EnigooActionDropDown/> : <></>}
                <EnigooLanguageDropDown type={"admin"}/>
                <LoggedInUser/>
                <EnigooDarkModeSwitch/>
                </div>
            </div>
        </div>
    )
}

export default Navbar
