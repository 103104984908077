import React from 'react'
import {Link} from "react-router-dom";

const ErrorPage = ({error}) => {
    return (
        <div className={"h-screen w-full flex justify-center items-center"}>
            <div className="flex flex-col w-full max-w-xl text-center">
                <img
                    className="object-contain w-auto h-64 mb-8"
                    src="/pages/error-page/illustration.svg"
                    alt="svg"
                />
                <h1 className="text-6xl text-enigoo-500 mb-4">{error ? error.code : 404}</h1>

                <div className="mb-8 text-center text-grey-500">
                    {error ? error.message :
                        "Omlouváme se, ale stránka nebyla nalezena, vraťte se na domací stránku."
                    }
                </div>
                <div className="flex w-full">
                    <Link
                        to="/"
                        className="btn btn-lg btn-rounded btn-block bg-enigoo-500 hover:bg-enigoo-600 text-white">
                        Go back
                    </Link>
                </div>
            </div>
        </div>
    )
}
export default ErrorPage;
