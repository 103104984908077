import React, {useEffect, useState} from 'react'
import {axios} from "../../../axiosConfig";
import {Form} from "react-final-form";
import CogoToast from "cogo-toast";
import EnigooButton from "../../../MyComponent/EnigooButton";
import {EnigooTextField} from "../../../MyComponent/EnigooTextField";
import SectionTitle from "../../../components/section-title";
import {FiLogIn} from "react-icons/all";
import Widget from "../../../components/widget";
import {apiManageLoginUser} from "./Actions";
import {useHistory} from "react-router-dom";

export const ManageLoginForm = ({setToken}) => {

    let [saving, setSaving] = useState(false);
    let submit;

    let history = useHistory();


    useEffect(() => {
    }, []);
    const onKeyDown = (event)=>{
        if(event.key==='Enter'){
            event.preventDefault();
            submit();
        }
    }


    const onSubmit = values => {
        setSaving(true);
        apiManageLoginUser(values, (data) => {
            axios.defaults.headers.common['authorization-manage'] = data.token;
            localStorage.setItem("ath-acc-en-mn", data.token);
            setSaving(false);
            setToken(data.token);
            history.push("/manage");
        }, (error) => {
            CogoToast.error("Špatné přihlašovací údaje");
            setSaving(false);
        });

    }
    return (
        <>
            <SectionTitle title="Akreditační systém - MANAGE MODUL" subtitle={"PŘIHLÁŠENÍ"}
                          right={<EnigooButton onClick={() => submit()} icon={<FiLogIn/>} text={"Přihlásit"}
                                               loading={saving} disabled={saving}/>}/>
            <Widget>
                <div className="flex flex-col">
                    <Form onSubmit={onSubmit}
                          validate={values => {
                              let errors = {};
                              if (!values.password) {
                                  errors.password = "Heslo je povinné"
                              }
                              if (!values.login) {
                                  errors.login = "Login je povinný"
                              }
                              return errors;
                          }}
                          render={({handleSubmit}) => {
                              submit = handleSubmit;
                              return (
                                  <div className="w-full">
                                      <EnigooTextField label={"Login:"} name={"login"} onKeyDown={onKeyDown}/>
                                      <EnigooTextField label={"Heslo:"} name={"password"}
                                                       type={"password"} onKeyDown={onKeyDown}/>
                                  </div>
                              )
                          }
                          }

                    />
                </div>
            </Widget>
        </>
    );
}

