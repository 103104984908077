import React, {useEffect, useState} from 'react';
import {Form, useFormState} from "react-final-form";
import {useHistory, useParams} from "react-router";
import CogoToast from "cogo-toast";
import EnigooButton from "../../../MyComponent/EnigooButton";
import {FiInfo, FiSave} from "react-icons/all";
import Widget from "../../../components/widget";
import {EnigooLoader} from "../../../MyComponent/EnigooLoader";
import {EnigooFieldsGroup} from "../../../MyComponent/EnigooFieldsGroup";
import {EnigooTextField} from "../../../MyComponent/EnigooTextField";
import {apiCreateZone, apiGetZoneById, apiUpdateZone} from "./Actions";
import {IconTabs} from "../../../components/tabs";
import EnigooBreadcrumbs from "../../../MyComponent/EnigooBreadcrumbs";
import {ActionSelectField} from "../../../MyComponent/Api/Admin/ActionSelectField";
import VerifyRights from "../../VerifyRights";
import {Trans, useTranslation} from "react-i18next";
import EnigooFormChangedAlert from "../../../MyComponent/EnigooFormChangedAlert";

const ZoneForm = () => {
    let [zoneData, setZoneData] = useState(null);
    let [loading, setLoading] = useState(false);
    let [saving, setSaving] = useState(false);
    let submit;
    const history = useHistory();
    const {t} = useTranslation()
    const {id} = useParams();

    useEffect(() => {
        const handleKeyDown = (event) => {
            if ((event.ctrlKey || event.metaKey) && event.key === 's') {
                event.preventDefault();
                submit();
                console.log('Ctrl/Command + S was pressed');
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    useEffect(() => {
        init();
    }, []);


    const init = () => {
        if (id) {
            setLoading(true);
            apiGetZoneById(id, (data) => {
                setZoneData(data);
                setLoading(false);
            }, (error) => {
                CogoToast.error(t("zones.loadingDataError"))
            })
        }
    }

    const onSubmitSuccess = (data,values) => {
        CogoToast.success(t("zones.saveSuccess"));
        setSaving(false);
        setZoneData(values)
        history.push("/zone/detail/" + data.id);
    }

    const onSubmitError = (error) => {
        CogoToast.error(t("zones.saveError"));
        setSaving(false);
    }

    const onSubmit = (values) => {
        setSaving(true);
        if (id) {
            apiUpdateZone(id, values, (data)=>onSubmitSuccess(data,values), onSubmitError)
        } else {
            apiCreateZone(values, (data)=>onSubmitSuccess(data,values), onSubmitError)
        }
    }

    const TabBasicsInfo = () => (
        <EnigooFieldsGroup items={[
            <EnigooTextField label={t("basics.name") + ": *"} name={"name"} outsideClassName={"w-full lg:w-1/3"}
                             placeHolder={t("placeHolder.name")} key={"1"}/>,
            <EnigooTextField label={t("zones.nameOnAccreditation") + ":"} name={"onAccreditation"}
                             outsideClassName={"w-full lg:w-1/3"} placeHolder={t("placeHolder.name")}
                             key={"2"}/>,
            <ActionSelectField name={"actionId"} label={t("action.single") + ": "} outsideClassName={"w-full lg:w-1/3"}
                               isMulti={false}/>
        ]}/>
    );

    const tabs = () => [
        {
            index: 0,
            content: <TabBasicsInfo/>,
            title: (<><FiInfo size={18} className={"stroke-current "}/><span
                className={"ml-2"}><Trans i18nKey={"basics.info"}>Zakladni info</Trans></span> </>)
        }
    ];

    return (
        <Form onSubmit={onSubmit} initialValues={zoneData}
              validate={values => {
                  let errors = {};
                  if (!values.name) {
                      errors.name = t("basics.requireField")
                  }
                  return errors;
              }
              } render={({handleSubmit, dirty,form}) => {
            submit = handleSubmit;
            return (
                <>
                    <EnigooBreadcrumbs
                        right={<VerifyRights page={"zone"} action={id ? "updateZone" : "createZone"}>
                            <EnigooButton onClick={() => submit()} icon={<FiSave/>} text={t("basics.save")}
                                          loading={saving} disabled={saving || loading}/>
                        </VerifyRights>}/>
                    {dirty ? <EnigooFormChangedAlert dirty={dirty} onSave={handleSubmit} onReset={()=>form.reset(zoneData)}/> : <></>}

                    <Widget>
                        {loading ? <EnigooLoader text={t("zones.loadingData")}/> :
                            <div className="flex flex-col">

                                <div className={"w-full"}>
                                    <IconTabs tabs={tabs()}/>
                                </div>

                            </div>
                        }
                    </Widget>
                </>)
        }}
        />
    )
}

export default ZoneForm;
