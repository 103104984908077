import React, {useEffect, useState} from "react";
import {shallowEqual, useSelector} from "react-redux";
import EnigooBreadcrumbs from "../../../MyComponent/EnigooBreadcrumbs";
import Widget from "../../../components/widget";
import EnigooButton from "../../../MyComponent/EnigooButton";
import {apiGetImageImport, apiGetImportTemplate, apiImport, apiImportImage} from "./Actions";
import CogoToast from "cogo-toast";
import {FiDownload, FiUpload} from "react-icons/all";
import {useFilePicker} from "use-file-picker";
import {FiFile, FiX} from "react-icons/fi";
import Dropzone from "react-dropzone";
import {useTranslation} from "react-i18next";
import VerifyRights from "../../VerifyRights";
import EnigooTablev2 from "../../../MyComponent/EnigooTablev2";
import {EnigooSelect} from "../../../MyComponent/EnigooSelect";
import {Form} from "react-final-form";
import {apiGetActionById} from "../Action/Actions";


const UserImport = () => {
    let [downloading, setDownloading] = useState(false);
    let [importing, setImporting] = useState(false);
    let [selectedFile, setSelectedFile] = useState(null);
    const [files, setFiles] = useState([]);
    let [importingImage, setImportingImage] = useState(false);
    let [imageImportData, setImageImportData] = useState([]);

    let [activeExtension, setActiveExtension] = useState([]);

    const {t, i18n} = useTranslation();
    const {actualAction} = useSelector(
        state => (state.reducerAdmin.actualAction),
        shallowEqual
    )

    const {adminRights} = useSelector(
        state => ({adminRights: state.reducerAdmin.adminRights}),
        shallowEqual
    )

    useEffect(() => {
        let ext = [];
        if (adminRights?.subject?.extensions && adminRights.subject.extensions.length > 0) {


            apiGetActionById(actualAction, (data) => {
                if (!data.extension) {
                    setActiveExtension([]);
                } else if (Array.isArray(data.extension)) {
                    data.extension.forEach(item => {
                        ext.push(item.type);
                    })
                }
                setActiveExtension(ext);
            }, (err) => {
                setActiveExtension(ext);
            })
        }
    }, [actualAction]);


    useEffect(() => {
        getImageImportData();
    }, [actualAction]);


    const getImageImportData = () => {
        apiGetImageImport(actualAction, (data) => {
            setImageImportData(data);
        }, (err) => {
        })
    }

    const downloadTemplate = () => {
        setDownloading(true);
        apiGetImportTemplate(actualAction, (data) => {
            const url = window.URL.createObjectURL(new Blob([data.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', data.fileName); //or any other extension
            document.body.appendChild(link);
            link.click();
            setDownloading(false);
        }, (error) => {
            CogoToast.error(t("userImport.cannotDownloadTemplate"));
            setDownloading(false);
        })

    }

    const importData = (values) => {
        if (selectedFile) {
            setImporting(true);
            let data = {
                dataFile: selectedFile,
                actionId: actualAction,
                zonesImportType: values.zonesImportType,
                extensionImportType: activeExtension.length > 0 ? values.extensionImportType : 1
            }
            apiImport(data, (data) => {
                CogoToast.success(t("userImport.dataImported"));
                setImporting(false);
                getImageImportData();
                setSelectedFile(null);
            }, (error) => {
                CogoToast.error(getErrorMessage(error));
                setImporting(false);
            });
        } else {
            CogoToast.error(t("userImport.chooseFile"));
        }
    }

    const importImage = () => {
        let ok = [];
        let err = [];
        setImportingImage(true);
        files.map((item, index) => {
            let image = URL.createObjectURL(item);
            let reader = new FileReader();
            reader.onload = (event) => {
                apiImportImage(event.target.result, item.name, (data) => {
                    ok.push(t("userImport.imageSaved", {name: item.name}));
                    if (ok.length + err.length === files.length) {
                        err.length > 0 && CogoToast.error(err);
                        ok.length > 0 && CogoToast.success(ok);
                        setFiles([]);
                        setImportingImage(false);
                    }
                }, (error) => {
                    err.push(t("userImpoprt.imageCannotPair", {name: item.name}))
                    if (ok.length + err.length === files.length) {
                        err.length > 0 && CogoToast.error(err);
                        ok.length > 0 && CogoToast.success(ok);
                        setFiles([]);
                        setImportingImage(false);
                    }
                })
            }
            reader.readAsDataURL(item);
        })
    }

    const getErrorMessage = (error) => {
        let message = error.response.data.error;
        if (!message) return t("userImport.errorAtImport");
        switch (message) {
            case "DATA_BASE64_INVALID":
                return t("userImport.dataBase64Invalid");
            case "INVALID_IMPORT_FILE_FORMAT":
                return t("userImport.invalidFileFormat");
            case "IMAGE_BASE64_INVALID":
                return t("userImport.imageBase64Invalid");
            case "ACTION_DOESNT_EXIST":
                return t("userImport.actionDoesntExist");
            default:
                let row = {row: message.substring(message.lastIndexOf("_") + 1)}
                if (message.search("CUSTOM_FIELD_DOESNT_EXIST_ON_ROW_") !== -1) {
                    return t("userImport.customFieldNotExist", row)
                } else if (message.search("ADDRESS_INVALID_ON_ROW_") !== -1) {
                    return t("userImport.addressInvalid", row)
                } else if (message.search("NUMBER_FORMAT_INVALID_ON_ROW_") !== -1) {
                    return t("userImport.numberFormatInvalid", row)
                } else if (message.search("ZONE_DOESNT_EXIST_ON_ROW_") !== -1) {
                    return t("userImport.zoneNotExist", row)
                } else if (message.search("GROUP_INVALID_ON_ROW_") !== -1) {
                    return t("userImport.groupNotExist", row)
                } else if (message.search("EMPTY_FIRSTNAME_ON_ROW_") !== -1) {
                    return t("userImport.fristnameEmpty", row)
                } else if (message.search("EMPTY_LASTNAME_ON_ROW_") !== -1) {
                    return t("userImport.lastnameEmpty", row)
                } else if (message.search("EMPTY_EMAIL_ON_ROW_") !== -1) {
                    return t("userImport.emailEmpty", row)
                } else if (message.search("INVALID_CUSTOM_FIELD_VALUE_ON_ROW_") !== -1) {
                    return t("userImport.customFielldNotValid", row)
                } else if (message.search("IMAGE_NAME_NOT_EXIST_ON_ROW_") !== -1) {
                    return t("userImport.imageNotFound", row)
                } else if (message.search("BC_EXIST_") !== -1) {
                    return t("userImport.bcExist", {bc: row.row})
                } else if (message.search("CUSTOM_FIELD_MANDATORY_ON_ROW_") !== -1) {
                    return t("userImport.customFieldMandatory", row)
                } else {
                    return t("userImport.basicsError", {bc: row.row})
                }
        }
    }

    const SelectFile = () => {
        const [openFileSelector, {filesContent, loading, clear}] = useFilePicker({
            accept: ['.xlsx', '.xls'],
            readAs: 'DataURL',
            multiple: false,
            limitFilesConfig: {max: 1}
        });
        if (filesContent && filesContent[0]) {
            setSelectedFile(filesContent[0].content);
        }
        return (
            <>
                <div className={"form-element mr-1"}>
                    {selectedFile ?
                        <EnigooButton onClick={() => {
                            clear();
                            setSelectedFile(null)
                        }}
                                      text={t("accreditation.import.cancelFileWithData")} color={"red"}
                                      icon={<FiX className={"mr-2"}/>}/> :

                        <EnigooButton onClick={() => openFileSelector()}
                                      text={t("accreditation.import.chooseFileWithData")}
                                      icon={<FiFile className={"mr-2"}/>}/>}
                </div>
            </>
        )
    }

    const SelectImageFile = () => {
        return (
            <div className={"form-element"}>
                <span className={"form-label"}>{t("photo.plural")}: </span>
                <div className={"form-input w-full h-32"}>
                    <Dropzone disabled={files.length >= 20}
                              onDropRejected={(e) => {
                                  let error = "";
                                  if (e.length > 20) {
                                      CogoToast.error(t("accreditation.impport.toMuchFiles"));
                                  } else {
                                      e.map((item, index) => {
                                          error += t("accreditation.import.imageNoAccepted", {fileName: item.file.name})
                                      })
                                      CogoToast.error(error)
                                  }
                              }
                              } maxFiles={20}
                              maxSize={5000000} onDrop={acceptedFiles => {
                        setFiles([...files, ...acceptedFiles]);
                    }}>
                        {({getRootProps, getInputProps}) => (
                            <div {...getRootProps()} className={"h-full text-center justify-center items-center"}>
                                <input {...getInputProps()} className={"w-full h-full"}/>
                                <div className={"text-xl flex flex-col items-center"}>
                                    {t("accreditation.import.imageImportLabel")}
                                    <FiUpload className={"mt-2"}/>
                                    <div className={"text-sm"}>{t("accreditation.import.imageFilesLimit")}
                                    </div>
                                </div>
                            </div>
                        )}
                    </Dropzone>
                </div>
                <div className={"form-label"}>{t("accreditation.import.loadedImage")}:</div>
                <div className={"form-input"}>
                    {files.map((item, index) => (
                            <div className={"rounded border p-2 m-1 "} key={index.toString()}>
                                <div className={"grid grid-cols-2"}>
                                    <div>#{index + 1} {item.name} - {item.size} bytes</div>
                                    <div
                                        className={"text-right "}
                                        onClick={() => {
                                            setFiles([...files.slice(0, index), ...files.slice((index + 1))])
                                        }
                                        }
                                        style={{cursor: "pointer"}}>❌
                                    </div>
                                </div>
                            </div>
                        )
                    )}
                    {files.length > 0 &&
                        <EnigooButton color={"red"} icon={<FiX/>} text={t("accreditation.import.cancelAll")}
                                      onClick={() => setFiles([])}/>}
                </div>
                <EnigooButton disabled={importingImage} loading={importingImage} onClick={() => {
                    importImage();
                }} text={"Odeslat"} icon={<FiUpload/>}/>
            </div>
        )
    }

    const ImageImports = () => {

        const columns = React.useMemo(() => [
            {header: t('accreditation.import.imageName'), id: "imageName", accessorKey: 'imageName'},
            {header: t('accreditation.import.accreditationName'), id: "accName", accessorKey: "user"}
        ], [i18n.language]);
        return (
            <>
                <div className={"text-xl"}>{t("accreditation.import.needImportPhoto")}:</div>
                <EnigooTablev2 data={imageImportData} columns={columns} selectable={false}/>
            </>
        )
    }

    let zoneImportType = React.useMemo(() => {
        return [
            {
                "id": 1,
                "value": t("accreditation.import.zonesManually")
            },
            {
                "id": 2,
                "value": t("accreditation.import.zonesFromGroup")
            }
        ]
    }, [i18n.language])

    let extensionImportType = React.useMemo(() => {
        return [
            {
                "id": 1,
                "value": t("accreditation.import.extensionsManually")
            },
            {
                "id": 2,
                "value": t("accreditation.import.extensionsFromGroup")
            }
        ]
    }, [i18n.language])

    return (
        <>
            <EnigooBreadcrumbs/>
            <div className={"lg:flex lg:flex-col"}>
                <div className={"lg:flex lg:flex-row"}>
                    <div className={"w-full flex-col"}>
                        <Widget>
                            <div
                                className={"flex flex-col content-center justify-center place-content-center text-center"}>

                                <div className={"text-left"}>
                                    <div dangerouslySetInnerHTML={{__html: t("accreditation.import.description")}}/>
                                </div>
                            </div>
                        </Widget>
                    </div>
                </div>
                <div className={"lg:flex lg:flex-row"}>
                    <div className={"lg:w-1/2 w-full flex-col"}>
                        <VerifyRights page={"userImport"} action={"getTemplateToImport"}> <Widget>
                            <div
                                className={"flex flex-col content-center justify-center place-content-center text-center"}>
                                <div className={"text-xl"}>{t("accreditation.import.templateForImport")}<br/>
                                </div>
                                <EnigooButton onClick={downloadTemplate} disabled={downloading} loading={downloading}
                                              text={t("accreditation.import.downloadTemplate")}
                                              icon={<FiDownload className={"mr-3"}/>}/>
                            </div>
                        </Widget>
                        </VerifyRights>
                    </div>
                    <div className={"lg:w-1/2 w-full ml-2"}>
                        <Widget>
                            <Form onSubmit={importData}
                                  validate={(values) => {
                                      let errors = {};
                                      if (!values.zonesImportType || ![1, 2].includes(values.zonesImportType)) {
                                          errors.zonesImportType = t("basics.requireField");
                                      }
                                      if (activeExtension.length > 0 && (!values.extensionImportType || ![1, 2].includes(values.extensionImportType))) {
                                          errors.extensionImportType = t("basics.requireField");
                                      }

                                      return errors;
                                  }}
                                  render={({handleSubmit, values}) => {
                                      return (
                                          <>
                                              <EnigooSelect
                                                  label={t("accreditation.import.howToSetZonesInImport") + ":"}
                                                  name={"zonesImportType"} items={zoneImportType}
                                                  defaultValue={["id", "value"]}/>
                                              {activeExtension.length > 0 && <EnigooSelect
                                                  label={t("accreditation.import.howToSetExtensionsInImport") + ":"}
                                                  name={"extensionImportType"} items={extensionImportType}
                                                  defaultValue={["id", "value"]}/>}
                                              <div
                                                  className={"flex flex-col content-center justify-center text-center place-content-center"}>
                                                  <div className={"text-xl"}>
                                                      {t("accreditation.import.insertFilesWithData")}<br/>
                                                  </div>
                                                  <><SelectFile/></>

                                                  <EnigooButton onClick={handleSubmit} disabled={importing}
                                                                loading={importing}
                                                                text={t("accreditation.import.import")}
                                                                icon={<FiUpload className={"mr-3"}/>}/>

                                              </div>
                                          </>
                                      )
                                  }}/>

                        </Widget>
                    </div>
                </div>
                <div className={"lg:flex lg:flex-row"}>
                    <VerifyRights page={"imageImport"} action={"importImage"}>
                        <div className={"lg:w-1/2 w-full ml-2"}>
                            <Widget>
                                <><SelectImageFile/></>
                            </Widget>
                        </div>
                    </VerifyRights>
                    <VerifyRights page={"imageImport"} action={"getImageImport"}>
                        <div className={"lg:w-1/2 w-full ml-2"}>
                            <Widget>
                                <><ImageImports/></>
                            </Widget>
                        </div>
                    </VerifyRights>
                </div>
            </div>
        </>
    )
}
export default UserImport;
