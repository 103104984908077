import {Field} from "react-final-form";
import PropTypes from "prop-types";
import React, {useState} from "react";
import {FiHelpCircle} from "react-icons/fi";
import {AiOutlineEye, AiOutlineEyeInvisible} from "react-icons/all";
import EnigooToolTip from "./EnigooToolTip";

export const EnigooTextField = ({
                                    inline = false,
                                    label,
                                    name,
                                    type = 'text',
                                    placeHolder = 'Zadejte text...',
                                    disabled = false,
                                    outsideClassName = "w-full",
                                    visible = true,
                                    onChange = null,
                                    onKeyDown = null,
                                    toolTip,
                                    initValue = undefined,
                                    reference = null,
    toolTipPosition="right"
                                }) => {
    let [showPassword, setShowPassword] = useState(false);

    if (visible) {
        if (type === "password") {
            return (
                <div className={outsideClassName}>
                    <div className={`form-element ${inline ? 'form-element-inline' : ''}`}>
                        <Field name={name} defaultValue={initValue}>
                            {({input, meta}) => (
                                <>
                                    {label !== "" &&
                                        <div className={"form-label flex flex-row"}>{label}{toolTip && <><FiHelpCircle data-tip data-for={"_tooltip_" + name} key={name}
                                                                                                                       className={"ml-2 text-red-900"} size={18}/>
                                            <EnigooToolTip content={toolTip} id={"_tooltip_" + name} place={toolTipPosition}/></>}</div>}
                                    <div className={"flex flex-row space-x-2"}>
                                        <input
                                            ref={reference}
                                            {...input}
                                            onChange={(event) => {
                                                input.onChange(event);
                                                if (onChange) onChange(event);
                                                event.preventDefault();
                                            }}
                                            onKeyDown={onKeyDown}
                                            name={name}
                                            type={showPassword?"text":"password"}
                                            className={meta.error ? "form-input  form-input-invalid w-10/12" : "form-input w-10/12"}
                                            placeholder={placeHolder}
                                            disabled={disabled}
                                        />
                                        <button onClick={()=>setShowPassword(!showPassword)}>
                                            {showPassword?<AiOutlineEyeInvisible size={20}/>:<AiOutlineEye size={20}/>}
                                        </button>
                                    </div>
                                    {meta.error && <div className="form-error">{meta.error}</div>}
                                </>
                            )}
                        </Field>
                    </div>
                </div>
            )
        } else {
            return (
                <div className={outsideClassName}>
                    <div className={`form-element ${inline ? 'form-element-inline' : ''}`}>
                        <Field name={name} defaultValue={initValue}>
                            {({input, meta}) => (
                                <>
                                    {label !== "" &&
                                        <div className={"form-label flex flex-row"}>{label}{toolTip && <><FiHelpCircle data-tip data-for={"_tooltip_" + name} key={name}
                                                                                                                       className={"ml-2 text-red-900"} size={18}/>
                                            <EnigooToolTip content={toolTip} id={"_tooltip_" + name} place={toolTipPosition}/></>}</div>}
                                    <input
                                        ref={reference}
                                        {...input}
                                        onChange={(event) => {
                                            input.onChange(event);
                                            if (onChange) onChange(event);
                                            event.preventDefault();
                                        }}
                                        onKeyDown={onKeyDown}
                                        name={name}
                                        type={type}
                                        className={meta.error ? "form-input form-input-invalid" : "form-input"}
                                        placeholder={placeHolder}
                                        disabled={disabled}
                                    />
                                    {meta.error && <div className="form-error">{meta.error}</div>}
                                </>
                            )}
                        </Field>
                    </div>
                </div>
            )
        }

    } else {
        return <></>
    }
}
EnigooTextField.propTypes = {
    inline: PropTypes.bool,
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    type: PropTypes.string,
    placeHolder: PropTypes.string,
    outsideClassName: PropTypes.string
}
