import {axios} from "../../../axiosConfig";

export const apiGetImportTemplate = (actionId, callback, error) => {
    axios.get("api/admin/v1/user/import/template/" + actionId, {responseType: "blob"})
        .then(response => {
            let fileName = response.headers['content-disposition'].toString();
            fileName = fileName.substring(fileName.indexOf("=") + 1);
            let data = response.data;
            let array = [];
            array["fileName"] = fileName;
            array["data"] = data;
            callback(array);
        })
        .catch(err => error(err))
}

export const apiImport = (data, callback, error) => {
    axios.post("api/admin/v1/user/import", data)
        .then(response => callback(response.data.data))
        .catch(err => error(err));

}

export const apiImportImage = (file,name,callback,error)=>{
    axios.post("api/admin/v1/image/import",{imageName:name,imageData:file})
        .then((response)=>{
            callback(response.data.data);
        })
        .catch((err)=>{
            error(err);
        })
}

export const apiGetImageImport = (actionId,callback,error)=>{
    axios.get("api/admin/v1/image/import/"+actionId)
        .then(response=>callback(response.data.data))
        .catch((err)=>error(err))
}
