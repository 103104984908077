import {shallowEqual, useDispatch, useSelector} from "react-redux";
import React from 'react'
import EnigooButton from "../../MyComponent/EnigooButton";
import SectionTitle from "../../components/section-title";
import EnigooLanguageDropDown from "../../MyComponent/EnigooMultiLanguageField/EnigooLanguageDropDown";
import {useTranslation} from "react-i18next";

const LayoutOnline = ({children}) => {
    const {palettes, onlineConfig} = useSelector(
        (state) => ({
            config: state.reducerAdmin.config,
            palettes: state.reducerAdmin.palettes,
            onlineConfig: state.reducerOnline.onlineConfig
        }),
        shallowEqual
    )
    let {background} = {
        ...palettes
    }
    const {onlineLogo} = {...onlineConfig};
    const {t} = useTranslation()
    return (<div className={"flex flex-col justify-between min-h-screen"}>
            <div
                className={`${background === 'dark' ? 'dark-mode' : 'default-mode'} flex-grow bg-grey-50 dark:bg-grey-900`}>
                <div className={"wrapper"}>
                    <div className={"main w-full bg-grey-50 text-grey-900 dark:bg-grey-900 dark:text-white mb-32 "}>
                        <div className={"flex flex-col"}>
                            <div className={"flex flex-row"}>
                                <span className="ml-auto"></span>
                                <div className={"relative mr-2 mt-2"}><EnigooLanguageDropDown type={"online"}/></div>
                            </div>


                            <div className={"flex flex-col justify-center items-center bg-grey-50"}>
                                <div style={{
                                    backgroundImage: `url(${onlineLogo})`,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "center"
                                }} className={`max-w-8xl w-full ${onlineLogo ? 'h-40' : ''} mb-5`}>
                                    {/*<img className={"max-h-sm rounded border border-enigoo-500 "} src={item.data} alt={item.name}/>*/}
                                </div>
                                <div className={"flex flex-col bg-white border border-grey-200 lg:p-6 p-2 w-full max-w-8xl"}>
                                    <Content>{children}</Content>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <footer
                className={"lg:px-20 px-2 lg:py-5 py-2 text-lg p-6 bg-grey-200 fixed bottom-0 w-full items-center text-center lg:justify-between justify-center grid lg:grid-cols-3 grid-cols-1"}>
            <span className={"uppercase"}>
                {t("online.info.helpLine")}: <a className={"text-blue-700"}
                                                href={"tel:+420461049232"}>+420 461 049 232</a>
            </span>
                <span>
                Powered by: <a href={"https://www.enigoo.cz"} target={"_blank"}
                               className={"text-enigoo-500 font-bold"}>ENIGOO</a>
            </span>
                <span>
                <a className={"text-blue-700"} href={"mailto:info@enigoo.cz"}>info@enigoo.cz</a>
            </span>
            </footer>
        </div>

    )
}

const Content = ({children}) => {
    const {onlineConfig} = useSelector(
        (state) => ({
            onlineConfig: state.reducerOnline.onlineConfig
        }),
        shallowEqual
    )

    const {t, i18n} = useTranslation()
    const {afterAdd, added, error, online, afterAddAction} = {...onlineConfig};
    const dispatch = useDispatch();
    const getText = (type) => {
        switch (type) {
            case "afterAdd":
                if (afterAdd && afterAdd[i18n.language]) {
                    return afterAdd[i18n.language];
                } else if (afterAdd && afterAdd.cs) {
                    return afterAdd.cs;
                } else {
                    return "";
                }
            case "online":
                if (online && online[i18n.language]) {
                    return online[i18n.language];
                } else if (online && online.cs) {
                    return online.cs;
                } else {
                    return "";
                }
        }
    }
    if (error) {
        return (
            <div className={"lg:px-10 px-5"}>
                <SectionTitle title={t("basics.accreditation")} subtitle={t("basics.registration")}/>
                {getText("online") !== "" && <div className={"w-full text-center pb-3"}
                                                  dangerouslySetInnerHTML={{__html: getText("online")}}/>}
                <div className={"flex flex-col items-center"}>
                    <div className={"w-full text-center text-lg"}><p>{error}</p></div>
                </div>
            </div>
        )
    }
    if (!added) return children;
    else return (
        <div className={"w-full text-center"}>
            <div className={"flex flex-col items-center"}>
                {getText("afterAdd") !== "" ? <div className={"w-full text-center"}
                                                       dangerouslySetInnerHTML={{__html: getText("afterAdd")}}/> :
                    <p>{t("online.info.successRegister")}</p>}
                {(!afterAddAction || afterAddAction === 1) &&
                    <EnigooButton onClick={() => {
                        dispatch({
                            type: 'SET_ADDED',
                            value: false
                        })
                    }} text={t("online.info.addNext")}/>
                }
            </div>
        </div>

    )
}

export default LayoutOnline;
