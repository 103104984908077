import React, {useEffect, useState} from "react";
import {apiGetDevice} from "./Actions";
import CogoToast from "cogo-toast";
import {EnigooLoader} from "../../../MyComponent/EnigooLoader";
import EnigooBreadcrumbs from "../../../MyComponent/EnigooBreadcrumbs";
import EnigooButton from "../../../MyComponent/EnigooButton";
import {FiEdit} from "react-icons/fi";
import Widget from "../../../components/widget";
import {useTranslation} from "react-i18next";
import EnigooTablev2 from "../../../MyComponent/EnigooTablev2";

const Device = () =>{
    let [loading,setLoading] = useState(false);
    let[data,setData] = useState(null);
    const {t,i18n} = useTranslation()

    useEffect(()=>{
        getDevice();
    },[]);

    const getDevice=()=>{
        setLoading(true);
        apiGetDevice((data)=>{
            setData(data);
            setLoading(false);
        },(err)=>{
            CogoToast.error(t("device.loadingError"));
            setLoading(false);
        })
    }

    const columns = React.useMemo(()=>[
        {header:t("basics.name"),id:"name",accessorKey:'name'},
        {header:'UID',id:"uid",accessorKey:'uid'},
        {header:t('basics.options'),id:"options",
            cell:({row})=>{
            return <EnigooButton icon={<FiEdit/>} link={"device/detail/"+row.original.id}/>
            }
        }
    ],[i18n.language]);

    const _renderBody = ()=>{
        if(loading) return <EnigooLoader text={t("device.loading")}/>
        if(!data) return <EnigooTablev2 data={[]} columns={columns} selectable={false}/>
        if(data && data.length===0) return <EnigooTablev2 data={[]} columns={columns} selectable={false}/>
        if(data) return <EnigooTablev2 data={data} columns={columns} selectable={false}/>
    }

    return (
      <>
          <>
              <EnigooBreadcrumbs/>
              <Widget>
                  {_renderBody()}
              </Widget>
          </>
      </>
    );



}

export default Device;
