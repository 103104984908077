import React, {useEffect, useRef, useState} from 'react'
import {usePopper} from 'react-popper'
import {FiChevronDown, FiChevronUp} from "react-icons/fi";

const Dropdown = ({
                      placement = 'bottom-start',
                      children,
                      title,
                      disabled = false,
                      width = 'w-32',
                      dropdownToggleClass = "btn btn-default btn rounded btn-icon hover:bg-enigoo-600 bg-enigoo-500 text-white mt-1",
                      iconHidden = <FiChevronDown className={"mr-2"}/>,
                      iconOpen = <FiChevronUp className={"mr-2"}/>
                  }) => {
    const [hidden, setHidden] = useState(true)

    const buttonRef = useRef(null)
    const dropdownRef = useRef(null)

    const {styles, attributes} = usePopper(
        buttonRef.current,
        dropdownRef.current,
        {
            placement: placement,
        }
    )

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                hidden ||
                buttonRef.current.contains(event.target) ||
                dropdownRef.current.contains(event.target)
            ) {
                return false
            } else {
                setHidden(true)
            }
        }
        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [hidden, dropdownRef, buttonRef])

    const handleDropdownClick = () => {
        setHidden(!hidden)
    }

    return (
        <div className="flex relative">
            <button
                disabled={disabled}
                ref={buttonRef}
                onClick={handleDropdownClick}
                className={dropdownToggleClass}>
                {hidden ? iconHidden : iconOpen}
                {title}
            </button>
            <div ref={dropdownRef} style={styles.popper} {...attributes.popper}>
                <div
                    style={styles.offset}
                    className={`dropdown ${hidden ? '' : 'open'}`}>
                    <div className={`dropdown-content ${width} ${placement}`} onClick={(event) => {
                        if (
                            hidden ||
                            buttonRef.current.contains(event.target) ||
                            dropdownRef.current.contains(event.target)
                        ) {
                            setHidden(true)
                        }
                    }
                    }>
                        {children}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Dropdown
