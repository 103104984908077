import React, {useEffect, useState} from "react";
import {apiGetWebChannel} from "./Actions";
import {IconTabs} from "../../../components/tabs";
import {EnigooLoader} from "../../../MyComponent/EnigooLoader";
import EnigooButton from "../../../MyComponent/EnigooButton";
import {FiPlus} from "react-icons/fi";
import Widget from "../../../components/widget";
import WebChannelTable from "./WebChannelTable";
import EnigooBreadcrumbs from "../../../MyComponent/EnigooBreadcrumbs";
import VerifyRights from "../../VerifyRights";
import {useTranslation} from "react-i18next";


const WebChannel = () => {
    let [loading, setLoading] = useState(false);
    let [data, setData] = useState(null);
    let [errorMessage, setErrorMessage] = useState(null);

    const {t} = useTranslation()

    useEffect(()=>{
        getWebChannel();
    },[]);

    const getWebChannel=()=>{
        setLoading(true);
        apiGetWebChannel((data)=>{
            setData(data);
            setLoading(false);
            setErrorMessage(null)
        },(error)=>{
            setLoading(false);
            setErrorMessage(t("webChannels.loadingError"))
        });
    }

    const _renderBody = () => {
        if (loading) return <EnigooLoader text={t("webChannels.loading")}/>
        if (errorMessage) return <div>{errorMessage}</div>
        if (data && data.length===0) return <WebChannelTable items={{"items":[]}}/>
        if (data) return prepareData()
    }

    const prepareData = () =>{
        let tabs =[];
        data.map((item,index)=>{
            tabs.push({
                index: index,
                content: <WebChannelTable items={item}/>,
                title: <>{item.actionName ? item.actionName : t("groups.notAssign")}</>
            })
        })

        return <IconTabs tabs={tabs}/>
    }

    return (
        <>
            <EnigooBreadcrumbs right={<VerifyRights page={"webChannel"} action={"createWebChannel"}> <EnigooButton link={"/web-channel/create"} icon={<FiPlus/>} text={t("basics.add")}/></VerifyRights>}/>
            <Widget>
                {_renderBody()}
            </Widget>
        </>
    )
}

export default WebChannel;
