import React, {useEffect, useRef, useState} from "react";
import Widget from "../../../components/widget";
import EnigooBreadcrumbs from "../../../MyComponent/EnigooBreadcrumbs";
import {FiChevronsLeft, FiChevronsRight, FiCopy, FiEdit, FiPlus} from "react-icons/fi";
import EnigooButton from "../../../MyComponent/EnigooButton";
import {Form} from "react-final-form";
import {apiGetImages} from "./Actions";
import CogoToast from "cogo-toast";
import {EnigooLoader} from "../../../MyComponent/EnigooLoader";
import VerifyRights from "../../VerifyRights";
import {Link} from 'react-router-dom';
import {EnigooSelect} from "../../../MyComponent/EnigooSelect";
import {EnigooTextField} from "../../../MyComponent/EnigooTextField";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import EnigooToolTip from "../../../MyComponent/EnigooToolTip";

const Image = () => {

    let [loading, setLoading] = useState(false);
    let [filtering, setFiltering] = useState(false);
    let [data, setData] = useState(null);
    let [canNextPage, setCanNextPage] = useState(false);
    let [canPreviousPage, setCanPreviousPage] = useState(false);
    let [maxOfRecords, setMaxOfRecords] = useState(0);
    let [pageSize, setPageSize] = useState(24);
    let [pageOptions, setPageOptions] = useState(0); //poslední stránka

    const dispatch = useDispatch();
    const {t} = useTranslation();
    const timer = useRef(null)

    const {image} = useSelector(
        state => ({
            image: state.reducerAdmin.image
        }),
        shallowEqual
    )

    useEffect(() => {
        clearTimeout(timer.current);
        timer.current = setTimeout(() => {
            getImages();
        }, 600);

    }, [image.imagePageIndex, image.imageType, image.phrase])

    const getImages = () => {

        setLoading(true);
        apiGetImages({
            page: image.imagePageIndex + 1,
            pageSize: pageSize,
            type: image.imageType,
            phrase: image.phrase
        }, (data) => {
            setPageOptions(Math.ceil(data.max / pageSize));
            setMaxOfRecords(data.max);
            setCanPreviousPage(image.imagePageIndex - 1 >= 0);
            setCanNextPage(image.imagePageIndex + 1 < Math.ceil(data.max / pageSize));
            setData(data.data);
            setLoading(false);
        }, (error) => {
            CogoToast.error(t("photogallery.noImageError"));
            setData(null);
            setLoading(false);
        })

    }

    const nextPage = () => {
        if (canNextPage) {
            gotoPage(image.imagePageIndex + 1);
        }
    }

    const previousPage = () => {
        if (canPreviousPage) {
            gotoPage(image.imagePageIndex - 1);

        }
    }

    const gotoPage = (page) => {
        if (page >= 0 && page < pageOptions) {
            dispatch({
                type: 'CHANGE_IMAGE_PAGE_INDEX',
                imagePageIndex: page
            })
            setCanPreviousPage(image.imagePageIndex - 1 >= 0);
            setCanNextPage(image.imagePageIndex + 1 < pageOptions);
        }
    }

    const renderGallery = () => {
        return (
            <>
                <div className={"grid lg:grid-cols-8 grid-cols-4 gap-2"}>
                    {data.map((item, index) => (
                        <div key={index.toString()} style={{
                            backgroundImage: `url(${item.path})`,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "contain",
                            backgroundPosition: "center"
                        }} className={"max-h lg:h-64 h-32 rounded border p-2"}>
                            <div className={"flex flex-row"}>
                                <div data-for={"detail_image_"+item.id} data-tip
                                     className={"bg-enigoo-500 rounded border border-enigoo-500 text-white w-2/12 grid place-content-center py-1"}>
                                    <Link to={"/gallery/detail/" + item.id}>
                                        <div><FiEdit size={13} color={"enigoo-500"}/></div>
                                    </Link>
                                </div>
                                <EnigooToolTip content={t("basics.detail")}  place={"right"} class={"enigoo-tooltip"} id={"detail_image_"+item.id}/>
                                <div data-for={"copy_image_"+item.id} data-tip
                                     className={"cursor-pointer ml-2 bg-enigoo-500 rounded border border-enigoo-500 text-white w-2/12 grid place-content-center py-1"}>
                                    <div onClick={() => {
                                        navigator.clipboard.writeText(item.path)
                                            .then(() => {
                                                CogoToast.success(t("photogallery.linkCopied"))
                                            })
                                    }}><FiCopy size={13} color={"enigoo-500"}/></div>
                                </div>
                                <EnigooToolTip content={t("basics.copy")} effect={"solid"} place={"right"} class={"enigoo-tooltip"} id={"copy_image_"+item.id}/>
                            </div>
                            {/*<img className={"max-h-sm rounded border border-enigoo-500 "} src={item.data} alt={item.name}/>*/}
                        </div>
                    ))}
                </div>
                <div className="flex flex-wrap items-center space-x-2 pagination mt-2">

                    <button className={'btn btn-circle bg-enigoo-500 hover:bg-enigoo-600 text-white'}
                            onClick={() => previousPage()} disabled={!canPreviousPage}>
                        <FiChevronsLeft/>
                    </button>
                    {' '}
                    <span className={"form-label"}>
                    {t("table.page")}{' '}
                        <strong>{image.imagePageIndex + 1} {t("table.of")} {pageOptions}</strong>{' '}
                </span>
                    <button className={'btn btn-circle bg-enigoo-500 hover:bg-enigoo-600 text-white'}
                            onClick={() => nextPage()} disabled={!canNextPage}>
                        <FiChevronsRight/>
                    </button>
                    {' '}
                    <span className={"form-label"}>
                        | {t("table.goToPage")}:{' '}</span>
                    <input
                        className={"form-input text-sm"}
                        type="number"
                        defaultValue={image.imagePageIndex + 1}
                        onChange={e => {
                            const page = e.target.value ? Number(e.target.value) - 1 : 0
                            gotoPage(page)
                        }}
                        style={{width: '70px'}}
                    />
                    <span className={"form-label"}> | {t("basics.sumOfImage")}:{' '}{maxOfRecords} </span>
                </div>
            </>
        )
    }

    const _renderBody = () => {
        if (loading || filtering) return <EnigooLoader text={t("photo.loading")}/>
        if (!data || (data && data.length === 0)) return <div>{t("basics.noData")}</div>
        if (data) return renderGallery();
    }

    const onTypeChange = (event) => {
        dispatch({
            type: 'CHANGE_TYPE',
            imageType: event.value
        })
        dispatch({
            type: 'CHANGE_IMAGE_PAGE_INDEX',
            imagePageIndex: 0
        })
    }

    return (
        <>
            <EnigooBreadcrumbs right={
                <div>
                    <VerifyRights page={"image"} action={"createImage"}> <EnigooButton
                        link={"/gallery/add"}
                        icon={<FiPlus/>}
                        text={t("basics.add")}/></VerifyRights>
                </div>

            }/>
            <Widget>
                <Form onSubmit={(values) => {
                }} initialValues={{type: image.imageType, phrase: image.phrase}}
                      render={({form}) => {
                          return (
                              <div className={"grid lg:grid-cols-2 grid-cols-1 gap-2"}>
                                  <EnigooSelect onValueChange={onTypeChange} isClearable={false}
                                                name={"type"}
                                                label={""} placeHolder={"Vyberte typ"}
                                                defaultValue={["value", "label"]}
                                                items={[{value: 1, label: t("accreditation.plural")}, {
                                                    value: 2,
                                                    label: t("template.plural")
                                                }, {value: 3, label: t("basics.other")}]}/>
                                  <EnigooTextField name={"phrase"}
                                                   label={""} placeHolder={t("placeHolder.findingPhrase")}
                                                   onChange={(event) => {
                                                       dispatch({
                                                           type: 'CHANGE_PHRASE',
                                                           phrase: event.target ? event.target.value : null
                                                       })
                                                       dispatch({
                                                           type: 'CHANGE_IMAGE_PAGE_INDEX',
                                                           imagePageIndex: 0
                                                       })
                                                   }
                                                   }/>
                              </div>
                          );
                      }}
                />
            </Widget>
            <Widget>

                {_renderBody()}
            </Widget>
        </>
    )
}

export default Image;
