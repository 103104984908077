import {axios} from "../../../axiosConfig";

const url = "api/admin/v1/user";

export function apiGetUser(data,callback,error){
    axios.post(url+"/all",data)
        .then(response => callback(response.data.data))
        .catch(err=>error(err))
}

export function apiGetUserById(id,actionId,callback,error){
    axios.get(url+"/"+actionId+"/"+id)
        .then(response => callback(response.data.data))
        .catch(err=>error(err))
}

export function apiUpdateUser(id,data,callback,error){
    axios.put(url+"/"+id,data)
        .then(response=>callback(response.data.data))
        .catch(err=>error(err))
}

export function apiCreateUser(data,callback,error){
    axios.post(url,data)
        .then(response=>callback(response.data.data))
        .catch(err=>error(err))
}

export function apiGetUserPrintData(data,callback,error){
    axios.post(url+"/print",data)
        .then(response=>callback(response.data.data))
        .catch(err=>error(err));
}

export function apiFilterUsers(data,callback,error){
    axios.post(url+"/filter",data)
        .then(response=>callback(response.data.data))
        .catch(err=>error(err))
}

export function apiAssignUserToGroups(data,callback,error){
    axios.post(url+"/assign-groups",data)
        .then(response=>callback(response.data.data))
        .catch(err=>error(err))
}

export function apiUserAssignDays(data,callback,error){
    axios.post(url+"/assign-days",data)
        .then(response=>callback(response.data.data))
        .catch(err=>error(err))
}

export function apiUserAssignZones(data,callback,error){
    axios.post(url+"/assign-zone",data)
        .then(response=>callback(response.data.data))
        .catch(err=>error(err))
}

export function apiExportUser(data,callback,error){
    axios.post(url+"/export",data,{responseType:data.type==="xls"?"blob":"json"})
        .then(response => {
            if(data.type==="xls"){
                let fileName = response.headers['content-disposition'].toString();
                fileName = fileName.substring(fileName.indexOf("=") + 1);
                let data = response.data;
                let array = [];
                array["fileName"] = fileName;
                array["data"] = data;
                callback(array);
            }else {
                let fileName = response.headers['content-disposition'].toString();
                fileName = fileName.substring(fileName.indexOf("=") + 1);
                let data = response.data.data.data;
                let error = response.data.data.error;
                let array = [];
                array["fileName"] = fileName;
                array["data"] = data;
                array["error"] = error;
                callback(array);
            }
        })
        .catch(err=>error(err))
}

export function apiCopyUserToAction(data,callback,error){
    axios.post(url+"/copy",data)
        .then(response =>callback(response.data.data))
        .catch(err=>error(err));
}

export function apiDeleteUser(actualAction,type,id,callback,error){
    axios.post(url+"/delete",{actionId:actualAction, type:type,id:id})
        .then(response=>callback(response.data.data))
        .catch(err=>error(err));
}





