import React from "react";
import {useFilePicker} from "use-file-picker";
import EnigooButton from "../../../MyComponent/EnigooButton";
import {FiX} from "react-icons/fi";
import {FaFileImport} from "react-icons/all";
import {useTranslation} from "react-i18next";

export const WebChannelExternalCodeImport = ({importCodes,values,push,update}) => {
    const [openFileSelector, {filesContent, loading, clear}] = useFilePicker({
        accept: ['.csv'],
        readAs: 'Text',
        multiple: false,
        limitFilesConfig: {max: 1}
    });

    const {t} = useTranslation()

    React.useMemo(() => {
        if (filesContent && filesContent[0]) {
            importCodes(filesContent[0],clear,values,push,update);
        }
    }, [filesContent])


    return (
        <>
            <div className={"form-element mr-1"}>
                {filesContent && filesContent[0] ?
                    <EnigooButton onClick={() => clear()}
                                  text={t("basics.cancel")} color={"red"}
                                  icon={<FiX/>}/> :

                    <EnigooButton onClick={() => openFileSelector()}
                                  text={t("accreditation.import.import")}
                                  icon={<FaFileImport/>}/>}
            </div>
        </>
    )
}
