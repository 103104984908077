import React from "react";
import EnigooMultiLanguageField from "../../../../MyComponent/EnigooMultiLanguageField/EnigooMultiLanguageField";
import EnigooModal from "../../../../MyComponent/Modal/EnigooModal";
import EnigooButton from "../../../../MyComponent/EnigooButton";
import {useTranslation} from "react-i18next";
import {AiFillDelete} from "react-icons/all";
import {EnigooTextField} from "../../../../MyComponent/EnigooTextField";
import PropTypes from "prop-types";

const MealOptionModal = ({fieldName, index, remove, fields,error}) => {

    const {t, i18n} = useTranslation();

    let title = React.useMemo(() => {
        let value = "";
        if (fields.value[index]?.name && fields.value[index]?.name[i18n.language]) {
            value = fields.value[index]?.name[i18n.language]
        } else if (fields.value[index]?.name && fields.value[index]?.name.cs) {
            value = fields.value[index]?.name.cs;
        } else {
            value = `#${index + 1}`;
        }
        if(value.length>15){
            return value.substring(0,14)+"...";
        }else{
            return value;
        }
    }, [fields.value[index]]?.name?.cs)

    let submit = null;

    const onOpen = () => {
        fields.update(index, {...fields.value[index], open: true})
    }

    const onClose = () => {
        fields.update(index, {...fields.value[index], open: false})
    }
    const renderDeleteButton = () => {
        return (<EnigooButton onClick={() => remove(index)} color={"red"} text={t("basics.delete")}
                              icon={<AiFillDelete/>}/>)
    }
    return <>

        <EnigooModal title={t("mealExtension.meal") + " #" + (index + 1)} open={fields.value[index].open}
                     onSave={onClose} onOpen={onOpen} disabledSaveButton={!!error}
                     buttonColor={"white"} buttonExtraStyle={`btn-block border-2 border-${error?"red-700":"enigoo-500"}`} extraStyle={" border-2 border-enigoo-500"}
                     onClose={onClose} buttonText={title} customButton={renderDeleteButton()} buttonTextColor={"enigoo-500"}>

            <div className={"flex flex-col space-y-2 p-2"}>
                <div className={"hidden"}><EnigooTextField name={`${fieldName}.id`} label={""}/></div>
                <EnigooMultiLanguageField name={`${fieldName}.name`}
                                          label={t("mealExtension.mealName") + ":*"}/>
                <EnigooMultiLanguageField name={`${fieldName}.note`} label={t("basics.note") + ":"}/>
                <EnigooTextField label={t("mealExtension.capacityOfMeal")+":"} toolTip={t("mealExtension.capacityOfMealToolTip")} name={`${fieldName}.capacity`} type={"number"}/>
            </div>
        </EnigooModal>

    </>

}
MealOptionModal.propTypes = {
    fieldName: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
    remove: PropTypes.func.isRequired,
    fields: PropTypes.object.isRequired,
    error: PropTypes.any
}
export default MealOptionModal;
