import React, {useEffect, useState} from "react";
import {useFilePicker} from 'use-file-picker';
import Widget from "../../../components/widget";
import EnigooBreadcrumbs from "../../../MyComponent/EnigooBreadcrumbs";
import EnigooButton from "../../../MyComponent/EnigooButton";
import {FiSave} from "react-icons/all";
import {Field, Form} from "react-final-form";
import {EnigooSelect} from "../../../MyComponent/EnigooSelect";
import {EnigooTextField} from "../../../MyComponent/EnigooTextField";
import {apiCreateImage} from "./Actions";
import CogoToast from "cogo-toast";
import {useHistory} from "react-router-dom";
import {FiFile, FiX} from "react-icons/fi";
import {useTranslation} from "react-i18next";

const UploadImage = () => {
    const [openFileSelector, {filesContent,clear}] = useFilePicker({
        accept: ['.png','.jpg','.jpeg','.gif'],
        readAs: 'DataURL',
        multiple: false,
        limitFilesConfig: {max: 1}
    });
    let [saving,setSaving] = useState(false);
    let submit = null;


    useEffect(() => {

    }, []);

    const history = useHistory();
    const {t} = useTranslation()
    const onSubmit = (values) => {
        setSaving(true);
        values.baseData = filesContent[0].content;
        apiCreateImage(values, (data) => {
            CogoToast.success(t("photo.saved"));
            setSaving(false);
            history.push("/gallery");
        }, (error) => {
            setSaving(false)
            CogoToast.error(t("photo.errorSaved"));
        })
    }

    return (
        <>
            <EnigooBreadcrumbs right={<EnigooButton onClick={() => submit()} text={t("basics.save")} loading={saving} disabled={saving} icon={<FiSave/>}/>}/>
            <Widget>
                <Form onSubmit={onSubmit}
                      validate={values => {
                          let errors = {};
                          if (!values.type) {
                              errors.type = t("basics.requireField");
                          }
                          if (filesContent && filesContent.length === 0) {
                              errors.data = t("basics.requireField");
                          }
                          return errors;
                      }}
                      render={({form, handleSubmit}) => {
                          submit = handleSubmit;
                          return (
                              <div className={"w-full lg:grid lg:grid-cols-2 gap-2"}>
                                  <Field name={"data"}>
                                      {({input, meta}) => (
                                          <>
                                              <div className={"form-element"}>
                                                  <div className="form-label">{""}</div>
                                                  {filesContent && filesContent[0] ? <EnigooButton onClick={clear} icon={<FiX/>} color={"red"} text={"Zrušit výběr"}/>:
                                                  <EnigooButton onClick={() => openFileSelector()}
                                                                text={t("photo.chooseImage")}
                                                                icon={<FiFile/>}/>
                                                  }
                                                  {meta.error && meta.touched &&
                                                      <div className="form-error">{meta.error}</div>}
                                              </div>

                                          </>
                                      )}
                                  </Field>
                                  <div className={"flex flex-col"}>
                                      <div className="form-label">{t("photo.choosedImage")+":"}</div>
                                      {filesContent && filesContent[0] ?
                                          <div className={'rounded border lg:h-64 h-32'}
                                              style={{
                                              backgroundImage: `url(${filesContent[0].content})`,
                                              backgroundRepeat: "no-repeat",
                                              backgroundSize: "contain",
                                              backgroundPosition: "center",
                                          }}></div>
                                          : <strong className={"text-red-800"}>{t("photo.noChoosedImage")}</strong>}
                                  </div>
                                  <EnigooSelect name={"type"} label={"Typ: *"} defaultValue={["value", "label"]}
                                                items={[{value: 1, label: t("accreditation.plural")}, {
                                                    value: 2,
                                                    label: t("template.plural")
                                                }, {value: 3, label: t("basics.other")}]} placeHolder={t("placeHolder.select")}/>
                                  <EnigooTextField name={"name"} label={t("basics.name")+":"} placeHolder={t("placeHolder.name")}/>
                              </div>
                          );
                      }}
                />

            </Widget>
        </>
    )
}

export default UploadImage;


