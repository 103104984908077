import React, {useEffect, useState} from "react";


import PropTypes from "prop-types";
import {apiManageGetSubject} from "../../../Manage/pages/Subject/Actions";
import {EnigooSelectMulti} from "../../EnigooSelectMulti";
import {EnigooSelect} from "../../EnigooSelect";

export const EnigooManageSubjectSelectField = ({
                                                   label,
                                                   name,
                                                   isMulti = true,
                                                   outsideClassName = "w-full",
                                                   onValueChange = null
                                               }) => {
    let [data, setData] = useState(null);
    let [loading, setLoading] = useState(false);
    //data z databaze
    useEffect(() => {
        get()
    }, [])

    const get = () => {
        setLoading(true);
        apiManageGetSubject((data) => {
            let subjData;
            subjData = [{"id":-1,"name":"Manage users"},...data];
            setData(subjData);
            setLoading(false);
        }, (error) => {
            setLoading(false);
        })
    }
    return (isMulti ? <EnigooSelectMulti onValueChange={onValueChange} outsideClassName={outsideClassName} label={label}
                                         disabled={loading} name={name} items={data}/> :
        <EnigooSelect isClearable={false} onValueChange={onValueChange} searchable={true}
                      outsideClassName={outsideClassName} label={label}
                      name={name} disabled={loading}
                      items={data}/>);

}
EnigooManageSubjectSelectField.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    isMulti: PropTypes.bool

}
