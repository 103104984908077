import React, {useEffect, useState} from "react";
import {
    FiActivity,
    FiCalendar,
    FiChevronsLeft,
    FiChevronsRight,
    FiCircle,
    FiEdit,
    FiMail,
    FiPrinter
} from "react-icons/fi";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {apiFilterUsers, apiGetUser} from "./Actions";
import {isArray, isBoolean} from "@craco/craco/lib/utils";
import EnigooButton from "../../../MyComponent/EnigooButton";
import CogoToast from "cogo-toast";
import {EnigooLoader} from "../../../MyComponent/EnigooLoader";
import {IoMdStats} from "react-icons/all";
import {useTranslation} from "react-i18next";
import {apiGetActionById} from "../Action/Actions";
import {Link} from "react-router-dom";
import EnigooTablev2, {EnigooTablev2CheckBox} from "../../../MyComponent/EnigooTablev2";
import {useHistory} from "react-router";
import EnigooToolTip from "../../../MyComponent/EnigooToolTip";

const UserTable = ({selectedRows, setSelectedRows, selectedRowsAll, setSelectedRowsAll, filtering, setFiltering}) => {
    let [pageSize, setPageSize] = useState(10);
    let [pageOptions, setPageOptions] = useState(0); //poslední stránka
    let [actualData, setActualData] = useState([]);
    let [customField, setCustomField] = useState(null);
    let [canNextPage, setCanNextPage] = useState(false);
    let [canPreviousPage, setCanPreviousPage] = useState(false);
    let [maxOfRecords, setMaxOfRecords] = useState(0);
    let [action, setAction] = useState(null);

    const {t, i18n} = useTranslation()
    const dispatch = useDispatch();
    const history = useHistory();

    let debounceTimeout;

    const {adminRights} = useSelector(
        state => ({adminRights: state.reducerAdmin.adminRights}),
        shallowEqual
    )

    const getPlatformShortCut = (letter)=>{
        const platform = navigator.userAgent.toLowerCase();
        if(platform.includes("mac")){
            return ("(⌘Cmd + "+letter.toUpperCase()+")");
        }else{
            return ("(Ctrl + "+letter.toUpperCase()+")");
        }
    }

    const {filterUser} = useSelector(
        state => ({
            filterUser: state.reducerAdmin.filterUser,
            filterData: state.reducerAdmin.filterData,
            filtered: state.reducerAdmin.filtered,
            reloadUserData: state.reloadUserData
        }),
        shallowEqual
    )
    const {actualAction: actAction} = useSelector(
        state => ({
            actualAction: state.reducerAdmin.actualAction,
            pageIndex: state.reducerAdmin.pageIndex
        }),
        shallowEqual
    )

    const {filterData, filtered, reloadUserData} = {...filterUser};
    const {actualAction, pageIndex} = {...actAction};

    React.useMemo(() => {
        if (actualAction) {
            apiGetActionById(actualAction, (data) => {
                setAction(data);
            }, (err) => {
                setAction(null);
            })
        }
    }, [actualAction])

    const getData = () => {
        if (action) {
            if (filtered) {
                setFiltering(true);
                apiFilterUsers({
                    ...filterData,
                    actionId: action.id,
                    page: pageIndex + 1,
                    pageSize: pageSize
                }, (data) => {
                    setPageOptions(Math.ceil(data.max / pageSize));
                    setActualData(data.data);
                    setCustomField(data.customField);
                    setCanPreviousPage(pageIndex - 1 >= 0);
                    setCanNextPage(pageIndex + 1 < Math.ceil(data.max / pageSize));
                    setMaxOfRecords(data.max);
                    setFiltering(false);
                }, (error) => {
                    setActualData([]);
                    setCustomField([]);
                    setMaxOfRecords(0);
                    CogoToast.error(t("basics.noDataError"));
                    setFiltering(false);
                })
            } else {
                setFiltering(true);
                apiGetUser({actionId: action.id, page: pageIndex + 1, pageSize: pageSize}, (data) => {
                    setPageOptions(Math.ceil(data.max / pageSize));
                    setActualData(data.data);
                    setMaxOfRecords(data.max);
                    setCustomField(data.customField);
                    setCanPreviousPage(pageIndex - 1 >= 0);
                    setCanNextPage(pageIndex + 1 < Math.ceil(data.max / pageSize));
                    setFiltering(false);
                    setSelectedRowsAll(selectedRowsAll)
                }, (error) => {
                    setActualData([]);
                    setCustomField([]);
                    setMaxOfRecords(0);
                    CogoToast.error(t("basics.noDataError"));
                    setFiltering(false);
                })
            }
        }
    }


    useEffect(() => {
        if (reloadUserData) {
            dispatch({
                type: 'SET_RELOAD_ACCREDITATION',
                reloadUserData: false
            })
        }
        getData();
    }, [pageIndex, pageSize, action, filterData, filtered, reloadUserData])

    useEffect(() => {

    }, [selectedRows]);

    const data = React.useMemo(() => actualData, [actualData]);

    const getId = (item) => {
        let id;
        let msg = "";
        if (item.customId !== null)
            id = item.customId;

        else
            id = item.id;

        if (item.dateCreate) {
            msg += `<p><b>${t("accreditation.dateCreated")}: </b>${item.dateCreate}</p>`;

        }
        if (item.externalCode) {
            msg += `<p><b>${t("externalCode.single")}: </b>${item.externalCode}</p>`;
        }
        if (msg === "") {
            return id;
        } else {
            return <><span data-tip data-for={"_tooltip_usrid_" + id}
                           key={"usrid_" + id}><b>{id}</b></span><EnigooToolTip id={"_tooltip_usrid_" + id}
                                                                                content={msg}/></>;
        }


    }

    const getEntranceLog = (item) => {
        let d = item.entrance;
        if ((isArray(d.today) && d.today.length === 0) &&
            (isArray(d.all) && d.all.length === 0)
        ) {
            return <></>;
        } else {
            let msgToday = "";
            let todayIn = 0;
            let todayOut = 0;
            let msgAll = "";
            let allIn = 0;
            let allOut = 0;
            d.today.sort((a, b) => a.date > b.date ? 1 : -1)
                .forEach((item, index) => {
                        if (item.type === 1) {
                            todayIn++;
                        }
                        if (item.type === 0) {
                            todayOut++;
                        }
                        if (index < 5) {
                            let msg = `<p>${item.type === 1 ? t("basics.entry") : t("basics.exit")}: ${item.date}</p>`;
                            msgToday += msg;
                        }
                    }
                )
            msgToday = `<p><span class="uppercase font-bold">${t("basics.today")}:</span> ${todayIn} ${t("basics.entries")}, ${todayOut} ${t("basics.exits")}</p>` + msgToday;
            d.all.sort((a, b) => a.date > b.date ? 1 : -1)
                .forEach((item, index) => {
                        if (item.type === 1) {
                            allIn++;
                        }
                        if (item.type === 0) {
                            allOut++;
                        }
                        if (index < 5) {
                            let msg = `<p>${item.type === 1 ? t("basics.entry") : t("basics.exit")}: ${item.date}</p>`;
                            msgAll += msg;
                        }
                    }
                )


            msgAll = `<p><b>${t("basics.inTotal")} </b>${t("basics.inTotal")}: ${allIn + todayIn}  ${t("basics.entries")}, ${allOut + todayOut}  ${t("basics.exits")}</p>` + msgAll;

            return <><IoMdStats size={20} data-tip data-for={"_tooltip_usrentr_" + item.id}/><EnigooToolTip
                place={"right"} id={"_tooltip_usrentr_" + item.id} content={msgAll}/></>;
        }


    }

    const _getDayName = (item) => {
        return item.name[i18n.language] ? item.name[i18n.language] : item.name["cs"];
    }

    const getMailLogs = (d) => {
        let msgQueue = "";
        let msgSend = "";
        let countErr = 0;
        if (isArray(d.mailQueue) && d.mailQueue.length > 0) {

            d.mailQueue.sort((a, b) => a.date < b.date ? 1 : -1)
                .forEach((item, index) => {
                    if (item.fail) {
                        countErr++;
                    }
                    if (index < 5) {
                        let adminUser = item.adminUser ? t("user.byTheUser") + ': ' + item.adminUser : t('basics.autoBeforeRegister');
                        msgQueue += "<p>" + t("accreditation.log.insertedToQueue") + ": " + item.date + ", " + adminUser + ", " + t("template.single") + ": " + item.mailTemplate + `${item.note ? ", " + t("basics.error") + ": " + item.note : ""}` + "</p>";
                    }
                })
            if (countErr > 0) {
                msgQueue = "<p>" + t("basics.inTotal") + " " + countErr + " " + t("accreditation.log.errorAtSending") + ".</p>" + "<p>" + t("basics.inTotal") + " " + `${d.mailQueue.length - countErr}` + " " + t("accreditation.log.mailInQueue") + ".</p>" + msgQueue;
            } else {
                msgQueue = "<p>" + t("basics.inTotal") + " " + `${d.mailQueue.length - countErr}` + " " + t("accreditation.log.mailInQueue") + ".</p>" + msgQueue;
            }
        }
        if (isArray(d.mail) && d.mail.length > 0) {

            if (d.mail.length > 0) msgSend = "<p>" + t("basics.inTotal") + " " + d.mail.length + " " + t("accreditation.log.mailSended") + ".</p>";
            d.mail
                .sort((a, b) => a.date < b.date ? 1 : -1)
                .forEach((item, index) => {
                    if (index < 5) {
                        let adminUser = item.adminUser ? t("user.byTheUser") + ': ' + item.adminUser : t('basics.autoBeforeRegister');
                        msgSend += "<p>" + t("accreditation.log.sended") + ": " + item.dateSend + ", " + adminUser + ", " + t("template.single") + ": " + item.mailTemplate + "</p>";
                    }

                })
        }
        if (msgQueue !== "" && msgSend !== "") {
            return <><FiMail className={`${countErr > 0 ? "text-red-800" : "text-yellow-800"}`}
                             data-tip data-for={"_tooltip_usrmail_" + d.id}/><EnigooToolTip content={(msgQueue + msgSend)}
                                                                                           place={"right"}
                                                                                           id={"_tooltip_usrmail_" + d.id}/>
            </>;
        } else if (msgQueue !== "" && msgSend === "") {
            return <><FiMail className={`${countErr > 0 ? "text-red-800" : "text-yellow-800"}`}
                             data-tip data-for={"_tooltip_usrmail_" + d.id}/><EnigooToolTip content={msgQueue}
                                                                                           place={"right"}

                                                                                           id={"_tooltip_usrmail_" + d.id}/></>
        } else if (msgSend !== "" && msgQueue === "") {
            return <><FiMail data-tip data-for={"_tooltip_usrmail_" + d.id}/><EnigooToolTip content={msgSend}
                                                                                           place={"right"}
                                                                                           id={"_tooltip_usrmail_" + d.id}/></>
        } else {
            return <></>;
        }

    }

    const columns = React.useMemo(() => {
        let array = [
            {
                id: 'select',
                header: ({table}) => (
                    <EnigooTablev2CheckBox
                        {...{
                            checked: table.getIsAllRowsSelected(),
                            indeterminate: table.getIsSomeRowsSelected(),
                            onChange: table.getToggleAllRowsSelectedHandler(),
                        }}
                    />
                ),
                cell: ({row}) => (
                    <div className="px-1">
                        <EnigooTablev2CheckBox
                            {...{
                                checked: row.getIsSelected(),
                                disabled: !row.getCanSelect(),
                                indeterminate: row.getIsSomeSelected(),
                                onChange: row.getToggleSelectedHandler(),
                            }}
                        />
                    </div>
                ),
            },
            {
                cell: ({row}) => {
                    return getId(row.original);
                }, header: '#', id: "id"
            },
            {
                header: '', id: 'mail', cell: ({row}) => {
                    return getMailLogs(row.original)
                }
            },
            {
                header: '', id: 'print', cell: ({row}) => {
                    if (isArray(row.original.print) && row.original.print.length > 0) {

                        let msg = "";
                        if (row.original.print.length > 0) msg = "<p>" + t("basics.inTotal") + " " + row.original.print.length + " " + t("accreditation.log.timesPrinted") + ".</p>";
                        row.original.print
                            .sort((a, b) => a.date < b.date ? 1 : -1)
                            .forEach((item, index) => {
                                if (index < 5) {
                                    msg += "<p>" + t("accreditation.log.printed") + ": " + item.date + ", " + t("user.byTheUser") + ": " + item.adminUser + ".</p>";
                                }

                            })
                        return <><FiPrinter data-tip data-for={"_tooltip_usrprnt_" + row.original.id}/><EnigooToolTip
                            content={msg}
                             place={"right"}
                            id={"_tooltip_usrprnt_" + row.original.id}/></>

                    } else {
                        return <></>
                    }
                }
            },
            {
                header: '', id: 'entrance', cell: ({row}) => {
                    return getEntranceLog(row.original);
                }
            },
            {
                id: 'name',
                header: t('basics.firstname') + " " + t("basics.lastname"),
                cell: ({row}) => <>
                    <Link className={"hover:font-bold hover:underline"} data-tip
                          data-for={"_tooltip_usrdetail_" + row.original.id}
                          to={"/accreditation/detail/" + row.original.id}>{row.original.firstname + " " + row.original.lastname}</Link>
                    <EnigooToolTip content={t("accreditation.goToDetail")} id={"_tooltip_usrdetail_" + row.original.id} place={"right"}
                                  class={"enigoo-tooltip"}/>
                </>
            },
            {
                id: 'email',
                accessorKey: 'email', header: t('basics.email')
            }
        ];
        const getCfLabel = (cf) => {
            if (!cf.label) {
                return cf.name;
            } else {
                if (cf.label && cf.label[i18n.language]) {
                    return cf.label[i18n.language];
                } else if (cf.label && cf.label.cs) {
                    return cf.label.cs;
                } else {
                    return cf.name;
                }
            }
        }
        customField && customField.forEach((item, index) => {
            array.push({
                header: getCfLabel(item), cell: ({row}) => {
                    let filtered = row.original.customField.filter(i => i.id === item.id);
                    if (filtered === null || filtered.length === 0) return "-";
                    if (filtered[0].value === null) return "-";
                    if (isBoolean(filtered[0].value)) return filtered[0].value ?
                        <span className={"uppercase"}>{t("basics.yes")}</span> : <span>{t("basics.no")}</span>;
                    if (Array.isArray(filtered[0].value)) {
                        let val = "";
                        filtered[0].value.forEach((item, index) => {
                            val += item;
                            if (filtered[0].value.length !== index + 1) {
                                val += ", ";
                            }
                        })
                        return val;
                    }
                    if (typeof filtered[0].value === "object") return filtered[0].value.street + ", " + filtered[0].value.city + " - " + filtered[0].value.zip;
                    else {
                        return filtered[0].value;
                    }
                }
            })
        })

        array.push({
            id: 'groups',
            header: t("groups.single"),
            cell: ({row}) => {
                if (row.original.groups)
                    if(row.original.groups.displayName){
                        if(row.original.groups.displayName[i18n.language]){
                            return row.original.groups.displayName[i18n.language];
                        }else if(row.original.groups.displayName.cs){
                            return row.original.groups.displayName.cs;
                        }else{
                            return row.original.groups.name;
                        }
                    }else {
                        return row.original.groups.name;
                    }
                else return "";
            }
        })
        array.push({
            id: 'zones',
            header: t('zones.plural'),
            cell: ({row}) => {

                let value = "";
                row.original.zones.forEach((item, index) => {
                    value += `<p>${item.name}</p>`
                })

                if (value === "") {
                    return <></>
                } else {
                    return <>
                        <div
                            className={`py-1 px-1 bg-enigoo-100 text-enigoo-900 text-center font-bold rounded border border-enigoo-900 w-full`}
                            data-tip data-for={"_tooltip_usrzones_" + row.original.id}>
                            <div className={"flex flex-row justify-center items-center"}><FiCircle
                                size={15}/><span className={"text-2xs"}>&nbsp;{`(${row.original.zones.length})`}</span>
                            </div>
                        </div>
                        <EnigooToolTip content={value} id={"_tooltip_usrzones_" + row.original.id} place={"right"}
                                      class={"enigoo-tooltip"}/>
                    </>
                }

            }
        })

        array.push({
            id: "days",
            header: t("action.days"),
            cell: ({row}) => {
                if (row.original.days && isArray(row.original.days) && row.original.days.length > 0) {
                    let value = "";
                    row.original.days.forEach((item, index) => {
                        value += `<p>${_getDayName(item)}</p>`;
                    })
                    return <>

                        <div
                            className={`py-1 px-1 bg-enigoo-100 text-enigoo-900 text-center font-bold rounded border border-enigoo-900 w-full`}
                            data-tip data-for={"_tooltip_usrdays_" + row.original.id}>
                            <div className={"flex flex-row justify-center items-center"}><FiCalendar
                                size={15}/><span className={"text-2xs"}>&nbsp;{`(${row.original.days.length})`}</span>
                            </div>
                        </div>
                        <EnigooToolTip content={value} id={"_tooltip_usrdays_" + row.original.id} place={"right"}
                                      class={"enigoo-tooltip"}/>
                    </>
                } else {
                    return "";
                }


            }
        })
        array.push({
            id: 'options',
            header: t('basics.options'),
            cell: ({row}) => (<div className={"flex flex-row space-x-2"}>
                <EnigooButton link={"/accreditation/detail/" + row.original.id}
                              _key={"usr_table_detail_" + row.original.id} icon={<FiEdit/>}
                              toolTip={t("basics.detail")} toolTipPosition={"top"}/>
                {adminRights.manageuser &&
                    <EnigooButton _key={"usr_table_preview_" + row.original.id} toolTipPosition={"top"}
                                  link={"/preview/" + adminRights.subject.key + "/cid/" + row.original.bc}
                                  icon={<FiActivity/>} toolTip={t("action.showPreview")} newTab={true}/>}
            </div>),
            filterable: false
        })
        return array;

    }, [customField, i18n.language]);


    const nextPage = () => {
        if (canNextPage) {
            gotoPage(pageIndex + 1);
        }
    }

    const previousPage = () => {
        if (canPreviousPage) {
            gotoPage(pageIndex - 1);

        }
    }

    const gotoPage = (page) => {
        if (page >= 0 && page < pageOptions) {
            dispatch({
                type: 'CHANGE_PAGE_INDEX',
                pageIndex: page,
            })
            setCanPreviousPage(pageIndex - 1 >= 0);
            setCanNextPage(pageIndex + 1 < pageOptions);
        }
    }

    const changePageSize = (size) => {
        dispatch({
            type: 'CHANGE_PAGE_INDEX',
            pageIndex: 0,
        })
        setPageSize(size);
        setCanPreviousPage(pageIndex - 1 >= 0);
        setCanNextPage(pageIndex + 1 < pageOptions);
    }
    const {palettes} = useSelector(
        (state) => ({
            palettes: state.reducerAdmin.palettes
        }),
        shallowEqual
    )

    const {background, leftSidebar, navbar} = palettes;

    let isDark = React.useMemo(() => {
        return background === "dark" && leftSidebar === "dark" && navbar === "dark";
    }, [background, leftSidebar, navbar])

    const _renderBody = () => {
        if (filtering) return <EnigooLoader text={t("basics.loadingData")}/>;
        if (!data || data.length === 0) return <div>{t("basics.noData")}</div>
        else {
            return (
                <>
                    <EnigooTablev2 data={data} columns={columns} selectable={true} onRowDoubleClick={(id) => {
                        history.push("/accreditation/detail/" + id);
                    }}
                                   setSelectedRows={setSelectedRows} manualPagination={true}
                                   selectedAll={selectedRowsAll} setSelectedAll={setSelectedRowsAll}/>
                    <div className="flex flex-wrap items-center space-x-2 pagination mt-2">

                        <button className={'btn btn-circle bg-enigoo-500 hover:bg-enigoo-600 text-white'}
                                onClick={() => previousPage()} disabled={!canPreviousPage}>
                            <FiChevronsLeft/>
                        </button>
                        {' '}
                        <span className={"form-label"}>
                    {t("table.page")}{' '}
                            <strong>{pageIndex + 1}</strong> {t("table.of")} <strong>{pageOptions}</strong>{' '}
                </span>
                        <button className={'btn btn-circle bg-enigoo-500 hover:bg-enigoo-600 text-white'}
                                onClick={() => nextPage()} disabled={!canNextPage}>
                            <FiChevronsRight/>
                        </button>
                        {' '}
                        <span className={"form-label"}>
          | {t("table.goToPage")}:{' '}
                            <input
                                className={`form-input text-sm ${isDark && "bg-grey-800"}`}
                                type="number"
                                defaultValue={pageIndex + 1}
                                onChange={e => {
                                    if (debounceTimeout) {
                                        clearTimeout(debounceTimeout);
                                    }
                                    debounceTimeout = setTimeout(()=>{
                                        const page = e.target.value ? Number(e.target.value) - 1 : 0
                                        gotoPage(page)
                                    },500)
                                }}
                                style={{width: '70px'}}
                            />
        </span>{' '}
                        <select
                            className={`form-select text-sm ${isDark && "bg-grey-800"}`}
                            value={pageSize}
                            onChange={e => {
                                changePageSize(Number(e.target.value))
                            }}
                        >
                            {[10, 20, 30, 40, 50].map(pageSize => (
                                <option key={pageSize} value={pageSize}>
                                    {pageSize + " " + t("table.records")}
                                </option>
                            ))}
                        </select>
                        <span className={"form-label"}> | {t("table.sumAccreditation")}:{' '}{maxOfRecords} </span>
                        <span
                            className={"form-label"}> | {t("table.sumSelected")}:{' '}{selectedRowsAll ? maxOfRecords : selectedRows ? selectedRows.length : "0"} | </span>
                        <input className={"form-check"} type="checkbox" checked={selectedRowsAll} onChange={(event) => {
                            setSelectedRowsAll(event.target.checked);
                        }}/><span className={"form-label"}>  {t("table.selectAll")+" "}<i>{getPlatformShortCut("A")}</i></span>

                    </div>
                </>
            )
        }
    }

    return _renderBody();

}
const TableCheckBox = React.forwardRef(
    ({indeterminate, ...rest}, ref) => {
        const defaultRef = React.useRef()
        const resolvedRef = ref || defaultRef

        React.useEffect(() => {
            resolvedRef.current.indeterminate = indeterminate
        }, [resolvedRef, indeterminate])

        return (
            <>
                <input className={"form-check"} type="checkbox" ref={resolvedRef} {...rest} />
            </>
        )
    }
)


export default UserTable;
