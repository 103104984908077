import React, {useEffect, useState} from "react";
import {EnigooFieldsGroup} from "../../../MyComponent/EnigooFieldsGroup";
import {FiCopy, FiLink} from "react-icons/fi";
import {Link} from "react-router-dom";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {apiManageGetWebChannelForSubject} from "./Actions";

const WebChannelTable = ({subjectId}) => {
    let [loading, setLoading] = useState(false);
    let [errorMessage, setErrorMessage] = useState(null);
    let [items, setItems] = useState(null);

    useEffect(() => {
        init();
    }, []);

    const init = () => {
        setLoading(true);
        apiManageGetWebChannelForSubject(subjectId, (data) => {
            setItems(data);
            if(data.length===0) setErrorMessage("Žádné webové kanály nenalezeny");
            setLoading(false);
            setErrorMessage(null);
        }, error => {
            setLoading(false);
            setErrorMessage("Nepodařilo se načíst žádné webové kanály");
        })
    }
    if(errorMessage) return <div>{errorMessage}</div>
    if(loading) return <>Načítám webové kanály...</>;
    if(!items) return <>Nepodařílo se načíst webové kanály..</>;
    if(items) return (
        <table className={"table striped"}>
            <thead>
            <tr>
                <th>Název</th>
                <th>Klíč kanálu</th>
                <th>Autorizace</th>
                <th>Akce</th>
            </tr>
            </thead>
            <tbody>
            {items.length === 0 && <tr>
                <td colSpan={4}>Žádná data k zobrazení</td>
            </tr>}
            {items.map((item, index) => (
                <Row data={item} index={index} key={index.toString()}/>
            ))}
            </tbody>
        </table>
    )
}

const Row = (props) => (
    <tr key={props.index.toString()}>
        <td>{props.data.name}</td>
        <td>
            <EnigooFieldsGroup items={[
                <span key={"1"}>{props.data.channelKey}</span>,
                <Link key={"2"} target={"_blank"} to={"/app/online/" + props.data.channelKey}>
                    <button><FiLink/></button>
                </Link>,
                <CopyToClipboard key={"3"} text={window.location.origin + "/app/online/" + props.data.channelKey}>
                    <button><FiCopy/></button>
                </CopyToClipboard>
            ]}/>
        </td>
        <td>{!props.data.withAuthorization ? "-" : (props.data.byPassword ? "Heslo" : "Přihlášení")}</td>
        <td>{!props.data.action?"-":props.data.action}</td>
    </tr>
)

export default WebChannelTable;
