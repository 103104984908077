import React, {useEffect, useState} from "react";
import {useHistory, useParams} from "react-router";
import Widget from "../../../../components/widget";
import {EnigooLoader} from "../../../../MyComponent/EnigooLoader";
import {Form} from "react-final-form";
import EnigooButton from "../../../../MyComponent/EnigooButton";
import {FiInfo, FiSave} from "react-icons/all";
import {apiCreateAction, apiGetActionById, apiUpdateAction} from "../Actions";
import {IconTabs} from "../../../../components/tabs";
import {FiActivity, FiCalendar, FiCheck, FiFileText} from "react-icons/fi";
import CogoToast from "cogo-toast";
import EnigooBreadcrumbs from "../../../../MyComponent/EnigooBreadcrumbs";
import arrayMutators from "final-form-arrays";
import {useDispatch} from "react-redux";
import VerifyRights from "../../../VerifyRights";
import {Trans, useTranslation} from "react-i18next";
import TabBasicInfo from "./TabBasicInfo";
import TabDate from "./TabDate";
import TabTextsOnline from "./TabTextsOnline";
import TabCheckingAttributes from "./TabsCheckingAttributes";
import TabTextPreview from "./TabTextPreview";
import EnigooFormChangedAlert from "../../../../MyComponent/EnigooFormChangedAlert";
import {userPrint} from "../../User/CollectiveOperations/UserPrint";


const ActionForm = () => {
    let [actionData, setActionData] = useState({"isCredit":false,"withPhoto":false,"requirePhoto":false,"smartBc":false,"previewData":{"credit":{"show":false},"custom":{"show":false},"meal":{"show":false},"parking":{"show":false}}});
    let [loading, setLoading] = useState(false);
    let [saving, setSaving] = useState(false);
    let [showCredit, setShowCredit] = useState(false);
    let [showCustom, setShowCustom] = useState(false);
    let[previewInitImage,setPreviewInitImage]=useState(null);
    const history = useHistory();

    const {id} = useParams();
    const dispatch = useDispatch();
    const {t} = useTranslation()
    let doPush;
    let submit;
    useEffect(() => {
        const handleKeyDown = (event) => {
            if ((event.ctrlKey || event.metaKey) && event.key === 's') {
                event.preventDefault();
                submit();
                console.log('Ctrl/Command + S was pressed');
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    useEffect(() => {
        init();
    }, []);

    const init = () => {
        if (id) {
            setLoading(true);
            apiGetActionById(id,
                (data) => {
                    setActionData(data);
                    setShowCredit(data.previewData.credit.show);
                    setShowCustom(data.previewData.custom.show);
                    setPreviewInitImage(data?.previewData?.image?.data);
                    setLoading(false);
                },
                (error) => {
                    alert(error);
                })
        }
    }

    const onSubmit = (values) => {
        console.log(values)
        setSaving(true);
        if (id) {
            apiUpdateAction(values, id, (data) => {
                CogoToast.success(t("action.successSave"));
                setSaving(false);
                dispatch({
                    type: 'SET_ACTION',
                    actualAction: null
                })

                init();
            }, (error) => {
                CogoToast.error(t("action.errorSave"))
                setSaving(false);
            })
        } else {
            apiCreateAction(values, (data) => {
                CogoToast.success(t("action.successSave"));
                console.log(data)
                setSaving(false);
                history.push("/action/detail/" + data.id)
            }, (error) => {
                CogoToast.error(t("action.errorSave"));
                setSaving(false);
            })
        }
    }


    return (
        <div className="flex flex-col">
            <Form onSubmit={onSubmit} initialValues={actionData}
                  validate={values => {
                      let errors = {};
                      if (!values.name) {
                          errors.name = t("basics.requireField")
                      }
                      if (values.dateFrom && values.dateTo) {
                          const dateFrom = new Date(values.dateFrom);
                          const dateTo = new Date(values.dateTo);
                          if (dateFrom > dateTo) errors.dateFrom = t("basics.enterCorrectDate");
                      } else if (values.dateFrom && !values.dateTo) {
                          errors.dateTo = t("basics.requireField");
                      } else if (values.dateTo && !values.dateFrom) {
                          errors.dateFrom = t("basics.requireField");
                      }

                      if (values.multiDay && values.days) {
                          errors.days = [];
                          values.days.forEach((item, index) => {
                              errors.days[index] = {};
                              if (!item.name?.cs) {
                                  errors.days[index].name = {};
                                  errors.days[index].name.cs = t("basics.requireField");
                              }
                              if (!item.onAccreditation) {
                                  errors.days[index].onAccreditation = t("basics.requireField");
                              }
                          })
                      }
                      errors.previewData = {};
                      errors.previewData.credit = {};
                      if (values.previewData?.credit?.show && !values.previewData?.credit?.title?.cs) {
                          errors.previewData.credit.title = {};
                          errors.previewData.credit.title.cs = t("basics.requireField");
                      }

                      errors.previewData.custom = {};
                      if (values.previewData?.custom?.show && !values.previewData?.custom?.title?.cs) {
                          errors.previewData.custom.title = {};
                          errors.previewData.custom.title.cs = t("basics.requireField");
                      }

                      errors.previewData.meal = {};
                      if (values.previewData?.meal?.show && !values.previewData?.meal?.title?.cs) {
                          errors.previewData.meal.title = {};
                          errors.previewData.meal.title.cs = t("basics.requireField");
                      }
                      return errors;
                  }}
                  mutators={{...arrayMutators}}
                  render={({
                               values,
                               handleSubmit,
                               form,
                               form: {
                                   mutators: {push, pop, unshift}
                               },
                               dirty,
                      dirtyFields
                           }) => {
                      submit = handleSubmit;
                      return (
                          <>
                              <EnigooBreadcrumbs
                                  right={<VerifyRights page={"action"} action={id ? "updateAction" : "createAction"}>
                                      <EnigooButton disabled={saving || loading} icon={<FiSave size={15}/>}
                                                    loading={saving}
                                                    onClick={handleSubmit} text={t("basics.save")}/></VerifyRights>}/>
                              <EnigooFormChangedAlert onReset={() => form.reset(actionData)} onSave={handleSubmit}
                                                      dirty={dirty}/>
                              <Widget zIndex={0}>
                                  {loading ? <EnigooLoader text={t("basics.loadingData")}/> :
                                      <div className="w-full">
                                          <IconTabs
                                              tabs={tabs(actionData, push, showCredit, setShowCredit, showCustom, setShowCustom, values,loading,previewInitImage)}/>
                                      </div>
                                  }
                              </Widget>
                          </>)
                  }}
            />
        </div>
    )
}

const tabs = (actionData, doPush, showCredit, setShowCredit, showCustom, setShowCustom, values,loading,previewInitImage) => {
    const haveMealExt = () => {
        if (actionData && actionData.extension) {
            return actionData.extension.some((item) => item.type === 1);
        } else {
            return false;
        }
    }



    let tabs =  [{
        index: 0,
        content: <TabBasicInfo actionData={actionData} doPush={doPush} values={values}/>,
        title: (<><FiInfo size={18} className={"stroke-current"}/><span
            className={"ml-2"}><Trans i18nKey={"basics.info"}>Základní</Trans></span> </>)

    }
        ,
        {
            index: 1,
            content: <TabDate values={values} doPush={doPush}/>,
            title: <><FiCalendar size={18} className={"stroke-current"}/><span
                className={"ml-2"}><Trans
                i18nKey={"basics.date"}>Datum</Trans></span> </>
        }
        , {
            index: 2,
            content: <TabTextsOnline/>,
            title: <><FiFileText size={18} className={"stroke-current"}/><span className={"ml-2"}><Trans
                i18nKey={"action.textsOnline"}>Online</Trans></span> </>,
            tooltip: `<div>${<Trans i18nKey={"action.textsOnlineToolTip"}/>}</div>`
        }, {
            index: 3,
            content: <TabCheckingAttributes doPush={doPush}/>,
            title: <><FiCheck size={18} className={"stroke-current"}/><span className={"ml-2"}><Trans
                i18nKey={"action.checkingAttributes"}>Volitelné</Trans></span> </>,
            tooltip: "<span><Trans i18nKey={'action.checkingAttributesToolTip'}>Volitelné atributy jsou například podmínky, se kterými musíte souhlasit.</Trans></span>"
        },/*
        {
            index: 5,
            content: <TabFunctionality/>,
            title: <><VscSettings size={18} className={"stroke-current"}/><span className={"ml-2"}>Funkcionality</span> </>,
            tooltip: "<span>Funkcionality</span>"
        }*/
    ];

    if(values.smartBc){
        tabs.push( {
            index: 4,
            content: <TabTextPreview showCredit={showCredit} setShowCredit={setShowCredit} showCustom={showCustom}
                                     setShowCustom={setShowCustom} values={values} showMeal={haveMealExt()} loading={loading} previewInitImage={previewInitImage}/>,
            title: <><FiActivity size={18} className={"stroke-current"}/><span className={"ml-2"}><Trans
                i18nKey={"action.textsPreview"}>Preview</Trans></span> </>,
            tooltip: "<span><Trans i18nKey={'action.textsPreviewToolTip'}>Texty v preview modulu (v případě, kdy je aktivní chytrý QR kód)</Trans></span>"
        })
    }

    return tabs;
}
export default ActionForm;
