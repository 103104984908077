import React from "react";
import {FiHelpCircle} from "react-icons/fi";
import {EnigooTextField} from "../EnigooTextField";
import {shallowEqual, useSelector} from "react-redux";
import {EnigooHtmlTextArray} from "../EnigooHtmlTextArray";
import {MultiLanguageTabs} from "./MultiLanguageTabs";
import EnigooToolTip from "../EnigooToolTip";

const EnigooMultiLanguageField = ({
                                      label,
                                      name,
                                      type = 'text',
                                      placeHolder = 'Zadejte text...',
                                      outsideClassName = "w-full",
                                      toolTip,
                                      _key,
                                      toolTipPosition = "right"
                                  }) => {


    const {adminRights} = useSelector(
        state => ({adminRights: state.reducerAdmin.adminRights}),
        shallowEqual
    )
    const languages = ["cs", "sk", "en", "de"];

    const _renderTabs = () => {
        let array = [];
        languages.map((item, index) => {
            array.push({
                index: index,
                title: item,
                content: _renderField(item, "w-full mb-0")
            })
        })

        return array;
    }

    const _renderField = (id, className) => {
        switch (type) {
            case "text": {
                return <EnigooTextField outsideClassName={className} placeHolder={placeHolder} name={name + "." + id}
                                        label={""}/>
            }
            case "html": {
                return <EnigooHtmlTextArray placeHolder={placeHolder}
                                            name={name + "." + id} label={""}/>
            }
        }

    }

    return (
        <div className={outsideClassName}>
            {label !== "" && <div className={"form-label flex flex-row"}>{label}
                {toolTip && <><FiHelpCircle
                    className={"ml-2 text-red-900"} size={18}
                    data-tip key={name} data-for={"_tooltip_" + name}/>
                    <EnigooToolTip content={toolTip} id={"_tooltip_" + name} effect={"solid"} place={toolTipPosition}
                                  class={"enigoo-tooltip"}/></>}</div>}
            <div className={"flex " + type !== "html" ? " flex-row mt-2" : " flex-col"}>
                {adminRights.subject.multiLanguage ?
                    <div className={"border rounded mb-2"}><MultiLanguageTabs tabs={_renderTabs()}/></div>
                    : _renderField("cs", "w-full")}
            </div>
        </div>
    )

}
export default EnigooMultiLanguageField;
