import {Route, Switch, useHistory} from "react-router-dom";
import VerifyRights from "./VerifyRights";
import Dashboard from "./pages/Dashboard/Dashboard";
import DashBoardv2 from "./pages/Dashboard/DashBoardv2";
import React from "react";
import Login from "./pages/Login/AdminUserLogin";
import NewPassword from "./pages/Login/NewPassword";
import ConfirmAdminUser from "./pages/Login/ConfirmAdminUser";

const LoginRoutes = () => {
    let token = localStorage.getItem('ath-acc-en')
    let history = useHistory();
    return (
        <Switch>
            {/*Dashboard*/}
            <Route exact path={"/login"}>
                {token ? history.push("/"):<Login/>}
            </Route>
            <Route exact path={"/forgot-password/:passwordLink"}><NewPassword/></Route>
            <Route exact path={"/verify/:confirmLink"}><ConfirmAdminUser/></Route>
        </Switch>)
}
export default LoginRoutes;
