import {axios} from "../../../axiosConfig";

const url = "api/admin/v1/web-channel";

export function apiGetWebChannel(callback, error) {
    axios.get(url)
        .then(response => callback(response.data.data))
        .catch(err => error(err));
}

export function apiGetWebChannelById(id, callback, error) {
    axios.get(url + "/" + id)
        .then(response => callback(response.data.data))
        .catch(err => error(err));
}

export function apiUpdateWebChannel(id, data, callback, error) {
    axios.put(url + "/" + id, data)
        .then(response => callback(response.data.data))
        .catch(err => error(err));
}

export function apiCreateWebChannel(data, callback, error) {
    axios.post(url, data)
        .then(response => callback(response.data.data))
        .catch(err => error(err));
}

export function apiExportWebChannelExternalCodes(id, callback, error) {
    axios.get(url + "/external-code/" + id,{responseType:"blob"})
        .then(response => {
            let fileName = response.headers['content-disposition'].toString();
            fileName = fileName.substring(fileName.indexOf("=") + 1);
            let data = response.data;
            let array = [];
            array["fileName"] = fileName;
            array["data"] = data;
            callback(array);
        })
        .catch(err => error(err))
}
