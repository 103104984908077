import {EnigooTextField} from "../../../../MyComponent/EnigooTextField";
import EnigooMultiLanguageField from "../../../../MyComponent/EnigooMultiLanguageField/EnigooMultiLanguageField";
import {FieldArray} from "react-final-form-arrays";
import EnigooButton from "../../../../MyComponent/EnigooButton";
import {FiCopy, FiHelpCircle, FiPlus} from "react-icons/fi";
import React, {useEffect, useState} from "react";
import MealOptionModal from "./MealOptionModal";
import {useTranslation} from "react-i18next";
import {Field} from "react-final-form";
import {EnigooDateTimeFieldv2} from "../../../../MyComponent/EnigooDateTimeFieldv2";
import EnigooToolTip from "../../../../MyComponent/EnigooToolTip";
import CogoToast from "cogo-toast";


const Option = ({name, index, fields}) => {

    const {t} = useTranslation();

    let inputRef = null;

    const _renderErrors = (inputRef) => {
        let arr = [];
        if (!inputRef?.name?.cs) {
            arr.push("mealExtension.mealNameRequired")
        }
        if (inputRef?.capacity && inputRef.capacity <= 0) {
            arr.push("actionExtension.capacityMustBePositive")
        }

        return arr;
    }

    return <div key={index}><Field name={`${name}`} key={index}>
        {({input, meta}) => {
            let errors = _renderErrors(input.value)
            return (
                <div className={"flex flex-col space-y-1"}>
                    <div className={"grid grid-cols-10 place-items-center"}>
                        <div>{`#${index + 1}`}</div>
                        <div className={"col-span-8 justify-self-stretch"}>
                            <MealOptionModal index={index} fieldName={name} remove={fields.remove} fields={fields}
                                             error={errors.length > 0}/>
                        </div>
                        <div
                            className={"text-right w-1/8"}
                            style={{cursor: "pointer"}}>
                            <div onClick={() => {
                                fields.remove(index)
                            }}> ❌
                            </div>
                        </div>

                    </div>
                    {errors.length > 0 && <div className={"flex flex-col space-y-1"}>
                        {errors.map((item) => <div
                            className={"text-xs text-red-500 whitespace-no-wrap text-center"}>{t(item)}</div>)}
                    </div>}
                </div>)
        }}
    </Field>
    </div>
}

export const MealItem = ({name, index, remove, fields, data}) => {
    const {t} = useTranslation();
    return (
        <div className={`text-sm form-input`} key={name}>
            <div className={"grid grid-cols-2"}>
                <div className={"form-label"}>#{index + 1}:</div>
                <div className={"flex flex-row space-x-2 justify-end w-full"}>
                    <div
                        className={"text-right"}
                        style={{cursor: "pointer"}}>
                        <div onClick={() => {
                            remove(index)
                        }}> ❌
                        </div>
                    </div>
                </div>
            </div>
            <div className={"flex flex-col space-y-2"}>
                <div className={"hidden"}><EnigooTextField label={""} name={`${name}.id`}/></div>
                {!data.action.multiDay &&
                    <EnigooDateTimeFieldv2 name={`${name}.date`} label={t("basics.date") + ":*"} showTimeSelect={false}
                                         onlyTime={false}/>}
                <EnigooMultiLanguageField name={`${name}.label`} label={t("basics.label") + ":*"}/>
                <EnigooDateTimeFieldv2 name={`${name}.timeFrom`} label={t("mealExtension.timeFrom") + ":*"}
                                     onlyTime={true}/>
                <EnigooDateTimeFieldv2 name={`${name}.timeTo`} label={t("mealExtension.timeTo") + ":*"} onlyTime={true}/>
                <FieldArray name={`${name}.options`}>
                    {({fields}) => {
                        return (
                            <div className={"flex flex-col space-y-2"}>
                                <>
                                    <div className={"form-label flex flex-row"}>
                                        {t("mealExtension.optionsForSelectMeal")}:
                                        <><FiHelpCircle data-tip data-for={"_tooltip_meal_extension_"+name}
                                            className={"ml-2 text-red-900"} size={18}/>
                                            <EnigooToolTip content={t("mealExtension.optionsForSelectMealToolTip")} id={"_tooltip_meal_extension_"+name} place={"right"}/></>
                                    </div>
                                </>
                                <div
                                    className={`flex flex-col space-y-2`}>
                                    {fields.map((name, index) => <Option key={name} name={name} index={index} fields={fields}/>)}
                                    <EnigooButton  toolTip={t("mealExtension.createNewOptionForSelectMeal")} _key={"add_option_meal_select_"+name}
                                                  icon={<FiPlus/>} text={t("basics.add")}
                                                  onClick={() => {
                                                      fields.push({name: {cs: ""}, open: true})
                                                  }}/>
                                </div>
                            </div>
                        )
                    }}
                </FieldArray>
            </div>
        </div>
    )
}

export const MealDay = ({
                            day, fieldName, data, duplicate = null,push,values,daysFields
                        }) => {
    const {t} = useTranslation();

    return (
        <FieldArray name={`${fieldName}.items`}>
            {({fields}) => {
                return (
                    <>
                        <div className={"flex flex-row space-x-2"}>
                            <EnigooButton toolTip={t("mealExtension.createNewKindOfMeal")} _key={"action_meal_extension_add"} icon={<FiPlus/>}
                                          onClick={() => {
                                              fields.push({options: [], date: day})
                                          }}/>
                            {push && <EnigooButton toolTip={t("actionExtension.copyToOtherDays")} _key={"action_meal_extension_duplicate"} icon={<FiCopy/>} onClick={()=>{
                                let itemsToCopy = [];
                                values.extension.items.forEach(item => {
                                    if(item.day===day){
                                        itemsToCopy = item.items
                                    }
                                })
                                let newItemsToCopy = [];
                                itemsToCopy.forEach(item=>{
                                    let opts = [];
                                    item.options.forEach(opt=>{
                                        opts.push({
                                            capacity:opt.capacity,
                                            day:opt.day,
                                            name:opt.name,
                                            note:opt.note
                                        })
                                    })
                                    newItemsToCopy.push({
                                        capacity:item.capacity,
                                        label: item.label,
                                        options: opts,
                                        timeFrom:item.timeFrom,
                                        timeTo:item.timeTo
                                    })
                                })
                                /*values.extension.items.forEach(extItem=>{
                                    if(extItem.day!==day){
                                        if(!extItem.items){
                                            extItem.items = [];
                                        }
                                        newItemsToCopy.forEach((newItem)=>{
                                            extItem.items.push({
                                                capacity:newItem.capacity,
                                                label:newItem.label,
                                                options:newItem.options,
                                                timeFrom:newItem.timeFrom,
                                                timeTo:newItem.timeTo,
                                                day:extItem.day,
                                            })
                                        })
                                    }
                                })*/
                                daysFields.forEach((item,index)=>{
                                    if(item!==fieldName){
                                        let newDayItems = [];
                                        newItemsToCopy.forEach((nItem)=>{
                                            push(item+".items",{
                                                capacity:nItem.capacity,
                                                label:nItem.label,
                                                options:nItem.options,
                                                timeFrom:nItem.timeFrom,
                                                timeTo:nItem.timeTo,
                                                date:daysFields.value[index].day,
                                            })
                                        })
                                    }
                                })
                                CogoToast.success(t("actionExtension.copyToOtherDaysSuccess"));
                            }}/>}
                        </div>
                        <div
                            className={`grid text-sm mt-5 lg:grid-cols-4 grid-cols-1 gap-2`}>
                            {fields.map((name, index) => <MealItem key={name} name={name} fields={fields} index={index}
                                                                   data={data}
                                                                   remove={fields.remove}/>)}
                        </div>

                    </>
                )
            }
            }
        </FieldArray>
    )
}

const areTimeSlotsOverlapping = (slot1, slot2) => {
    return (slot1.timeFrom <= slot2.timeTo && slot2.timeFrom <= slot1.timeTo);
}
const validateTimeSlots = (times, errors, t) => {
    for (let i = 0; i < times.length; i++) {
        for (let j = i + 1; j < times.length; j++) {
            if (areTimeSlotsOverlapping(times[i], times[j])) {
                errors.extension.items[times[i].index1].items[times[i].index2].timeFrom = t("mealExtension.timeSlotsOverlap");
                errors.extension.items[times[i].index1].items[times[i].index2].timeTo = t("mealExtension.timeSlotsOverlap");
                errors.extension.items[times[j].index1].items[times[j].index2].timeFrom = t("mealExtension.timeSlotsOverlap");
                errors.extension.items[times[j].index1].items[times[j].index2].timeTo = t("mealExtension.timeSlotsOverlap");
                return true;
            }
        }
    }
    return false;
}

export const _validateMeal = (data,values, t) => {
    let errors = {};
    if (values.extension.items) {
        errors.extension = {};
        errors.extension.items = [];


        values.extension.items.forEach((item1, index1) => {
            errors.extension.items[index1] = {};
            let times = [];
            if (item1.items) {
                errors.extension.items[index1].items = [];
                item1.items.forEach((item2, index2) => {
                    errors.extension.items[index1].items[index2] = {};
                    if (!item2.date) {
                        errors.extension.items[index1].items[index2].date = t("basics.requireField")
                    }
                    else if(!data.action.multiDay){
                        if(data.action.dateFrom){
                            let dateFrom = new Date(data.action.dateFrom);
                            dateFrom = dateFrom.setHours(0,0);
                            if(dateFrom > new Date(item2.date)){
                                errors.extension.items[index1].items[index2].date = t("actionExtension.dateIsNotActionDateArray")
                            }
                        }
                        if(data.action.dateTo){
                            let dateTo = new Date(data.action.dateTo);
                            dateTo =dateTo.setHours(23,59);
                            if(dateTo < new Date(item2.date)){
                                errors.extension.items[index1].items[index2].date = t("actionExtension.dateIsNotActionDateArray")
                            }

                        }
                    }
                    if (!item2.timeFrom) {
                        errors.extension.items[index1].items[index2].timeFrom = t("basics.requireField")
                    }
                    if (!item2.timeTo) {
                        errors.extension.items[index1].items[index2].timeTo = t("basics.requireField")
                    }
                    if (item2.timeFrom && item2.timeTo) {

                        const [hours1, minutes1] = item2.timeFrom.split(":");
                        const [hours2, minutes2] = item2.timeTo.split(":");

                        const date1 = new Date(0, 0, 0, hours1, minutes1);
                        const date2 = new Date(0, 0, 0, hours2, minutes2);
                        times.push({timeFrom: date1, timeTo: date2, index1: index1, index2: index2})
                        if (date1 > date2) {
                            errors.extension.items[index1].items[index2].timeTo = t("basics.timeSlotNotValid")
                            errors.extension.items[index1].items[index2].timeFrom = t("basics.timeSlotNotValid")
                        }
                    }
                    if (!item2.label || !item2.label.cs) {
                        errors.extension.items[index1].items[index2].label = {}
                        errors.extension.items[index1].items[index2].label.cs = t("basics.requireField")
                    }
                    if (item2.options) {
                        errors.extension.items[index1].items[index2].options = [];
                        item2.options.forEach((item3, index3) => {
                            errors.extension.items[index1].items[index2].options[index3] = {};
                            if (!item3?.name?.cs) {
                                errors.extension.items[index1].items[index2].options[index3].name = {cs: t("basics.requireField")};
                            }
                            if (item3?.capacity && item3.capacity <= 0) {
                                errors.extension.items[index1].items[index2].options[index3].capacity = t("externalCode.enterPositiveNumber");
                            }
                        })
                    }
                })
                if (validateTimeSlots(times, errors, t)) {
                    errors.times = t("basics.timeSlotNotValid");
                }
            }
        })
    }
    return errors;
}
