import React, {useEffect, useRef, useState} from "react";
import {shallowEqual, useSelector} from "react-redux";
import Portal from "../../../components/portal";
import {FiDelete, FiPlus, FiX} from "react-icons/fi";
import PropTypes from "prop-types";
import EnigooButton from "../../../MyComponent/EnigooButton";
import {FiSave} from "react-icons/all";
import {Form} from "react-final-form";
import Widget from "../../../components/widget";
import {EnigooFieldsGroup} from "../../../MyComponent/EnigooFieldsGroup";
import {EnigooTextField} from "../../../MyComponent/EnigooTextField";
import {EnigooSwitch} from "../../../MyComponent/EnigooSwitch";
import CogoToast from "cogo-toast";

export const WebChannelUserModal = ({item, saveUser,removeUser, index = null}) => {

    const {palettes} = useSelector(
        (state) => ({
            palettes: state.reducerAdmin.palettes
        }),
        shallowEqual
    )
    let {background} = {
        ...palettes
    }

    const modalRef = useRef(null)
    const [open, setOpen] = useState(false)

    let submit;

    const show = () => {
        setOpen(true)
    }
    const hide = () => {
        setOpen(false)
    }

    const save = (data) => {
        let result = false;
        if (item) {
            result = saveUser(index, data);
        } else {
            result = saveUser(data);
        }
        if (result) {
            hide();
            CogoToast.success(item?"Úprava uživatele proběhla v pořádku":"Uživatel byl uložen");
        }else{
            CogoToast.error("Při vytváření nastala chyba, pravděpodobně již uživatel s tímto loginem existuje...");
        }
    }

    const remove = ()=>{
        if(index!==null && removeUser!==null) {
            removeUser(index);
            hide();
            CogoToast.success("Uživatel byl tomuto kanálu odebrán.")
        }
    }

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (!modalRef || !modalRef.current) return false
            if (!open || modalRef.current.contains(event.target)) {
                return false
            }
            setOpen(!open)
        }
        document.addEventListener('mousedown', handleClickOutside)

        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [open, modalRef])

    return (
        <>
            <EnigooButton onClick={show} icon={!item ? <FiPlus/> : <></>} text={item ? item.login : ""}/>
            {open && (
                <Portal selector="#portal">
                    <div className="modal-backdrop fade-in"></div>
                    <div className={`modal show ${background === 'dark' ? 'dark-mode' : ''}`}
                         data-background={background}>
                        <div className="relative min-w-sm w-auto mx-auto lg:max-w-5xl" ref={modalRef}>
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h3 className="text-xl font-semibold">{item ? "Úprava uživatele webového kanálu" : "Nový uživatel webového kanálu"}</h3>
                                    <button
                                        className="modal-close btn btn-transparent"
                                        onClick={hide}>
                                        <FiX size={18} className="stroke-current"/>
                                    </button>
                                </div>
                                <div className="relative p-4 flex-auto">
                                    <div className={"max-h-sm overflow-y-scroll p-2 pb-10"}>
                                    <Widget>
                                        <div className="flex flex-col">
                                            <Form onSubmit={save} initialValues={item}
                                                  validate={values => {
                                                      let errors = {};
                                                      if (!values.login) {
                                                          errors.login = "Login je povinný";
                                                      }
                                                      if (!item && !values.password) {
                                                          errors.password = "Heslo je povinné";
                                                      }
                                                      return errors;
                                                  }
                                                  } render={({handleSubmit}) => {
                                                submit = handleSubmit;
                                                return (
                                                    <>
                                                        <EnigooFieldsGroup items={[
                                                            <EnigooTextField disabled={item} label={"Login: *"}
                                                                             name={"login"}
                                                                             placeHolder={"Zadejte login.."}
                                                                             outsideClassName={"w-full lg:w-1/2"}
                                                                             key={"1"}/>,
                                                            <EnigooTextField label={"Heslo: *"} name={"password"}
                                                                             type={"password"}
                                                                             placeHolder={"Zadejte heslo.."}
                                                                             outsideClassName={"w-full lg:w-1/2"}
                                                                             key={"2"}/>
                                                        ]}/>
                                                        <EnigooSwitch label={"Aktivní:"} name={"active"}/>
                                                    </>
                                                );
                                            }}
                                            />
                                        </div>

                                    </Widget>
                                    </div>
                                </div>
                                <div className="modal-footer space-x-2">
                                    <EnigooButton onClick={remove} text={"Odstranit"} icon={<FiDelete/>} visible={index !== null}/>
                                    <EnigooButton onClick={hide} text={"Zavřít"} icon={<FiX/>}/>
                                    <EnigooButton onClick={() => submit()} text={"Uložit"} icon={<FiSave/>}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </Portal>
            )}
        </>
    )
}
WebChannelUserModal.propTypes = {
    item: PropTypes.object
}
