import React from "react";
import {EnigooTextField} from "../../../MyComponent/EnigooTextField";
import GroupsSelectField from "../../../MyComponent/Api/Admin/GroupsSelectField";
import {EnigooSwitch} from "../../../MyComponent/EnigooSwitch";
import DeviceTemplateCustomRules from "./DeviceTemplateCustomRules";
import {useTranslation} from "react-i18next";
import {shallowEqual, useSelector} from "react-redux";
import {FiCopy} from "react-icons/fi";

const DeviceTemplateRuleAssignField = ({fields, name, index, doPush,values}) => {
    const {t} = useTranslation()
    const {palettes} = useSelector(
        (state) => ({
            palettes: state.reducerAdmin.palettes
        }),
        shallowEqual
    )

    const {background, leftSidebar, navbar} = palettes;

    let isDark = React.useMemo(() => {
        return background === "dark" && leftSidebar === "dark" && navbar === "dark";
    }, [background, leftSidebar, navbar])
    return (
        <div className={`text-sm form-input ${isDark ? "bg-grey-800" : ""}`}>
            <div className={"grid grid-cols-2"}>
                <div className={"form-label"}>{t("device.rule")} #{index + 1}:</div>
                <div className={"flex flex-row space-x-2 justify-end w-full"}>
                    <div
                        className={"text-right"}
                        style={{cursor: "pointer"}}>
                        <div onClick={() => {
                            if(fields.value&&fields.value[index]) {
                                fields.push(fields.value[index])
                            }
                        }}> <FiCopy size={15}/>
                        </div>
                    </div>
                    <div
                        className={"text-right"}
                        style={{cursor: "pointer"}}>
                        <div onClick={() => {
                            fields.remove(index)
                        }}> ❌
                        </div>
                    </div>
                </div>
            </div>
            <div className={"grid grid-cols-5 text-sm mt-5 gap-2"}>
                <GroupsSelectField isMulti={false} name={`${name}.groupId`} label={t("groups.single") + ": *"}
                                   all={true}/>
                <EnigooTextField name={`${name}.entrances`} label={t("deviceTemplate.numOfEntrance") + ": "}
                                 type={"number"}
                                 placeHolder={t("placeHolder.numOfEntrance")}/>
                <EnigooSwitch name={`${name}.daily`} label={t("deviceTemplate.daily") + ": "}/>
                <EnigooTextField name={`${name}.levelId`} label={t("deviceTemplate.level") + ": "}
                                 toolTip={t("deviceTemplate.levelToolTip")} type={"number"}
                                 placeHolder={t("placeHolder.level")}/>
                <EnigooSwitch name={`${name}.info`} label={t("deviceTemplate.accreditationInfo") + ": "}/>
            </div>
            <DeviceTemplateCustomRules name={name} doPush={doPush}/>
        </div>
    )
}
export default DeviceTemplateRuleAssignField;
