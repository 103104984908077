import {useTranslation} from "react-i18next";
import EnigooButton from "../../../../MyComponent/EnigooButton";
import {FiPlus} from "react-icons/fi";
import {FieldArray} from "react-final-form-arrays";
import {EnigooTextField} from "../../../../MyComponent/EnigooTextField";
import {EnigooSwitch} from "../../../../MyComponent/EnigooSwitch";
import React from "react";
import PropTypes from "prop-types";
import EnigooNote from "../../../../MyComponent/EnigooNote";

const TabCheckingAttributes = ({doPush}) => {
    const {t} = useTranslation()
    return (
        <>
            <EnigooNote>
                <div className={"flex flex-col space-y-2"}>
                    <div className={"font-bold text-enigoo-500"}>{t("action.checkingAttributes")}</div>
                    <div dangerouslySetInnerHTML={{__html:t("action.checkingAttributeNote")}}></div>
                </div>
            </EnigooNote>
            <EnigooButton text={t("action.addAttribute")} icon={<FiPlus size={10}/>} onClick={() => {
                doPush("checkingAttributes", undefined);
            }}/>
            <FieldArray name={"checkingAttributes"}>
                {({fields}) => {
                    return (
                        <div className={"w-full flex flex-row flex-wrap mt-2"}>
                            {fields.map((name, index) => {
                                return (
                                    <div className={"mt-1 mr-1 w-full text-sm form-input"}>
                                        <div className={'grid grid-cols-4 gap-2 items-center w-full'}>
                                            <EnigooTextField label={t("action.visibleText") + ": "}
                                                             name={`${name}.label`}/>
                                            <EnigooTextField name={`${name}.url`}
                                                             label={t("action.linkToFile") + ": "}/>
                                            <EnigooSwitch name={`${name}.required`} label={t("action.require") + ": "}
                                                          outsideClassName={"w-11/12"}/>
                                            <div
                                                className={"ml-2 w-1/12"}
                                                onClick={() => {
                                                    fields.remove(index)
                                                }
                                                }
                                                style={{cursor: "pointer"}}>❌
                                            </div>
                                        </div>
                                    </div>)
                            })}
                        </div>
                    )
                }
                }
            </FieldArray>
        </>
    )
}
TabCheckingAttributes.propTypes={
    doPush:PropTypes.func.isRequired
}
export default TabCheckingAttributes;
