import React, {useEffect, useState} from "react";
import {apiManageGetAdminUser} from "./Actions";
import {EnigooLoader} from "../../../MyComponent/EnigooLoader";
import SectionTitle from "../../../components/section-title";
import EnigooButton from "../../../MyComponent/EnigooButton";
import {FiPlus} from "react-icons/fi";
import Widget from "../../../components/widget";
import AdminUserTable from "./AdminUserTable";
import {EnigooManageSubjectSelectField} from "../../../MyComponent/Api/Manage/EnigooManageSubjectSelectField";
import {Form} from "react-final-form";


const WebChannel = () => {
    let [loading, setLoading] = useState(false);
    let [data, setData] = useState(null);
    let [errorMessage, setErrorMessage] = useState(null);
    let [actualSubject, setActualSubject] = useState(null);
    let [actualData, setActualData] = useState(null);

    useEffect(() => {
        getAdminUser();
    }, []);

    const getAdminUser = () => {
        setLoading(true);
        apiManageGetAdminUser((result) => {
            setData(result);
            setActualSubject(result[0].id);
            setActualData(result.filter(item=>item.id===result[0].id)[0]);
            setLoading(false);
            setErrorMessage(null)
        }, (error) => {
            setLoading(false);
            setErrorMessage("Nepodařilo se načíst admin uživatele")
        });
    }

    const _renderBody = () => {
        if (loading) return <EnigooLoader text={"Načítám admin uživatele..."}/>
        if (errorMessage) return <div>{errorMessage}</div>
        if (!data || !actualData) return <AdminUserTable items={{items:[]}}/>
        if (data && actualData) return <AdminUserTable items={actualData}/>
    }

    const changeSubject = (id) => {
        setActualSubject(id);
        setActualData(data.filter(item=>item.id===id)[0]);
    }

    return (
        <>
            <SectionTitle title={"Admin uživatelé"}
                          right={<EnigooButton link={"/manage/admin-user/create"} icon={<FiPlus/>} text={"Přidat"}/>}/>
            <Widget>
                <Form onSubmit={(values)=>changeSubject(values.subject)} initialValues={{"subject":actualSubject}}
                      render={({handleSubmit}) => {
                          return (<EnigooManageSubjectSelectField isMulti={false} onValueChange={(event) => {
                              changeSubject(event.value)
                          }} name={"subject"} label={"Subjekt:"}/>);
                      }}
                >

                </Form>
                <div className={"section-title"}><div className={"subtitle"}>Admin uživatelé subjektu</div></div>
                {_renderBody()}
            </Widget>
        </>
    )
}

export default WebChannel;
