import React, {useEffect, useState} from "react";
import {apiManageGetSubject} from "./Actions";
import {EnigooLoader} from "../../../MyComponent/EnigooLoader";
import SectionTitle from "../../../components/section-title";
import EnigooButton from "../../../MyComponent/EnigooButton";
import {FiPlus} from "react-icons/fi";
import Widget from "../../../components/widget";
import SubjectTable from "./SubjectTable";


const Subject = () => {
    let [loading, setLoading] = useState(false);
    let [data, setData] = useState(null);
    let [errorMessage, setErrorMessage] = useState(null);

    useEffect(()=>{
        getSubject();
    },[]);

    const getSubject=()=>{
        setLoading(true);
        apiManageGetSubject((data)=>{
            setData(data);
            setLoading(false);
            setErrorMessage(null)
        },(error)=>{
            setLoading(false);
            setErrorMessage("Nepodařilo se načíst subjekty")
        });
    }

    const _renderBody = () => {
        if (loading) return <EnigooLoader text={"Načítám subjekty..."}/>
        if (errorMessage) return <div>{errorMessage}</div>
        if (!data) return null
        if (data) return  <SubjectTable items={data}/>;
    }

    return (
        <>
            <SectionTitle title={"Subjekty"} subtitle={"Všechny subjekty"}
                          right={<EnigooButton link={"/manage/subject/create"} icon={<FiPlus/>} text={"Přidat"}/>}/>
            <Widget>
                {_renderBody()}
            </Widget>
        </>
    )
}

export default Subject;