import React, {useEffect, useState} from "react";
import EnigooBreadcrumbs from "../../../MyComponent/EnigooBreadcrumbs";
import EnigooButton from "../../../MyComponent/EnigooButton";
import {FiInfo, FiSave} from "react-icons/all";
import Widget from "../../../components/widget";
import {EnigooLoader} from "../../../MyComponent/EnigooLoader";
import {Form} from "react-final-form";
import {IconTabs} from "../../../components/tabs";
import {EnigooFieldsGroup} from "../../../MyComponent/EnigooFieldsGroup";
import {EnigooTextField} from "../../../MyComponent/EnigooTextField";
import {apiGetActualAdminUser, apiUpdateUserAdmin} from "./Actions";
import CogoToast from "cogo-toast";
import {useHistory} from "react-router-dom";
import {Trans, useTranslation} from "react-i18next";

const UserProfile = () => {
    let [loading, setLoading] = useState(false);
    let [data, setData] = useState(null);
    let [saving,setSaving] = useState(false);

    let history = useHistory();
    let submit = null;

    const {t} = useTranslation()

    useEffect(() => {
        init();
    }, [])

    useEffect(() => {
        const handleKeyDown = (event) => {
            if ((event.ctrlKey || event.metaKey) && event.key === 's') {
                event.preventDefault();
                submit();
                console.log('Ctrl/Command + S was pressed');
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    const init = () => {
        setLoading(true);
        apiGetActualAdminUser((data)=>{
            setData(data);
            setLoading(false);
        },(error)=>{
            CogoToast.error(t("adminUser.loadingDataError"));
            history.push("/");
            setLoading(false);
        })
    }

    const onSubmit = (values) => {
        setSaving(true);
        apiUpdateUserAdmin(data.id,values,(data)=>{
            CogoToast.success(t("adminUser.saved"));
            setSaving(false);
        },(error)=>{
            CogoToast.error(t("adminUser.savedError"));
            setSaving(false);
        })
    }

    return (
        <>
            <EnigooBreadcrumbs
                right={<EnigooButton onClick={() => submit()} icon={<FiSave/>} text={t("basics.save")}
                                     loading={saving} disabled={saving || loading}/>}/>
            <Widget>
                {loading ? <EnigooLoader text={t("adminUser.loadingData")}/> :
                    <div className="flex flex-col">
                        <Form onSubmit={onSubmit} initialValues={data}
                              validate={values => {
                                  let errors = {};
                                  if(!values.name){
                                      errors.name = t("basics.requireField");
                                  }
                                  return errors;
                              }}
                              render={({handleSubmit}) => {
                                  submit = handleSubmit;
                                  return (
                                      <div className={"w-full"}>
                                          <IconTabs tabs={tabs()}/>
                                      </div>
                                  )
                              }}
                        />
                    </div>
                }
            </Widget>
        </>
    )
}

export default UserProfile;

const tabs = () => [
    {
        index: 0,
        content: <TabBasicInfo/>,
        title: (<><FiInfo size={18} className={"stroke-current"}/><span
            className={"ml-2"}><Trans i18nKey={"basics.info"}>Základní informace</Trans></span> </>)

    }
];

const TabBasicInfo = () => {
    const {t} = useTranslation()
    return(
        <>
            <EnigooFieldsGroup
                items={[<EnigooTextField name={"login"} label={t("adminUser.login")+": *"} disabled={true}
                                         outsideClassName={"w-full lg:w-1/2"} placeHolder={t("placeHolder.login")} key={"1"}/>,
                    <EnigooTextField type={"password"} name={"password"} label={t("basics.password")+": *"} key={"2"}
                                     outsideClassName={"w-full lg:w-1/2"} placeHolder={t("placeHolder.password")}/>]}
            />
            <EnigooFieldsGroup
                items={[<EnigooTextField label={t("basics.firstname")+" "+t("basics.lastname")+":"} name={"name"} placeHolder={t("placeHolder.text")}
                                         outsideClassName={"w-full lg:w-1/2"} key={"1"}/>
                ]}/>
        </>
    )
}
