import React from 'react'
import Item from './item'
import '../../css/components/left-sidebar-1.css'
import {Link} from "react-router-dom";
import {FiDatabase, FiHome, FiUsers} from "react-icons/fi";

const menuItems = [
    {
        title: 'Menu',
        items: [
            {
                url: '/manage',
                icon: <FiHome size={20}/>,
                title: 'Dashboard',
                items: []
            },
            {
                url: '/manage/subject',
                icon: <FiDatabase size={20}/>,
                title: 'Subjekty',
                items: []
            },
            {
                url: '/manage/admin-user',
                icon: <FiUsers size={20}/>,
                title: 'Uživatelé',
                items: []
            }
        ]
    }
]

const Sidebar = () => {
    let token = localStorage.getItem("ath-acc-en-mn");
    return (
        <div className="left-sidebar left-sidebar-1">
            <div className="logo truncate">
                <Link
                    to="#"
                    className="flex flex-row items-center justify-start space-x-2">
                    <img src='/logo-E.png' alt={""} width={"30"}/>
                    <span>AK manage</span>
                </Link>
            </div>
            {token?menuItems.map((menu, i) => (
                <div key={i}>
                    <div className="left-sidebar-title">
                        <span>{menu.title}</span>
                    </div>
                    <ul>
                        {menu.items.map((l0, a) => (
                            <li key={a} className="l0">
                                <Item {...l0} />
                                <ul>
                                    {l0.items.map((l1, b) => (
                                        <li key={b} className="l1">
                                            <Item {...l1} />
                                            <ul>
                                                {l1.items.map((l2, c) => (
                                                    <li key={c} className="l2">
                                                        <Item {...l2} />
                                                        <ul>
                                                            {l2.items.map((l3, d) => (
                                                                <li key={d} className="l3">
                                                                    <Item {...l3} />
                                                                    <ul>
                                                                        {l3.items.map((l4, e) => (
                                                                            <li key={e} className="l4">
                                                                                <Item {...l4} />
                                                                            </li>
                                                                        ))}
                                                                    </ul>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </li>
                                                ))}
                                            </ul>
                                        </li>
                                    ))}
                                </ul>
                            </li>
                        ))}
                    </ul>
                </div>
            )):<></>}
        </div>
    )
}

export default Sidebar
