export default function actualAction(
    state={
        actualAction: null,
        customField: [],
        pageIndex: 0,
    },
    action
){
    switch(action.type){
        case "SET_ACTION":
            if(state.actualAction) return state;
            else {
                return {...state,actualAction:action.actualAction,customField: action.customField}
            }
        case "CHANGE_ACTION":
            return {...state,actualAction:action.actualAction}
        case "CHANGE_CUSTOM_FIELD":
            return {...state,customField:action.customField}
        case "CHANGE_PAGE_INDEX":
            return {...state,pageIndex:action.pageIndex}
        case "RESET_ACTUAL_ACTION":
            return {actualAction: null,customField: [],pageIndex: 0}
        case "RESET":
            return {actualAction: null,customField: [],pageIndex: 0}
        default:
            return state;
    }
}
