export const whiteTheme = {
    "common.bisize.width": "0",
    "common.bisize.height": "0",
    "common.backgroundColor": "white",

    // header
    "header.backgroundImage": "none",
    "header.backgroundColor": "white",
    "header.border": "0px",

    // load button
    "loadButton.backgroundColor": "#fff",
    "loadButton.border": "1px solid #ddd",
    "loadButton.color": "#222",
    "loadButton.fontFamily": "'Noto Sans', sans-serif",
    "loadButton.fontSize": "12px",
    "loadButton.visibility":"hidden",

    // download button
    "downloadButton.backgroundColor": "#63438e",
    "downloadButton.border": "1px solid #63438e",
    "downloadButton.color": "#fff",
    "downloadButton.fontFamily": "'Noto Sans', sans-serif",
    "downloadButton.fontSize": "12px",
    "downloadButton.visibility":"hidden",

    // main icons
    "helpMenu.border":"1px solid black",
    "controlMenu.backgroundColor":"white",
    "controls.backgroundColor":"white",
    "menu.backgroundColor":"white",
    "menu.normalIcon.color": "#725599",
    "menu.activeIcon.color": "#63438e",
    "menu.disabledIcon.color": "#917baf",
    "menu.hoverIcon.color": "#593c7f",
    "menu.iconSize.width": "24px",
    "menu.iconSize.height": "24px",

    // submenu icons
    "submenu.normalIcon.color": "#725599",
    "submenu.activeIcon.color": "#63438e",
    "submenu.iconSize.width": "32px",
    "submenu.iconSize.height": "32px",

    // submenu primary color
    "submenu.backgroundColor": "white",
    "submenu.partition.color": "#e5e5e5",

    // submenu labels
    "submenu.normalLabel.color": "#858585",
    "submenu.normalLabel.fontWeight": "normal",
    "submenu.activeLabel.color": "#000",
    "submenu.activeLabel.fontWeight": "normal",

    // checkbox style
    "checkbox.border": "1px solid #ccc",
    "checkbox.backgroundColor": "#fff",

    // rango style
    "range.pointer.color": "#333",
    "range.bar.color": "#ccc",
    "range.subbar.color": "#606060",

    "range.disabledPointer.color": "#d3d3d3",
    "range.disabledBar.color": "rgba(85,85,85,0.06)",
    "range.disabledSubbar.color": "rgba(51,51,51,0.2)",

    "range.value.color": "#000",
    "range.value.fontWeight": "normal",
    "range.value.fontSize": "11px",
    "range.value.border": "0",
    "range.value.backgroundColor": "#f5f5f5",
    "range.title.color": "#000",
    "range.title.fontWeight": "lighter",

    // colorpicker style
    "colorpicker.button.border": "0px",
    "colorpicker.title.color": "#000",
};
