import React, {useState} from "react";
import JsonAttributes from "./JsonAttributes";
import {useTranslation} from "react-i18next";
import ReactJson from "react-json-view";
import {EnigooHtmlTextArray} from "../EnigooHtmlTextArray";

const EnigooJsonView = ({
                            values,
                            label,
                            type = "template",
    name="htmlData"
                        }) => {

    const {t} = useTranslation();

    const jsonData = type==="template"?{
        '{$id}': `${t('templateJsonAttributes.id')} | int`,
        '{$custom_id}': `${t('templateJsonAttributes.customId')} | int`,
        '{$bc}': `${t('templateJsonAttributes.bc')} {$bc|nocheck} -> <img src="{$bc|nocheck}"/> | string`,
        '{$firstname}': `${t('templateJsonAttributes.firstname')} | string`,
        '{$lastname}': `${t('templateJsonAttributes.lastname')} | string`,
        '{$email}': `${t('templateJsonAttributes.email')} | string`,
        '{$group}': `${t('templateJsonAttributes.group')} | string`,
        '{$zones}': {
            1: `${t('templateJsonAttributes.zones')} {$zones[1]} | string`
        },
        '{$custom_field}': {
            1: `${t('templateJsonAttributes.customField')} {$custom_field[1]} | string`
        },
        '{$user_image}': `${t('templateJsonAttributes.userImage')} {$user_image|nocheck} -> <img src="{$user_image|nocheck}"/> | string`,
        '{$html_data}': `${t('templateJsonAttributes.htmlData')} | string`,
        '{$days}': {
            0:`${t('templateJsonAttributes.days')} | array`,
            1:{
                'id':`${t('templateJsonAttributes.daysId')} - {$days[index]->id}, {$day->id}| string`,
                'onAccreditation':`${t('templateJsonAttributes.daysOnAccreditation')} - {$days[index]->onAccreditation}, {$day->onAccreditation} | string`,
                'name':`${t('templateJsonAttributes.daysName')} - {$days[index]->name}, {$day->name} | string`
            }
        },
        '{$actionName}':`${t('templateJsonAttributes.actionName')} | string`,
        '{$smartLink}':`${t('templateJsonAttributes.smartLink')} | string`,


    }:{
        '{$id}': `${t('templateJsonAttributes.id')} | int`,
        '{$custom_id}': `${t('templateJsonAttributes.customId')} | int`,
        '{$firstname}': `${t('templateJsonAttributes.firstname')} | string`,
        '{$lastname}': `${t('templateJsonAttributes.lastname')} | string`,
        '{$email}': `${t('templateJsonAttributes.email')} | string`,
        '{$group}': `${t('templateJsonAttributes.group')} | string`,
        '{$zones}': {
            1: `${t('templateJsonAttributes.zones')} {$zones[1]} | string`
        },
        '{$custom_field}': {
            1: `${t('templateJsonAttributes.customField')} {$custom_field[1]} | string`
        },
        '{$html_data}': `${t('templateJsonAttributes.htmlData')} | string`,
        '{$days}': {
            0:`${t('templateJsonAttributes.days')} | array`,
            1:{
                'id':`${t('templateJsonAttributes.daysId')} - {$days[index]->id}, {$day->id}| string`,
                'onAccreditation':`${t('templateJsonAttributes.daysOnAccreditation')} - {$days[index]->onAccreditation}, {$day->onAccreditation} | string`,
                'name':`${t('templateJsonAttributes.daysName')} - {$days[index]->name}, {$day->name} | string`
            }
        },
        '{$actionName}':`${t('templateJsonAttributes.actionName')} | string`,
        '{$smartLink}':`${t('templateJsonAttributes.smartLink')} | string`,

    }

    const randomValues = {
        'id': 123,
        'custom_id': 1,
        'bc': "445566333",
        'firstname': "Sirius",
        'lastname': "Black",
        'email': "test@email.com",
        'group': "Group name",
        'zones': {
            1: "Zone 1"
        },
        'user_image': "image",
        'html_data': "<div>Group HTML Data</div>",
    }

    return (
        <>
            <div className={"flex flex-col space-y-2 border-2 text-sm border-grey-800 p-2 rounded z-0"}>
                <div className={"text-xl"}>{label}</div>
                <div className={"text-sm"}>
                    <ReactJson src={jsonData} name={null} displayDataTypes={false}/>
                </div>
            </div>
            <JsonAttributes text={values[name]} pageSize={values.pageSize} json={jsonData}
                            randomValues={randomValues} type={type}/>
            <EnigooHtmlTextArray label={t("groups.htmlData") + ":"} name={name}
                                 placeHolder={t("placeHolder.html")}/>
        </>
    )
}
export default EnigooJsonView;
