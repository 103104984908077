import React from "react";
import {AiOutlineReload, AiOutlineWarning, FiInfo, FiSave} from "react-icons/all";
import EnigooButton from "./EnigooButton";
import {useTranslation} from "react-i18next";

const EnigooFormChangedAlert = ({
                                    onSave,
                                    onReset,
                                    message,
                                    dirty = false
                                }) => {
    const {t} = useTranslation();
    if (dirty) {
        return (
            <div
                className={"w-full flex flex-col lg:flex-row items-center justify-start p-4  border border-red-900 text-red-900 shadow rounded-lg border-l-4 mb-5"}>
                <div className={"w-full flex items-center"}>
                    <div className="flex-shrink"><AiOutlineWarning size={30} className={"pr-2"}/></div>
                    <div className="flex-grow text">{message ? message : t("basics.formChanged")}</div>
                </div>
                <div className="flex-shrink">
                    <div className={"flex flex-row space-x-2"}>
                        <EnigooButton onClick={() => onSave()} icon={<FiSave/>} text={t("basics.save")}/>
                        <EnigooButton onClick={() => onReset()} icon={<AiOutlineReload/>}
                                      text={t("basics.discardChanges")} textColor={"enigoo"}
                                      extraStyle={"border border-enigoo-500 bg-grey-50 text-enigoo-500 hover:text-white"}/>
                    </div>
                </div>
            </div>
        )
    } else {
        return <></>
    }


}
EnigooFormChangedAlert.propTypes = {}
export default EnigooFormChangedAlert;
