import React, {useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {FiSave} from "react-icons/all";
import PropTypes from "prop-types";
import {useParams} from "react-router";
import {apiPutAssignExtension} from "./Actions";
import CogoToast from "cogo-toast";
import MealChooseForm from "../MyComponent/MealChooseForm";
import EnigooButton from "../MyComponent/EnigooButton";

const PreviewMeal = ({allowSelect = true, userDays, mealItems, userMeal}) => {
    let [saving,setSaving] = useState(false);

    const {t, i18n} = useTranslation()

    const {subjectKey} = useParams();
    const {bc} = useParams();

    let submit = useRef();

    const onSubmit = (values) => {
        if (bc && subjectKey) {
            setSaving(true);
            apiPutAssignExtension(1,subjectKey, bc, values,
                (data) => {
                    CogoToast.success(t("mealExtension.assigned"),{hideAfter:1}).then(() => {

                        window.location.reload();
                    });
                    setSaving(false);

                }, (err) => {
                    CogoToast.error(t("mealExtension.assignedError"));
                    setSaving(false);
                });
        }
    }

    return (<div className={"flex flex-col text-left"}>
            <div className={"w-full"}>{t("preview.selectedMeal")}:</div>
            <MealChooseForm disabled={!allowSelect} name={"items"} onSave={onSubmit} submitRef={submit} days={userDays}
                            extensionItems={mealItems} initData={userMeal} tabs={userDays.length>1}/>
             <EnigooButton icon={<FiSave/>} text={t("preview.updateMeal")} onClick={() => submit.current()}
                          disabled={!allowSelect || saving} loading={saving} visible={allowSelect}/>


        </div>

    )

}
PreviewMeal.propTypes = {
    allowSelect: PropTypes.bool,
    userDays: PropTypes.array,
    mealItems: PropTypes.array,
    userMeal: PropTypes.object
}
export default PreviewMeal;
