import React, {useEffect, useState} from 'react';
import Centered from "../../../layouts/centered";
import CenteredForm from "../../../layouts/centered-form";
import {useParams} from "react-router-dom";
import {Form} from "react-final-form";
import {useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {EnigooTextField} from "../../../MyComponent/EnigooTextField";
import EnigooButton from "../../../MyComponent/EnigooButton";
import {FiSend} from "react-icons/fi";
import {apiPostVerifyAccount, apiPostVerifyAuthorize} from "./Actions";
import CogoToast from "cogo-toast";
import EnigooDarkModeSwitch from "../../../MyComponent/EnigooDarkModeSwitch";
import EnigooLanguageDropDown from "../../../MyComponent/EnigooMultiLanguageField/EnigooLanguageDropDown";
import {EnigooEmailField} from "../../../MyComponent/EnigooEmailField";


const NewPassword = () => {
    let [saving, setSaving] = useState(false);
    let [loading, setLoading] = useState(false);
    const {confirmLink} = useParams();
    const history = useHistory();
    const {t} = useTranslation();

    const verifyAdminUses = (values) => {
        let newValues = values;
        newValues.confirmLink = confirmLink;
        setSaving(true);
        apiPostVerifyAccount(newValues,(data)=>{
            CogoToast.success(t("basics.accountSuccessfullyConfirmed"))
            history.push("/login");
            setSaving(false);
        },(err)=>{

            setSaving(false);
        })
    }

    if (!confirmLink) {
        history.push("/login");
    }

    useEffect(() => {
        setLoading(true);
        apiPostVerifyAuthorize({"confirmLink": confirmLink}, (data) => {
            setLoading(false);
        }, (err) => {
            setLoading(false);
            if(err?.response?.data?.error==="ADMIN_USER_ALREADY_VERIFIED"){
                CogoToast.error(t("basics.adminUserAlreadyVerified"));
            }else{
                CogoToast.error(t("basics.enteredLinkNotExist"));
            }


            history.push("/login");
        })
    }, [])


    return <>
        <div className={"w-full flex fixed"}>
                    <span className={"ml-auto mr-3 flex flex-row space-x-2"}>
                        <div className={"flex content-center mt-auto"}> <EnigooDarkModeSwitch/></div>
                    <EnigooLanguageDropDown loginPage={true} type={"login"}/>
                        </span>
        </div>
        <Centered heading={true}>

            <CenteredForm subtitle={t("basics.enterNewPassword")} title={t("basics.confirmRegister")}>
                <Form onSubmit={verifyAdminUses} validate={(values) => {
                    let error = {};
                    if (!values.email) {
                        error.email = t("basics.requireField");
                    }
                    if (!values.password) {
                        error.password = t("basics.requireField");
                    }
                    if (!values.confirmPassword) {
                        error.confirmPassword = t("basics.requireField");
                    }
                    if (values.password && values.confirmPassword && values.password !== values.confirmPassword) {
                        error.confirmPassword = t("basics.passwordsNotSame")
                    }
                    return error;
                }} render={({handleSubmit, values}) => {
                    return (
                        <div className={"flex flex-col space-y-2 w-full"}>
                            <EnigooEmailField label={t("basics.email") + ": *"} name={"email"} mandatory={true}
                                             placeHolder={"example@enigoo.cz"}/>
                            <EnigooTextField label={t("basics.password") + ": *"} name={"password"} type={"password"}
                                             placeHolder={"******"}/>
                            <EnigooTextField label={t("basics.passwordConfirm") + ": *"} name={"confirmPassword"}
                                             type={"password"} placeHolder={"******"}/>
                            <EnigooButton icon={<FiSend/>} disabled={saving} loading={saving} text={t("basics.confirm")} onClick={()=>handleSubmit()}/>

                        </div>)
                }
                }/>
            </CenteredForm>
        </Centered>
    </>

}
NewPassword.propTypes = {}

export default NewPassword;
