import React from 'react'
import {shallowEqual, useSelector} from 'react-redux'
import LeftSidebar1 from '../../components/left-sidebar-1'
import RightSidebar1 from '../../components/right-sidebar-1'
import Navbar1 from '../../components/navbar-1'
import '../../css/layouts/layout-1.css'

const Layout1 = ({children}) => {
    const {config, palettes} = useSelector(
        (state) => ({
            config: state.reducerAdmin.config,
            palettes: state.reducerAdmin.palettes
        }),
        shallowEqual
    )
    const {layout, collapsed} = {...config}
    let {background, navbar, logo, leftSidebar, rightSidebar} = {
        ...palettes
    }

    return (
        <div
            data-layout={layout}
            data-collapsed={collapsed}
            data-background={background}
            data-navbar={navbar}
            data-logo={logo}
            data-left-sidebar={leftSidebar}
            data-right-sidebar={rightSidebar}
            className={`${background === 'dark' ? 'dark-mode' : 'default-mode'} w-screen min-h-screen bg-grey-50`}>
            <RightSidebar1/>
            <div className="wrapper bg-grey-50 min-h-screen">
                <LeftSidebar1/>
                <div style={{
                    zIndex:0,
                    transition: "max-width 0.3s ease-in-out"
                }} className={`main w-full bg-grey-50 text-grey-900 dark:bg-grey-900 dark:text-white md:${!collapsed?"ml-64":"ml-16"} lg:${!collapsed?"ml-64":"ml-16"}`}>
                    <Navbar1/>
                    <div className="w-full p-4 mt-40">{children}</div>
                </div>
            </div>
        </div>
    )
}
export default Layout1
