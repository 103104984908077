import {axios} from "../../../axiosConfig";
import CogoToast from "cogo-toast";
import {Trans} from "react-i18next";
import React from "react";

const url = "api/admin/v1/action-extension";

const handleBlobError = (error) => {
    const reader = new FileReader();
    reader.onload = (event) => {
        const result = event.target.result;
        handleError(JSON.parse(result));
    }
    reader.readAsText(error.response.data);
}
const handleError = (error) => {
    switch (error.error) {
        case "ACTION_EXTENSION_DOESNT_EXIST":
            CogoToast.error(<span><Trans i18nKey={"errors.actionExtensionDoesntExist"}>CHYBA</Trans> </span>)
            break;
        default:
            CogoToast.error(<span><Trans i18nKey={"errors.generalError"}>CHYBA</Trans> </span>)
    }

}

export const apiGetActionExtension = (type, actionId, callback, error) => {
    let getUrl = url + "/" + type;
    if (actionId) {
        getUrl += "/";
        getUrl += actionId;
    }
    axios.get(getUrl)
        .then(response => callback(response.data.data))
        .catch((err) => {
            if (err?.response?.data) {
                handleError(err.response.data)
            }
            error(err)
        })
}

export const apiActiveActionExtension = (type, actionId, data, callback, error) => {
    axios.post(url + "/" + type + "/" + actionId, data)
        .then(response => callback(response.data.data))
        .catch((err) => {
            if (err?.response?.data) {
                handleError(err.response.data)
            }
            error(err)
        })
}

export const apiPutUpdateActionExtension = (type, actionId, data, callback, error) => {
    axios.put(url + "/" + type + "/" + actionId, data)
        .then(response => callback(response.data.data))
        .catch((err) => {
            if (err?.response?.data) {
                handleError(err.response.data)
            }
            error(err)
        })
}

export const apiGetUserExtension = (type,actionId, callback, error) => {
    axios.post(url + "/"+type+"/" + actionId + "/users")
        .then(response => callback(response.data.data))
        .catch((err) => {
            if (err?.response?.data) {
                handleError(err.response.data)
            }
            error(err)
        })
}

export const apiGetUserExtensionByUserId = (actionId, userId,type=1, callback, error) => {
    axios.get(`${url}/${type}/${actionId}/users/${userId}`)
        .then(response => callback(response.data.data))
        .catch((err) => {
            if (err?.response?.data) {
                handleError(err.response.data)
            }
            error(err)
        })
}

export const apiGetGroupsExtensionByGroupsId = (type,actionId, groupsId, callback, error)=>{
    axios.get(`${url}/${type}/${actionId}/groups/${groupsId}`)
        .then(response => callback(response.data.data))
        .catch((err) => {
            if (err?.response?.data) {
                handleError(err.response.data)
            }
            error(err)
        })
}

export const apiPutAssignUserExtension = (type, actionId, data, callback, error) => {
    axios.put(url + "/" + type + "/" + actionId + "/users", data)
        .then(response => callback(response.data.data))
        .catch((err) => {
            if (err?.response?.data) {
                handleError(err.response.data)
            }
            error(err)
        })
}

export const apiPutAssignGroupsExtension = (type, actionId,groupsId, data,callback,error)=>{
    axios.put(url + "/" + type + "/" + actionId + "/groups/"+groupsId, data)
        .then(response => callback(response.data.data))
        .catch((err) => {
            if (err?.response?.data) {
                handleError(err.response.data)
            }
            error(err)
        })
}

export const apiGetActionExtensionReport = (type, actionId, data, callback, error) => {
    axios.post(url + "/" + type + "/" + actionId + "/report", data, {responseType: "blob"})
        .then(response => {
                let fileName = response.headers['content-disposition'].toString();
                fileName = fileName.substring(fileName.indexOf("=") + 1);
                let data = response.data;
                let array = [];
                array["fileName"] = fileName;
                array["data"] = data;
                callback(array);
            }
        )
        .catch((err) => {
            handleBlobError(err)
            error(err)
        })
}
