import React from "react";
import {EnigooFieldsGroup} from "../../../MyComponent/EnigooFieldsGroup";
import EnigooButton from "../../../MyComponent/EnigooButton";
import {FiDelete, FiEdit} from "react-icons/fi";

const SubjectTable = ({items}) => {
    return (
        <table className={"table striped"}>
            <thead>
            <tr>
                <th>Název</th>
                <th>Cesta k souborům</th>
                <th>Možnosti</th>
            </tr>
            </thead>
            <tbody>
            {items.length === 0 && <tr>
                <td colSpan={3}>Žádná data k zobrazení</td>
            </tr>}
            {items.map((item, index) => (
                <Row data={item} index={index} key={index.toString()}/>
            ))}
            </tbody>
        </table>
    )

}

const Row = (props) => (
    <tr key={props.index.toString()}>
        <td>{props.data.name}</td>
        <td>{props.data.path}</td>
        <td>
            <EnigooFieldsGroup
                items={[<EnigooButton icon={<FiEdit/>} link={"/manage/subject/detail/" + props.data.id} key={"1"}/>,
                    <EnigooButton visible={false} icon={<FiDelete/>} link={"/manage/subject/delete/" + props.data.id}
                                  key={"2"}/>]}/>
        </td>
    </tr>
)

export default SubjectTable;