import React from "react";
import {FieldArray} from "react-final-form-arrays";
import {EnigooTextField} from "./EnigooTextField";
import {useTranslation} from "react-i18next";
import UserMealExtensionRadioField from "../Admin/pages/ActionExtension/MealExtensionUsers/UserMealExtensionRadioField";
import YesNoRadioField from "./YesNoRadioField";
import arrayMutators from "final-form-arrays";
import {Form} from "react-final-form";
import PropTypes from "prop-types";
import {IconTabs} from "../components/tabs";

const MealChooseForm = ({
                            extensionItems,
                            name,
                            days,
                            onSave,
                            initData,
                            submitRef,
                            disabled = false,
                            tabs = false,
                            newForm = true
                        }) => {

    const {t, i18n} = useTranslation();
    let submit = null;
    const getTranslate = (item) => {
        if (item[i18n.language]) {
            return item[i18n.language];
        } else {
            return item["cs"];
        }
    }

    const initValues = React.useMemo(()=>{
        if(!initData){
            extensionItems.forEach((item,index)=>{

            })
        }else{
            return initData;
        }
    },[initData])

    const _renderItem = (item, dayIndex, itemIndex, fields) => {
        return <div className={"form element flex flex-col space-y-2"} key={itemIndex}>
            <div className={"hidden"}><EnigooTextField label={""}
                                                       name={`${name}[${dayIndex}].items[${itemIndex}].id`}
                                                       visible={true}
                                                       initValue={item.id}/></div>
            <div className={"hidden"}><EnigooTextField label={""}
                                                       name={`${name}[${dayIndex}].items[${itemIndex}].dateUsed`}
                                                       visible={true}/></div>
            {_renderOptions(item.options, dayIndex, itemIndex, item.label, fields)}
        </div>
    }

    const _renderOptions = (options, dayIndex, itemIndex, label, fields) => {
        let dateUsed = fields.value && fields.value[itemIndex] ? fields.value[itemIndex].dateUsed : null;
        let option = fields.value && fields.value[itemIndex] ? fields.value[itemIndex].option : null;
        if (options.length > 0) {
            return <>
                {dateUsed ? <div className={"flex flex-col space-y-2"}>
                        <div className={""}>{`${getTranslate(label)}:`}</div>
                        <div><b>{t("mealExtension.used") + ":"}</b> {dateUsed}</div>
                    </div> :
                    <UserMealExtensionRadioField initValue={option} disabled={dateUsed || disabled}
                                                 name={`${name}[${dayIndex}].items[${itemIndex}].option`}
                                                 options={options}
                                                 label={`${getTranslate(label)}:`}/>}</>
        } else {
            return <>
                {dateUsed ? <div className={"flex flex-col space-y-2"}>
                        <div className={""}>{`${getTranslate(label)}:`}</div>
                        <div><b>{t("mealExtension.used") + ":"}</b> {dateUsed}</div>
                    </div> :
                    <YesNoRadioField disabled={dateUsed || disabled}
                                     name={`${name}[${dayIndex}].items[${itemIndex}].have`}
                                     label={`${getTranslate(label)}:`} columns={4}/>}</>
        }
    }

    const _renderDay = (day, dayIndex) => {
        if (!days || days.includes(day.day)) {
            return <div className={`form-element flex flex-row w-full rounded border p-2`} key={dayIndex}>
                {(day.day !== 0 && !tabs) &&
                    <div className={"form-label font-bold"}>{`${getTranslate(day.name)} (${day.day})`} </div>}
                <FieldArray name={`${name}[${dayIndex}].items`}>
                    {({fields}) => {
                        return <div className={"flex flex-col w-full space-y-2"}>
                            <div className={"hidden"}><EnigooTextField label={""} name={`${name}[${dayIndex}].day`}
                                                                       initValue={day.day}/></div>
                            {day.items.map((item, itemIndex) => _renderItem(item, dayIndex, itemIndex, fields))}
                        </div>
                    }
                    }
                </FieldArray>
            </div>
        } else {
            return <></>
        }
    }

    const _renderFields = () => {
        return extensionItems.map((item, index) => _renderDay(item, index))
    }

    const _renderTabs = () => {
        let tabs = [];
        extensionItems.forEach((item, index) => {
            if(!days || days.includes(item.day)){
                tabs.push({
                    index: index,
                    title: `${getTranslate(item.name)} (${item.day})`,
                    content: _renderDay(item, index)
                })
            }

        })

        return <IconTabs tabs={tabs}/>
    }

    if (newForm) {
        return (
            <Form onSubmit={onSave} initialValues={initData}
                  mutators={{...arrayMutators}}
                  render={({handleSubmit,values}) => {
                      submitRef.current = handleSubmit;

                      return<>
                          <div className={"flex flex-col space-y-2 p-2 text-sm"}><FieldArray name={name}>
                          {({fields}) => {
                              if (tabs) {
                                  return _renderTabs();
                              } else {
                                  return _renderFields();
                              }
                          }}
                      </FieldArray></div></>
                  }}/>
        )
    } else {
        return <div className={"flex flex-col space-y-2 p-2 text-sm"}><FieldArray name={name}>
            {({fields}) => {
                if (tabs) {
                    return _renderTabs();
                } else {
                    return _renderFields();
                }
            }}
        </FieldArray></div>
    }
}
MealChooseForm.propTypes = {
    extensionItems: PropTypes.array.isRequired,
    name: PropTypes.string.isRequired,
    days: PropTypes.array,
    onSave: PropTypes.func,
    initData: PropTypes.object,
    submitRef: PropTypes.any,
    disabled: PropTypes.bool,
    tabs: PropTypes.bool
}
export default MealChooseForm;
