import React, {useEffect, useState} from 'react';
import EnigooButton from "../../../MyComponent/EnigooButton";
import {Form} from "react-final-form";
import Widget from "../../../components/widget";
import {useHistory, useParams} from "react-router";
import {apiCreateUserAdmin, apiGetSendChangePasswordRequest, apiGetUserAdminById, apiUpdateUserAdmin} from "./Actions";
import CogoToast from "cogo-toast";
import {EnigooLoader} from "../../../MyComponent/EnigooLoader";
import {FiInfo, FiLock, FiSave} from "react-icons/all";
import {IconTabs} from "../../../components/tabs";
import {EnigooTextField} from "../../../MyComponent/EnigooTextField";
import {EnigooFieldsGroup} from "../../../MyComponent/EnigooFieldsGroup";
import {EnigooSwitch} from "../../../MyComponent/EnigooSwitch";
import {ActionSelectField} from "../../../MyComponent/Api/Admin/ActionSelectField";
import EnigooBreadcrumbs from "../../../MyComponent/EnigooBreadcrumbs";
import arrayMutators from "final-form-arrays";
import VerifyRights from "../../VerifyRights";
import {shallowEqual, useSelector} from "react-redux";
import {Trans, useTranslation} from "react-i18next";
import EnigooFormChangedAlert from "../../../MyComponent/EnigooFormChangedAlert";
import {FiSend} from "react-icons/fi";
import {EnigooEmailField} from "../../../MyComponent/EnigooEmailField";


const AdminUserForm = () => {
    let [userData, setUserData] = useState({
        "login": null,
        "password": null,
        "name": null,
        "active": false,
        "actionId": null
    });
    let [loading, setLoading] = useState(false);
    let [saving, setSaving] = useState(false);
    let [sending, setSending] = useState(false);
    let [superUser, setSuperUser] = useState(false);
    let submit;

    const history = useHistory();
    const {t} = useTranslation()
    useEffect(() => {
        const handleKeyDown = (event) => {
            if ((event.ctrlKey || event.metaKey) && event.key === 's') {
                event.preventDefault();
                submit();
                console.log('Ctrl/Command + S was pressed');
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    const {id} = useParams();
    const {adminRights} = useSelector(
        state => ({adminRights: state.reducerAdmin.adminRights}),
        shallowEqual
    )


    useEffect(() => {
        init()
    }, [])

    const init = () => {
        if (id) {
            setLoading(true);
            apiGetUserAdminById(id, (data) => {
                setSuperUser(data.superuser);
                setUserData(data)
                setLoading(false);
            }, err => alert(err))
        }
    }

    const onSuccessSubmit = (res, values) => {
        setUserData(values)
        CogoToast.success(t("adminUser.saved"));
        setSaving(false);
        history.push("/admin-user/detail/" + res.id)
    }

    const onErrorSubmit = (err) => {
        setSaving(false);
        if (err?.response?.data?.error && err.response.data.error === "USER_EXIST") {
            CogoToast.error(t("adminUser.loginExist"));
        } else {
            CogoToast.error(t("adminUser.saveError"));
        }

    }


    const onSubmit = (values) => {
        setSaving(true);
        if (id) {
            apiUpdateUserAdmin(id, values, (data) => onSuccessSubmit(data, values), onErrorSubmit)
        } else {
            apiCreateUserAdmin(values, (data) => onSuccessSubmit(data, values), onErrorSubmit)
        }
    }

    const sendChangePasswordRequest=()=>{
        setSending(true);
        apiGetSendChangePasswordRequest(id,(data)=>{
            setSending(false);
            CogoToast.success(t("adminUser.requestToChangePasswordSent"))
        },(err)=>{
            setSending(false);
            CogoToast.error(t("adminUser.requestToChangePasswordCannotBeSent"))
        })
    }


    return (
        <Form onSubmit={onSubmit} initialValues={userData}
              validate={values => {
                  let errors = {};
                  if (!values.login) {
                      errors.login = t("basics.requireField")
                  }
                  if (!values.password && !id) {
                      errors.password = t("basics.requireField")
                  }
                  return errors;
              }}
              mutators={{...arrayMutators}}
              render={({
                           values,
                           handleSubmit,
                           dirty,
                           form,
                           form: {
                               mutators: {push, pop, unshift}
                           },
                       }) => {
                  submit = handleSubmit;
                  return (
                      <>
                          <EnigooBreadcrumbs
                              right={
                                  <div className={"flex flex-row space-x-2"}>
                                      {id && <EnigooButton onClick={sendChangePasswordRequest} icon={<FiSend/>} loading={sending} disabled={sending}
                                                           text={t("adminUser.sendRequestToChangePassword")} toolTip={t("adminUser.sendRequestToChangePasswordTooltip")}/>}
                                      <VerifyRights page={"adminUser"}
                                                    action={id ? "updateAdminUser" : "createAdminUser"}>
                                          <EnigooButton onClick={() => submit()} icon={<FiSave/>}
                                                        text={t("basics.save")}
                                                        loading={saving} disabled={saving || loading}/></VerifyRights>

                                  </div>
                              }/>
                          <EnigooFormChangedAlert onReset={() => form.reset(userData)} onSave={handleSubmit}
                                                  dirty={dirty}/>
                          <Widget>
                              {loading ? <EnigooLoader text={t("adminUser.loadingData")}/> :
                                  <div className="flex flex-col">


                                      <div className="w-full">
                                          <IconTabs
                                              tabs={tabs(id, superUser, setSuperUser, adminRights.superuser || adminRights.manageuser)}/>
                                      </div>

                                  </div>}
                          </Widget>
                      </>
                  )
              }
              }
        />
    )


}
export default AdminUserForm;

const TabBasicInfo = ({id}) => {
    const {t} = useTranslation()
    return (
        <>
            <EnigooFieldsGroup
                items={[<EnigooEmailField mandatory={true} name={"login"} label={t("basics.email") + ": *"} disabled={id} type={"email"}
                                         outsideClassName={"w-full lg:w-1/2"} placeholder={"example@enigoo.cz"}
                                         key={"1"}/>,
                    <EnigooTextField type={"password"} name={"password"} label={t("basics.password") + ": *"} key={"2"}
                                     outsideClassName={"w-full lg:w-1/2"} placeHolder={t("placeHolder.password")}/>]}
            />
            <EnigooFieldsGroup
                items={[<EnigooTextField label={t("basics.firstname") + " " + t("basics.lastname") + ":"} name={"name"}
                                         placeHolder={t("placeHolder.name")}
                                         outsideClassName={"w-full lg:w-1/2"} key={"1"}/>,
                    <EnigooSwitch label={t("adminUser.active") + ": *"} name={"active"}
                                  outsideClassName={"w-full lg:w-1/2"} key={"2"}/>
                ]}/>
            <ActionSelectField name={"actionId"} label={t("action.single") + ":"}/>
        </>
    )
}

const TabRights = ({id, userData, superUser, setSuperUser}) => {
    const {t} = useTranslation()
    return (
        <>
            <EnigooSwitch name={"superuser"} label={"Super admin: "} setChecked={setSuperUser}/>
            {!superUser ?
                <div className={"w-full grid grid-cols-2"}>
                    <div className={"flex flex-col"}>
                        <div className={"border-t py-2 font-bold text-lg"}>Dashboard:</div>
                        <div className={"w-full grid content-center lg:grid-cols-6 grid-cols-3 gap-2"}>
                            <EnigooSwitch name={`rights.dashboard.getDashboardData.is`}
                                          label={t("rights.show") + ": "}/>
                        </div>
                    </div>
                    <div className={"flex flex-col"}>
                        <div className={"border-t py-2 font-bold text-lg"}>{t("accreditation.plural")}:</div>
                        <div className={"w-full grid content-center lg:grid-cols-6 grid-cols-3 gap-2"}>
                            <EnigooSwitch name={`rights.user.getUser.is`} label={t("rights.show") + ": "}/>
                            <EnigooSwitch name={`rights.user.createUser.is`} label={t("rights.add") + ": "}/>
                            <EnigooSwitch name={`rights.user.updateUser.is`} label={t("rights.edit") + ": "}/>
                            <EnigooSwitch name={`rights.user.filterUser.is`} label={t("rights.filter") + ": "}/>
                            <EnigooSwitch name={`rights.user.getUserPrintData.is`} label={t("rights.print") + ": "}/>
                            <EnigooSwitch name={`rights.user.assignUserToGroups.is`}
                                          label={"Hromadné přiřazení skupin: "}/>
                            <EnigooSwitch name={`rights.user.exportUser.is`} label={"Export: "}/>
                            <EnigooSwitch name={`rights.user.deleteUser.is`} label={"Smazat uživatele: "}/>
                            <EnigooSwitch name={`rights.user.checkUser.is`} label={"Kontrola akreditací: "}/>
                            <EnigooSwitch name={`rights.user.assignZone.is`} label={t("rights.zoneAssign") + ": "}/>
                            <EnigooSwitch name={`rights.user.assignDays.is`} label={t("rights.daysAssign") + ": "}/>

                            <EnigooSwitch name={`rights.userImport.getTemplateToImport.is`}
                                          label={"Šablona pro import: "}/>
                            <EnigooSwitch name={`rights.userImport.importUser.is`} label={"Import: "}/>

                            <EnigooSwitch name={`rights.userCreditTransaction.getUserCreditTransaction.is`}
                                          label={"Kredit: "}/>
                            <EnigooSwitch name={`rights.userCreditTransaction.updateCredit.is`}
                                          label={"Úprava kreditu: "}/>
                            <EnigooSwitch name={`rights.user.sendMail.is`} label={"Odeslání emailu: "}/>
                            <EnigooSwitch name={`rights.emailQueue.sendEmail.is`} label={"Duplikovat do akce: "}/>


                        </div>
                    </div>
                    <div className={"flex flex-col"}>
                        <div className={"border-t py-2 font-bold text-lg"}>Akce:</div>
                        <div className={"w-full grid content-center lg:grid-cols-6 grid-cols-3 gap-2"}>
                            <EnigooSwitch name={`rights.action.getAction.is`} label={t("rights.show") + ": "}/>
                            <EnigooSwitch name={`rights.action.createAction.is`} label={t("rights.add") + ": "}/>
                            <EnigooSwitch name={`rights.action.updateAction.is`} label={t("rights.edit") + ": "}/>
                        </div>
                    </div>
                    <div className={"flex flex-col"}>
                        <div className={"border-t py-2 font-bold text-lg"}>Rozšíření akcí:</div>
                        <div className={"w-full grid content-center lg:grid-cols-6 grid-cols-3 gap-2"}>
                            <EnigooSwitch name={`rights.actionExtension.getActionExtension.is`}
                                          label={t("rights.show") + ": "}/>
                            <EnigooSwitch name={`rights.actionExtension.activeActionExtension.is`}
                                          label={t("basics.activate") + ": "}/>
                            <EnigooSwitch name={`rights.actionExtension.updateActionExtension.is`}
                                          label={t("rights.edit") + ": "}/>
                            <EnigooSwitch name={`rights.actionExtension.getUserExtension.is`}
                                          label={t("actionExtension.forUsers") + ": "}/>
                            <EnigooSwitch name={`rights.actionExtension.assignUserExtension.is`}
                                          label={t("webChannels.assign") + " (" + t("users.plural") + "): "}/>
                            <EnigooSwitch name={`rights.actionExtension.getActionExtensionReport.is`}
                                          label={t("device.reports") + ": "}/>
                            <EnigooSwitch name={`rights.actionExtension.getGroupsActionExtension.is`}
                                          label={t("actionExtension.forGroups") + ": "}/>
                            <EnigooSwitch name={`rights.actionExtension.assignGroupsActionExtension.is`}
                                          label={t("webChannels.assign") + " (" + t("groups.plural") + "): "}/>
                        </div>
                    </div>
                    <div className={"flex flex-col"}>
                        <div className={"border-t py-2 font-bold text-lg"}>Fotogalerie:</div>
                        <div className={"w-full grid content-center lg:grid-cols-6 grid-cols-3 gap-2"}>
                            <EnigooSwitch name={`rights.image.getImage.is`} label={t("rights.show") + ": "}/>
                            <EnigooSwitch name={`rights.image.createImage.is`} label={t("rights.add") + ": "}/>
                            <EnigooSwitch name={`rights.image.updateImage.is`} label={"Úprava obrázku: "}/>
                        </div>
                    </div>
                    <div className={"flex flex-col"}>
                        <div className={"border-t py-2 font-bold text-lg"}>Skupiny:</div>
                        <div className={"w-full grid content-center lg:grid-cols-6 grid-cols-3 gap-2"}>
                            <EnigooSwitch name={`rights.groups.getGroups.is`} label={t("rights.show") + ": "}/>
                            <EnigooSwitch name={`rights.groups.createGroups.is`} label={t("rights.add") + ": "}/>
                            <EnigooSwitch name={`rights.groups.updateGroups.is`} label={t("rights.edit") + ": "}/>
                        </div>
                    </div>
                    <div className={"flex flex-col"}>
                        <div className={"border-t py-2 font-bold text-lg"}>Zóny:</div>
                        <div className={"w-full grid content-center lg:grid-cols-6 grid-cols-3 gap-2"}>
                            <EnigooSwitch name={`rights.zone.getZone.is`} label={t("rights.show") + ": "}/>
                            <EnigooSwitch name={`rights.zone.createZone.is`} label={t("rights.add") + ": "}/>
                            <EnigooSwitch name={`rights.zone.updateZone.is`} label={t("rights.edit") + ": "}/>
                        </div>
                    </div>
                    <div className={"flex flex-col"}>
                        <div className={"border-t py-2 font-bold text-lg"}>Uživatelská pole:</div>
                        <div className={"w-full grid content-center lg:grid-cols-6 grid-cols-3 gap-2"}>
                            <EnigooSwitch name={`rights.customField.getCustomField.is`}
                                          label={t("rights.show") + ": "}/>
                            <EnigooSwitch name={`rights.customField.createCustomField.is`}
                                          label={t("rights.add") + ": "}/>
                            <EnigooSwitch name={`rights.customField.updateCustomField.is`}
                                          label={t("rights.edit") + ": "}/>
                        </div>
                    </div>
                    <div className={"flex flex-col"}>
                        <div className={"border-t py-2 font-bold text-lg"}>Zařízení:</div>
                        <div className={"w-full grid content-center lg:grid-cols-6 grid-cols-3 gap-2"}>
                            <EnigooSwitch name={`rights.device.getDevice.is`} label={t("rights.show") + ": "}/>
                            <EnigooSwitch name={`rights.device.getDeviceKey.is`} label={"Nastavení: "}/>
                            <EnigooSwitch name={`rights.device.updateDevice.is`} label={t("rights.edit") + ": "}/>

                            <EnigooSwitch name={`rights.deviceTemplate.getDeviceTemplate.is`}
                                          label={"Zobrazení pravidel: "}/>
                            <EnigooSwitch name={`rights.deviceTemplate.createDeviceTemplate.is`}
                                          label={"Přidání pravidla: "}/>
                            <EnigooSwitch name={`rights.deviceTemplate.updateDeviceTemplate.is`}
                                          label={"Editace pravidla: "}/>

                            <EnigooSwitch name={`rights.report.getReport.is`}
                                          label={"Sestavy: "}/>
                        </div>
                    </div>
                    <div className={"flex flex-col"}>
                        <div className={"border-t py-2 font-bold text-lg"}>Subjekt:</div>
                        <div className={"w-full grid content-center lg:grid-cols-6 grid-cols-3 gap-2"}>
                            <EnigooSwitch name={`rights.subject.getSubject.is`} label={t("rights.show") + ": "}/>
                            <EnigooSwitch name={`rights.subject.updateSubject.is`} label={t("rights.edit") + ": "}/>
                        </div>
                    </div>
                    <div className={"flex flex-col"}>

                        <div className={"border-t py-2 font-bold text-lg"}>Uživatelé systému:</div>
                        <div className={"w-full grid content-center lg:grid-cols-6 grid-cols-3 gap-2"}>
                            <EnigooSwitch name={`rights.adminUser.getAdminUser.is`} label={t("rights.show") + ": "}/>
                            <EnigooSwitch name={`rights.adminUser.createAdminUser.is`} label={t("rights.add") + ": "}/>
                            <EnigooSwitch name={`rights.adminUser.updateAdminUser.is`} label={t("rights.edit") + ": "}/>
                            <EnigooSwitch name={`rights.adminUser.getActualAdminUser.is`} label={"Profil: "}/>
                        </div>
                    </div>
                    <div className={"flex flex-col"}>
                        <div className={"border-t py-2 font-bold text-lg"}>Webové kanály:</div>
                        <div className={"w-full grid content-center lg:grid-cols-6 grid-cols-3 gap-2"}>
                            <EnigooSwitch name={`rights.webChannel.getWebChannel.is`}
                                          label={t("rights.show") + ": "}/>
                            <EnigooSwitch name={`rights.webChannel.createWebChannel.is`}
                                          label={t("rights.add") + ": "}/>
                            <EnigooSwitch name={`rights.webChannel.updateWebChannel.is`}
                                          label={t("rights.edit") + ": "}/>
                            <EnigooSwitch name={`rights.webChannel.exportExternalCode.is`}
                                          label={"Export externích kódů: "}/>
                        </div>
                    </div>
                    <div className={"flex flex-col"}>
                        <div className={"border-t py-2 font-bold text-lg"}>Šablony:</div>
                        <div className={"w-full grid content-center lg:grid-cols-6 grid-cols-3 gap-2"}>
                            <EnigooSwitch name={`rights.template.getTemplate.is`} label={t("rights.show") + ": "}/>
                            <EnigooSwitch name={`rights.template.createTemplate.is`} label={t("rights.add") + ": "}/>
                            <EnigooSwitch name={`rights.template.updateTemplate.is`} label={t("rights.edit") + ": "}/>
                            <EnigooSwitch name={`rights.template.postValidateTemplate.is`}
                                          label={"Testování šablony: "}/>
                        </div>
                    </div>
                    <div className={"flex flex-col"}>
                        <div className={"border-t py-2 font-bold text-lg"}>Emailové šablony:</div>
                        <div className={"w-full grid content-center lg:grid-cols-6 grid-cols-3 gap-2"}>
                            <EnigooSwitch name={`rights.mailTemplate.getMailTemplate.is`}
                                          label={t("rights.show") + ": "}/>
                            <EnigooSwitch name={`rights.mailTemplate.createMailTemplate.is`}
                                          label={t("rights.add") + ": "}/>
                            <EnigooSwitch name={`rights.mailTemplate.updateMailTemplate.is`}
                                          label={t("rights.edit") + ": "}/>
                            <EnigooSwitch name={`rights.mailTemplate.testMailTemplate.is`}
                                          label={"Testovací email: "}/>
                        </div>
                    </div>
                </div>
                : <></>
            }
        </>
    )
}

const tabs = (id, superUser, setSuperUser, actualSuperUser) => {
    let tabs = [{
        index: 0,
        content: <TabBasicInfo id={id}/>,
        title: (<><FiInfo size={18} className={"stroke-current"}/><span
            className={"ml-2"}><Trans i18nKey={"basics.info"}>Zakl info</Trans></span> </>)

    }];
    if (id && actualSuperUser) {
        tabs.push({
            index: 1,
            content: <TabRights id={id} setSuperUser={setSuperUser} superUser={superUser}/>,
            title: <><FiLock size={18} className={"stroke-current"}/><span className={"ml-2"}><Trans
                i18nKey={"adminUser.rights"}>Práva</Trans></span> </>
        })
    }
    return tabs;
}










