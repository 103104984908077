import React, {useEffect, useState} from "react";
import {useHistory} from "react-router";
import CogoToast from "cogo-toast";
import {EnigooTextField} from "../../../MyComponent/EnigooTextField";
import {FiInfo, FiSave} from "react-icons/all";
import {Form} from "react-final-form";
import EnigooButton from "../../../MyComponent/EnigooButton";
import Widget from "../../../components/widget";
import {EnigooLoader} from "../../../MyComponent/EnigooLoader";
import {IconTabs} from "../../../components/tabs";
import {apiGetSubject, apiUpdateSubject} from "./Actions";
import EnigooBreadcrumbs from "../../../MyComponent/EnigooBreadcrumbs";
import VerifyRights from "../../VerifyRights";
import {EnigooSwitch} from "../../../MyComponent/EnigooSwitch";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import EnigooFormChangedAlert from "../../../MyComponent/EnigooFormChangedAlert";

const SubjectForm = (props) => {
    let [subjectData, setSubjectData] = useState(null);
    let [loading, setLoading] = useState(false);
    let [saving, setSaving] = useState(false);

    const history = useHistory();
    const {t, i18n} = useTranslation()
    const dispatch = useDispatch();
    let submit = null;
    useEffect(() => {
        init();
    }, []);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if ((event.ctrlKey || event.metaKey) && event.key === 's') {
                event.preventDefault();
                submit();
                console.log('Ctrl/Command + S was pressed');
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    const init = () => {
        setLoading(true);
        apiGetSubject((data) => {
            setSubjectData(data);
            setLoading(false);
        }, (error) => {
            setSubjectData(null);
            setLoading(false);
            CogoToast.error(t("subject.loadingDataError"));
        })
    }
    const onSubmit = (values) => {
        setSaving(true);
        apiUpdateSubject(values, (data) => {
            setSubjectData(values)
            setSaving(false);
            dispatch({
                type: "SET_SUBJECT",
                subject: {
                    id: data.id,
                    name: values.name,
                    multiLanguage: values.multiLanguage
                }
            })
            if (!values.multiLanguage) {
                i18n.changeLanguage("cs");
            }
            CogoToast.success(t("subject.saved"))
            history.push("/subject");
        }, (error) => {
            setSaving(false);
            CogoToast.error(t("subject.saveError") + "\n" + error.message);
        })
    }

    const TabBasicInfo = () => (
        <>
            <EnigooTextField name={"name"} label={t("basics.name") + ": *"} placeHolder={t("placeHolder.name")}/>
            <EnigooTextField name={"deviceKey"} label={t("subject.keyForDevice") + ": *"} disabled={true}/>
            <EnigooTextField name={"key"} label={t("subject.subjectKey") + ": *"} disabled={true}/>
            <EnigooTextField name={"path"} label={t("subject.pathToFiles") + ": *"} disabled={true}/>
            <EnigooSwitch name={"multiLanguage"} label={t("subject.multiLanguage") + ": *"}/>
        </>
    )

    const tabs = [{
        index: 0,
        content: <TabBasicInfo/>,
        title: (<><FiInfo size={18} className={"stroke-current"}/><span
            className={"ml-2"}>{t("basics.info")}</span></>)

    }
    ];

    return (
        <div className="flex flex-col">
            <Form onSubmit={onSubmit} initialValues={subjectData}
                  validate={values => {
                      let errors = {};
                      if (!values.name) {
                          errors.name = t("basics.requireField")
                      }
                      return errors;
                  }}
                  render={({handleSubmit, form, dirty}) => {
                      submit = handleSubmit;
                      return (
                          <>
                              <EnigooBreadcrumbs
                                  right={<VerifyRights page={"subject"} action={"updateSubject"}> <EnigooButton
                                      onClick={handleSubmit} icon={<FiSave/>} text={t("basics.save")}
                                      loading={saving} disabled={saving || loading}/></VerifyRights>}/>
                              <EnigooFormChangedAlert onReset={() => form.reset(subjectData)} onSave={handleSubmit}
                                                      dirty={dirty}/>
                              <Widget zIndex={0}>
                                  {loading ? <EnigooLoader text={t("subject.loadingData")}/> :
                                      <div className="w-full">
                                          <IconTabs tabs={tabs}/>
                                      </div>
                                  }
                              </Widget>
                          </>)
                  }
                  }
            />
        </div>
    )
}

export default SubjectForm;
