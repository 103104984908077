import {axios} from "../../../axiosConfig";

const url = "api/admin/v1/report";

export function apiGetReport(data, callback, error){
    axios.post(url,data,{responseType: data.responseType==="xls"?"blob":"json"})
        .then((response)=>{
            if(data.responseType==="xls") {
                let fileName = response.headers['content-disposition'].toString();
                fileName = fileName.substring(fileName.indexOf("=") + 1);
                let data = response.data;
                let array = [];
                array["fileName"] = fileName;
                array["data"] = data;
                callback(array);
            }else{
                callback(response.data.data);
            }
        })
        .catch(err=>error(err));
}

export function apiGetEntranceReport(data,callback,error){
    axios.post(url+"/entrance",data,{responseType: "blob"})
        .then((response)=>{
            let fileName = response.headers['content-disposition'].toString();
            fileName = fileName.substring(fileName.indexOf("=") + 1);
            let data = response.data;
            let array = [];
            array["fileName"] = fileName;
            array["data"] = data;
            callback(array);
        })
        .catch(err=>error(err));
}