import React from "react";
import {EnigooFieldsGroup} from "../../../MyComponent/EnigooFieldsGroup";
import EnigooButton from "../../../MyComponent/EnigooButton";
import {FiCopy, FiEdit, FiLink} from "react-icons/fi";
import {Link} from "react-router-dom";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {useTranslation} from "react-i18next";
import EnigooTablev2 from "../../../MyComponent/EnigooTablev2";
import CogoToast from "cogo-toast";

const WebChannelTable = ({items}) => {
    const {t, i18n} = useTranslation()

    const getAuthType = (channel) => {
        if (!channel.withAuthorization) {
            return "-";
        }
        if (channel.byPassword && !channel.byCode) {
            return t("basics.password");
        } else if (!channel.byPassword && channel.byCode) {
            return t("online.authorization.code");
        } else {
            return t("basics.webChannelLogin");
        }
    }

    const columns = React.useMemo(() => [
        {header: t('basics.name'), id: "name", accessorKey: 'name'},
        {
            header: t('webChannels.code'), id: "code", accessorKey:"channelKey"
        },
        {header: t('basics.auth'), id: "auth", cell: ({row}) => getAuthType(row.original)},
        {
            header: t('basics.options'), id: "options", cell: ({row}) => (
                <div className={"flex flex-row space-x-2"}>
                    <EnigooButton _key={"wch_detail_"+row.original.id} icon={<FiEdit/>} link={"web-channel/detail/" + row.original.id} toolTip={t("basics.detail")}/>
                    <EnigooButton _key={"wch_go_online_"+row.original.id} link={"/app/online/" + row.original.channelKey} icon={<FiLink/>} toolTip={t("webChannels.goToToolTip")} newTab={true}/>
                    <EnigooButton _key={"wch_copy_link_"+row.original.id} icon={<FiCopy/>} toolTip={t("webChannels.copyToolTip")} onClick={() =>
                        navigator.clipboard.writeText(window.location.origin + "/app/online/" + row.original.channelKey)
                            .then(() => {
                                CogoToast.success(t("webChannels.linkCopied"))
                            })}/>
                </div>
            )

        }

    ], [i18n.language]);
    const tableData = React.useMemo(() => items.items, [items]);

    return <EnigooTablev2 columns={columns} data={tableData} selectable={false}/>

}
export default WebChannelTable;
