import React, {useEffect, useState} from 'react'
import {axios} from "../../../axiosConfig";
import {Form} from "react-final-form";
import CogoToast from "cogo-toast";
import EnigooButton from "../../../MyComponent/EnigooButton";
import {EnigooTextField} from "../../../MyComponent/EnigooTextField";
import {useParams} from "react-router-dom";
import SectionTitle from "../../../components/section-title";
import {FiLogIn} from "react-icons/all";
import {apiOnlineGetWebChannel, apiOnlineLoginWebChannel} from "./Actions";
import {EnigooLoader} from "../../../MyComponent/EnigooLoader";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";

export const OnlineLoginForm = (props) => {
    let [saving, setSaving] = useState(false);
    let submit;
    let [webChannelData, setWebChannelData] = useState(null);
    let [loading, setLoading] = useState(false);

    const dispatch = useDispatch();
    const {id} = useParams();

    const {onlineConfig} = useSelector(
        (state) => ({
            onlineConfig: state.reducerOnline.onlineConfig
        }),
        shallowEqual
    )

    const {t, i18n} = useTranslation()

    useEffect(() => {
        getData();
    }, []);
    const onKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            submit();
        }
    }

    const getData = () => {
        setLoading(true);
        apiOnlineGetWebChannel(id, (data) => {
                setWebChannelData(data);
                let err = null;
                switch (data.error) {
                    case "WEB_CHANNEL_NOT_ACTIVE":
                        err = t("online.error.channelNotActive");
                        break;
                    case "WEB_CHANNEL_NOT_ACTIVE_YET":
                        err = t("online.error.channelNotActiveYet");
                        break;
                    case "WEB_CHANNEL_IS_CLOSED":
                        err = t("online.error.channelClosed");
                        break;
                    default:
                        err = null;
                        break;
                }
                dispatch({
                    type: 'SET_ONLINE',
                    onlineConfig: {
                        online: data.textOnline,
                        afterAdd: data.textAfterAdd,
                        added: false,
                        onlineLogo: data.image,
                        withAuthorization: data.withAuthorization,
                        byPassword: data.byPassword,
                        byCode: data.byCode,
                        authorizationText: data.authorizationText,
                        error: err,
                        multiLanguage: data.multiLanguage
                    }
                })
                if (data.authorizationText && data.authorizationText.cs) {
                    i18n.addResourceBundle('cs', 'translation', {[`online.authorizationText`]: data.authorizationText.cs})
                }
                if (data.authorizationText && data.authorizationText.en) {
                    i18n.addResourceBundle('en', 'translation', {[`online.authorizationText`]: data.authorizationText.en})
                }
                if (data.authorizationText && data.authorizationText.de) {
                    i18n.addResourceBundle('de', 'translation', {[`online.authorizationText`]: data.authorizationText.de})
                }


                if (!data.withAuthorization && !err) onSubmit({});
                setLoading(false);
            }, (error) => {
                if (error.response && error.response.data) {
                    switch (error.response.data.error) {
                        case "WEB_CHANNEL_NOT_ACTIVE":
                            dispatch({type: 'SET_ONLINE_ERROR', value: t("online.error.channelNotActive")});
                            setLoading(false);
                            break;
                        case "WEB_CHANNEL_NOT_ACTIVE_YET":
                            dispatch({type: 'SET_ONLINE_ERROR', value: t("online.error.channelNotActiveYet")});
                            setLoading(false);
                            break;
                        case "WEB_CHANNEL_IS_CLOSED":
                            dispatch({type: 'SET_ONLINE_ERROR', value: t("online.error.channelClosed")});
                            setLoading(false);
                            break;
                        case "AUTH_INVALID":
                        case "TOKEN_EXPIRED":
                        case "TOKEN_INVALID":
                            axios.defaults.headers.common['authorization-online'] = null;
                            localStorage.removeItem("ath-acc-en-on");
                            window.location.reload();
                            setLoading(false);
                            break;
                        case "WEB_CHANNEL_DOESNT_EXIST":
                            dispatch({type: 'SET_ONLINE_ERROR', value: t("online.error.checkChannelCode")});
                            setLoading(false);
                            break;
                        default:
                            dispatch({type: 'SET_ONLINE_ERROR', value: t("online.error.noData")});
                            setLoading(false);
                            break;
                    }
                }
            }
        )
    }

    const onSubmit = values => {
        values.channelKey = id;
        setSaving(true);
        apiOnlineLoginWebChannel(values, (data) => {
            axios.defaults.headers.common['authorization-online'] = data.token;
            localStorage.setItem("ath-acc-en-on", data.token);
            setSaving(false);
            props.setToken(data.token);

        }, (error) => {
            let msg = t("online.error.defaultLoginError");
            if (error.response.data.error) {
                switch (error.response.data.error) {
                    case "WEB_CHANNEL_DOESNT_EXIST":
                        msg = t("online.error.channelDoesNotExist")
                        break;
                    case "USER_DOESNT_EXIST":
                        msg = t("online.error.userDoesNotExist")
                        break;
                    case "USED_WEB_CHANNEL_CODE":
                        msg = t("online.error.usedWebChannelCode")
                        break;
                    case "INVALID_WEB_CHANNEL_CODE":
                        msg = t("online.error.invalidWebChannelCode")
                        break;
                    default:
                        msg = t("online.error.authError")
                }
            }

            CogoToast.error(msg);
            setSaving(false);
        })

    }

    const _renderFormFields = () => {
        if (!webChannelData.byPassword && !webChannelData.byCode) {
            return <>
                <EnigooTextField label={t("adminUser.login")} name={"login"}
                                 onKeyDown={onKeyDown}/>
                <EnigooTextField label={t("basics.password") + ":"} name={"password"}
                                 type={"password"} onKeyDown={onKeyDown} placeHolder={t("placeHolder.password")}/>
            </>;
        }
        if (webChannelData.byPassword && !webChannelData.byCode) {
            return <>
                <EnigooTextField label={t("basics.password") + ":"} name={"password"}
                                 type={"password"} onKeyDown={onKeyDown} placeHolder={t("placeHolder.password")}/>
            </>
        }
        if (!webChannelData.byPassword && webChannelData.byCode) {
            return <>
                <EnigooTextField label={t("online.authorization.code") + ":"} name={"code"}
                                 type={"text"} onKeyDown={onKeyDown}/>
            </>
        }
    }

    const _getAuthorizationLabel = ()=>{
        if(webChannelData.byCode){
            return t("online.authorization.enterCode")
        }else if(webChannelData.byPassword){
            return t("online.authorization.enterPassword")
        }else{
            return t("online.authorization.enterLoginData")
        }
    }

    return (
        <>
            {loading ?
                <EnigooLoader text={"Načítám stránku..."}/> : webChannelData && webChannelData.withAuthorization ?
                    <div className={"px-10"}>
                        <SectionTitle title={t("basics.accreditation")} subtitle={t("basics.auth")}/>
                        {onlineConfig.authorizationText && onlineConfig.authorizationText.cs ?
                            <div
                                className={"w-full text-center text-lg mb-3"}
                                dangerouslySetInnerHTML={{__html: t("online.authorizationText")}}/>
                            : <div
                                className={"w-full text-center text-lg mb-3"}>
                                {
                                    _getAuthorizationLabel()
                                }

                            </div>}


                        <div className="flex flex-col">
                            <Form onSubmit={onSubmit}
                                  validate={values => {
                                      let errors = {};

                                      if (webChannelData.withAuthorization) {
                                          if (!webChannelData.byPassword && !webChannelData.byCode) {
                                              if (!values.password) {
                                                  errors.password = t("basics.requireField")
                                              }
                                              if (!values.login) {
                                                  errors.login = t("basics.requireField")
                                              }
                                          }
                                          if (webChannelData.byPassword && !webChannelData.byCode) {
                                              if (!values.password) {
                                                  errors.password = t("basics.requireField");
                                              }
                                          }
                                          if (!webChannelData.byPassword && webChannelData.byCode) {
                                              if (!values.code) {
                                                  errors.code = t("basics.requireField")
                                              }
                                          }


                                      }
                                      return errors;
                                  }}
                                  render={({handleSubmit}) => {
                                      submit = handleSubmit;
                                      return (
                                          <div className="w-full">
                                              {_renderFormFields()}
                                              <div className={"grid lg:grid-cols-3 grid-cols-2"}>
                                                  <div className={"grid lg:col-start-3 col-start-2"}>
                                                      <EnigooButton onClick={() => submit()} icon={<FiLogIn/>}
                                                                    text={t("basics.check")} extraStyle={"btn-block"}
                                                                    loading={saving} disabled={saving}/>
                                                  </div>
                                              </div>
                                          </div>
                                      )
                                  }
                                  }
                            />
                        </div>
                    </div> : <></>
            }
        </>
    );
}

