import React from "react";
import {useTranslation} from "react-i18next";
import PropTypes from "prop-types";
import EnigooTablev2, {EnigooTablev2CheckBox} from "../../../../MyComponent/EnigooTablev2";

const UserExtensionTable = ({items, setSelectedRows, renderOptionsButton}) => {
    const {t, i18n} = useTranslation()
    let columns = React.useMemo(() => {
        let cols = [
            {
                id: 'select',
                header: ({table}) => (
                    <EnigooTablev2CheckBox
                        {...{
                            checked: table.getIsAllRowsSelected(),
                            indeterminate: table.getIsSomeRowsSelected(),
                            onChange: table.getToggleAllRowsSelectedHandler(),
                        }}
                    />
                ),
                cell: ({row}) => (
                    <div className="px-1">
                        <EnigooTablev2CheckBox
                            {...{
                                checked: row.getIsSelected(),
                                disabled: !row.getCanSelect(),
                                indeterminate: row.getIsSomeSelected(),
                                onChange: row.getToggleSelectedHandler(),
                            }}
                        />
                    </div>
                ),
            },
            {
                accessorFn: row => {
                    return row.customId?row.customId:row.id
                }, header: '#', id: "id",
                cell: info => info.getValue(),
            },
            {
                id: "name",
                cell: ({row}) => {
                    return row.original.lastname + ", " + row.original.firstname
                }, header: `${t("basics.lastname")}, ${t("basics.firstname")}`
            },
            {
                accessorKey: "email",
                header: t("basics.email")
            },
            {
                id: "groups",
                cell: ({row}) => {
                    if (row.original.groups) {
                        return row.original.groups.name;
                    } else {
                        return "-";
                    }
                },
                header: t("groups.single")
            }

        ];

        if (renderOptionsButton) {
            cols.push({
                id: "options",
                cell: ({row}) => {
                    return renderOptionsButton(row.original.id);
                }, header: t("basics.options")
            })
        }

        return cols;

    }, [i18n.language])


    return <EnigooTablev2 columns={columns} data={items} selectable={true} setSelectedRows={setSelectedRows}/>
}
UserExtensionTable.propTypes = {
    setSelectedRows: PropTypes.func.isRequired,
    items: PropTypes.array.isRequired

}
export default UserExtensionTable;
