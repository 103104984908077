import React from "react";
import EnigooButton from "../../../MyComponent/EnigooButton";
import {FiEdit} from "react-icons/fi";
import {useTranslation} from "react-i18next";
import EnigooTablev2 from "../../../MyComponent/EnigooTablev2";


const ZoneTable =({items})=>{
    const {t,i18n} = useTranslation()

    const columns = React.useMemo(()=>[
        {header:t("basics.name"),id:"name",accessorKey:`name`},
        {header: t("zones.nameOnAccreditation"),id:"onAccreditation",accessorKey: "onAccreditation"},
        {header: t("groups.plural"),id:"groups",
            cell:({row})=>{
                if(row.original.groups) {
                    return row.original.groups.map((item,index)=>{return(
                        <>
                            {item.name}
                            {row.original.groups.length !== index + 1 ? ", " : ""}
                        </>
                    )})
                }else{
                    return "";
                }
            }
        },
        {header: t("basics.options"),id:"options",
            cell: ({row})=>{
                return(
                    <EnigooButton icon={<FiEdit/>} link={"zone/detail/"+row.original.id}/>
                )
            }
        }
    ],[i18n.language]);
    const tableData = React.useMemo(()=>items.items,[items]);

        return <EnigooTablev2 columns={columns} data={tableData} paginated={true} selectable={true}/>;
}

export default ZoneTable;
