import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {apiGetActionById} from "../../Action/Actions";
import {EnigooSwitch} from "../../../../MyComponent/EnigooSwitch";
import {useTranslation} from "react-i18next";
import EnigooMultiLanguageField from "../../../../MyComponent/EnigooMultiLanguageField/EnigooMultiLanguageField";
import {EnigooSelectMulti} from "../../../../MyComponent/EnigooSelectMulti";

const WebChannelActionFields = ({values}) => {
    let [action, setAction] = useState();
    let [loading, setLoading] = useState(false);
    let [dayItems, setDayItems] = useState([]);

    const {t, i18n} = useTranslation();
    const getLabel = (item) => {
        return item.name[i18n.language] ? item.name[i18n.language] : item.name["cs"];
    }
    useEffect(() => {
        if (values.actionId) {
            setLoading(true);
            apiGetActionById(values.actionId, (data) => {
                if (data.multiDay && data.days) {
                    let days = [];
                    data.days.forEach((item, index) => {
                        days.push({
                            label: getLabel(item),
                            value: item.id
                        })
                    })
                    setDayItems(days);
                } else {
                    setDayItems([]);
                }
                setAction(data);
                setLoading(false);
            }, (err) => {
                setAction(null);
                setLoading(false);
            })
        } else {
            setAction(null);
        }
    }, [values.actionId]);

    const  haveExtension = (type) => {
        if(action && action.extension){
            return action.extension.some((i)=>i.type===type);
        }else{
            return false;
        }
    }

    const _renderDaySelect = () => {
        if (action && action.multiDay) {
            return <>

                <EnigooSwitch name={"viewDays"} label={t("webChannels.showDays") + ":"}
                              toolTip={t("webChannels.showDaysToolTip")}/>
                {values.viewDays ?
                    <EnigooMultiLanguageField name={"daysSelectName"} label={t("webChannels.daysCustomLabel") + ":"}/> :
                    <EnigooSelectMulti defaultValue={["value", "label"]} toolTip={t("webChannels.autoDaysToolTip")}
                                       items={dayItems} name={"defaultDays"} label={t("webChannels.autoDays") + ":"}/>}
            </>
        } else {
            return <></>
        }
    }

    const _renderShowMealSelect = () => {
        if (haveExtension(1)) {
            return <EnigooSwitch name={"viewMealSelect"} label={t("webChannels.allowMealChoose")+":"}/>
        } else {
            return <></>
        }
    }

    const _renderShowParkingSelect = () => {
        if (haveExtension(2)) {
            return <EnigooSwitch name={"viewParkingSelect"} label={t("webChannels.allowParkingChoose")+":"}/>
        } else {
            return <></>
        }
    }

    if (loading || !action) {
        return <></>;
    } else {
        return (
            <div className={"grid grid-cols-2 gap-2"}>
                {_renderDaySelect()}
                {_renderShowMealSelect()}
                {_renderShowParkingSelect()}
            </div>
        )
    }


}
WebChannelActionFields.propTypes = {
    values: PropTypes.object
}
export default WebChannelActionFields;
