export default function image(
    state={
        imageType: 3,
        phrase: "",
        imagePageIndex: 0,
    },
    action
){
    switch(action.type){
        case "SET_IMAGE":
            if(state.image) return state;
            else {
                return {...state,imageType:action.type,phrase: action.phrase}
            }
        case "CHANGE_TYPE":
            return {...state,imageType:action.imageType}
        case "CHANGE_PHRASE":
            return {...state,phrase:action.phrase}
        case "CHANGE_IMAGE_PAGE_INDEX":
            return {...state,imagePageIndex:action.imagePageIndex}
        case "RESET":
            return {
                imageType: 3,
                phrase: "",
                imagePageIndex: 0,
            }
        default:
            return state;
    }
}
