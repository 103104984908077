import React, {useEffect, useRef, useState} from "react";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import Portal from "../../../components/portal";
import {FiX} from "react-icons/fi";
import EnigooButton from "../../../MyComponent/EnigooButton";
import {FiSave} from "react-icons/all";
import CogoToast from "cogo-toast";
import {TemplateSelectField} from "../../../MyComponent/Api/Admin/TemplateSelectField";
import {apiSendMail} from "./Actions";
import {Form} from "react-final-form";
import {count} from "react-table/src/aggregations";
import {isArray} from "@craco/craco/lib/utils";
import Widget from "../../../components/widget";
import {Trans, useTranslation} from "react-i18next";

export const SendMailModal = ({
                                  setOpen,
                                  open,
                                  selectedIds,
                                 selectAll
                              }) => {
    let [saving, setSaving] = useState(false);
    let [selectedMailTemplate, setSelectedMailTemplate] = useState(null);
    let [error,setError] = useState([]);
    const dispatch = useDispatch();

    const {palettes,actualAction,filterUser} = useSelector(
        (state) => ({
            palettes: state.reducerAdmin.palettes,
            actualAction: state.reducerAdmin.actualAction,
            filterUser: state.reducerAdmin.filterUser
        }),
        shallowEqual
    )
    const {t} = useTranslation()
    let {background} = {
        ...palettes
    }
    let {filterData}={...filterUser};

    const modalRef = useRef(null)

    const hide = () => {
        setError([]);
        setOpen(false)
    }

    const send = () => {
        if (selectedMailTemplate) {
            setSaving(true);
            let data = {};
            data.userIds = selectedIds;
            data.actionId = actualAction.actualAction;
            data.filterData = filterData;
            data.all = selectAll;
            data.mailTemplate = selectedMailTemplate;
            apiSendMail(data, (data) => {
                if(isArray(data.error) && count(data.error)>0){
                    setError(data.error);
                    CogoToast.error(t("accreditation.mailSendCompleteError"));
                    setSaving(false);
                }
                else{
                    setError([]);
                    CogoToast.success(t("accreditation.mailSendComplete"));
                    dispatch({
                        type: 'SET_RELOAD_ACCREDITATION',
                        reloadUserData: true
                    })
                    hide();
                    setSaving(false);
                }

            }, (error) => {
                CogoToast.error(t("accreditation.mailSendError"));
                setSaving(false);
            })
        } else {
            CogoToast.error(t("mailTemplate.selectTemplateForMaill"));
        }
    }

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (!modalRef || !modalRef.current) return false
            if (!open || modalRef.current.contains(event.target)) {
                return false
            }
            setOpen(!open)
        }
        document.addEventListener('mousedown', handleClickOutside)

        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [open, modalRef])

    return (
        <>
            {open && (
                <Portal selector="#portal">
                    <div className="modal-backdrop fade-in"></div>
                    <div className={`modal show ${background === 'dark' ? 'dark-mode' : ''}`}
                         data-background={background}>
                        <div className="relative min-w-sm w-1/3 mx-auto lg:max-w-5xl" ref={modalRef}>
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h3 className="text-xl font-semibold">{count(error)===0?t("accreditation.chooseTemplateSend"):t("accreditation.mailSendErrorTitle")}</h3>
                                    <button
                                        className="modal-close btn btn-transparent"
                                        onClick={hide}>
                                        <FiX size={18} className="stroke-current"/>
                                    </button>
                                </div>
                                <div className={"max-h-sm overflow-y-scroll p-2 pb-10"}>
                                <Form onSubmit={() => {
                                }} render={() => (
                                    <>
                                        {(error && count(error)>0) ?
                                            <Widget>
                                            <div className="flex flex-col">
                                                <div className={"text-enigoo-500 font-bold text-lg"}>{t("accreditation.mailSendSomeError")}:</div>
                                                {error.map((item, index) => (
                                                    <Trans i18nKey={"accreditation.mailSendUserError"} values={{id:item.user,error:item.error}}>
                                                        <div className={"text-sm"}>Uživatel s id {item.user}: {item.error}</div>
                                                    </Trans>

                                                ))}
                                            </div>
                                            </Widget>:
                                            <div className={"m-2"}>
                                                <TemplateSelectField inModal={true}
                                                    onValueChange={(event) => setSelectedMailTemplate(event.value)}
                                                    name={"mailTemplate"} label={t("mailTemplate.single")+":"} type={0} mailTemplateType={2} isMulti={false}/>
                                            </div>
                                        }

                                    </>
                                )}/>
                                </div>
                                <div className="modal-footer space-x-2">
                                    <EnigooButton  disabled={saving} loading={saving} onClick={hide} text={count(error)===0?t("basics.cancel"):t("basics.close")}
                                                  icon={<FiX/>}/>
                                    <EnigooButton visible={count(error)===0} disabled={saving} loading={saving} onClick={() => send()}
                                                  text={t("basics.send")} icon={<FiSave/>}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </Portal>
            )}
        </>
    )
}
