export default function adminRights(
    state={
        rights:null,
        superuser: false,
        manageuser:false,
        subject: null,
    },
    action
){
    switch (action.type){
        case "SET_RIGHTS":
            return {...state,rights:action.rights, superuser: action.superuser,manageuser: action.manageuser,subject:action.subject}
        case "SET_SUBJECT":
            return {...state,subject:action.subject}
        case "RESET":
            return {rights: null, superuser: false,manageuser: false,subject: null}
        default:
            return state;
    }
}
