export default function config(
    state = {
        name: 'Akreditační systém',
        description: 'Enigoo accreditations admin',
        url: 'https://www.enigoo.cz',
        layout: 'layout-1',
        collapsed: false,
        rightSidebar: false,
        backdrop: false,
    },
    action
) {
    switch (action.type) {
        case 'SET_CONFIG':
            return {
                ...state,
                ...action.config
            }
        case 'SET_CONFIG_KEY':
            let {key, value} = {...action}
            return {
                ...state,
                [`${key}`]: value
            }
        case "RESET":
            return {
                name: 'Akreditační systém',
                description: 'Enigoo accreditations admin',
                url: 'https://www.enigoo.cz',
                layout: 'layout-1',
                collapsed: false,
                rightSidebar: false,
                backdrop: false,
            }
        default:
            return state
    }
}
