import React, {useRef} from "react";
import {shallowEqual, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import Portal from "../../../components/portal";
import {FiX} from "react-icons/fi";
import Widget from "../../../components/widget";
import EnigooButton from "../../../MyComponent/EnigooButton";

const TemplateViewModal = ({
                               data,
                               open,
                               setOpen
                           }) => {
    const {palettes} = useSelector(
        (state) => ({
            palettes: state.reducerAdmin.palettes,
            actualAction: state.reducerAdmin.actualAction,
            filterUser: state.reducerAdmin.filterUser
        }),
        shallowEqual
    )
    const {t} = useTranslation()
    let {background} = {
        ...palettes
    }

    const modalRef = useRef(null)

    const hide = () => {
        setOpen(false)
    }

    return (
        <>
            {open && (
                <Portal selector="#portal">
                    <div className="modal-backdrop fade-in"></div>
                    <div className={`modal show ${background === 'dark' ? 'dark-mode' : ''}`}
                         data-background={background}>
                        <div className="relative min-w-sm w-2/3 mx-auto lg:max-w-5xl" ref={modalRef}>
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h3 className="text-xl font-semibold">{t("templateJsonAttributes.view")}</h3>
                                    <button
                                        className="modal-close btn btn-transparent"
                                        onClick={hide}>
                                        <FiX size={18} className="stroke-current"/>
                                    </button>
                                </div>
                                <div className={"p-2 pb-10 flex-grow"}>
                                <Widget>
                                    <iframe width={"100%"} height={"500"} src={data}></iframe>
                                </Widget>
                                </div>

                                <div className="modal-footer space-x-2">
                                    <EnigooButton onClick={hide}
                                                  text={t("basics.close")}
                                                  icon={<FiX/>}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </Portal>
            )}
        </>
    )
}
export default TemplateViewModal
