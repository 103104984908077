import React from "react";
import {WebExternalCodeModal} from "./WebExternalCodeModal";
import {useTranslation} from "react-i18next";
import EnigooTablev2 from "../../../MyComponent/EnigooTablev2";
import EnigooToolTip from "../../../MyComponent/EnigooToolTip";

const WebChannelExternalCodeTable = ({
                                         data,
                                         onDelete,
                                         onUpdate,
                                     }) => {
    let fieldValues;

    const {t, i18n} = useTranslation()
    let columns = React.useMemo(() => [
        {header: '#', id: "index", cell: ({row}) => row.index + 1},
        {header: 'Kód', id: "key", accessorKey: 'code'},
        {
            header: t("externalCode.allowedUse"),
            cell: ({row}) => row.original.numberOfUse ? row.original.numberOfUse + "x" : "-"
        },
        {
            header: t("externalCode.used"), cell: ({row}) => {
                let label = "0x";
                let msg = "";
                if (row.original.log && row.original.log.length > 0) {
                    msg += "<p><b>Použití kódu:</b></p>"
                    label = row.original.log.length + "x";
                    row.original.log.map((item, index) => {
                        msg += `<p><b>${item.date}: </b>${item.user.firstname} ${item.user.lastname} (${item.user.email}) </p>`
                    })
                }
                if (msg === "") {
                    return label;
                } else {
                    return <><span data-for={"wch_ec_usage_" + row.original.id} data-tip><b>{label}</b></span>
                        <EnigooToolTip content={msg} id={"wch_ec_usage_" + row.original.id} place={"right"}/></>;
                }
            }
        },
        {
            header: t('basics.options'), cell: ({row}) => (
                <WebExternalCodeModal item={row.original} index={row.index} key={row.index.toString()}
                                      saveCode={onUpdate} removeCode={onDelete}/>
            )
        },

    ], [i18n.language]);


    let tableData = React.useMemo(() => data, [data]);

    return <EnigooTablev2 columns={columns} data={data && data.value ? data.value : []} selectable={false}/>;


}
export default WebChannelExternalCodeTable;
