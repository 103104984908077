import {axios} from "../../../axiosConfig";

const url = "api/admin/v1/template";

export function apiGetTemplate(callback, error) {
    axios.get(url)
        .then(response => callback(response.data.data))
        .catch(err => error(err));
}

export function apiGetTemplateById(id, callback, error) {
    axios.get(url + "/" + id)
        .then(response => callback(response.data.data))
        .catch(err => error(err));
}

export function apiUpdateTemplate(id, data, callback, error) {
    axios.put(url + "/" + id, data)
        .then(response => callback(response.data.data))
        .catch(err => error(err));
}

export function apiCreateTemplate(data, callback, error) {
    axios.post(url, data)
        .then(response => callback(response.data.data))
        .catch(err => error(err));
}

export const apiPostValidateTemplate = (data, callback, error) => {
    axios.post(url+"/validate",data)
        .then(response => callback(response.data.data))
        .catch(err => error(err));
}
