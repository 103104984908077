import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Form} from "react-final-form";
import {EnigooTextField} from "../EnigooTextField";
import {EnigooHtmlTextArray} from "../EnigooHtmlTextArray";
import EnigooButton from "../EnigooButton";
import {apiPostValidateTemplate} from "../../Admin/pages/Template/Actions";
import TemplateViewModal from "../../Admin/pages/Template/TemplateViewModal";
import {AiOutlineFilePdf, VscOpenPreview} from "react-icons/all";
import {EnigooEmailField} from "../EnigooEmailField";
import {FiPlus, FiSend} from "react-icons/fi";
import CogoToast from "cogo-toast";
import VerifyRights from "../../Admin/VerifyRights";
import {FieldArray} from "react-final-form-arrays";
import arrayMutators from "final-form-arrays";

const JsonAttributes = ({
                            text,
                            pageSize,
                            json,
                            type,
                            randomValues
                        }) => {

    const {t} = useTranslation();

    let [items, setItems] = useState([])
    let [notFound, setNotFound] = useState([])
    let [foreach, setForeach] = useState([])
    let [templateData, setTemplateData] = useState(null);
    let [loadingData, setLoadingData] = useState(false);

    useEffect(() => {
        validateData(text)
    }, [text]);

    const addToArray = (array, item) => {
        let find = array.find(_item => _item === item);
        if (!find) {
            array.push(item);
        }

        return array;
    }

    const validateData = (text) => {
        if (!text) return null
        let pattern = /\{\$\S+.}/g
        let result = text.match(pattern);
        let arrayPattern = /\[\S+\]/g
        let found = [];
        let _foreach = [];
        let _notFound = [];
        let keys = Object.keys(json);
        if (result) {

            keys.push("{$day}")
            keys.push("{$d}")
            keys.map(item => {
                result.map(regex => {
                    // pokud je tam dalsi format -> datum napriklad
                    if (regex.includes("|")) {
                        let pattern = /\|\S+/g
                        let _item = regex.match(pattern)
                        _item = regex.replace(_item, "}")
                        if (_item === item) {
                            found = addToArray(found, regex)

                        }
                    }
                    // pokud to sedi a neni to object
                    if (item === regex && typeof json[item] !== 'object') {
                        found = addToArray(found, regex)
                    }
                    // pokud je to object -> napriklad atributy
                    if (regex.match(arrayPattern) && typeof json[item] === 'object') {
                        let _pattern = regex.match(arrayPattern)[0]
                        let _item = regex.replace(_pattern, "")
                        if (_item === item) {
                            if (Number.isInteger(parseInt(_pattern.replace('[', "").replace(']', "")))) {
                                found = addToArray(found, regex)
                            } else {
                                Object.keys(json[item]).map(_object => {
                                    if (_pattern.match(/\w+/g)) {
                                        if (_object === _pattern.match(/\w+/g)[0]) {
                                            found = addToArray(found, regex)
                                        }
                                    }
                                })
                            }
                        }
                    }
                })
            })

            // pokud je to pole objektu
            keys.map(item => {
                if (Array.isArray(json[item])) {
                    let _pattern = /\{foreach.\$\w+.\w+.\S+/g
                    let _result = text.match(_pattern);
                    if (_result) {
                        _result.map(_item => {
                            if (_item.match(/\$\w+\s/g)) {
                                let a = _item.match(/\$\w+\s/g)[0].replace(" ", "")
                                let _a = ("{" + a + "}")
                                if (_a === item) {
                                    if (_item.match(/\$\w+}/g)) {
                                        let b = _item.match(/\$\w+}/g)[0].replace("}", "")
                                        let _b = "{" + b + "}"
                                        result.map(resultItem => {
                                            if (resultItem.match(arrayPattern)) {
                                                let _pattern = resultItem.match(arrayPattern)[0]
                                                let c = resultItem.replace(_pattern, "")
                                                if (_b === c) {
                                                    json[item].map(jsonItem => {
                                                        if (_pattern.match(/\w+/g)) {
                                                            let find = Object.keys(jsonItem).find(_find => _find === _pattern.match(/\w+/g)[0])
                                                            if (find) {
                                                                found = addToArray(found, resultItem)
                                                                _foreach.push({property: a, value: b})
                                                            }
                                                        }
                                                    })
                                                }
                                            }
                                        })
                                    }
                                }
                            }
                        })
                    }
                }
            })
        }

        // pokud neni promena naprimo vypsana ale je v podmince atd
        pattern = /\$\w+/g
        result = text.match(pattern);
        if (result) {
            result.map(_result => {
                let isFound = false;
                keys.map(_json => {
                    let find = found.find(item => item.match(/\$\w+/g)[0] === _json.match(/\$\w+/g)[0])
                    if (!find) {
                        if (("{" + _result + "}") === _json) {
                            if (!Array.isArray(json[_json])) {
                                isFound = true;
                                found = addToArray(found, "{" + _result + "}")
                            }
                        }
                    }
                })
            })

            result.map(item => {
                let find = found.find(_item => _item.match(/\$\w+/g)[0] === item.match(/\$\w+/g)[0])
                if (!find) {
                    let found = keys.find(data => data === "{" + item + "}")
                    if (!found) {
                        _notFound.push(item);
                    }
                }
            })
        }

        setNotFound(_notFound);
        setItems(found)
        setForeach(_foreach)
    }
    const onPdf = (values) => {
        let count = values.count;
        let params = {...values};
        delete params.count;
        if (!params.days) {
            params.days = [];
        }
        setLoadingData(true);
        apiPostValidateTemplate({data: text, params: params, count: count, type: "pdf", pageSize: pageSize}, (data) => {
            setLoadingData(false);
            setTemplateData('data:application/pdf;base64, ' + encodeURI(data.data));
        }, (err) => {
            setLoadingData(false);
            setTemplateData(null);
            CogoToast.error(t("templateJsonAttributes.onPdfError"))
        })

    }

    const onHtml = (values) => {
        let count = values.count;
        let params = {...values};
        delete params.count;
        if (!params.days) {
            params.days = [];
        }


        setLoadingData(true);
        apiPostValidateTemplate({
            data: text,
            params: params,
            count: count,
            type: "html",
            pageSize: pageSize
        }, (data) => {
            setLoadingData(false);
            setTemplateData('data:text/html;charset=utf-8, ' + encodeURIComponent(data.data));
        }, (err) => {
            setLoadingData(false);
            setTemplateData(null);
            CogoToast.error(t("templateJsonAttributes.onHtmlError"))
        })
    }

    const onMail = (values) => {
        setLoadingData(true);
        apiPostValidateTemplate({data: text, params: values, type: "mail"}, (data) => {
            setLoadingData(false);
            CogoToast.success(t("mailTemplate.mailSend"));
        }, (err) => {
            setLoadingData(false);
            CogoToast.success(t("mailTemplate.mailSendError"));
        })
    }

    const getName = (item) => {
        let regex = item.match(/\[\S+\]/g)
        if (regex) {
            let find = foreach.find(_item => _item.value === item.match(/\$\w+/g)[0])
            if (find) {
                return find.property.replace("$", "") + "___" + regex[0].match(/\w+/g)[0]
            } else {
                return item.match(/\$\w+/g)[0].replace("$", "") + "__" + regex[0].match(/\w+/g)[0]
            }
        } else {
            return item.match(/\$\w+/g)[0].replace("$", "")
        }
    }
    const _renderNotFound = () => {
        if (notFound.length) return (
            <div className={"w-full mb-2 border border-red-800 rounded p-5 flex flex-col space-y-2"}>
                {
                    notFound.map((item, index) => {
                        return <div key={index + "_" + "not_found"}>
                            <strong className={"text-red-800"}>{item}</strong> - Parametr nebyl nalezen
                        </div>
                    })
                }
            </div>

        )
    }

    const _renderDaysArray = (item) => {
        return <>
            <FieldArray name={getName(item)}>
                {({fields}) => {
                    return (
                        <div className={"col-span-2 lg:col-span-4 mb-2"}>
                            <div className={"flex flex-col space-y-2"}>
                                <div className={"form-label"}>{item}:*</div>
                                <button
                                    onClick={() => {
                                        fields.push({id: "", name: "", onAccreditation: ""})
                                    }}
                                    className={"btn btn-default btn-rounded btn-icon hover:bg-enigoo-600 bg-enigoo-500 text-white w-10"}>
                                    <FiPlus size={10}/>
                                </button>
                                <div className={"grid lg:grid-cols-2 grid-cols-1 gap-2"}>
                                    {fields.map((name, index) => (
                                        <div className={`text-sm form-input`} key={name}>
                                            <div className={"grid grid-cols-2 gap-2"}>
                                                <div className={"font-bold"}>#{index}:</div>
                                                <div className={"flex place-self-end"}>
                                                    <div
                                                        className={"text-right"}
                                                        onClick={() => {
                                                            fields.remove(index)
                                                        }
                                                        }
                                                        style={{cursor: "pointer"}}>❌
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={"grid grid-cols-1 lg:grid-cols-3 text-sm mt-5 gap-2"}>
                                                <EnigooTextField label={"ID: *"} name={`${name}.id`} visible={true}/>
                                                <EnigooTextField name={`${name}.name`} label={t("basics.name") + ": *"}
                                                                 visible={true}/>
                                                <EnigooTextField label={t("zones.nameOnAccreditation") + ": *"}
                                                                 name={`${name}.onAccreditation`} visible={true}/>

                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    )
                }}
            </FieldArray>
        </>
    }

    const _renderItems = () => items.map((item, index) => {
        if (item === "{$d}" || item === "{$day}") {
            return <></>
        }
        if (item === "{$days}") {
            return _renderDaysArray(item);
        } else if (item.includes("html_data")) {
            return <EnigooHtmlTextArray placeHolder={t("placeHolder.text")} key={index} name={getName(item)}
                                        label={item + ":*"}/>
        } else {
            let label = item + ":*";
            if (item.includes("user_image")) {
                label = item + " (" + t("templateJsonAttributes.imageLabel") + "):*"
            }
            return (
                <EnigooTextField initValue={randomValues[getName(item)]} placeHolder={t("placeHolder.text")} key={index}
                                 name={getName(item)} label={label}
                                 type={"text"}/>
            )
        }
    })

    const _renderButtons = (valid, values, errors) => {
        switch (type) {
            case "template":
                return <>
                    <div className={"col-span-2 lg:col-span-1"}>
                        <EnigooTextField initValue={1} name={"count"} label={t("templateJsonAttributes.countOf") + ":*"}
                                         type={"number"} toolTip={t("templateJsonAttributes.countOfToolTip")}/>
                    </div>

                    <VerifyRights page={"template"} action={"postValidateTemplate"}><>
                        <div className={"form-element"}>
                            <div className={"form-label"}>{t("templateJsonAttributes.forSendInMailPdf")}:</div>
                            <EnigooButton extraStyle={"btn-block"} disabled={!valid || loadingData || !text}
                                          loading={loadingData}
                                          icon={<AiOutlineFilePdf/>} text={t("templateJsonAttributes.generatePdf")}
                                          onClick={() => onPdf(values)}/>
                        </div>

                        <div className={"form-element"}>
                            <div className={"form-label"}>{t("templateJsonAttributes.forPrintFromAdmin")}:</div>
                            <EnigooButton extraStyle={"btn-block"} disabled={!valid || loadingData || !text}
                                          loading={loadingData}
                                          icon={<VscOpenPreview/>} text={t("templateJsonAttributes.showPreview")}
                                          onClick={() => onHtml(values)}/>
                        </div>
                    </>
                    </VerifyRights>
                </>
            case "mail-template":
                return <>
                    <div className={"col-span-2 lg:col-span-1"}><EnigooEmailField name={"testEmail"}
                                                                                  label={t("templateJsonAttributes.emailForTest") + ":*"}/>
                    </div>
                    <VerifyRights page={"template"} action={"postValidateTemplate"}><>
                        <div className={"lg:mt-6"}><EnigooButton extraStyle={"btn-block"}
                                                                 disabled={!valid || !values.testEmail || loadingData || !text}
                                                                 loading={loadingData} icon={<FiSend/>}
                                                                 text={t("templateJsonAttributes.sendTestMail")}
                                                                 onClick={() => onMail(values)}/></div>
                        <div className={"lg:mt-6"}><EnigooButton extraStyle={"btn-block"}
                                                                 disabled={!valid || loadingData || !text}
                                                                 loading={loadingData} icon={<VscOpenPreview/>}
                                                                 text={t("templateJsonAttributes.generatePreview")}
                                                                 onClick={() => onHtml(values)}/></div>
                    </>
                    </VerifyRights>
                </>
        }
    }

    return (
        <Form onSubmit={onHtml}
              validate={(values) => {
                  let errors = {};
                  items.map((item) => {
                      if (item === "{$days}" && values[getName(item)]) {
                          errors[getName(item)] = [];
                          values[getName(item)].forEach((day, index) => {
                              errors[getName(item)][index] = {};
                              if (!day.id) {
                                  errors[getName(item)][index].id = t("basics.requireField")
                              }
                              if (!day.name) {
                                  errors[getName(item)][index].name = t("basics.requireField")
                              }
                              if (!day.onAccreditation) {
                                  errors[getName(item)][index].onAccreditation = t("basics.requireField")
                              }
                          })
                      }
                      if (item !== "{$days}" && item !== "{$day}" && item !== "{$d}" && !values[getName(item)]) {
                          errors[getName(item)] = t("basics.requireField")
                      }
                  })
                  return errors;
              }}
              mutators={{...arrayMutators}}
              render={({handleSubmit, values, valid}) => {
                  return (
                      <>
                          <div className={"mt-5 grid grid-cols-2 lg:grid-cols-4 gap-2"}>
                              {_renderItems()}
                          </div>
                          <div className={"grid grid-cols-2 lg:grid-cols-4 gap-2"}>
                              {_renderButtons(valid, values)}
                          </div>
                          {_renderNotFound()}
                          <TemplateViewModal data={templateData} open={templateData !== null} setOpen={() => {
                              setTemplateData(null)
                          }}/>
                      </>
                  )
              }}
        />
    )
}
export default JsonAttributes
