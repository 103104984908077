import React, {useEffect, useState} from "react";
import {apiGetUserCreditTransaction} from "./Actions";
import CogoToast from "cogo-toast";
import {EnigooLoader} from "../../../MyComponent/EnigooLoader";
import EnigooTablev2 from "../../../MyComponent/EnigooTablev2";
import {useTranslation} from "react-i18next";

const TransactionTable = ({userId}) =>{
    let [data, setData] = useState(null);
    let [loading,setLoading] = useState(false);
    const {t,i18n} = useTranslation()

    const columns =  React.useMemo(()=> [
        {cell: ({row})=>(<>{formatDateTime(row.original.date)}</>), header: t('basics.date')},
        {cell: ({row})=>{
            if(row.original.type ===1) return <>Vklad</>;
            if(row.original.type ===2) return <>Čerpání</>;
            if(row.original.type === 3) return <>Oprava</>;
            }, header: t('basics.type')},
        {header: t('device.single'),cell: ({row})=>{return row.original.device?row.original.device:"-"}},
        {header: t('adminUser.admin'),cell: ({row})=>{return row.original.adminUser?row.original.adminUser:"-"}},
        {header: t('deviceReports.cost'), accessorKey: 'price'}

    ],[i18n.language]);
    useEffect(()=>{
        setLoading(true);
        apiGetUserCreditTransaction(userId,(data)=>{
            setData(data);
            setLoading(false);
        },error=>{
            CogoToast.error("Nepodařilo se načíst historii transakcí.");
            setLoading(false);
        })
    },[userId]);

    const formatDateTime = (dateTimeInString) => {
        if (dateTimeInString) {
            let date = new Date(dateTimeInString);
            return date.toLocaleDateString() + " " + date.toLocaleTimeString();
        }
    }


    if(loading) return <EnigooLoader text={"Načítám historii transakcí..."}/>
    if(!data ||data.length<=0) return <><span className={"text-xl"}>Historie transakcí</span> <EnigooTablev2 columns={columns} data={[]}/></>
    if(data) return(
        <><span className={"text-xl"}>Historie transakcí</span><EnigooTablev2 columns={columns} data={data} selectable={false}/></>
    )

}
export default TransactionTable;
