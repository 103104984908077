import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {apiGetCustomField} from "./Actions";
import CogoToast from "cogo-toast";
import {FiPlus} from "react-icons/fi";
import EnigooButton from "../../../MyComponent/EnigooButton";
import Widget from "../../../components/widget";
import {EnigooLoader} from "../../../MyComponent/EnigooLoader";
import EnigooBreadcrumbs from "../../../MyComponent/EnigooBreadcrumbs";
import VerifyRights from "../../VerifyRights";
import {useTranslation} from "react-i18next";
import CustomFieldTable from "./CustomFieldTable";

const CustomField = () => {
    const history = useHistory();
    let [customField, setCustomField] = useState([])
    let [loading, setLoading] = useState(false)
    const {t,i18n} = useTranslation()

    useEffect(() => {
        getCustomField();
    }, []);

    const getCustomField = () => {
        setLoading(true);
        apiGetCustomField((data) => {
            setCustomField(data);
            setLoading(false);
        }, (error) => {
            CogoToast.error(t("userFields.loadingError"));
            setLoading(false);
            history.push("/");
        })
    }

    const tableData = React.useMemo(()=>customField,[customField,i18n.language]);

    return (
        <>
            <EnigooBreadcrumbs
                right={<VerifyRights page={"customField"} action={"createCustomField"}> <EnigooButton link={"/custom-field/create"} icon={<FiPlus/>} text={t("basics.add")}/></VerifyRights>}/>
            <Widget>
                {loading ? <EnigooLoader text={t("userFields.loading")}/> : <CustomFieldTable data={tableData}/>}
            </Widget>
        </>
    );
}

export default CustomField;
