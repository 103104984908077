import React from "react";
import {useTranslation} from "react-i18next";

const Dashboard = () => {
    const {t} = useTranslation();
    return (
        <div className={"bg-white min-h-screen min-w-screen flex -mt-20"}>
            <div className={"m-auto text-center"}>
                <div className={"flex flex-row items-center "}>
                    <div><img src={"/logo-E.png"} width={50}/></div>
                    <div className={"text-5xl h-full text-enigoo-500 ml-3 uppercase"}>{t("basics.accreditationSystem")}</div>
                </div>
                <div className={"text-2xl"}>&copy; ENIGOO {new Date().getFullYear()}</div>
            </div>
        </div>
    )
}

export default Dashboard;
