import React, {useEffect, useState} from "react";
import {apiGetImageById, apiUpdateImage} from "./Actions";
import {useParams} from "react-router";
import EnigooBreadcrumbs from "../../../MyComponent/EnigooBreadcrumbs";
import {EnigooLoader} from "../../../MyComponent/EnigooLoader";
import Widget from "../../../components/widget";
import CogoToast from "cogo-toast";
import 'tui-image-editor/dist/tui-image-editor.css';
import ImageEditor from '@toast-ui/react-image-editor';
import {whiteTheme} from "./white-theme";
import EnigooButton from "../../../MyComponent/EnigooButton";
import {FiSave} from "react-icons/all";
import {EnigooTextField} from "../../../MyComponent/EnigooTextField";
import {Form} from "react-final-form";
import VerifyRights from "../../VerifyRights";
import {useTranslation} from "react-i18next";

const ImageForm = () => {
    let [loading, setLoading] = useState(false);
    let [imageData, setImageData] = useState(null);
    let [saving, setSaving] = useState(false);
    let editorRef = React.createRef();

    let submit;

    const {id} = useParams();

    useEffect(() => {
        getImage();
    }, [])
    const {t} = useTranslation()
    const getImage = () => {
        setLoading(true);
        apiGetImageById(id, (data) => {
            setImageData(data);
            setLoading(false);
        }, (err) => {
            setImageData(null);
            setLoading(false);
            CogoToast.error(t("basics.noData"));
        })
    }

    const onSubmit = (values) => {
        setSaving(true);
        let img = editorRef.current.imageEditorInst.toDataURL();
        apiUpdateImage(id, {data: img, name: values.name}, (data) => {
            CogoToast.success(t("photo.saved"));
            setSaving(false);
        }, (error) => {
            CogoToast.error(t("photo.saveError"));
            setSaving(false);
        })
    }

    const _renderBody = () => {
        if (loading) return <EnigooLoader text={t("loading.image")}/>;
        else if (imageData) return (
            <div className={"grid grid-cols-1 justify-items-center"}>
                <Form onSubmit={onSubmit} initialValues={imageData}
                      render={({form, handleSubmit}) => {
                          submit = handleSubmit;
                          return (
                              <>
                                  <EnigooTextField name={"name"} label={t("basics.name")+":"} placeHolder={t("placeHolder.name")}/>
                              </>);
                      }}/>

                <div className={"border border-grey-400 rounded"}>
                    <ImageEditor
                        ref={editorRef}
                        includeUI={{
                            loadImage: {
                                path: imageData.data,
                                name: 'SampleImage',
                            },
                            menu: ['crop', 'flip', 'rotate'],
                            theme: whiteTheme,
                            uiSize: {
                                width: '1000px',
                                height: '700px',
                            },
                            menuBarPosition: 'bottom',
                        }}
                        cssMaxHeight={500}
                        cssMaxWidth={700}
                        selectionStyle={{
                            cornerSize: 20,
                            rotatingPointOffset: 70,
                        }}
                        usageStatistics={false}
                    />
                </div>
            </div>
        )
        else return <>Nepodařilo se načíst obrázek</>;
    }


    return (<>
        <EnigooBreadcrumbs right={<VerifyRights page={"image"} action={"updateImage"}>
            <EnigooButton text={"Uložit"} onClick={() => {
                submit()
            }} loading={saving} disabled={saving}
                          icon={<FiSave/>}/></VerifyRights>}/>
        <Widget>
            {_renderBody()}
        </Widget>
    </>)

}
export default ImageForm;
