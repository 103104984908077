import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";
import {apiActiveActionExtension, apiGetActionExtension} from "./Actions";
import CogoToast from "cogo-toast";
import {FiCheckCircle, FiEdit, FiPlayCircle, FiStopCircle, FiUsers, FiXCircle} from "react-icons/fi";
import EnigooButton from "../../../MyComponent/EnigooButton";
import {EnigooLoader} from "../../../MyComponent/EnigooLoader";
import EnigooTablev2 from "../../../MyComponent/EnigooTablev2";

const ActionExtensionTable = ({
                                  type = 1
                              }) => {
    let [data, setData] = useState([]);
    let [loading, setLoading] = useState(false);
    let [creating, setCreating] = useState(false);
    let typePath = React.useMemo(() => {
        switch (type) {
            case 1:
                return "meal"
            case 2:
                return "parking"
            default:
                return "meal"
        }
    }, [type])

    const {t, i18n} = useTranslation();
    useEffect(() => {
        getData();
    }, []);

    const getData = () => {
        setLoading(true);
        apiGetActionExtension(type, null, (data) => {
            setData(data);
            setLoading(false);
        }, (error) => {
            CogoToast.error(t("actionExtension.loadDataError"))
            setData([]);
            setLoading(false);
        })
    }

    const _getActivatedCell = (item) => {
        if (item.extension) {
            return <FiCheckCircle size={20} color={"green"}/>
        } else {
            return <FiXCircle size={20} color={"red"}/>
        }
    }

    const activeMealExtension = (item, active) => {
        setCreating(true);
        apiActiveActionExtension(type, item.action.id, {active: active}, (result) => {

            CogoToast.success(active ? t("actionExtension.activated") : t("actionExtension.deactivateSuccess"))
            setCreating(false);
            getData();
        }, (err) => {
            CogoToast.error(active ? t("actionExtension.activatedError") : t("actionExtension.deactivatedError"))
            setCreating(false);
        })
    }

    const _getOptionsButtons = (item, index) => {
        if (item.extension) {
            return <div className={"flex flex-row space-x-2"}>
                <EnigooButton icon={<FiEdit/>} _key={"action_extension_edit_"+item.id} link={`/action-extension/${typePath}/detail/${item.action.id}`}
                              disabled={creating} loading={creating} toolTip={t("basics.detail")}/>
                <EnigooButton disabled={creating} _key={"action_extension_users_"+item.id} loading={creating} icon={<FiUsers/>}
                              link={`/action-extension/${typePath}/${item.action.id}/users`}
                              toolTip={t("users.plural")}/>
                <EnigooButton disabled={creating} _key={"action_extension_active_"+item.id} loading={creating} icon={<FiStopCircle/>} color={"red"}
                              onClick={() => activeMealExtension(item, 0)} toolTip={t("basics.deactivate")}/>
            </div>
        } else {
            return <div className={"flex flex-row space-x-2"}>
                <EnigooButton disabled={creating} loading={creating} icon={<FiPlayCircle/>} color={"green"}
                              onClick={() => activeMealExtension(item, 1)} toolTip={t("basics.activate")}/>
            </div>
        }

    }

    let columns = React.useMemo(() => [
        {
            header: t('action.name'),
            id: 'actionName',
            cell: ({row}) => {
                if (row.original.action.name) {
                    return row.original.action.name;
                } else {
                    return ""
                }
            }
        },
        {
            header: t('actionExtension.activated'),
            id: "activated",
            cell: ({row}) => {
                return _getActivatedCell(row.original);
            }
        },
        {
            header: t("basics.options"),
            id: "options", cell: ({row}) => {
                return _getOptionsButtons(row.original);
            }
        }
    ], [i18n.language,data])

    if (loading) return <EnigooLoader text={t("actionExtension.loadingData")}/>
    else return <EnigooTablev2 data={data} columns={columns} selectable={false}/>
}
ActionExtensionTable.propTypes = {
    type: PropTypes.oneOf([1, 2])
}
export default ActionExtensionTable;
