import {axios} from "../../../axiosConfig";

const url = "api/online/v1";

export function apiOnlineGetWebChannel(channelKey,callback, error){
    axios.get(url+"/web-channel/"+channelKey)
        .then(response=>callback(response.data.data))
        .catch(err=>error(err));
}

export function apiOnlineGetWebChannelData(channelKey,callback,error){
    axios.get(url+"/web-channel/data/"+channelKey)
        .then(response=>
            callback(response.data.data))
        .catch(err=>
            error(err));
}

export function apiOnlineLoginWebChannel(data, callback, error){
    axios.post(url+"/web-channel/login",data)
        .then(response=>callback(response.data.data))
        .catch(err=>error(err));
}

export function apiOnlineAuthorizateWebChannel(channelKey,callback,error){
    axios.post(url+"/web-channel/authorize/"+channelKey)
        .then(response=>callback(response.data.data))
        .catch(err=>error(err));
}

export function apiCreateUser(channelKey, data, callback, error){
    axios.post(url+"/accreditation/"+channelKey,data)
        .then(response=>callback(response.data.data))
        .catch(err=>error(err));
}

export function apiOnlineLogoutWebChannel(channelKey,callback,error){
    axios.post(url+"/web-channel/logout/"+channelKey)
        .then(response=>callback(response.data.data))
        .catch(err=>error(err));
}

