import {useTranslation} from "react-i18next";
import {EnigooSwitch} from "../../../../MyComponent/EnigooSwitch";
import EnigooMultiLanguageField from "../../../../MyComponent/EnigooMultiLanguageField/EnigooMultiLanguageField";
import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import VerifyRights from "../../../VerifyRights";
import EnigooNote from "../../../../MyComponent/EnigooNote";
import {FiInfo, FiSkipBack} from "react-icons/all";
import EnigooButton from "../../../../MyComponent/EnigooButton";
import {FiHelpCircle, FiLink, FiX} from "react-icons/fi";
import {useParams} from "react-router-dom";
import {shallowEqual, useSelector} from "react-redux";
import {EnigooLoader} from "../../../../MyComponent/EnigooLoader";
import {Field} from "react-final-form";
import {ImageModal} from "../../Image/ImageModal";
import {EnigooDateTimeFieldv2} from "../../../../MyComponent/EnigooDateTimeFieldv2";
import EnigooToolTip from "../../../../MyComponent/EnigooToolTip";

const TabTextPreview = ({
                            showCredit,
                            setShowCredit,
                            showCustom,
                            setShowCustom,
                            values,
                            showMeal,
                            loading = false,
                            previewInitImage
                        }) => {
    const {t} = useTranslation()
    const {id} = useParams();

    const {adminRights} = useSelector(
        state => ({adminRights: state.reducerAdmin.adminRights}),
        shallowEqual
    )

    return (
        <>
            {id && adminRights?.subject?.key ?
                <EnigooButton extraStyle={"mb-2"} link={"/preview/" + adminRights.subject.key + "/cid/tst" + id}
                              icon={<FiLink/>} newTab={true} text={t("action.showTestPreview")}/> : <></>}
            <EnigooNote>
                <div className={"flex flex-col space-y-2"}>
                    <div className={"font-bold text-enigoo-500"}>{t("action.textsPreview")}</div>
                    <div dangerouslySetInnerHTML={{__html: t("action.textPreviewNote")}}></div>
                </div>
            </EnigooNote>
            <EnigooMultiLanguageField name={"previewData.title"} label={t("action.titleForPreview")} toolTip={t("action.titleForPreviewToolTip")} type={"text"}/>
            <TabPhoto initImage={previewInitImage} loading={loading}/>
            {values.isCredit && <div className={"form-element form-input text-sm border-grey-500"}>
                <div className={"grid lg:grid-cols-2 grid-col-1"}>
                    <EnigooSwitch name={"previewData.credit.show"} label={t("action.showCredit") + ":"}
                                  setChecked={setShowCredit}/>
                    {showCredit && <EnigooMultiLanguageField type={"text"} name={"previewData.credit.title"}
                                                             label={t("action.titleForCredit") + ": *"}
                                                             placeHolder={t("placeHolder.text")}/>}
                </div>
                {showCredit && <EnigooMultiLanguageField type={"html"} name={"previewData.credit.html"}
                                                         label={t("action.pageForCredit") + ": "}
                                                         placeHolder={t("placeHolder.html")}/>}

            </div>}

            <div className={"form-element form-input text-sm border-grey-500"}>
                <div className={"grid lg:grid-cols-2 grid-col-1"}>

                    <EnigooSwitch name={"previewData.custom.show"} label={t("action.showCustom") + ":"}
                                  setChecked={setShowCustom}/>
                    {showCustom && <EnigooMultiLanguageField type={"text"} name={"previewData.custom.title"}
                                                             label={t("action.titleForCustom") + ": *"}
                                                             placeHolder={t("placeHolder.text")}/>}
                </div>
                {showCustom && <EnigooMultiLanguageField type={"html"} name={"previewData.custom.html"}
                                                         label={t("action.pageForCustom") + ": "}
                                                         placeHolder={t("placeHolder.html")}/>}
            </div>


            <VerifyRights extension={[1]} page={[]} action={[]}>
                <div className={"form-element form-input text-sm border-grey-500"}>

                    <div className={"grid lg:grid-cols-2 grid-col-1"}>

                        <EnigooSwitch name={"previewData.meal.show"} label={t("action.showMeal") + ":"} toolTip={t("action.showMealToolTip")}/>
                        {values.previewData?.meal?.show &&
                            <EnigooMultiLanguageField type={"text"} name={"previewData.meal.title"}
                                                      label={t("action.titleForMeal") + ": *"}
                                                      placeHolder={t("placeHolder.text")}/>}
                    </div>

                    {values.previewData?.meal?.show && <div className={"grid grid-cols-2"}>
                        <EnigooSwitch name={"previewData.meal.allowSelect"} toolTip={t("action.allowSelectMealToolTip")}
                                      label={t("action.allowSelectMeal") + ":"}/>
                        {values.previewData?.meal?.allowSelect &&
                            <EnigooDateTimeFieldv2 name={"previewData.meal.selectDateTime"} showTimeSelect={true} toolTip={t("action.selectDateTimeToolTip")}
                                                 label={t("action.selectDateTime") + ":"}/>}
                    </div>}

                    {values.previewData?.meal?.show &&
                        <EnigooMultiLanguageField type={"html"} name={"previewData.meal.html"}
                                                  label={t("action.pageForMeal") + ": "}
                                                  placeHolder={t("placeHolder.html")}/>}
                </div>
            </VerifyRights>

            <VerifyRights extension={[2]} page={[]} action={[]}>
                <div className={"form-element form-input text-sm border-grey-500"}>

                    <>
                        <div className={"grid lg:grid-cols-2 grid-col-1"}>

                            <EnigooSwitch name={"previewData.parking.show"} label={t("action.showParking") + ":"} toolTip={t("action.showParkingToolTip")}/>
                            {values.previewData?.parking?.show &&
                                <EnigooMultiLanguageField type={"text"} name={"previewData.parking.title"}
                                                          label={t("action.titleForParking") + ": *"}
                                                          placeHolder={t("placeHolder.text")}/>}
                        </div>

                        {values.previewData?.parking?.show && <div className={"grid lg:grid-cols-2 grid-col-1"}>
                            <EnigooSwitch name={"previewData.parking.allowSelect"} toolTip={t("action.allowSelectParkingToolTip")}
                                          label={t("action.allowSelectParking") + ":"}/>
                            {values.previewData?.parking?.allowSelect &&
                                <EnigooDateTimeFieldv2 name={"previewData.parking.selectDateTime"} toolTip={t("action.selectDateTimeParkingToolTip")} showTimeSelect={true}
                                                     label={t("action.selectDateTimeParking") + ":"}/>}
                        </div>}

                        {values.previewData?.parking?.show &&
                            <EnigooMultiLanguageField type={"html"} name={"previewData.parking.html"}
                                                      label={t("action.pageForParking") + ": "}
                                                      placeHolder={t("placeHolder.html")}/>}
                    </>
                </div>
            </VerifyRights>


        </>
    )
}

const TabPhoto = ({initImage, loading}) => {
    let [selectedImage, setSelectedImage] = useState(null);
    let [keepOriginal, setKeepOriginal] = useState(true);
    let [imageBase, setImageBase] = useState(null);
    let [loadingImage, setLoadingImage] = useState(false);
    const {t} = useTranslation()
    useEffect(() => {
        getSelectedImageBase();
    }, [selectedImage])

    const getSelectedImageBase = () => {
        if (selectedImage && selectedImage.data) {
            setImageBase(selectedImage.data);
        } else {
            setImageBase(null);
        }
    }

    const renderPhotoField = (field) => {
        if (loading) return <></>;
        if (loadingImage) return <EnigooLoader text={t("webChannels.imageLoading")}/>;
        if (initImage && keepOriginal) {
            return <>
                <div className={"form-label"}>{t("webChannels.originalLogo")}:</div>
                <div className={"border rounded grid place-content-center p-2 mb-1"}>
                    <img className={"max-h-sm"}
                         src={initImage}
                         alt={"img"}/>
                </div>
                <EnigooButton text={t("webChannels.selectOther")} icon={<FiX size={15}/>}
                              color={"red"}
                              onClick={() => {
                                  field.input.onChange(null);
                                  setSelectedImage(null);
                                  setImageBase(null);
                                  setKeepOriginal(false);
                              }}
                />
            </>
        } else if (!loadingImage && selectedImage && imageBase) return (
            <>
                <div className={"form-label"}>{t("webChannels.selectedLogo")}:</div>
                <div className={"border rounded grid place-content-center p-2 mb-1"}>
                    <img className={"max-h-sm"}
                         src={imageBase}
                         alt={"img"}/>

                </div>
                <EnigooButton text={t("webChannels.selectNew")} icon={<FiX size={15}/>}
                              color={"red"}
                              onClick={() => {
                                  field.input.onChange(null);
                                  setSelectedImage(null);
                                  setImageBase(null);
                              }}
                />
            </>
        )
        else return (
                <>
                    <ChoosePhoto field={field} setSelectedImage={setSelectedImage}/>
                    {initImage && <EnigooButton text={t("webChannels.keepOriginalLogo")} icon={<FiSkipBack size={15}/>}
                                                onClick={() => {
                                                    field.input.onChange(null);
                                                    setSelectedImage(null);
                                                    setKeepOriginal(true);
                                                }}
                    />}
                </>
            )
    }

    return (
        <div className={`form-element`}>
            <div className="form-label text-xl flex flex-row">{t("webChannels.logoToShow")}: <FiHelpCircle
                className={"ml-2 text-red-900 mt-2"} size={18}
                data-tip data-for={"_tooltip_previewlogo"}/><EnigooToolTip content={t("webChannels.imageToolTip")} id={"_tooltip_previewlogo"}  place={"right"}/></div>
            <Field name={"previewData.image"} component={renderPhotoField}/>
        </div>
    )
}

const ChoosePhoto = ({field, setSelectedImage}) => {
    const setImage = (img) => {
        field.input.onChange(img);
        setSelectedImage(img);
        return true;
    }
    return <ImageModal label={""} imageTypes={3} setImage={setImage}/>
}
TabTextPreview.propTypes = {
    showCredit: PropTypes.bool.isRequired,
    setShowCredit:
    PropTypes.func.isRequired,
    showCustom:
    PropTypes.bool.isRequired,
    setShowCustom:
    PropTypes.func.isRequired,
    values:
    PropTypes.object,
    showMeal:
    PropTypes.bool
}
export default TabTextPreview;
