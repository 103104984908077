import React from "react";
import {EnigooSwitch} from "../../../../MyComponent/EnigooSwitch";
import ActionDaySelect from "./ActionDaySelect";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";
import EnigooNote from "../../../../MyComponent/EnigooNote";
import {EnigooDateTimeFieldv2} from "../../../../MyComponent/EnigooDateTimeFieldv2";

const TabDate = ({values, doPush}) => {
    const {t} = useTranslation();

    return (<>

            <div className={"grid grid-cols-2 gap-2"}>
                <EnigooDateTimeFieldv2 label={t("action.startOfAction") + ":"} name={"dateFrom"}
                                     type={"from"}
                                     showTimeSelect={true} key={"1"}/>
                <EnigooDateTimeFieldv2 label={t("action.endOfAction") + ":"} name={"dateTo"}
                                     type={"to"}
                                     showTimeSelect={true} key={"2"}/>
            </div>
            {/*TODO: přidat překlad*/}
            {(values.dateFrom && values.dateTo) ?
                <>
                    <EnigooNote color={"enigoo"}
                                visible={true}>
                        <div className={"flex flex-row space-x-2"}>
                            <div><span className={"font-bold text-enigoo-500"}>{t("action.divideIntoDays")} </span>
                                <div dangerouslySetInnerHTML={{__html:t("action.divideIntoDaysNote")}}></div>
                            </div>
                        </div>
                    </EnigooNote>
                    <EnigooSwitch name={"multiDay"} label={t("action.divideIntoDays") + ":"}/>
                </> : <></>}
            <ActionDaySelect values={values} doPush={doPush}/>
        </>
    )
}
TabDate.propTypes = {
    values: PropTypes.object.isRequired,
    doPush: PropTypes.func.isRequired
}
export default TabDate;
