import React from 'react'
import {shallowEqual, useSelector} from 'react-redux'
import {useTranslation} from "react-i18next";

const Text = () => {
  const {config} = useSelector(
    (state) => ({
      config: state.reducerAdmin.config
    }),
    shallowEqual
  )
    const {t} = useTranslation()
  return (
    <div className="flex flex-col">
      <p className="text-2xl font-bold mb-4">{t("login.welcome")}</p>
      <p className="text-sm font-thin">

      </p>
    </div>
  )
}
export default Text
