import React from "react";
import ActionExtensionTable from "./ActionExtensionTable";
import PropTypes from "prop-types";
import EnigooBreadcrumbs from "../../../MyComponent/EnigooBreadcrumbs";
import Widget from "../../../components/widget";

const ActionExtensionItem = ({type=1}) => {

    return <>
        <EnigooBreadcrumbs/>
        <Widget>
            <ActionExtensionTable type={type}/>
        </Widget>
    </>
}
ActionExtensionItem.propTypes = {
    type:PropTypes.oneOf([1,2])
}
export default ActionExtensionItem;
