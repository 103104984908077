import React, {useEffect, useRef, useState} from "react";
import PropTypes from "prop-types";
import {
    flexRender,
    getCoreRowModel, getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useReactTable
} from '@tanstack/react-table';
import {useTranslation} from "react-i18next";
import {FiChevronsLeft, FiChevronsRight} from "react-icons/fi";

const EnigooTablev2 = ({
                           columns,
                           data,
                           selectable = false,
                           setSelectedRows = null,
                           manualPagination = false,
                           onRowDoubleClick = null,
                           setSelectedAll = null,
                           selectedAll = null
                       }) => {

    const [rowSelection, setRowSelection] = React.useState({})
    let tableInstance = useReactTable({
        manualPagination: manualPagination,
        columns,
        data,
        onRowSelectionChange: setRowSelection,
        getRowId: (original, index, parent) => original.id,
        enableRowSelection: selectable, //enable row selection for all rows
        // enableRowSelection: row => row.original.age > 18, // or enable row selection conditionally per row
        getFilteredRowModel: getFilteredRowModel(),
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
        state: {pageIndex: 0, hideOnSinglePage: true, rowSelection}
    })
    let debounceTimeout;

    const [startRowIndex, setStartRowIndex] = useState(null);
    const tableRef = useRef(null);

    const handleMouseUp = () => {
        setStartRowIndex(null);
    };

    useEffect(() => {
        if (tableRef.current) {
            tableRef.current.addEventListener('mouseup', handleMouseUp);
        }
        return () => {
            if (tableRef.current) {
                tableRef.current.removeEventListener('mouseup', handleMouseUp);
            }
        };
    }, [tableRef.current]);

    const selectedRows = React.useMemo(() => {
        const array = [];
        if (setSelectedRows) {
            Object.entries(rowSelection).forEach(([key, value]) => {
                if (value === true) {
                    array.push(parseInt(key));
                }
            })
            setSelectedRows(array);
            if (tableInstance.getRowModel().rows.length > array.length && setSelectedAll && selectedAll != null && selectedAll === true) {
                setSelectedAll(false);
            }
        }
        return array;
    }, [rowSelection]);

    useEffect(() => {
        if (selectedAll) {
            tableInstance.getRowModel().rows.forEach(row => {
                row.toggleSelected(true);
            })
        }
    }, [selectedAll]);


    const handleRowClick = (row, e, rowIndex) => {
        let rows = tableInstance.getRowModel().rows;
        if (e.ctrlKey || e.metaKey) {
            if (startRowIndex != null) {

                const [start, end] = [startRowIndex, rowIndex].sort((a, b) => a - b);
                let selected = rows[startRowIndex].getIsSelected();

                for (let i = start; i <= end; i++) {
                    rows[i].toggleSelected(selected);
                }
                setStartRowIndex(rowIndex);
            } else {
                rows[rowIndex].toggleSelected(!rows[rowIndex].getIsSelected());
                setStartRowIndex(rowIndex);
            }
        } else {
            rows[rowIndex].toggleSelected(!rows[rowIndex].getIsSelected());
            setStartRowIndex(rowIndex);
        }
    }

    const {t} = useTranslation()


    return (
        <>
            <div className={"overflow-x-auto"}>
                <table className={"table striped"}>
                    <thead className={"table-header-group"}>
                    {tableInstance.getHeaderGroups().map(headerGroup => (
                        <tr key={headerGroup.id}>
                            {headerGroup.headers.map(header => (
                                <th colSpan={header.colSpan} key={header.id}>
                                    {flexRender(header.column.columnDef.header, header.getContext())}
                                    {/*header.column.getCanFilter() ? (
                                        <div>
                                            <Filter column={header.column} />
                                        </div>
                                    ) : null*/}
                                </th>
                            ))}
                        </tr>
                    ))}
                    </thead>
                    <tbody>
                    {data.length === 0 ? <tr>
                            <td colSpan={columns.length}>{t("table.noData")}</td>
                        </tr> :
                        tableInstance.getRowModel().rows.map((row, i) => {
                            return (
                                <tr onDoubleClick={(e) => {
                                    if (onRowDoubleClick) {
                                        onRowDoubleClick(row.original.id)
                                    }
                                }} key={row.id} data-selected={"true"}
                                    className={`row table-row ${row.getIsSelected() ? "bg-enigoo-300" : ""}`}
                                    onClick={(e) => handleRowClick(row, e, i)}>
                                    {row.getVisibleCells().map(cell => {
                                        return <td key={cell.id}
                                                   className={`table-cell ${row.getIsSelected() ? "table-row-selected" : ""}`}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                                    })}
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
            {(10 <= data.length && !manualPagination) ?
                <div className="flex flex-wrap items-center justify-center space-x-2 pagination mt-2">
                    <button className={'btn btn-circle bg-enigoo-500 hover:bg-enigoo-600 text-white'}
                            onClick={() => tableInstance.previousPage()} disabled={!tableInstance.getCanPreviousPage()}>
                        <FiChevronsLeft/>
                    </button>
                    {' '}
                    <span className={"form-label"}>
                    {t("table.page")}{' '}
                        <strong>{tableInstance.getState().pagination.pageIndex + 1} z {tableInstance.getPageCount().toLocaleString()}</strong>{' '}
                </span>
                    <button className={'btn btn-circle bg-enigoo-500 hover:bg-enigoo-600 text-white'}
                            onClick={() => tableInstance.nextPage()} disabled={!tableInstance.getCanNextPage()}>
                        <FiChevronsRight/>
                    </button>
                    {' '}
                    <span className={"form-label"}>
          | {t("table.goToPage")}:{' '}
                        <input
                            className={"form-input text-sm"}
                            type="number"
                            defaultValue={tableInstance.getState().pagination.pageIndex + 1}
                            onChange={e => {
                                if (debounceTimeout) {
                                    clearTimeout(debounceTimeout);
                                }
                                debounceTimeout = setTimeout(()=>{
                                const page = e.target.value ? Number(e.target.value) - 1 : 0
                                tableInstance.setPageIndex(page)},500)
                            }}
                            style={{width: '70px'}}
                        />
        </span>{' '}
                    <select
                        className={"form-select text-sm"}
                        value={tableInstance.getState().pagination.pageSize}
                        onChange={e => {
                            tableInstance.setPageSize(Number(e.target.value))
                        }}
                    >
                        {[10, 20, 30, 40, 50].map(pageSize => (
                            <option key={pageSize} value={pageSize}>
                                {pageSize} {t("table.records")}
                            </option>
                        ))}
                    </select>
                </div> : <></>}
        </>
    )


}

export const EnigooTablev2CheckBox = ({
                                          indeterminate,
                                          className = '',
                                          ...rest
                                      }) => {
    const ref = useRef(null);

    useEffect(() => {
        if (typeof indeterminate === 'boolean') {
            ref.current.indeterminate = !rest.checked && indeterminate
        }
    }, [ref, indeterminate])

    return (
        <input
            type="checkbox"
            ref={ref}
            className={className + ' cursor-pointer'}
            {...rest}
        />
    )
}

const Filter = ({column}) => {
    const columnFilterValue = column.getFilterValue()
    const {filterVariant} = column.columnDef.meta ?? {}

    return filterVariant === 'range' ? (
        <div>
            <div className="flex space-x-2">
                {/* See faceted column filters example for min max values functionality */}
                <DebouncedInput
                    type="number"
                    value={columnFilterValue[0] ?? ''}
                    onChange={value =>
                        column.setFilterValue((old) => [value, old?.[1]])
                    }
                    placeholder={`Min`}
                    className="w-24 border shadow rounded"
                />
                <DebouncedInput
                    type="number"
                    value={columnFilterValue[1] ?? ''}
                    onChange={value =>
                        column.setFilterValue((old) => [old?.[0], value])
                    }
                    placeholder={`Max`}
                    className="w-24 border shadow rounded"
                />
            </div>
            <div className="h-1"/>
        </div>
    ) : filterVariant === 'select' ? (
        <select
            onChange={e => column.setFilterValue(e.target.value)}
            value={columnFilterValue?.toString()}
        >
            {/* See faceted column filters example for dynamic select options */}
            <option value="">All</option>
            <option value="complicated">complicated</option>
            <option value="relationship">relationship</option>
            <option value="single">single</option>
        </select>
    ) : (
        <DebouncedInput
            className="w-36 border shadow rounded"
            onChange={value => column.setFilterValue(value)}
            placeholder={`Search...`}
            type="text"
            value={(columnFilterValue ?? '')}
        />
        // See faceted column filters example for datalist search suggestions
    )
}

// A typical debounced input react component
const DebouncedInput = ({
                            value: initialValue,
                            onChange,
                            debounce = 500,
                            ...props
                        }) => {
    const [value, setValue] = React.useState(initialValue)

    React.useEffect(() => {
        setValue(initialValue)
    }, [initialValue])

    React.useEffect(() => {
        const timeout = setTimeout(() => {
            onChange(value)
        }, debounce)

        return () => clearTimeout(timeout)
    }, [value])

    return (
        <input {...props} value={value} onChange={e => setValue(e.target.value)}/>
    )
}


EnigooTablev2.propTypes = {
    columns: PropTypes.arrayOf(PropTypes.exact({
        accessorKey: PropTypes.string,
        header: PropTypes.string.isRequired,
        id: PropTypes.string.isRequired,
        cell: PropTypes.func
    })).isRequired,
    data: PropTypes.array.isRequired,
    selectable: PropTypes.bool,
    setSelectedRows: PropTypes.func
}
export default EnigooTablev2;
