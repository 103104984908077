import React, {useEffect, useState} from "react";
import {EnigooSelectMulti} from "../../EnigooSelectMulti";
import {EnigooSelect} from "../../EnigooSelect";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";

export const TemplateTypeSelectField = ({
                                        label,
                                        name,
                                        isMulti = true,
                                        outsideClassName = "w-full"
                                    }) => {
    let [data, setData] = useState(null);
    const {t,i18n} = useTranslation()
    //data z databaze
    useEffect(() => {
        set()
    }, [i18n.language])

    const set = () => {
        setData([
            {id:1,name:t("mailTemplate.mailAfterAdd")},
            {id:2,name:t("mailTemplate.mailFromAdmin")},
        ])
    }

    return (isMulti ? <EnigooSelectMulti outsideClassName={outsideClassName} label={label} name={name} items={data} placeHolder={t("placeHolder.selectMulti")}/> :
        <EnigooSelect outsideClassName={outsideClassName} label={label} name={name} items={data} placeHolder={t("placeHolder.select")}/>);

}
TemplateTypeSelectField.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    isMulti: PropTypes.bool
}
