import React from "react";
import PropTypes from "prop-types";

export const EnigooFieldsGroup = ({
                                      items = []
                                  }) => {
    return(
        <div className="flex flex-col lg:flex-row w-full lg:space-x-2">
            {items.map((item, index) => (item))}
        </div>
    )
}
EnigooFieldsGroup.propTypes = {
    items: PropTypes.array
}
