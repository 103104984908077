import {axios} from "../../../axiosConfig";

const url = "api/admin/v1/zone";

export function apiGetZone(callback, error){
    axios.get(url)
        .then(response=>callback(response.data.data))
        .catch(err=>error(err));
}

export function apiGetZoneById(id, callback, error){
    axios.get(url+"/"+id)
        .then(response=>callback(response.data.data))
        .catch(err=>error(err));
}

export function apiUpdateZone(id, data, callback, error){
    axios.put(url+"/"+id,data)
        .then(response=>callback(response.data.data))
        .catch(err=>error(err));
}

export function apiCreateZone(data, callback, error){
    axios.post(url,data)
        .then(response=>callback(response.data.data))
        .catch(err=>error(err));
}