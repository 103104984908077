import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router";
import {apiGetActionExtension, apiPutUpdateActionExtension} from "./Actions";
import CogoToast from "cogo-toast";
import {FieldArray} from "react-final-form-arrays";
import EnigooButton from "../../../MyComponent/EnigooButton";
import {IconTabs} from "../../../components/tabs";
import EnigooBreadcrumbs from "../../../MyComponent/EnigooBreadcrumbs";
import {FiSave} from "react-icons/all";
import Widget from "../../../components/widget";
import {EnigooLoader} from "../../../MyComponent/EnigooLoader";
import {Form} from "react-final-form";
import arrayMutators from "final-form-arrays";
import {_validateMeal, MealDay} from "./MealExtension/FormItems";
import {_validateParking, ParkingDay} from "./ParkingExtension/FormItems";

const ActionExtensionDetail = ({
    type=1
})=>{
    const {t, i18n} = useTranslation();
    let [saving, setSaving] = useState(false);
    let [loading, setLoading] = useState(false);
    let [data, setData] = useState(null);

    let submit = null;

    useEffect(() => {
        const handleKeyDown = (event) => {
            if ((event.ctrlKey || event.metaKey) && event.key === 's') {
                event.preventDefault();
                if(submit) submit();
                console.log('Ctrl/Command + S was pressed');
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    const {actionId} = useParams();

    useEffect(() => {
        setLoading(true);
        apiGetActionExtension(type, actionId, (data) => {
            setData(data);
            setLoading(false);
        }, (error) => {
            setLoading(false);
            setData(null);
            CogoToast.error(t("actionExtension.loadDetailError"))
        })
    }, []);

    const onSave = (values) => {
        setSaving(true);
        let data = {
            items:values.extension?values.extension.items:[]
        };

        console.log(data);
        apiPutUpdateActionExtension(type,actionId,data,(data)=>{
            CogoToast.success(t("actionExtension.saved"));
            setSaving(false);
        },(err)=>{
            CogoToast.error(t("actionExtension.saveError"));
            setSaving(false);
        })

    }

    const getTranslate = (item) => {
        if (item[i18n.language]) {
            return item[i18n.language];
        } else {
            return item["cs"];
        }
    }

    const _renderDay = (day, fieldName,values,daysFields,push=null,) => {
        switch (type){
            case 1:
                return <MealDay day={day} fieldName={fieldName} data={data} push={push} values={values} daysFields={daysFields}/>;
            case 2:
                return <ParkingDay day={day} fieldName={fieldName} data={data} push={push} values={values} daysFields={daysFields}/>
        }
    }

    const _renderTabs = (fields,push,values) => {
        let tabs = [];
        fields.forEach((name, index) => {
            tabs.push({
                index: index,
                title: getTranslate(fields.value[index].name) + (fields.value[index].day ? ' (' + fields.value[index].day + ')' : ""),
                content: _renderDay(fields.value[index].day, name,values,fields,push)
            })
        })
        return <IconTabs tabs={tabs}/>
    }

    return (
        <>
            <EnigooBreadcrumbs
                right={<EnigooButton disabled={saving} loading={saving} icon={<FiSave/>} text={t("basics.save")} onClick={() => submit()}/>}/>
            <Widget>
                {loading || !data ? <EnigooLoader text={t("actionExtension.loadingDetail")}/> :
                    <div className="flex flex-col">
                        <Form onSubmit={onSave} initialValues={data}
                              mutators={{...arrayMutators}}
                              validate={(values)=>{
                                  switch (type) {
                                      case 1:
                                          return _validateMeal(data,values,t);
                                      case 2:
                                          return _validateParking(data,values,t);
                                  }
                              }}
                              render={({handleSubmit,values,form: {
                                  mutators: {push, pop, unshift}
                              }}) => {
                                  submit = handleSubmit;
                                  if (data.action && data.action.multiDay && data.extension && data.extension.items) {
                                      return (

                                          <div
                                              className={"grid grid-cols-1 gap-2"}>
                                              <FieldArray name={"extension.items"} >
                                                  {({fields}) => {
                                                      return _renderTabs(fields,push,values)
                                                  }}
                                              </FieldArray>
                                          </div>)
                                  } else {

                                      return <IconTabs tabs={[
                                          {
                                              index: 0,
                                              title: getTranslate({
                                                  cs: "Pro celou akci",
                                                  en: "For whole event",
                                                  de: "Für die gesamte Veranstaltung",
                                                  sk: "Pre celú akciu"
                                              }),
                                              content: _renderDay(0, "extension.items[0]",values)
                                          }
                                      ]}/>

                                  }
                              }}
                        />
                    </div>}
            </Widget>
        </>)
}
ActionExtensionDetail.propTypes={
    type:PropTypes.oneOf([1,2])
}
export default ActionExtensionDetail;
