import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import PhoneInput, {parsePhoneNumber} from 'react-phone-number-input';
import cz from 'react-phone-number-input/locale/cz.json'
import en from 'react-phone-number-input/locale/en.json'
import de from 'react-phone-number-input/locale/de.json'
import sk from 'react-phone-number-input/locale/sk.json'

import 'react-phone-number-input/style.css'
import {Field} from "react-final-form";
import {useTranslation} from "react-i18next";

export const EnigooPhoneField = ({
                                     name,
                                     placeHolder = "Zadejte telefonní číslo...",
                                     label,
                                     inline = false,
                                     defaultCountry = "CZ",
                                     limitMaxLength = false,
                                     outsideClassName = "w-full",
                                     onKeyDown = null,
                                     mandatory = false
                                 }) => {
    const {t, i18n} = useTranslation();
    let [labels, setLabels] = useState(cz);
    let [initCountry, setInitCountry] = useState("CZ");

    const validatePhoneField = (value) => {
        if (!mandatory && !value) {
            return undefined;
        } else if (mandatory && value === "" || value === null) {
            return t("basics.requireField");
        } else if (value !== "" && value !== null && typeof value === "string") {
            const phoneNumber = parsePhoneNumber(value);
            let countryCode = "CZ";
            if (phoneNumber) {
                countryCode = phoneNumber.country?phoneNumber.country.toUpperCase():"CZ";
            }
            let item = phones[countryCode];
            if (!item) {
                if (value.length > 5) {
                    return undefined;
                } else {
                    return t('basics.phoneInvalid');
                }
            } else if (value.match(item)) {
                return undefined;
            } else {
                return t('basics.phoneInvalid');
            }

        }
    }

    useEffect(() => {
        switch (i18n.language) {
            case "cs":
                setLabels(cz);
                setInitCountry("CZ");
                break;
            case "en":
                setLabels(en);
                setInitCountry("GB");
                break;
            case "de":
                setLabels(de);
                setInitCountry("DE");
                break;
            case "sk":
                setLabels(sk);
                setInitCountry("SK");
                break;
            default:
                setInitCountry("CZ");
                setLabels(cz);
        }
    }, [i18n.language]);
    return (
        <div className={outsideClassName}>
            <div className={`form-element ${inline ? 'form-element-inline' : ''}`}>
                <div className="form-label">{label}</div>
                <Field name={name} validate={validatePhoneField}>
                    {({input, meta}) => (
                        <>
                            <div
                                className={meta.error ? "form-input form-input-invalid flex flex-row items-center justify-start" : "form-input flex flex-row items-center justify-start"}>
                                <PhoneInput
                                    {...input}
                                    onKeyDown={onKeyDown}
                                    labels={labels}
                                    international
                                    countries={["CZ","SK","GB","DE","US"]}
                                    placeholder={"+420 xxx xxx xxx"}
                                    defaultCountry={initCountry}
                                    limitMaxLength={limitMaxLength}
                                    countryCallingCodeEditable={false}
                                    useNationalFormatForDefaultCountryValue={true}
                                    onChange={(value) => input.onChange(value)}
                                />
                            </div>
                            {meta.error && <div className="form-error">{meta.error}</div>}
                        </>
                    )}
                </Field>
            </div>
        </div>
    )
}
EnigooPhoneField.propTypes = {
    name: PropTypes.string.isRequired,
    placeHolder: PropTypes.string,
    label: PropTypes.string.isRequired,
    inline: PropTypes.bool,
    defaultCountry: PropTypes.string,
    limitMaxLength: PropTypes.bool,
    outsideClassName: PropTypes.string
}

const phones = {
    'CZ': /^(\+?420)? ?[1-9][0-9]{2} ?[0-9]{3} ?[0-9]{3}$/,
    'SK': /^(\+?421)? ?[1-9][0-9]{2} ?[0-9]{3} ?[0-9]{3}$/,
    'GB': /^(\+?44|0) ?\d{4} ?\d{6}|\d{3} ?\d{3} ?\d{4}|\d{5} ?\d{5}$/,
    'US': /^(\+?1[-\s.]?)?\(?\d{3}\)?[-\s.]?\d{3}[-\s.]?\d{4}$/,
    'DE': /^(\+?49|0) ?\d{3,4} ?\d{6,8}$/,
};
