import React from "react";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {FiMoon, FiSun} from "react-icons/all";

const EnigooDarkModeSwitch = () => {
    const dispatch = useDispatch();

    const {palettes} = useSelector(
        (state) => ({
            palettes: state.reducerAdmin.palettes
        }),
        shallowEqual
    )

    const {background,leftSidebar,navbar} = palettes;

    let isDark = React.useMemo(()=>{
        return background==="dark" && leftSidebar==="dark" && navbar==="dark";
    },[background,leftSidebar,navbar])
    const changeMode = (isDark) => {
        dispatch({
            type: 'SET_PALETTE',
            palette: {
                background: isDark ? 'dark' : 'light',
                leftSidebar: isDark ? 'dark' : 'light',
                navbar: isDark ? 'dark' : 'light'
            }
        })
    }

    return <></>
    if (isDark) {
        return <button className={"btn btn-circle bg-grey-200 text-grey-700"} onClick={() => changeMode(false)}><FiSun/></button>
    } else {
        return <button className={"btn btn-circle bg-grey-700 text-grey-200"} onClick={() => changeMode(true)}><FiMoon/></button>
    }

}
export default EnigooDarkModeSwitch;
