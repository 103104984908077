import {axios} from "../../../axiosConfig";

const url = "api/manage/v1/admin-user";

export function apiManageCreateAdminUser(data,callback, error){
    axios.post(url,data)
        .then(response=>callback(response.data.data))
        .catch(err=>error(err));
}

export function apiManageGetAdminUser(callback,error){
    axios.get(url)
        .then(response=>callback(response.data.data))
        .catch(err=>error(err));
}

export function apiManageGetAdminUserById(id,callback,error){
    axios.get(url+"/"+id)
        .then(response=>callback(response.data.data))
        .catch(err=>error(err));
}

export function apiManageUpdateAdminUser(id,data,callback,error){
    axios.put(url+"/"+id,data)
        .then(response=>callback(response.data.data))
        .catch(err=>error(err));
}

