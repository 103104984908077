import React from "react";
import {useTranslation} from "react-i18next";
import EnigooTablev2 from "../../../MyComponent/EnigooTablev2";

const EntranceTable = ({data}) => {
    const {t,i18n} = useTranslation();
    const columns = React.useMemo(()=>[
        {
            header: t('device.single'), id: "device",
            cell: ({row}) => (row.original.device ? row.original.device : "-")
        },
        {
            header: t('basics.date'), id: "date", accessorKey: 'date'
        },
        {
            header: t('accreditation.single'), id: "user", accessorKey: 'user'
        },
        {
            header: 'BC', id: "bc", accessorKey: 'bc'
        },
        {
            header: t('groups.single'), id: "groups", accessorKey: 'groups'
        },
        {
            header: t('action.single'), id: "action", accessorKey: 'action'
        }

    ],[i18n.language])

    return <EnigooTablev2 columns={columns} data={data} selectable={false}/>
}
export default EntranceTable;
