import React, {useState} from "react";
import CogoToast from "cogo-toast";
import EnigooCreditPanel from "../../../MyComponent/EnigooCreditPanel";
import {EnigooTextField} from "../../../MyComponent/EnigooTextField";
import EnigooButton from "../../../MyComponent/EnigooButton";
import {FiPlus} from "react-icons/fi";
import {FiMinus} from "react-icons/all";
import TransactionTable from "./TransactionTable";
import {apiUpdateCredit} from "./Actions";
import {EnigooSwitch} from "../../../MyComponent/EnigooSwitch";
import VerifyRights from "../../VerifyRights";

const TransactionTab = ({userId}) => {
    let [value, setValue] = useState(null);
    let [processing, setProcessing] = useState(false);

    const process = (type) => {
        let val;
        if (value && !isNaN(value) && value !== "") {
            val = parseFloat(value);
        } else {
            val = null;
        }
        if (val && val > 0) {
            let typeOfTransaction;
            if (type === "-") {
                typeOfTransaction = 3;
            } else {
                typeOfTransaction = 1;
            }
            setProcessing(true);
            apiUpdateCredit(userId, {price: val, type: typeOfTransaction}, (data) => {
                CogoToast.success("Kredit uživatele byl změněn.");
                setProcessing(false);
                window.location.reload();
            }, (error) => {
                CogoToast.error("Nepodařilo se změnit uživateli kredit.");
                setProcessing(false);
            })

        } else {
            CogoToast.error("Zadejte validní částku.")
        }
    }

    return (
        <VerifyRights page={"userCreditTransaction"} action={"getUserCreditTransaction"}>
            <>
                <EnigooCreditPanel name={"credit"}/>
                <VerifyRights page={"userCreditTransaction"} action={"updateCredit"}>
                    <div className={"flex flex-row"}>


                        <div className={"lg:w-1/3 mr-1"}><EnigooTextField placeHolder={"Zadejte částku"} name={"price"}
                                                                          label={""} type={"number"} onChange={
                            (event) => {
                                setValue(event.target.value);
                            }}/></div>
                        <div className={"mr-1 mt-5"}><span>&nbsp;Kč</span></div>

                        <div className={"mr-1 mt-1"}><EnigooButton onClick={() => process("+")}
                                                                   icon={<FiPlus size={10}/>}
                                                                   text={"Přidat"} disabled={processing}
                                                                   loading={processing}/></div>
                        <div className={"mr-1 mt-1"}><EnigooButton onClick={() => process("-")}
                                                                   icon={<FiMinus size={10}/>}
                                                                   text={"Odebrat"} disabled={processing}
                                                                   loading={processing}/></div>

                    </div>
                    <EnigooSwitch name={"isUnlimited"} label={"Neomezený kredit: "} inline={false}/>
                </VerifyRights>
                <TransactionTable userId={userId}/>


            </>
        </VerifyRights>
    )
}

export default TransactionTab;
