import React, {useState} from "react";
import EnigooBreadcrumbs from "../../../../MyComponent/EnigooBreadcrumbs";
import Widget from "../../../../components/widget";
import {Form} from "react-final-form";
import {ActionSelectField} from "../../../../MyComponent/Api/Admin/ActionSelectField";
import {useTranslation} from "react-i18next";
import {EnigooSelect} from "../../../../MyComponent/EnigooSelect";
import EnigooButton from "../../../../MyComponent/EnigooButton";
import {FaFileExport} from "react-icons/all";
import {apiGetActionExtensionReport} from "../Actions";
import {shallowEqual, useSelector} from "react-redux";

const ActionExtensionReport = ({}) => {

    let [loading, setLoading] = useState(false)

    const {t, i18n} = useTranslation();

    const {adminRights} = useSelector(
        state => ({adminRights:state.reducerAdmin.adminRights}),
        shallowEqual
    )

    let mealTypes = React.useMemo(() => [
        {
            label: t("extensionReport.drawing"),
            value: 1
        },
        {
            label: t("extensionReport.chooseMeals"),
            value: 2
        },
        {
            label: t("extensionReport.numbersOfSelectedMeals"),
            value: 3
        }
    ], [i18n.language]);

    let parkingTypes = React.useMemo(() => [
        {
            label: t("extensionReport.arriving"),
            value: 1
        },
        {
            label: t("extensionReport.chooseParking"),
            value: 2
        },
        {
            label: t("extensionReport.usageOfParking"),
            value: 3
        }
    ], [i18n.language]);

    let extensions = React.useMemo(() => {
        let array = [];
        if(adminRights.subject.extensions.includes(1)){
            array.push({
                label: t("mealExtension.single"),
                value: 1
            })
        }
        if(adminRights.subject.extensions.includes(2)){
            array.push(
                {
                    label: t("parkingExtension.single"),
                    value: 2
                }
            )
        }

        return array;
    }, [i18n.language])


    const onSubmit = (values) => {
        setLoading(true);
        apiGetActionExtensionReport(values.extensionType, values.actionId, {type: values.type}, (data) => {
            const url = window.URL.createObjectURL(new Blob([data.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', data.fileName); //or any other extension
            document.body.appendChild(link);
            link.click();

            setLoading(false);
        }, (err) => {
            setLoading(false);
        })
    }

    const _renderExtensionReportTypeSelect = (extensionType) => {
        switch (extensionType) {
            case 1:
                return <EnigooSelect label={t("basics.type") + ":*"} name={"type"} defaultValue={["value", "label"]}
                                     items={mealTypes}/>
            case 2:
                return <EnigooSelect label={t("basics.type") + ":*"} name={"type"} defaultValue={["value", "label"]}
                                     items={parkingTypes}/>
            default:
                return <></>
        }
    }

    return <>
        <EnigooBreadcrumbs/>
        <Widget>
            <Form onSubmit={onSubmit}
                  validate={(values) => {
                      let errors = {};
                      if (!values.type) {
                          errors.type = t("basics.requireField");
                      }
                      if (!values.actionId) {
                          errors.actionId = t("basics.requireField");
                      }
                      if (!values.extensionType) {
                          errors.extensionType = t("basics.requireField");
                      }
                      return errors;
                  }}
                  render={({handleSubmit, values}) => {
                      return <div className={"grid lg:grid-cols-3 grid-cols-2 gap-2"}>
                          <EnigooSelect label={t("actionExtension.single") + ":*"} name={"extensionType"}
                                        defaultValue={["value", "label"]}
                                        items={extensions}/>
                          {_renderExtensionReportTypeSelect(values.extensionType)}

                          <ActionSelectField name={"actionId"} label={t("action.single") + ": *"} isMulti={false}/>
                          <div className={"col-start-2 lg:col-start-3 mt-6"}>
                              <EnigooButton extraStyle={"btn-block"} onClick={handleSubmit}
                                            text={t("basics.export")} loading={loading} disabled={loading}
                                            icon={<FaFileExport className={"mr-3"}/>}/>
                          </div>
                      </div>
                  }}/>
        </Widget>
        <Widget>

        </Widget>
    </>
}
ActionExtensionReport.propTypes = {}
export default ActionExtensionReport;
