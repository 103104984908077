import {axios} from "../../../axiosConfig";

const url = "api/admin/v1/login";


export function apiPostForgotPassword(data,callback, error) {
    axios.post(url+"/forgot-password",data)
        .then(response => callback(response.data.data))
        .catch(err => error(err))
}


export function apiPostPassLinkAuthorize(data,callback,error){
    axios.post(url+"/pass-link-authorize",data)
        .then(response => callback(response.data.data))
        .catch(err => error(err))
}

export function apiPostVerifyAuthorize(data,callback,error){
    axios.post(url+"/verify-authorize",data)
        .then(response => callback(response.data.data))
        .catch(err => error(err))
}

export function apiPostRenewPassword(data,callback,error){
    axios.post(url+"/renew-password",data)
        .then(response=>callback(response.data.data))
        .catch(err => error(err))
}

export function apiPostVerifyAccount(data,callback,error){
    axios.post(url+"/verify-account",data)
        .then(response=>callback(response.data.data))
        .catch(err => error(err))
}
