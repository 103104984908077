import React, {useEffect, useState} from "react";
import {EnigooSelectMulti} from "../../EnigooSelectMulti";
import {EnigooSelect} from "../../EnigooSelect";
import PropTypes from "prop-types";
import {apiGetCustomField} from "../../../Admin/pages/CustomField/Actions";
import {useTranslation} from "react-i18next";

export const CustomFieldSelectField = ({
                                           label = "Typ uživatelského pole: ",
                                           name,
                                           isMulti = true,
                                           outsideClassName = "w-full",
                                           onValueChange = null,
                                           selectedValues,
                                           required = false
                                       }) => {

    const {t,i18n   } = useTranslation()

    let [data, setData] = useState(null);
    let [loading, setLoading] = useState(false);

    useEffect(() => {
        get();
    }, [])

    const get = () => {
        setLoading(true);
        apiGetCustomField((data) => {
            setData(data);
            setLoading(false);
        }, (error) => {
            setLoading(false);
        })
    }

    const selectData = React.useMemo(()=>{
        let array = [];
        data && data.forEach((item,index)=>{
            if(item.label[i18n.language]){
                array.push({
                    id:item.id,
                    label:item.label[i18n.language]
                })
            }else{
                array.push({
                    id:item.id,
                    label:item.label.cs
                })
            }
        })
        return array;
    },[data,i18n.language])

    const prepareData = (data) => {
        let newData = [];
        data.map((item, index) => {
            const exist = selectedValues.find(d => d!==undefined&&d.customField&&d.customField===item.id);
            if(!exist){
                newData.push(item);
            }
        });
        setData(newData);
    }

    return (isMulti ?
        <EnigooSelectMulti placeHolder={t("placeHolder.selectMulti")} label={label} name={name} disabled={loading} items={selectData}
                           outsideClassName={outsideClassName} onValueChange={onValueChange}
                           defaultValue={["id", "label"]} required={required}/> :
        <EnigooSelect  placeHolder={t("placeHolder.select")} label={label} name={name} disabled={loading} items={selectData}
                      outsideClassName={outsideClassName} onValueChange={onValueChange}
                      defaultValue={["id", "label"]} required={required}/>);

}

CustomFieldSelectField.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    isMulti: PropTypes.bool.isRequired,
    outsideClassName: PropTypes.string,
    onValueChange: PropTypes.func
}
