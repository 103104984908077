import React, {Component, useEffect, useState} from 'react';
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";
import {EnigooSelect} from "../../../MyComponent/EnigooSelect";


const OnlineGroupSelect = ({
        items = [],
        name="groups",
        labelI18Key = "groups.single"
                           }) => {

    const {t, i18n} = useTranslation();
    let [actualItems,setActualItems] = useState([]);

    useEffect(() => {
        prepareData();
    },[i18n.language])

    const prepareData = () => {
        let array = [];
        items.forEach(item => {
            let name = item.name;
            let id = item.id;
            if(item.displayName){
                if(item.displayName[i18n.language]){
                    name = item.displayName[i18n.language]
                }else if(item.displayName.cs){
                    name = item.displayName.cs;
                }
                else{
                    name = item.name;
                }
            }else{
                name = item.name;
            }

            array.push({id:id,name:name});
        })

        setActualItems(array);
    }


    return (
        <EnigooSelect
            label={t(labelI18Key) + ": *"}
            name={name} items={actualItems}
            placeHolder={t("placeHolder.select")}/>
    )







}
OnlineGroupSelect.propTypes = {
    items: PropTypes.array.isRequired,
    name: PropTypes.string,
}
export default OnlineGroupSelect;
