export default function checkUserCam(
    state={
        camEnable: false
    },
    action
){
    switch(action.type){
        case "CHANGE_CAM_STATE":
                return {camEnable: action.camEnable}
        case "ENABLE_CAM":
            return {camEnable: true}
        case "DISABLE_CAM":
        case "RESET":
            return {camEnable: false}
        default:
            return state;
    }
}
