import {axios} from "../../../axiosConfig";

const url = "api/admin/v1/image";

export function apiGetImages(data,callback,error){
    axios.post(url+"/all",data)
        .then(response => callback(response.data.data))
        .catch(err=>error(err));
}

export function apiFilterImage(type, callback, error){
    axios.post(url+"/filter",type)
        .then(response=>callback(response.data.data))
        .catch(err=>error(err));
}

export function apiGetImageById(id,callback,error){
    axios.get(url+"/"+id)
        .then(response=>callback(response.data.data))
        .catch(err=>error(err));
}

export function apiCreateImage(data,callback,error){
    axios.post(url,data)
        .then(response=>callback(response.data.data))
        .catch(err=>error(err));
}

export function apiUpdateImage(id,data,callback,error){
    axios.put(url+"/"+id,data)
        .then(response=>callback(response.data.data))
        .catch(err=>error(err))
}
