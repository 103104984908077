import React, {useEffect, useState} from "react";
import {apiGetGroup} from "./Actions";
import EnigooButton from "../../../MyComponent/EnigooButton";
import {FiPlus} from "react-icons/fi";
import {EnigooLoader} from "../../../MyComponent/EnigooLoader";
import Widget from "../../../components/widget";
import GroupsTable from "./GroupsTable";
import {IconTabs} from "../../../components/tabs";
import EnigooBreadcrumbs from "../../../MyComponent/EnigooBreadcrumbs";
import VerifyRights from "../../VerifyRights";
import {useTranslation} from "react-i18next";

const Groups = () => {

    let [loading, setLoading] = useState(false);
    let [data, setData] = useState(null);
    let [errorMessage, setErrorMessage] = useState(null);
    const {t} = useTranslation()


    useEffect(() => {
        getGroup()
    }, [])

    const getGroup = () => {
        setLoading(true);
        apiGetGroup((data) => {
            setData(data);
            setLoading(false);
            setErrorMessage(null)
        }, (error) => {
            //CogoToast.error("Nepodařilo se načíst skupiny");
            setLoading(false);
            setErrorMessage("Nepodařilo se načíst skupiny")
        })
    }

    const _renderBody = () => {
        if (loading) return <EnigooLoader text={t("groups.loading")}/>
        if (errorMessage) return <div>{errorMessage}</div>
        if (data && data.length===0) return <GroupsTable items={{"items":[]}}/>
        if (data) return prepareData();
    }


    const prepareData = () => {
        let tabs = []
        data.map((item, index) => item.items&&tabs.push({
            index: index,
            content: <GroupsTable items={item}/>,
            title: <>{item.actionName ? item.actionName : t("groups.notAssign")}</>
        }))

        return <IconTabs tabs={tabs}/>

    }

    return (
        <>
            <EnigooBreadcrumbs
                right={<VerifyRights page={"groups"} action={"createGroups"}> <EnigooButton link={"/groups/create"} icon={<FiPlus/>} text={t("basics.add")}/></VerifyRights>}/>
            <Widget>
                {_renderBody()}
            </Widget>
        </>
    )
}

export default Groups;
