import {EnigooFieldsGroup} from "../../../MyComponent/EnigooFieldsGroup";
import EnigooButton from "../../../MyComponent/EnigooButton";
import {FiDelete, FiEdit} from "react-icons/fi";
import React from "react";

const AdminUserTable = (props) => (
    <table className={"table striped"}>
        <thead>
        <tr>
            <th>Přihlašovací login</th>
            <th>Jméno</th>
            <th>Poslední akce</th>
            <th>Aktivní</th>
            <th>Možnosti</th>
        </tr>
        </thead>
        <tbody>
        {props.items.items.length === 0 && <tr>
            <td colSpan={5}>Žádná data k zobrazení</td>
        </tr>}
        {props.items.items.map((item, index) => (<AdminUserRow data={item} index={index} key={index.toString()}/>))}
        </tbody>
    </table>
)

const AdminUserRow = (props) => (
    <tr key={props.index.toString()}>
        <td>{props.data.login}</td>
        <td>{props.data.name}</td>
        <td>{props.data.lastAction}</td>
        <td>{props.data.active ? "ANO" : "NE"}</td>
        <td>
            <EnigooFieldsGroup items={[<EnigooButton icon={<FiEdit/>} link={"/manage/admin-user/detail/" + props.data.id} key={"1"}/>,
                <EnigooButton visible={false} icon={<FiDelete/>} link={"/manage/admin-user/delete/" + props.data.id} key={"2"}/> ]}/>
        </td>
        <td>

        </td>
    </tr>
)

export default AdminUserTable;