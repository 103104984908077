import React, {useEffect, useRef, useState} from "react";
import {shallowEqual, useSelector} from "react-redux";
import Portal from "../../../components/portal";
import {FiCheck, FiX} from "react-icons/fi";
import EnigooButton from "../../../MyComponent/EnigooButton";
import CogoToast from "cogo-toast";
import {apiDeleteUser} from "./Actions";
import {useParams} from "react-router";
import {useHistory} from "react-router-dom";
import {Form} from "react-final-form";
import {EnigooSelect} from "../../../MyComponent/EnigooSelect";
import {Trans, useTranslation} from "react-i18next";

export const UserDeleteModal = ({
                                    setOpen,
                                    open
                                }) => {
    let [saving, setSaving] = useState(false);
    let [selectedType, setSelectedType] = useState(null);
    const {palettes, actualAction} = useSelector(
        (state) => ({
            palettes: state.reducerAdmin.palettes,
            actualAction: state.reducerAdmin.actualAction,
            filterUser: state.reducerAdmin.filterUser
        }),
        shallowEqual
    )
    let {background} = {
        ...palettes
    }
    const modalRef = useRef(null)

    const hide = () => {
        setOpen(false);
        setSelectedType(null);
    }

    const {id} = useParams();
    const {t} = useTranslation()

    const history = useHistory();

    const deleteUser = () => {
        if (id && selectedType) {
            setSaving(true);
            apiDeleteUser(actualAction.actualAction, selectedType, id, (data) => {
                CogoToast.success(t("accreditation.delete"));
                setSaving(false);
                history.push("/accreditation");
            }, (error) => {
                CogoToast.error(t("accreditation.deleteError"));
                setSaving(false);
            })
        } else {
            CogoToast.error("")
        }
    }

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (!modalRef || !modalRef.current) return false
            if (!open || modalRef.current.contains(event.target)) {
                return false
            }
            setOpen(!open);
            setSelectedType(null);
        }
        document.addEventListener('mousedown', handleClickOutside)

        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [open, modalRef])

    return (
        <>
            {open && id && (
                <Portal selector="#portal">
                    <div className="modal-backdrop fade-in"></div>
                    <div className={`modal show ${background === 'dark' ? 'dark-mode' : ''}`}
                         data-background={background}>
                        <div className="relative min-w-sm w-1/3 mx-auto lg:max-w-5xl" ref={modalRef}>
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h3 className="text-xl font-semibold">{"Odstranění akreditace"}</h3>
                                    <button
                                        className="modal-close btn btn-transparent"
                                        onClick={hide}>
                                        <FiX size={18} className="stroke-current"/>
                                    </button>
                                </div>
                                <div className={"max-h-sm overflow-y-scroll p-2 pb-10"}>
                                <Form onSubmit={() => {
                                }} render={() => (
                                    <div className={"m-2"}>
                                        <EnigooSelect inModal={true}
                                            onValueChange={(event) => setSelectedType(event ? event.value : null)}
                                            items={[{"id": 1, "name": t("accreditation.deleteFromAction")}, {
                                                "id": 2,
                                                "name": t("accreditation.deleteFromSystem")
                                            }]}
                                            name={"type"} label={"Typ: *"} isClearable={false}/>
                                    </div>
                                )}/>
                                </div>
                                <div className={"text-xl text-center"}>
                                    {!selectedType && t("basics.selectType")}
                                    {selectedType && <Trans i18nKey={"accreditation.deleteQuestion"} values={{
                                        id: id,
                                        type: selectedType === 1 ? t("accreditation.fromAction") : t("accreditation.fromSystem")
                                    }}>
                                    </Trans>}
                                </div>

                                <div className="modal-footer space-x-2">
                                    <EnigooButton disabled={saving} loading={saving} color={"red"} onClick={hide}
                                                  text={t("basics.no")}
                                                  icon={<FiX/>}/>
                                    <EnigooButton disabled={saving} loading={saving} onClick={() => deleteUser()}
                                                  text={t("basics.yes")} icon={<FiCheck/>}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </Portal>
            )}
        </>
    )
}
