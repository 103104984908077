import React from 'react';
import {Route, Switch} from 'react-router-dom';

import Dashboard from "./pages/Dashboard/Dashboard";
import AdminUser from "./pages/AdminUser/AdminUser";
import AdminUserForm from "./pages/AdminUser/AdminUserForm";

import CustomField from "./pages/CustomField/CustomField";
import CustomFieldForm from "./pages/CustomField/CustomFieldForm";

import Action from "./pages/Action/Action";
import ActionForm from "./pages/Action/Form/ActionForm";

import Groups from "./pages/Groups/Groups";
import GroupsForm from "./pages/Groups/GroupsForm";
import Template from "./pages/Template/Template";
import TemplateForm from "./pages/Template/TemplateForm";
import MailTemplateForm from "./pages/MailTemplate/MailTemplateForm";
import Zone from "./pages/Zone/Zone";
import ZoneForm from "./pages/Zone/ZoneForm";
import WebChannel from "./pages/WebChannel/WebChannel";
import WebChannelForm from "./pages/WebChannel/Form/WebChannelForm";

import User from "./pages/User/User";
import UserForm from "./pages/User/Form/UserForm";
import SubjectForm from "./pages/Subject/SubjectForm";
import ErrorPage from "../Error/ErrorPage";
import Image from "./pages/Image/Image";
import UploadImage from "./pages/Image/UploadImage";
import DeviceSettings from "./pages/Device/DeviceSettings";
import Device from "./pages/Device/Device";
import DeviceTemplate from "./pages/DeviceTemplate/DeviceTemplate";
import DeviceTemplateForm from "./pages/DeviceTemplate/DeviceTemplateForm";
import DeviceForm from "./pages/Device/DeviceForm";
import UserImport from "./pages/UserImport/UserImport";
import UserProfile from "./pages/AdminUser/UserProfile";
import VerifyRights from "./VerifyRights";
import DeviceReport from "./pages/Report/DeviceReport";
import EntranceReport from "./pages/Report/EntranceReport";
import ImageForm from "./pages/Image/ImageForm";
import UserCheck from "./pages/UserCheck/UserCheck";
import ActionExtension from "./pages/ActionExtension/ActionExtension";
import ActionExtensionReport from "./pages/ActionExtension/Report/ActionExtensionReport";
import ActionExtensionItem from "./pages/ActionExtension/ActionExtensionItem";
import UserExtension from "./pages/ActionExtension/UserExtension/UserExtension";
import ActionExtensionDetail from "./pages/ActionExtension/ActionExtensionDetail";
import DashBoardv2 from "./pages/Dashboard/DashBoardv2";

const Routes = () => {
    return (
        <Switch>
            {/*Dashboard*/}
                <Route exact path={"/"}><VerifyRights page={"dashboard"} action={"getDashboardData"} type={"route"} onNoRights={<Dashboard/>}><DashBoardv2/></VerifyRights> </Route>

            {/*Accreditation*/}
            <Route exact path={"/accreditation"}><VerifyRights page={"user"} action={"getUser"}
                                                               type={"route"}><User/></VerifyRights></Route>
            <Route exact path={"/accreditation/create"}><VerifyRights page={"user"} action={"createUser"}
                                                                      type={"route"}><UserForm/></VerifyRights></Route>
            <Route exact path={"/accreditation/import"}><VerifyRights page={"userImport"} action={"importUser"}
                                                                      type={"route"}><UserImport/></VerifyRights></Route>
            <Route exact path={"/accreditation/detail/:id"}><VerifyRights page={"user"} action={"getUser"}
                                                                          type={"route"}><UserForm/></VerifyRights>
            </Route>

            <Route exact path={"/accreditation/check"}><VerifyRights page={"user"} action={"checkUser"}
                                                                     type={"route"}><UserCheck/></VerifyRights>
            </Route>
            <Route exact path={"/accreditation/check/:bc"}><VerifyRights page={"user"} action={"checkUser"}
                                                                         type={"route"}><UserForm/></VerifyRights>
            </Route>

            {/*Action*/}
            <Route exact path={"/action"}><VerifyRights page={"action"} action={"getAction"}
                                                        type={"route"}><Action/></VerifyRights></Route>
            <Route exact path={"/action/create"}><VerifyRights page={"action"} action={"createAction"}
                                                               type={"route"}><ActionForm/></VerifyRights></Route>
            <Route exact path={"/action/detail/:id"}><VerifyRights page={"action"} action={"getAction"}
                                                                   type={"route"}><ActionForm/></VerifyRights></Route>


            {/*ActionExtension*/}
            <Route exact path={"/action-extension"}><VerifyRights page={"actionExtension"} action={"getActionExtension"}
                                                                  type={"route"} extension={[1,2]}><ActionExtension/></VerifyRights></Route>
            <Route exact path={"/action-extension/meal"}><VerifyRights page={"actionExtension"}
                                                                       action={"getActionExtension"}
                                                                       type={"route"} extension={[1]}><ActionExtensionItem type={1}/></VerifyRights></Route>
                <Route exact path={"/action-extension/parking"}><VerifyRights page={"actionExtension"}
                                                                           action={"getActionExtension"}
                                                                           type={"route"} extension={[2]}><ActionExtensionItem type={2}/></VerifyRights></Route>
            <Route exact path={"/action-extension/report"}><VerifyRights page={"actionExtension"}
                                                                       action={"getActionExtensionReport"}
                                                                       type={"route"} extension={[1,2]}><ActionExtensionReport/></VerifyRights></Route>
            <Route exact path={"/action-extension/meal/detail/:actionId"}><VerifyRights page={"actionExtension"}
                                                                                        action={"getActionExtension"}
                                                                                        type={"route"} extension={[1]}><ActionExtensionDetail type={1}/></VerifyRights></Route>
                <Route exact path={"/action-extension/parking/detail/:actionId"}><VerifyRights page={"actionExtension"}
                                                                                            action={"getActionExtension"}
                                                                                            type={"route"} extension={[2]}><ActionExtensionDetail type={2}/></VerifyRights></Route>
            <Route exact path={"/action-extension/meal/:actionId/users"}><VerifyRights page={"actionExtension"}
                                                                                       action={"getActionExtensionUsers"}
                                                                                       type={"route"} extension={[1]}><UserExtension type={1}/></VerifyRights></Route>
                <Route exact path={"/action-extension/parking/:actionId/users"}><VerifyRights page={"actionExtension"} extension={[2]}
                                                                                           action={"getActionExtensionUsers"}
                                                                                           type={"route"}><UserExtension type={2}/></VerifyRights></Route>
            {/*Gallery*/}
            <Route exact path={"/gallery"}><VerifyRights page={"image"} action={"getImage"}
                                                         type={"route"}><Image/></VerifyRights> </Route>
            <Route exact path={"/gallery/add"}><VerifyRights page={"image"}
                                                             action={"createImage"}><UploadImage/></VerifyRights></Route>
            <Route exact path={"/gallery/detail/:id"}><VerifyRights page={"image"}
                                                                    action={"getImage"}><ImageForm/></VerifyRights></Route>

            {/*GROUPS*/}
            <Route exact path={"/groups"}><VerifyRights page={"groups"} action={"getGroups"}
                                                        type={"route"}><Groups/></VerifyRights></Route>
            <Route exact path={"/groups/create"}><VerifyRights page={"groups"} action={"createGroups"}
                                                               type={"route"}><GroupsForm/></VerifyRights></Route>
            <Route exact path={"/groups/detail/:id"}><VerifyRights page={"groups"} action={"getGroups"}
                                                                   type={"route"}><GroupsForm/></VerifyRights></Route>
            {/*ZONES*/}
            <Route exact path={"/zone"}><VerifyRights page={"zone"} action={"getZone"}
                                                      type={"route"}><Zone/></VerifyRights></Route>
            <Route exact path={"/zone/create"}><VerifyRights page={"zone"} action={"createZone"}
                                                             type={"route"}><ZoneForm/></VerifyRights> </Route>
            <Route exact path={"/zone/detail/:id"}><VerifyRights page={"zone"} action={"getZone"}
                                                                 type={"route"}><ZoneForm/></VerifyRights></Route>
            {/*CustomFields*/}
            <Route exact path={"/custom-field"}><VerifyRights page={"customField"} action={"getCustomField"}
                                                              type={"route"}><CustomField/></VerifyRights></Route>
            <Route exact path={"/custom-field/create"}><VerifyRights page={"customField"} action={"createCustomField"}
                                                                     type={"route"}><CustomFieldForm/></VerifyRights></Route>
            <Route exact path={"/custom-field/detail/:id"}><VerifyRights page={"customField"} action={"getCustomField"}
                                                                         type={"route"}><CustomFieldForm/></VerifyRights></Route>

            {/*Device*/}
            <Route exact path={"/device/settings"}><VerifyRights page={"device"} action={"getDeviceKey"} type={"route"}><DeviceSettings/></VerifyRights></Route>
            <Route exact path={"/device"} component={Device}><VerifyRights page={"device"} action={"getDevice"}
                                                                           type={"route"}><Device/></VerifyRights></Route>
            <Route exact path={"/device/detail/:id"}><VerifyRights page={"device"} action={"getDevice"}
                                                                   type={"route"}><DeviceForm/></VerifyRights> </Route>

            <Route exact path={"/device/rules"}><VerifyRights page={"deviceTemplate"} action={"getDeviceTemplate"}
                                                              type={"route"}><DeviceTemplate/></VerifyRights></Route>
            <Route exact path={"/device/rules/create"}><VerifyRights page={"deviceTemplate"}
                                                                     action={"createDeviceTemplate"}
                                                                     type={"route"}><DeviceTemplateForm/></VerifyRights></Route>
            <Route exact path={"/device/rules/detail/:id"}><VerifyRights page={"deviceTemplate"}
                                                                         action={"getDeviceTemplate"}><DeviceTemplateForm/></VerifyRights></Route>
            <Route exact path={"/device/report"}><VerifyRights page={"report"} action={"getReport"}
                                                               type={"route"}><DeviceReport/></VerifyRights></Route>

            <Route exact path={"/device/report/entrance"}><VerifyRights page={"report"} action={"getReport"}
                                                                        type={"route"}><EntranceReport/></VerifyRights></Route>

            {/*Settings*/}
            <Route exact path={"/admin-user"}><VerifyRights page={"adminUser"} action={"getAdminUser"}
                                                            type={"route"}><AdminUser/></VerifyRights></Route>
            <Route exact path={"/admin-user/create"}><VerifyRights page={"adminUser"} action={"createAdminUser"}
                                                                   type={"route"}><AdminUserForm/></VerifyRights></Route>
            <Route exact path={"/admin-user/detail/:id"}><VerifyRights page={"adminUser"} action={"getAdminUser"}
                                                                       type={"route"}><AdminUserForm/></VerifyRights>
            </Route>

            <Route exact path={"/user-profile"}><VerifyRights page={"adminUser"} action={"getActualAdminUser"}
                                                              type={"route"}><UserProfile/></VerifyRights></Route>


            <Route exact path={"/template"}><VerifyRights page={["template", "mailTemplate"]}
                                                          action={["getTemplate", "getMailTemplate"]}
                                                          type={"route"}><Template/></VerifyRights> </Route>
            <Route exact path={"/template/create"}><VerifyRights page={"template"} action={"createTemplate"}
                                                                 type={"route"}><TemplateForm/></VerifyRights> </Route>
            <Route exact path={"/template/detail/:id"}><VerifyRights page={"template"} action={"getTemplate"}
                                                                     type={"route"}><TemplateForm/></VerifyRights>
            </Route>

            <Route exact path={"/mail-template/create"}><VerifyRights page={"mailTemplate"}
                                                                      action={"createMailTemplate"}
                                                                      type={"route"}><MailTemplateForm/></VerifyRights>
            </Route>
            <Route exact path={"/mail-template/detail/:id"}><VerifyRights page={"mailTemplate"}
                                                                          action={"getMailTemplate"}
                                                                          type={"route"}><MailTemplateForm/></VerifyRights>
            </Route>

            <Route exact path={"/web-channel"}><VerifyRights page={"webChannel"} action={"getWebChannel"}
                                                             type={"route"}><WebChannel/></VerifyRights></Route>
            <Route exact path={"/web-channel/create"}><VerifyRights page={"webChannel"} action={"createWebChannel"}
                                                                    type={"route"}><WebChannelForm/></VerifyRights>
            </Route>
            <Route exact path={"/web-channel/detail/:id"}><VerifyRights page={"webChannel"} action={"getWebChannel"}
                                                                        type={"route"}><WebChannelForm/></VerifyRights></Route>

            <Route exact path={"/subject"}><VerifyRights page={"subject"} action={"getSubject"}
                                                         type={"route"}><SubjectForm/></VerifyRights></Route>

            <Route path={"*"} component={ErrorPage}/>


        </Switch>
    )
}
export default Routes
