import React, {useEffect, useRef} from "react";
import PropTypes from "prop-types";
import Portal from "../../components/portal";
import {FiX} from "react-icons/fi";
import EnigooButton from "../EnigooButton";
import {FiSave} from "react-icons/all";
import {shallowEqual, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";

const EnigooModal = ({
                         type = "button",
                         onSave,
                         onClose,
                         onOpen,
                         open,
                         disabled = false,
                         buttonIcon,
                         buttonText,
                         title,
                         children,
                         width = "w-2/3",
                         toolTip = null,
                    buttonColor="enigoo",
    customButton,
    disabledSaveButton=false,
    buttonExtraStyle ="",
    buttonTextColor="white",
    saveText=null,
    cancelText=null,
    saveIcon = <FiSave/>
                     }) => {
    const modalRef = useRef(null)

    const {palettes} = useSelector(
        (state) => ({
            palettes: state.reducerAdmin.palettes
        }),
        shallowEqual
    )

    const {t} = useTranslation();

    let {background} = {
        ...palettes
    }

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (!modalRef || !modalRef.current) return false
            if (!open || modalRef.current.contains(event.target)) {
                return false
            }
            onClose();
        }
        document.addEventListener('mousedown', handleClickOutside)

        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [open, modalRef])

    const hide = () => {
        onClose();
    }

    const _renderButton = () => {
        switch (type) {
            case "button":
                return <EnigooButton toolTip={toolTip} disabled={disabled} onClick={onOpen} color={buttonColor}
                                     text={buttonText}
                                     icon={buttonIcon} extraStyle={buttonExtraStyle} textColor={buttonTextColor}/>
            case "dropdown":
                return <div
                    className="items-center flex flex-wrap justify-center h-8 space-y-1 dropdown-item">
                    <button disabled={disabled} onClick={onOpen}
                            className="btn text-xs btn-icon w-full btn-rounded h-full hover:bg-enigoo-500 hover:text-white">
                        {buttonIcon && <span className={"mr-2"}>{buttonIcon}</span>} {buttonText}
                    </button>
                </div>
        }
    }

    return (
        <>
            {_renderButton()}
            {open && (
                <Portal selector="#portal">
                    <div className="modal-backdrop fade-in z-10"></div>
                    <div className={`modal show ${background === 'dark' ? 'dark-mode' : ''}`}
                         data-background={background}>
                        <div className={`relative min-w-sm  ${width} mx-auto lg:max-w-5xl`}>
                            <div className="modal-content" ref={modalRef}>
                                <div className="modal-header">
                                    <h3 className="text-xl font-semibold">{title}</h3>
                                    <button
                                        className="modal-close btn btn-transparent"
                                        onClick={hide}>
                                        <FiX size={18} className="stroke-current"/>
                                    </button>
                                </div>
                                <div className={"max-h-sm overflow-y-scroll p-2 pb-10"}>{children}</div>
                                <div className="modal-footer space-x-2">
                                    {customButton?customButton:<></>}
                                    <EnigooButton onClick={hide} text={cancelText?cancelText:t("basics.cancel")}
                                                  icon={<FiX/>}/>
                                    <EnigooButton disabled={disabledSaveButton} onClick={() => {
                                        onSave()
                                    }} text={saveText?saveText:t("basics.save")} icon={saveIcon}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </Portal>
            )}
        </>
    )
}
EnigooModal.propTypes = {
    type: PropTypes.oneOf(["button", "dropdown"]),
    onSave: PropTypes.func,
    onClose: PropTypes.func,
    disabled: PropTypes.bool,
    buttonIcon: PropTypes.any,
    buttonText: PropTypes.string,
    title: PropTypes.string.isRequired,
    children: PropTypes.any.isRequired,
    width: PropTypes.oneOf(["w-1/2", "w-2/3", "w-full", "w-1/3"]),
    buttonColor:PropTypes.string,
    customButton:PropTypes.any,
    disabledSaveButton:PropTypes.bool,
    buttonExtraStyle:PropTypes.string,
    buttonTextColor:PropTypes.string
}
export default EnigooModal;
