import {axios} from "../../../axiosConfig";

const url = "api/admin/v1/mail-template";

export function apiGetMailTemplate(callback, error){
    axios.get(url)
        .then(response=>callback(response.data.data))
        .catch(err=>error(err));
}

export function apiGetMailTemplateById(id, callback, error){
    axios.get(url+"/"+id)
        .then(response=>callback(response.data.data))
        .catch(err=>error(err));
}

export function apiUpdateMailTemplate(id, data, callback, error){
    axios.put(url+"/"+id,data)
        .then(response=>callback(response.data.data))
        .catch(err=>error(err));
}

export function apiCreateMailTemplate(data, callback, error){
    axios.post(url,data)
        .then(response=>callback(response.data.data))
        .catch(err=>error(err));
}

export function apiTestMailTemplate(data,callback,error){
    axios.post(url+"/test",data)
        .then(response=>callback(response.data.data))
        .catch(err=>error(err));
}