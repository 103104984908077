import React from 'react'
import {FiMenu} from 'react-icons/fi'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {Link} from 'react-router-dom'
import {useTranslation} from "react-i18next";
import {RiMenuFoldFill, RiMenuUnfoldFill} from "react-icons/all";

const Logo = () => {
    const dispatch = useDispatch()
    const {config, leftSidebar} = useSelector(
        state => ({
            config: state.reducerAdmin.config,
            leftSidebar: state.reducerAdmin.leftSidebar
        }),
        shallowEqual
    )
    const {name, collapsed} = {...config}
    const {showLogo} = {...leftSidebar}
    const {t} = useTranslation()
    if (showLogo) {
        return (
            <div className="logo truncate">
                <Link
                    to="/"
                    className="flex flex-row items-center justify-start space-x-2">
                    <img src='/logo-E.png' alt={""} width={"30"}/>
                    <span className={"ml-auto"}>{t("basics.accreditation")}</span>
                </Link>
                {collapsed?
                <button
                    onClick={() =>
                        dispatch({
                            type: 'SET_CONFIG_KEY',
                            key: 'collapsed',
                            value: !collapsed
                        })
                    }
                    className="ml-auto mr-4 block">
                    <RiMenuFoldFill size={20}/>
                </button>:<></>}
            </div>
        )
    }
    return null
}

export default Logo
