import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {apiUserAssignZones} from "../Actions";
import CogoToast from "cogo-toast";
import EnigooModal from "../../../../MyComponent/Modal/EnigooModal";
import Widget from "../../../../components/widget";
import {Form} from "react-final-form";
import {FiCircle} from "react-icons/fi";
import {ZoneSelectField} from "../../../../MyComponent/Api/Admin/ZoneSelectField";

const UserAssignZoneModal = ({
                                 selectedRows, actionId,
                                 selectAll
                             }) => {
    const {t} = useTranslation();
    let [saving, setSaving] = useState(false);
    let [loading, setLoading] = useState(false);
    let [open, setOpen] = useState(false);
    const dispatch = useDispatch();

    let submit = null;

    const {actualAction, filterUser} = useSelector(
        (state) => ({
            palettes: state.reducerAdmin.palettes,
            actualAction: state.reducerAdmin.actualAction,
            filterUser: state.reducerAdmin.filterUser
        }),
        shallowEqual
    )
    let {filterData} = {
        ...filterUser
    }

    const onSave = (values) => {
        setSaving(true);
        let data = {};
        data.userId = selectedRows;
        data.actualAction = actualAction.actualAction;
        data.actionId = actionId;
        data.filterData = filterData;
        data.all = selectAll;
        data.zones = values.zones ? values.zones : [];
        console.log(data);
        apiUserAssignZones(data, (data) => {
            CogoToast.success(t("accreditation.assignDaysSuccess"));
            onClose();
            setSaving(false);
            dispatch({
                type: 'SET_RELOAD_ACCREDITATION',
                reloadUserData: true
            })
        }, (err) => {
            CogoToast.error(t("accreditation.assignDaysError"));
            setSaving(false);
        })
    }

    const onClose = () => {
        setOpen(false);
    }
    const onOpen = () => {
        setOpen(true);
    }

    return (
        <EnigooModal open={open} onOpen={onOpen} onClose={onClose} buttonText={t("accreditation.assignZones")}
                     title={t("accreditation.assignZones")}
                     disabled={!selectedRows || selectedRows.length === 0 || loading || saving}
                     buttonIcon={<FiCircle/>} type={"dropdown"}
                     onSave={() => submit()}>
            <Widget>
                <div className="flex flex-col">
                    <Form onSubmit={onSave}
                          render={({handleSubmit}) => {
                              submit = handleSubmit;
                              return (
                                  <>
                                      <ZoneSelectField inModal={true} isMulti={true} selectedAction={actionId} name={"zones"}
                                                       label={t("zones.plural") + ": *"}/>
                                  </>
                              );
                          }}
                    />
                </div>

            </Widget>
        </EnigooModal>
    )
}
UserAssignZoneModal.propTypes = {}
export default UserAssignZoneModal;
