import React from "react";
import {EnigooTextField} from "../../../MyComponent/EnigooTextField";
import {useTranslation} from "react-i18next";
import {shallowEqual, useSelector} from "react-redux";
import {FiCopy} from "react-icons/fi";

const DeviceTemplateCustomRuleValuesAssignField = ({fields, name, index}) => {
    const {t} = useTranslation()

    const {palettes} = useSelector(
        (state) => ({
            palettes: state.reducerAdmin.palettes
        }),
        shallowEqual
    )

    const {background, leftSidebar, navbar} = palettes;

    let isDark = React.useMemo(() => {
        return background === "dark" && leftSidebar === "dark" && navbar === "dark";
    }, [background, leftSidebar, navbar])
    return (
        <div className={`text-sm form-input ${isDark ? "bg-grey-800" : ""}`}>
            <div className={"grid grid-cols-2"}>
                <div className={"form-label"}>{t("deviceTemplate.customRule")} #{index + 1}:</div>
                <div className={"flex flex-row space-x-2 justify-end w-full"}>
                    <div
                        className={"text-right"}
                        style={{cursor: "pointer"}}>
                        <div onClick={() => {
                            if (fields.value && fields.value[index]) {
                                fields.push(fields.value[index])
                            }
                        }}><FiCopy size={15}/>
                        </div>
                    </div>
                    <div
                        className={"text-right"}
                        style={{cursor: "pointer"}}>
                        <div onClick={() => {
                            fields.remove(index)
                        }}> ❌
                        </div>
                    </div>
                </div>
            </div>
            <div className={"grid grid-cols-3 text-sm mt-5 gap-2"}>
                <EnigooTextField placeHolder={t("placeHolder.text")} name={`${name}.system`}
                                 label={t("deviceTemplate.systemValue") + ": "}/>
                <EnigooTextField placeHolder={t("placeHolder.text")} name={`${name}.check`}
                                 label={t("deviceTemplate.valueToCheck") + ": *"}/>
                <EnigooTextField placeHolder={t("placeHolder.number")} name={`${name}.entrances`}
                                 label={t("deviceTemplate.numOfEntrance") + ": "} type={"number"}/>
            </div>
        </div>
    )
}
export default DeviceTemplateCustomRuleValuesAssignField;
