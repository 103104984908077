import {AccreditationLineChart} from "./AccreditationLineChart";
import React, {useEffect, useState} from "react";
import Dropdown from "../../../components/dropdowns/dropdown-1";
import {useTranslation} from "react-i18next";


const AccreditationLineChartByRegisterType = ({data}) => {
    let [actualData,setActualData] = useState([]);
    let [selectedAction, setSelectedAction] = useState(null);
    let [actions, setActions] = useState([]);

    const {t, i18n} = useTranslation();


    useEffect(()=>{
        setSelectedAction(0);
        let newActions = [];
        if(data){
            data.forEach((item) => {
                newActions.push({
                    "id":item.actionId,
                    "name":item.actionId===0?t("action.allActions"):item.actionName
                })
            })
        }
        setActions(newActions);
    },[data,i18n.language])

    useEffect(() => {
        if(selectedAction!==null){
            data.forEach((item) => {
                if(item.actionId===selectedAction){
                    setActualData(item.data);
                }
            })
        }
    }, [selectedAction,actions]);

    const getActualActionName = ()=>{
        let name = "";
        actions.forEach((item) => {
            if(item.id === selectedAction){
                name = item.name;
            }
        })
        return name;
    }

    const changeAction = (action)=>{
        setSelectedAction(action.id);
    }

    const ActionDropDown = ()=>{
        return (
            <div className="flex flex-row">
                <div className={"min-w-xxs"}>
                    <Dropdown
                        width="w-64"
                        placement={`bottom-start`}
                        title={getActualActionName()}
                        dropdownToggleClass={`w-full btn btn-default border btn-rounded bg-white text-enigoo-500 border-enigoo-500 btn-icon hover:bg-enigoo-600 bg-enigoo-500 hover:text-white space-x-1 mt-1 mr`}>
                        <>
                            <div className="flex flex-col text-center">
                                {actions.map((item, i) => (
                                    <div
                                        key={i}
                                        className="items-center flex flex-wrap justify-center h-8 space-y-1 dropdown-item">
                                        <button onClick={() => {
                                            changeAction(item);
                                        }}
                                                className="btn text-xs w-full btn-rounded h-full hover:bg-enigoo-500 hover:text-white">{item.name}</button>
                                    </div>
                                ))}
                            </div>
                        </>
                    </Dropdown>
                </div>
            </div>
        )
    }
    return (
        <>
            <ActionDropDown/>
            <AccreditationLineChart data={actualData ? actualData : []}/>
        </>

    )
}

export default AccreditationLineChartByRegisterType;
