import React, {useState} from 'react'
import {FiLogOut, FiMenu} from 'react-icons/fi'
import '../../css/components/navbar.css'
import {apiManageLogoutUser} from "../../Manage/pages/Login/Actions";
import {axios} from "../../axiosConfig";
import CogoToast from "cogo-toast";
import {useParams} from "react-router";
import {useHistory} from "react-router-dom";
import {shallowEqual, useDispatch, useSelector} from "react-redux";

const Navbar = () => {
    const {config} = useSelector(
        state => ({
            config: state.reducerAdmin.config
        }),
        shallowEqual
    )
    let [saving, setSaving] = useState(false);

    const history = useHistory();

    const {id} = useParams();
    let {rightSidebar, collapsed} = {...config}
    const dispatch = useDispatch()


    const logout = () => {
        setSaving(true);
        apiManageLogoutUser((data) => {
            axios.defaults.headers.common['authorization-manage'] = null;
            localStorage.removeItem("ath-acc-en-mn");
            CogoToast.success("Proběhlo úspěšné odhlášení.");
            setSaving(false);
            history.push("/manage/");
            window.location.reload();
        }, (error) => {
            CogoToast.error("Nepodařilo se vás odhlásit.");
            setSaving(false);
        });
    }

    let token = localStorage.getItem("ath-acc-en-mn")

    return (
        <div className="navbar navbar-1 border-b">
            <div className="navbar-inner w-full flex items-center justify-start">
                <button
                    onClick={() =>
                        dispatch({
                            type: 'SET_CONFIG_KEY',
                            key: 'collapsed',
                            value: !collapsed
                        })
                    }
                    className="mx-4 h-16">
                    <FiMenu size={20} />
                </button>
                <span className="ml-auto"></span>
                {saving?<span className={"text-sm"}>Odhlašuji...</span>:<></>}
                {token?
                <button
                    className="btn-icon flex items-center justify-center h-16 w-24 mx-4"
                    onClick={logout}>
                    <FiLogOut size={18} />Odhlásit se
                </button>:<></>}
            </div>
        </div>
    )
}

export default Navbar
