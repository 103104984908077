import PropTypes from "prop-types";
import React from "react";
import {Field} from "react-final-form";
import Select from "react-select";
import {FiChevronUp, FiHelpCircle} from "react-icons/fi";
import {shallowEqual, useSelector} from "react-redux";
import EnigooToolTip from "./EnigooToolTip";

export const EnigooSelect = ({
                                 inline = false,
                                 label,
                                 items = [],
                                 name,
                                 outsideClassName = "w-full",
                                 defaultValue = ["id", "name"],
                                 placeHolder = "Vyberte hodnotu...",
                                 disabled = false,
                                 onValueChange = null,
                                 required = false,
                                 searchable = false,
                                 isClearable = true,
                                 grouped = false,
                                 loading = false,
                                 initValue = null,
                                 toolTip = null,
                                 onKeyDown = null,
                                inModal = false,
                                 toolTipPosition="right"
                             }) => {
    const parseOptions = () => {
        if (grouped) return items;
        let array = [];
        if (items) {
            items.map(item => {
                array.push({
                    value: item[defaultValue[0]],
                    label: item[defaultValue[1]],
                    isDisabled: item.disabled
                })
            })
        }
        return array;
    }
    const {palettes} = useSelector(
        (state) => ({
            palettes: state.reducerAdmin.palettes
        }),
        shallowEqual
    )

    let zIndex = React.useMemo(()=>{
        if(inModal){
            return 100;
        }else{
            return 0;
        }
    },[inModal])

    const {background,leftSidebar,navbar} = palettes;

    let isDark = React.useMemo(()=>{
        return background==="dark" && leftSidebar==="dark" && navbar==="dark";
    },[background,leftSidebar,navbar])
    let error;
    const parse = (value) => {
        return value ? value.value : null;
    }

    const parseInitValue = (value) => {
        if (loading || !value) return [];
        let array = [];
        let options = parseOptions();
        if (grouped) {
            options.forEach(optValue => {
                optValue.options.forEach(val => {
                    if (val.value === value) {
                        array.push(val);
                        return array;
                    }
                })
            })
        } else {
            options.forEach(optValue => {
                if (optValue.value === value) {
                    array.push(optValue);
                    return array;
                }
            })
        }
        return array;
    }

    const getDisabled = () => {
        if (disabled) return true;
        else return loading;
    }

    const _getStyles = (error) => {
        const _border = (state) => {
            if(error){
                return "1px solid #f44336"
            }
            if (state.isFocused) {
                return "1px solid #63438e";
            } else if (error) {
                return '1px solid #ef4444';
            } else {
                return "";
            }
        }
        return {
            valueContainer:(provided,state)=>({
               ...provided,
               zIndex:zIndex
            }),
            container:(provided,state)=>({
                ...provided,
                zIndex:zIndex
            }),
            menu:(provided,state)=>({
                ...provided,
                zIndex:zIndex
            }),
            menuList:(provided,state)=>({
                ...provided,
                zIndex:zIndex
            }),
            menuPortal:(base)=>({
                ...base,
                zIndex:zIndex
            }),
            placeholder:(provided,state)=>({
                ...provided,
                zIndex:zIndex
            }),
            control: (provided, state) => ({
                ...provided,
                zIndex:zIndex,
                backgroundColor: isDark?"#424242":"",
                border: _border(state),
                ":hover": {
                    border: state.isFocused ? "1px solid #63438e" : ""
                },
                boxShadow: 'none',
            }),
            option: (provided, state) => ({
                ...provided,
                zIndex:zIndex,
                backgroundColor: state.isSelected
                    ? "#725599"
                    : state.isFocused
                        ? "#b0a1c6"
                        : isDark
                            ? "#424242"
                            : "",
                ":hover": {
                    backgroundColor: !state.isSelected?"#b0a1c6":""
                }
            }),
            components: {

                DropdownIndicator: (props) => (
                    <FiChevronUp {...props} size={20} color={"enigoo-500"}/>
                )
            }
        }
    }


    return (
        <div className={outsideClassName}>
            <div className={`form-element ${inline ? 'form-element-inline' : ''}`}>
                {label !== "" && <div className={"form-label flex flex-row"}>{label}{toolTip && <><FiHelpCircle data-tip data-for={"_tooltip_" + name} key={name}
                                                                                                                className={"ml-2 text-red-900"} size={18}/>
                    <EnigooToolTip content={toolTip} id={"_tooltip_" + name} place={toolTipPosition}/></>}</div>}
                <Field name={name} parse={parse}>
                    {(properties) => {
                        error = properties.meta.error
                        return (
                            <>
                                <Select
                                    menuPortalTarget={document.body}
                                    styles={_getStyles(error)}
                                    value={parseInitValue(properties.input.value)}
                                    placeholder={loading ? "Načítám data..." : placeHolder} name={name}
                                    isLoading={loading}
                                    options={parseOptions()}
                                    isClearable={isClearable}
                                    isSearchable={searchable}
                                    onChange={event => {
                                        properties.input.onChange(event);
                                        if (onValueChange && event) onValueChange(event);
                                        else if (onValueChange) onValueChange(null);
                                    }}
                                    classNamePrefix={"form-select"}
                                    isDisabled={getDisabled()}
                                    required={required}
                                    onKeyDown={onKeyDown}
                                />
                                {properties.meta.error &&
                                    <div className="form-error">{properties.meta.error}</div>}
                            </>
                        )
                    }}
                </Field>
            </div>
        </div>
    )
}

EnigooSelect.propTypes = {
    inline: PropTypes.bool,
    label: PropTypes.string.isRequired,
    items: PropTypes.array,
    name: PropTypes.string.isRequired,
    outsideClassName: PropTypes.string,
    defaultValue: PropTypes.arrayOf(PropTypes.string),
    placeHolder: PropTypes.string,
    disabled: PropTypes.bool,
    initValue: PropTypes.oneOfType([PropTypes.array,PropTypes.number])
}
