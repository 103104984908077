import {Field} from "react-final-form";
import PropTypes from "prop-types";
import React from "react";
import Select from "react-select";
import {FiChevronUp, FiHelpCircle} from "react-icons/fi";
import {shallowEqual, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import EnigooToolTip from "./EnigooToolTip";

export const EnigooSelectMulti = ({
                                      inline = false,
                                      label,
                                      items = [],
                                      name,
                                      outsideClassName = "w-full",
                                      defaultValue = ["id", "name"],
                                      placeHolder = "Vyberte hodnoty...",
                                      disabled = false,
                                      onValueChange = null,
                                      required = false,
                                      grouped = false,
                                      loading = false,
                                      toolTip = null,
                                      withSelectAll = false,
                                      onKeyDown = null,
    inModal = false,
                                      toolTipPosition="right"
                                  }) => {
    const {t} = useTranslation();
    const selectAllOption = {value: '*', label: t("table.selectAll")}

    let zIndex = React.useMemo(()=>{
        if(inModal){
            return 100;
        }else{
            return 0;
        }
    },[inModal])

    const parseOptions = () => {
        if (grouped) return items;
        let array = [];
        if (items) {
            items.map(item => {
                array.push({
                    value: item[defaultValue[0]],
                    label: item[defaultValue[1]]
                })
            })
        }
        return array;
    }

    const handleChange = (event, properties) => {
        if (event) {
            let isAll = false;
            event.map(item => {
                if (item.value === "*") {
                    isAll = true;
                }
            })
            if (isAll) {
                event = parseOptions();
            }
        }
        properties.input.onChange(event);
        if (onValueChange && event) onValueChange(event);
        else if (onValueChange) onValueChange(null);
    }

    let error;
    const parse = (value) => {
        return value && value.map(item => item.value);
    }
    const {palettes} = useSelector(
        (state) => ({
            palettes: state.reducerAdmin.palettes
        }),
        shallowEqual
    )

    const {background, leftSidebar, navbar} = palettes;

    let isDark = React.useMemo(() => {
        return background === "dark" && leftSidebar === "dark" && navbar === "dark";
    }, [background, leftSidebar, navbar])

    const _getStyles = (error) => {
        const _border = (state) => {
            if (error) {
                return "1px solid #f44336"
            }
            if (state.isFocused) {
                return "1px solid #63438e";
            } else if (error) {
                return '1px solid #ef4444';
            } else {
                return "";
            }
        }
        return {
            valueContainer:(provided,state)=>({
                ...provided,
                zIndex:zIndex
            }),
            container:(provided,state)=>({
                ...provided,
                zIndex:zIndex
            }),
            menu:(provided,state)=>({
                ...provided,
                zIndex:zIndex
            }),
            menuList:(provided,state)=>({
                ...provided,
                zIndex:zIndex
            }),
            menuPortal:(base)=>({
                ...base,
                zIndex:zIndex
            }),
            placeholder:(provided,state)=>({
                ...provided,
                zIndex:zIndex
            }),
            control: (provided, state) => ({
                ...provided,
                zIndex:zIndex,
                backgroundColor: isDark?"#424242":"",
                border: _border(state),
                ":hover": {
                    border: state.isFocused ? "1px solid #63438e" : ""
                },
                boxShadow: 'none',
            }),
            option: (provided, state) => ({
                ...provided,
                zIndex:zIndex,
                backgroundColor: state.isSelected
                    ? "#725599"
                    : state.isFocused
                        ? "#b0a1c6"
                        : isDark
                            ? "#424242"
                            : "",
                ":hover": {
                    backgroundColor: !state.isSelected?"#b0a1c6":""
                }
            }),
            components: {

                DropdownIndicator: (props) => (
                    <FiChevronUp {...props} size={20} color={"enigoo-500"}/>
                )
            },
            multiValue: (provided) => ({
                ...provided,
                backgroundColor: '#725599', // změna barvy pozadí
                color: '#725599',
            }),
            multiValueLabel: (provided) => ({
                ...provided,
                color: 'white', // změna barvy textu
            }),
            multiValueRemove: (provided) => ({
                ...provided,
                color: 'red',
                ':hover': {
                    backgroundColor: '#ef5350', // změna barvy pozadí při hoveru
                    color: '#b71c1c',
                },
            })
        }
    }

    const parseInitValue = (values) => {
        if (!values) return [];
        let array = [];
        let options = parseOptions();
        if (grouped) {
            values.forEach(value => {
                options.forEach(optValue => {
                    optValue.options.forEach(val => {
                        if (val.value === value) {
                            array.push(val);
                        }
                    })
                })
            })
        } else {
            values.forEach(value => {
                options.forEach(optValue => {
                    if (optValue.value === value) {
                        array.push(optValue);
                    }
                })
            })
        }
        return array;
    }

    const getDisabled = () => {
        if (disabled) return true;
        else return loading;
    }

    return (
        <div className={outsideClassName}>
            <div className={`form-element ${inline ? 'form-element-inline' : ''}`}>
                {label !== "" && <div className={"form-label flex flex-row"}>{label}{toolTip && <><FiHelpCircle data-tip data-for={"_tooltip_" + name} key={name}
                                                                                                                className={"ml-2 text-red-900"} size={18}/>
                    <EnigooToolTip content={toolTip} id={"_tooltip_" + name} place={toolTipPosition}/></>}</div>}
                <Field name={name} parse={parse}>
                    {(properties) => {
                        error = properties.meta.error && properties.meta.touched
                        return (
                            <>
                                <Select
                                    menuPortalTarget={document.body}
                                    value={parseInitValue(properties.input.value)}
                                    styles={_getStyles(error)}
                                    placeholder={loading ? "Načítám data..." : placeHolder} name={name}
                                    options={withSelectAll ? [selectAllOption, ...parseOptions()] : parseOptions()}
                                    isClearable={true} isMulti
                                    onChange={event => {
                                        handleChange(event, properties);
                                    }}
                                    isDisabled={getDisabled()}
                                    required={required}
                                    isLoading={loading}
                                    onKeyDown={onKeyDown}
                                />
                                {properties.meta.error && properties.meta.touched &&
                                    <div className="form-error">{properties.meta.error}</div>}
                            </>
                        )
                    }}
                </Field>
            </div>
        </div>
    )
}
EnigooSelectMulti.propTypes = {
    inline: PropTypes.bool,
    label: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.any,
            label: PropTypes.string
        })
    ),
    name: PropTypes.string.isRequired,
    outsideClassName: PropTypes.string,
    placeHolder: PropTypes.string
}
