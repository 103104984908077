import React, {useEffect, useState} from "react";
import {shallowEqual, useSelector} from "react-redux";
import {EnigooSelectMulti} from "../../EnigooSelectMulti";
import {EnigooSelect} from "../../EnigooSelect";
import {apiGetGroup} from "../../../Admin/pages/Groups/Actions";
import {useTranslation} from "react-i18next";

const GroupsSelectField = ({
                               label,
                               name,
                               isMulti = true,
                               outsideClassName = "w-full",
                               onChange = null,
                               filter = false,
                               all = false,
                               toolTip = null,
                               placeHolder = "Vyberte skupinu..",
                               forAction = null,
                               onKeyDown = null,
                               inModal = false
                           }) => {
    let [data, setData] = useState(null);
    let [loading, setLoading] = useState(null);

    const {actualAction} = useSelector(
        state => (state.reducerAdmin.actualAction),
        shallowEqual
    )
    const {t,i18n} = useTranslation()

    useEffect(() => {
        init();
    }, [])

    const init = () => {
        setLoading(true);
        apiGetGroup((data) => {
            setData(data);
            setLoading(false);
        }, (error) => {
            setData([]);
            setLoading(false);
        })
    }

    const getGroupName = (group) => {
        if (group) {
            if (group.displayName) {
                if(group.displayName[i18n.language]){
                    return group.displayName[i18n.language]
                }else if(group.displayName.cs){
                    return group.displayName.cs;
                }
                else{
                    return "-"
                }
            } else {
                return group.name;
            }
        }
    }

    let actualData = React.useMemo(() => {
        setLoading(true);
        let array = [];
        data && data.map((item, index) => {
            if (forAction) {
                if ((item.id === forAction || item.id === 0 || item.id === null)) {
                    let options = [];
                    item.items.map((ite, ind) => {
                        options.push({
                            label: getGroupName(ite),
                            value: ite.id
                        })
                    })
                    array.push({
                        label: item.actionName ? item.actionName : t("groups.notAssign"),
                        options: options
                    });
                }
            } else if (all === true) {
                let options = [];
                item.items.map((ite, ind) => {
                    options.push({
                        label: getGroupName(ite),
                        value: ite.id
                    })
                })
                array.push({
                    label: item.actionName ? item.actionName : t("groups.notAssign"),
                    options: options
                });
            } else if (item.id === actualAction || item.id === null) {
                let options = [];
                item.items.map((ite, ind) => {
                    options.push({
                        label: getGroupName(ite),
                        value: ite.id
                    })
                })
                array.push({
                    label: item.actionName ? item.actionName : t("groups.notAssign"),
                    options: options
                });
            }
        })
        if (filter) {
            array.push({
                label: t("groups.without"),
                options: [{label: t("groups.without"), value: null}]
            })
        }
        setLoading(false);
        return array;
    }, [actualAction, data, forAction,i18n.language])

    return (
        isMulti ?
            <EnigooSelectMulti inModal={inModal} placeHolder={t("placeHolder.groupSelect")} onValueChange={onChange}
                               name={name}
                               label={label} outsideClassName={outsideClassName}
                               disabled={loading} toolTip={toolTip}
                               items={actualData} grouped={true} onKeyDown={onKeyDown}/> :
            <EnigooSelect inModal={inModal} onValueChange={onChange} name={name} label={label}
                          outsideClassName={outsideClassName}
                          disabled={loading} toolTip={toolTip}
                          items={actualData} grouped={true} placeHolder={t("placeHolder.groupSelect")}
                          onKeyDown={onKeyDown}/>
    );
}

export default GroupsSelectField;
