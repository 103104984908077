import {axios} from "../../../axiosConfig";

const url = "api/admin/v1/user-credit"

export function apiGetUserCreditTransaction(userId, callback,error){
    axios.get(url+"/"+userId)
        .then(response=>(callback(response.data.data)))
        .catch(err=>(error(err)))
}

export function apiUpdateCredit(userId,data,callback,error){
    axios.put(url+"/"+userId,data)
        .then(response=>callback(response.data.data))
        .catch(err=>error(err))
}
