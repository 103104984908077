import React, {useEffect, useState} from "react";
import {EnigooSelectMulti} from "../../EnigooSelectMulti";
import {EnigooSelect} from "../../EnigooSelect";
import PropTypes from "prop-types";
import {apiGetTemplate} from "../../../Admin/pages/Template/Actions";

export const MailTemplateSelectField = ({
                                            label,
                                            name,
                                            isMulti = false,
                                            outsideClassName = "w-full",
                                            onValueChange = null,
                                            addNoMail = false,
                                            toolTip = null,
                                            placeHolder = "Vyberte šablonu",
                                            onKeyDown = null
                                        }) => {
    let [data, setData] = useState([]);
    let [loading, setLoading] = useState(false);
    //data z databaze
    useEffect(() => {
        get()
    }, [])

    const get = () => {
        setLoading(true);
        apiGetTemplate((data) => {
            let filteredData = [];
            data[0].items.map((item, index) => {
                filteredData.push(item);
            })
            if (addNoMail) {
                let finalData = [];
                let options = [];
                filteredData.map((item, index) => {
                    options.push({
                        value: item.id,
                        label: item.name
                    })
                })
                finalData.push({
                    label: 'Šablony systému',
                    options: options
                });
                finalData.push({
                    label: "Ostatní",
                    options: [{label: "Žádný odeslaný email", value: -1}]
                });
                setData(finalData)
            } else {
                setData(filteredData);
            }


            setLoading(false);
        }, (error) => {
            setLoading(false);
        })
    }

    return (isMulti ?
        <EnigooSelectMulti placeHolder={placeHolder} toolTip={toolTip} onValueChange={onValueChange}
                           outsideClassName={outsideClassName}
                           label={label}
                           disabled={loading} name={name} items={data} grouped={true} onKeyDown={onKeyDown}/> :
        <EnigooSelect placeHolder={placeHolder} toolTip={toolTip} onValueChange={onValueChange}
                      outsideClassName={outsideClassName} label={label}
                      name={name}
                      disabled={loading} items={data} onKeyDown={onKeyDown}/>);

}
MailTemplateSelectField.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    isMulti: PropTypes.bool,
    type: PropTypes.number,
}
