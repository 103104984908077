import React, {useEffect, useRef, useState} from "react";
import PropTypes from "prop-types";
import EnigooModal from "../../../../MyComponent/Modal/EnigooModal";
import {useTranslation} from "react-i18next";
import {GiKnifeFork} from "react-icons/all";
import {
    apiGetActionExtension,
    apiGetGroupsExtensionByGroupsId,
    apiGetUserExtensionByUserId,
    apiPutAssignGroupsExtension,
    apiPutAssignUserExtension
} from "../Actions";
import CogoToast from "cogo-toast";
import {EnigooLoader} from "../../../../MyComponent/EnigooLoader";
import {useParams} from "react-router-dom";
import MealChooseForm from "../../../../MyComponent/MealChooseForm";

const MealAssignModal = ({
                             usersIds,
                             days,
                             actionId,
                             icon = <GiKnifeFork/>,
                             text = null,
                             type = "user",
                             values = null
                         }) => {

    let [open, setOpen] = useState(false)
    let [data, setData] = useState(null);
    let [loading, setLoading] = useState(false);
    let [saving, setSaving] = useState(false);
    let [formData, setFormData] = useState(null);

    const {t, i18n} = useTranslation();
    let submit = useRef();
    let {id} = useParams();

    useEffect(() => {
        if (open && actionId) {
            setLoading(true);
            apiGetActionExtension(1, actionId, (data) => {
                prepareFormData(data);
            }, (err) => {
                CogoToast.error(t("mealExtension.loadOptionsError"));
                setLoading(false);
                setOpen(false);
            })
        }
    }, [open]);

    const prepareFormData = (data) => {
        setData(data);
        setLoading(false);
        switch (type) {
            case "groups":
                prepareGroupData();
                break;
            case "user":
                prepareUserData();
                break;
            default:
                break;
        }
    }

    const prepareGroupData = () => {
        if (id && type === "groups") {
            setLoading(true);
            apiGetGroupsExtensionByGroupsId(1, actionId, id, (data) => {
                if (data.items) {
                    setFormData({items: data.items});
                }
                setLoading(false);
            }, (err) => {
                setLoading(false);
            })
        } else {

        }
    }

    const prepareUserData = () => {
        if (usersIds.length === 1) {
            setLoading(true);
            apiGetUserExtensionByUserId(actionId, usersIds[0], 1, (data) => {
                if (data.items) {
                    setFormData({items: data.items});
                }
                setLoading(false);
            }, (err) => {
                setLoading(false);
            })
        } else {

        }
    }

    const onClose = () => {
        setOpen(false);
    }

    const onOpen = () => {
        setOpen(true);
    }

    const onSuccessSave = (data) => {
        CogoToast.success(t("mealExtension.assigned"));
        setSaving(false);
        onClose()
    }

    const onErrorSave = (err) => {
        setSaving(false);
        CogoToast.error(t("mealExtension.assignedError"));
    }

    const onSave = (values) => {
        switch (type) {
            case "groups":
                setSaving(true);
                apiPutAssignGroupsExtension(1, actionId, id, {
                    ...values,
                    actionId: actionId
                }, onSuccessSave, onErrorSave);
                break;
            case "user":
                setSaving(true);
                apiPutAssignUserExtension(1, actionId, {
                    ...values,
                    usersIds: usersIds,
                    actionId: actionId
                }, onSuccessSave, onErrorSave);
                break;
        }
    }

    const _renderBody = () => {
        if (open) {
            if (loading) return <EnigooLoader text={t("mealExtension.loadingDetail")}/>
            else if (!data) return <div> NO DATA </div>
            else return <MealChooseForm name={"items"} initData={formData} onSave={onSave} submitRef={submit}
                                        days={days} extensionItems={data.extension.items}/>
        } else {
            return <></>
        }
    }

    if (type === "groups") {
        return <>
            {values && values.assignDefaultMeal &&
                <EnigooModal buttonIcon={icon} onSave={() => submit.current()} disabled={!actionId}
                             buttonText={text !== null ? text : t("basics.detail")}
                             onOpen={onOpen} open={open}
                             onClose={onClose} title={t("mealExtension.assign")}>
                    <div className={"p-2"}>
                        {_renderBody()}
                    </div>
                </EnigooModal>}
        </>
    } else {
        return <EnigooModal buttonIcon={icon} onSave={() => submit.current()}
                            buttonText={text !== null ? text : t("basics.detail")}
                            onOpen={onOpen} open={open}
                            onClose={onClose} disabled={usersIds.length < 1 || !days} title={t("mealExtension.assign")}>
            <div className={"p-2"}>
                {_renderBody()}
            </div>
        </EnigooModal>
    }


}
MealAssignModal.propTypes = {
    usersIds: PropTypes.array.isRequired,
    days: PropTypes.string,
    actionId: PropTypes.number.isRequired,
    type: PropTypes.oneOf(["user", "groups"])
}
export default MealAssignModal;
