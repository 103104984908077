import React, {useEffect, useState} from "react";
import {Field} from "react-final-form";
import {FiCamera, FiFile, FiHelpCircle, FiX} from "react-icons/fi";
import {FiImage, FiSkipBack} from "react-icons/all";
import Webcam from "react-webcam";
import EnigooButton from "./EnigooButton";
import {IconTabs} from "../components/tabs";
import {useFilePicker} from "use-file-picker";
import {ImageModal} from "../Admin/pages/Image/ImageModal";
import {EnigooLoader} from "./EnigooLoader";
import {useTranslation} from "react-i18next";
import EnigooToolTip from "./EnigooToolTip";

const EnigooImageField = ({
                              name,
                              label,
                              width = 220,
                              height = 200,
                              inline = false,
                              outsideClassName = "w-full",
                              initImage = null
                          }) => {
    let [imageWC, setImageWC] = useState('');
    let [keepOriginal, setKeepOriginal] = useState(true);
    let [selectedImage, setSelectedImage] = useState(null);
    let [loadingImage, setLoadingImage] = useState(false);
    const webcamRef = React.useRef(null);
    let screen = null;

    useEffect(() => {
        getImageFromApi();
    }, [])
    const {t} = useTranslation()

    const getImageFromApi = () => {
        if (initImage) {
            setSelectedImage(initImage);
        }

    }

    const tabs = (field) => [
        {
            index: 0,
            content: <><PhotoCapture field={field}/></>,
            title: (<><FiCamera size={18} className={"stroke-current"}/> </>)
        },
        {
            index: 1,
            content: <><UploadPhoto field={field}/></>,
            title: (<><FiFile size={18} className={"stroke-current"}/> </>)
        },
        {
            index: 2,
            content: <><ChoosePhoto field={field}/></>,
            title: (<><FiImage size={18} className={"stroke-current"}/></>)
        }
    ];

    const PhotoCapture = ({field}) => {
        const videoConstraints = {
            width: width,
            height: height,
            facingMode: "user"
        };
        return (
            <>
                <div className={"form-label"}>{t("photo.fromWebcam")}:</div>
                <div className="form-input">
                    <div className={"mb-2"}>
                        {!imageWC ?
                            <Webcam className={"w-full"}
                                    audio={false}
                                    ref={webcamRef}
                                    screenshotFormat="image/png"
                                    videoConstraints={videoConstraints}
                            >
                                {({getScreenshot}) => {
                                    screen = getScreenshot
                                }}
                            </Webcam> : <></>}
                        <div>
                            <>
                                <div className="flex flex-col">
                                    <EnigooButton text={t("photo.take")} icon={<FiCamera size={15}/>}
                                                  onClick={() => {
                                                      const imgSrc = screen();
                                                      setImageWC(imgSrc);
                                                      field.input.onChange({"id": null, "data": imgSrc});
                                                      setSelectedImage({"id": null, "data": imgSrc});
                                                  }}
                                                  key={"2"}
                                    />
                                </div>
                            </>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    const ChoosePhoto = ({field}) => {
        const setImage = (img) => {
            field.input.onChange(img);
            setSelectedImage(img);
            return true;
        }
        return <ImageModal setImage={setImage}/>
    }

    const toolTip = t("photo.chooseToolTip");


    const UploadPhoto = ({field}) => {
        const [openFileSelector, {filesContent, loading, clear}] = useFilePicker({
            accept: ['.png', '.jpg', '.jpeg', '.gif'],
            readAs: 'DataURL',
            multiple: false,
            limitFilesConfig: {max: 1}
        });
        if (filesContent && filesContent[0]) {
            field.input.onChange({"id": null, "data": filesContent[0].content});
            setSelectedImage({"id": null, "data": filesContent[0].content});
        }
        return (
            <>
                <div className={"form-element mr-1"}>
                    <div className="form-label">{t("photo.fromPc")+": "}</div>
                    {filesContent && filesContent[0] ?
                        <EnigooButton onClick={() => clear()}
                                      text={t("basics.cancel")} color={"red"}
                                      icon={<FiX/>}/> :

                        <EnigooButton onClick={() => openFileSelector()}
                                      text={t("photo.chooseImage")}
                                      icon={<FiFile/>}/>}

                </div>
            </>
        )
    }

    const renderPhotoField = (field) => {
        if (initImage && keepOriginal) {
            return (
                <>
                    <div className="form-label">{t("photo.original")}:</div>
                    <div className="border rounded grid place-content-center p-2 mb-1">
                        <img className={"max-h-sm"} src={initImage.data} alt={"img"}/>
                    </div>
                    <EnigooButton text={t("photo.chooseNew")} icon={<FiX size={15}/>}
                                  color={"red"}
                                  onClick={() => {
                                      field.input.onChange(null);
                                      setKeepOriginal(false);
                                      setSelectedImage(null);
                                  }}
                    />
                </>
            );
        } else {
            return (
                <>
                    {loadingImage ? <EnigooLoader text={t("photo.loadingChoosed")}/> :
                        !loadingImage && selectedImage !== null ? <>
                                <div className={"form-label"}>{t("photo.choosedPhoto")}</div>
                                <div className={"border rounded grid place-content-center p-2 mb-1"}><img
                                    className={"max-h-sm"}
                                    src={selectedImage.path?selectedImage.path:selectedImage.data}
                                    alt={"img"}/></div>
                                <EnigooButton text={t("photo.chooseNew")} icon={<FiX size={15}/>}
                                              color={"red"}
                                              onClick={() => {
                                                  field.input.onChange(null);
                                                  setSelectedImage(null);
                                                  setImageWC(null);
                                              }}
                                />
                            </>
                            :
                            <IconTabs tabs={tabs(field)} disableNonActiveTab={true}/>}
                    {initImage && <EnigooButton text={t("photo.keepOriginal")} icon={<FiSkipBack size={15}/>}
                                                onClick={() => {
                                                    field.input.onChange(null);
                                                    setSelectedImage(null);
                                                    setKeepOriginal(true);
                                                    setImageWC(null);
                                                }}
                    />}
                </>
            )
        }
    }

    return (
        <div className={outsideClassName}>
            <div className={`form-element ${inline ? 'form-element-inline' : ''}`}>
                <div className="form-label text-xl flex flex-row">{label}{toolTip && <><FiHelpCircle data-tip data-for={"_tooltip_" + name} key={name}
                                                                                                     className={"ml-2 text-red-900"} size={18}/>
                    <EnigooToolTip content={toolTip} id={"_tooltip_" + name} place={"right"}/></>}</div>

                <Field name={name} component={renderPhotoField}/>

            </div>
        </div>
    )
}
export default EnigooImageField;
