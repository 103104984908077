import {apiExportUser, apiGetUserPrintData} from "../Actions";
import CogoToast from "cogo-toast";


export const userPrint = (userIds, setPrinting, actualAction, setError, type = "print", filteredData, all,errorNoData,errorPrint,errorExport) => {
    setPrinting(true);
    if ((userIds === null || userIds.length === 0) && !all) {
        CogoToast.error(errorNoData);
        setPrinting(false);
        return 0;
    }
    let data = {};
    data.userIds = userIds;
    data.actionId = actualAction.actualAction;
    data.filterData = filteredData;
    data.all = all;
    if (type === "print") {
        apiGetUserPrintData(data, (data) => {
            if (data.error && data.error.length > 0) setError(data.error);
            print(data.data, setPrinting);
        }, (error) => {
            CogoToast.error(errorPrint)
            setPrinting(false);
            return 0;
        })
    } else {
        data.type="pdf";
        apiExportUser(data, (data) => {
            if (data.error && data.error.length > 0) setError(data.error);
            exportData(data, setPrinting);
        }, (error) => {
            CogoToast.error(errorExport);
            setPrinting(false);
        })
    }

}

const exportData = (data, setPrinting) => {
    const link = document.createElement('a');
    link.href = "data:application/pdf;base64," + data.data;
    link.setAttribute('download', data.fileName); //or any other extension
    document.body.appendChild(link);
    link.click();
    setPrinting(false);
}

const print = (data, setPrinting) => {
    if (data) {
        let myWindow = window.open('about:blank', '_blank');
        let myWindowContent = "";

        myWindowContent = '<html>' +
            '<head>' +
            '<title>Results</title>' +
            '</head>' +
            '<body style="margin: 0">' + data +
            '<script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.2.1/jquery.min.js" type="text/javascript">' + '<' + '/script>' +

            '<script type="text/javascript">' +
            '$(document).ready(function () {' +
            'setTimeout(function () {this.window.print(); this.window.close()}, 500);' +
            '});' +
            '<' + '/script>' +
            '</body>' +
            '</html>';

        myWindow.document.write(myWindowContent);
        myWindow.document.close();
        myWindow.focus();
        setPrinting(false);
    } else {
        CogoToast.error("Nepodařilo připravit vybraná data k tisku...")
        setPrinting(false);
    }

}
