import React, {useEffect, useState} from "react";
import {apiGetCustomField} from "../../../Admin/pages/CustomField/Actions";
import {FiPlus} from "react-icons/fi";
import {FieldArray} from "react-final-form-arrays";
import {EnigooSelect} from "../../EnigooSelect";
import {EnigooSwitch} from "../../EnigooSwitch";
import {EnigooTextField} from "../../EnigooTextField";
import {EnigooFieldsGroup} from "../../EnigooFieldsGroup";
import {EnigooCustomField} from "../../EnigooCustomField";
import {useTranslation} from "react-i18next";
import {shallowEqual, useSelector} from "react-redux";

export const CustomFieldsAssign = ({
                                       label,
                                       fieldName,
                                       doPush,
                                       defaultSelectValues,
                                       type = null
                                   }) => {
    let [data, setData] = useState(null);
    let [loading, setLoading] = useState(false);
    let fieldsNumber = 0;
    let [selectCustomField, setSelectCustomField] = useState(null);
    let [dataForSelect, setDataForSelect] = useState([]);
    const {t} = useTranslation()
    let actualFields = null;

    useEffect(() => {
        if(data && actualFields){
            let array = [];
            data.forEach((item,index)=>{
                if(!actualFields || !actualFields.value || !actualFields.value.some((i)=>i.id===item.id)){
                    array.push(item);
                }
            })
            setDataForSelect(array);
            setSelectCustomField(null);
        }
    }, [actualFields,data]);

    useEffect(() => {
        get();
    }, []);



    const get = () => {
        setLoading(true);
        apiGetCustomField((data) => {
            setData(data);
            let array = [];
            data.map((item, index) => {
                array.push({
                    id: item.id,
                    name: item.name
                })
            })
            setDataForSelect(array);
            setLoading(false);
        }, (error) => {
            setLoading(false);
        })
    }

    const onValueChange = (event) => {
        setSelectCustomField(event);
    }

    const addValue = () => {
        doPush(fieldName, {id: selectCustomField.value, name: selectCustomField.label})
        let selArray = [];
        dataForSelect.forEach((item, index) => {
            if (selectCustomField.value !== item.id) {
                selArray.push(item);
            }
        })
        setDataForSelect(selArray);
        setSelectCustomField(null);
    }

    const ButtonAdd = () => (
        <div className={"form-element"}>
            <div className={"form-label"}>{t("webChannels.assign")}</div>
            <button disabled={selectCustomField===null}
                onClick={() => {
                    addValue();
                }}
                className={"btn btn-default btn-rounded btn-icon hover:bg-enigoo-600 bg-enigoo-500 text-white"}>
                <FiPlus size={10}/>
            </button>
        </div>
    )

    const getCustomFieldById = (id) => {
        let cf = null;
        data && data.map((item, index) => {
            if (item.id === id) {
                cf = item;
            }
        })
        return cf;

    }


    return (
        <>
            <div className="flex flex-col mt-1 mb-2 lg:space-x-2 text-sm w-full">
                <EnigooFieldsGroup items={[
                    <EnigooSelect disabled={loading} label={label} name={"customFieldsSelect"} items={dataForSelect}
                                  defaultValue={["id", "name"]} placeHolder={t("placeHolder.select")}
                                  onValueChange={onValueChange} key={"0"} outsideClassName={"w-full lg:w-11/12"}/>,
                    <ButtonAdd key={"1"}/>
                ]}/>
            </div>
            <div className={"form-label"}>{t("action.selectedUserFields")}:</div>

            <FieldArray name={fieldName}>
                {({fields}) => {
                    actualFields = fields;
                    fieldsNumber = fields.length;
                    return (
                        <div
                            className={`grid text-sm mt-5 ${type && type === "webChannel" ? "lg:grid-cols-2 grid-cols-1" : "lg:grid-cols-4 grid-cols-1"} gap-2`}>
                            {fields.map((name, index) => (
                                <div key={name}>
                                    {!loading && <CustomFieldAssignField type={type} fields={fields} name={name}
                                                                         index={index}
                                                                         getCustomFieldById={getCustomFieldById}
                                                                         dataForSelect={dataForSelect}
                                                                         setDataForSelect={setDataForSelect}/>}
                                </div>
                            ))}
                        </div>
                    )
                }
                }
            </FieldArray>

        </>
    )
}

const CustomFieldAssignField = ({
                                    type,
                                    fields,
                                    name,
                                    index,
                                    getCustomFieldById,
                                    dataForSelect,
                                    setDataForSelect
                                }) => {
    let [notShow, setNotShow] = useState(false);
    const {t,i18n} = useTranslation()
    useEffect(() => {
        if (fields.value[index].notShow) {
            setNotShow(true);
        }
    })

    const {palettes} = useSelector(
        (state) => ({
            palettes: state.reducerAdmin.palettes
        }),
        shallowEqual
    )

    const {background,leftSidebar,navbar} = palettes;

    let isDark = React.useMemo(()=>{
        return background==="dark" && leftSidebar==="dark" && navbar==="dark";
    },[background,leftSidebar,navbar])

    return (
        <div className={`text-sm form-input ${isDark?"bg-grey-800":""}`}>
            <div className={"grid grid-cols-2"}>
                <div>{fields.value[index].name}:</div>
                <div
                    className={"text-right"}
                    onClick={() => {
                        let selArray = dataForSelect?dataForSelect:[];
                        selArray.push({
                            name: fields.value[index].name,
                            id: fields.value[index].id
                        })
                        setDataForSelect(selArray);
                        fields.remove(index)
                    }
                    }
                    style={{cursor: "pointer"}}>❌
                </div>
            </div>
            <div className={`grid text-sm mt-5 ${(type && type === "webChannel")?"lg:grid-cols-2  grid-cols-2":"grid-cols-2"}`}>
                <EnigooTextField label={""} name={"id"} visible={false}/>
                <EnigooSwitch name={`${name}.isMandatory`} label={t("basics.requireField")+": "}/>
                {type && type === "webChannel" &&
                    <>
                        <EnigooSwitch name={`${name}.notShow`} label={t("webChannels.notShow")+": "} setChecked={setNotShow}/>
                        {notShow && <div className={"col-span-2 w-full justify-end"}><EnigooCustomField label={t("webChannels.defaultValue")+":"}
                            customField={getCustomFieldById(fields.value[index].id)} name={`${name}.defaultValue`}/>
                        </div>}
                    </>
                }

            </div>
        </div>
    )
}
