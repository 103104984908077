import React, {useEffect, useState} from "react";
import {apiGetTemplate} from "./Actions";
import EnigooButton from "../../../MyComponent/EnigooButton";
import {FiPlus} from "react-icons/fi";
import {EnigooLoader} from "../../../MyComponent/EnigooLoader";
import Widget from "../../../components/widget";
import {IconTabs} from "../../../components/tabs";
import TemplateTable from "./TemplateTable";
import MailTemplateTable from "../MailTemplate/MailTemplateTable";
import EnigooBreadcrumbs from "../../../MyComponent/EnigooBreadcrumbs";
import VerifyRights from "../../VerifyRights";
import {useTranslation} from "react-i18next";

const Template = () => {
    let [loading, setLoading] = useState(false);
    let [data, setData] = useState(null);
    let [errorMessage, setErrorMessage] = useState(null);

    let [linkToCreate,setLinkToCreate] = useState("/mail-template/create");
    const {t} = useTranslation()

    const changeTab = (val) =>{
        if(val===0){
            setLinkToCreate("/mail-template/create");
        }if(val===1){
            setLinkToCreate("/template/create");
        }
    }

    useEffect(() => {
        getTemplate();
    }, []);

    const getTemplate = () => {
        setLoading(true);
        apiGetTemplate((data) => {
            setData(data);
            setLoading(false);
            setErrorMessage(null);
        }, (error) => {
            setLoading(false);
            setErrorMessage(t("template.loadingError"));
        })
    }

    const _renderBody = () => {
        if (loading) return <EnigooLoader text={t("template.loading")}/>
        if (errorMessage) return <div>{errorMessage}</div>
        if (!data) return null;
        if (data) return prepareDate();
    }

    const prepareDate = () => {
        let tabs = [];
        tabs.push({
            index: 0,
            content: <VerifyRights page={"mailTemplate"} action={"getMailTemplate"}><MailTemplateTable items={data[0]}/></VerifyRights> ,
            title: <>{t("mailTemplate.plural")}</>
        });
        tabs.push({
            index: 1,
            content: <VerifyRights page={"template"} action={"getTemplate"}><TemplateTable items={data[1]}/></VerifyRights>,
            title: <>{t("accreditationTemplate.plural")}</>
        })

        return <IconTabs setOpenedTab={changeTab} tabs={tabs}/>
    }

    return (
        <>
            <EnigooBreadcrumbs right={<VerifyRights page={linkToCreate==="/mail-template/create"?"mailTemplate":"template"} action={linkToCreate==="/mail-template/create"?"createMailTemplate":"createTemplate"}> <EnigooButton link={linkToCreate} icon={<FiPlus/>} text={t("basics.add")}/></VerifyRights>}/>
            <Widget>
                {_renderBody()}
            </Widget>
        </>
    )

}
export default Template;

