import React, {useEffect, useState} from "react";
import {apiGetZone} from "./Actions";
import EnigooButton from "../../../MyComponent/EnigooButton";
import {FiPlus} from "react-icons/fi";
import {EnigooLoader} from "../../../MyComponent/EnigooLoader";
import Widget from "../../../components/widget";
import EnigooBreadcrumbs from "../../../MyComponent/EnigooBreadcrumbs";
import ZoneTable from "./ZoneTable";
import {IconTabs} from "../../../components/tabs";
import VerifyRights from "../../VerifyRights";
import {useTranslation} from "react-i18next";

const Zone = () => {
    let [loading, setLoading] = useState(false);
    let [data, setData] = useState(null);
    let [errorMessage, setErrorMessage] = useState(null);
    const {t} = useTranslation()

    useEffect(() => {
        getZone();
    }, []);

    const getZone = () => {
        setLoading(true);
        apiGetZone((data) => {
            setData(data);
            setLoading(false);
            setErrorMessage(null);
        }, (error) => {
            setLoading(false);
            setErrorMessage(t("zones.loadingError"))
        })
    }

    const prepareData = ()=>{
        let tabs = [];
        data.map((item, index) => item.items&&tabs.push({
            index: index,
            content: <ZoneTable items={item}/>,
            title: <>{item.actionName ? item.actionName : t("groups.notAssign")}</>
        }))
        return <IconTabs tabs={tabs}/>
    }

    const _renderBody = () => {
        if (loading || !data) return <EnigooLoader text={t("zones.loading")}/>
        if (errorMessage) return <div>{errorMessage}</div>
        if (!data || data.length===0) return <ZoneTable items={{items:[]}}/>;
        if(data) return prepareData();
    }

    return (
        <>
            <EnigooBreadcrumbs right={<VerifyRights page={"zone"} action={"createZone"}> <EnigooButton link={"/zone/create"} icon={<FiPlus/>} text={t("basics.add")}/></VerifyRights>}/>
            <Widget>
                {_renderBody()}
            </Widget>
        </>
    )
}

export default Zone;
