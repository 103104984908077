import React, {useEffect, useState} from "react";
import {useHistory, useParams} from "react-router";
import {
    apiCreateWebChannel,
    apiExportWebChannelExternalCodes,
    apiGetWebChannelById,
    apiUpdateWebChannel
} from "../Actions";
import CogoToast from "cogo-toast";
import {EnigooFieldsGroup} from "../../../../MyComponent/EnigooFieldsGroup";
import {EnigooTextField} from "../../../../MyComponent/EnigooTextField";
import {ActionSelectField} from "../../../../MyComponent/Api/Admin/ActionSelectField";
import {FaFileExport, FiInfo, FiLock, FiSave, FiSkipBack} from "react-icons/all";
import {EnigooSwitch} from "../../../../MyComponent/EnigooSwitch";
import EnigooButton from "../../../../MyComponent/EnigooButton";
import Widget from "../../../../components/widget";
import {EnigooLoader} from "../../../../MyComponent/EnigooLoader";
import {Field, Form} from "react-final-form";
import {IconTabs} from "../../../../components/tabs";
import {WebChannelUserModal} from "../../WebChannelUser/WebChannelUserModal";
import arrayMutators from "final-form-arrays";
import {CustomFieldsAssign} from "../../../../MyComponent/Api/Admin/CustomFieldsAssign";
import {FiHelpCircle, FiLink, FiX} from "react-icons/fi";
import EnigooBreadcrumbs from "../../../../MyComponent/EnigooBreadcrumbs";
import {ImageModal} from "../../Image/ImageModal";
import VerifyRights from "../../../VerifyRights";
import {EnigooSelect} from "../../../../MyComponent/EnigooSelect";
import {WebExternalCodeModal} from "../../WebChannelExternalCode/WebExternalCodeModal";
import WebChannelExternalCodeTable from "../../WebChannelExternalCode/WebChannelExternalCodeTable";
import {WebChannelExternalCodeImport} from "../../WebChannelExternalCode/WebChannelExternalCodeImport";
import {FieldArray} from "react-final-form-arrays";
import EnigooMultiLanguageField from "../../../../MyComponent/EnigooMultiLanguageField/EnigooMultiLanguageField";
import {Trans, useTranslation} from "react-i18next";
import GroupsSelectField from "../../../../MyComponent/Api/Admin/GroupsSelectField";
import {TemplateSelectField} from "../../../../MyComponent/Api/Admin/TemplateSelectField";
import WebChannelActionFields from "./WebChannelActionFields";
import EnigooFormChangedAlert from "../../../../MyComponent/EnigooFormChangedAlert";
import EnigooNote from "../../../../MyComponent/EnigooNote";
import {EnigooDateTimeFieldv2} from "../../../../MyComponent/EnigooDateTimeFieldv2";
import EnigooToolTip from "../../../../MyComponent/EnigooToolTip";


const WebChannelForm = () => {
    let [webChannelData, setWebChannelData] = useState({"viewGroups":false,"active":false,"withAuthorization":false});
    let [loading, setLoading] = useState(false);
    let [saving, setSaving] = useState(false);
    let [withAuthorization, setWithAuthorization] = useState(false);
    let [authorizationType, setAuthorizationType] = useState(null);
    let [webChannelUsers, setWebChannelUsers] = useState([]);
    let [isPasswordSet, setIsPasswordSet] = useState(false);
    let [viewGroups, setViewGroups] = useState(false);
    let [webChannelExternalCodes, setWebChannelExternalCodes] = useState([]);
    let [initImage, setInitImage] = useState(null);
    let submit;

    const {t} = useTranslation()

    const history = useHistory();

    const {id} = useParams();

    useEffect(() => {
        const handleKeyDown = (event) => {
            if ((event.ctrlKey || event.metaKey) && event.key === 's') {
                event.preventDefault();
                submit();
                console.log('Ctrl/Command + S was pressed');
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    useEffect(() => {
        init();
    }, []);

    const init = () => {

        if (id) {
            setLoading(true);
            apiGetWebChannelById(id, (data) => {
                setIsPasswordSet(data.password !== null);
                data.password = null;
                setInitImage(data.image);
                data.image = undefined;

                setWebChannelUsers(data.users);

                setWithAuthorization(data.withAuthorization);
                if (data.withAuthorization && data.byPassword) {
                    setAuthorizationType(1);
                    data.authorizationType = 1;
                } else if (data.withAuthorization && !data.byPassword && data.byCode) {
                    setAuthorizationType(3);
                    data.authorizationType = 3;
                } else if (data.withAuthorization && !data.byPassword && !data.byCode) {
                    setAuthorizationType(2);
                    data.authorizationType = 2;
                }
                setWebChannelData(data);
                setWebChannelExternalCodes(data.externalCode);

                setLoading(false);
                setViewGroups(data.viewGroups);
            }, (err) => {
                setLoading(false);
                CogoToast.error(t("webChannels.loadingDataError"));
            });
        }
    }

    const onSubmitSuccess = (data,values) => {
        setWebChannelData(values);
        CogoToast.success(t("webChannels.saved"));
        setSaving(false);
        history.push("/web-channel/detail/" + data.id);
    }
    const onSubmitError = (error) => {
        CogoToast.error(t("webChannels.saveError"));
        setSaving(false);
    }
    const onSubmit = (values) => {
        setSaving(true);
        let sendData = values;

        if (sendData.withAuthorization && authorizationType) {
            switch (authorizationType) {
                case 1:
                    sendData.byPassword = true;
                    sendData.byCode = false;
                    break;
                case 2:
                    sendData.byPassword = false;
                    sendData.byCode = false;
                    break;
                case 3:
                    sendData.byPassword = false;
                    sendData.byCode = true;
                    break;
                default:
                    sendData.byPassword = false;
                    sendData.byCode = false;
                    break;
            }
        }

        sendData.users = webChannelUsers;
        //sendData.externalCode = webChannelExternalCodes;
        if (id) {
            apiUpdateWebChannel(id, sendData, (data)=>onSubmitSuccess(data,values), onSubmitError);
        } else {
            apiCreateWebChannel(sendData, (data)=>onSubmitSuccess(data,values), onSubmitError);
        }
    }

    return (
        <Form onSubmit={onSubmit} initialValues={webChannelData}
              validate={values => {
                  let errors = {};
                  if (!values.name) {
                      errors.name = t("basics.requireField");
                  }
                  if (!values.actionId) {
                      errors.actionId = t("basics.requireField");
                  }
                  if (values.withAuthorization && !values.authorizationType) {
                      errors.authorizationType = t("basics.requireField")
                  }
                  if (values.withAuthorization && values.authorizationType) {
                      switch (values.authorizationType) {
                          //pomoci hesla
                          case 1:
                              if (id && !isPasswordSet && !values.password) {
                                  errors.password = t("basics.requireField");
                              } else if (!id && !values.password) {
                                  errors.password = t("basics.requireField");
                              }
                              break;
                          //pomoci prihlaseni
                          case 2:

                              break;
                          //pomoci autorizacnich kodu
                          case 3:
                              if (!values.numberUseExtCode && values.numberUseExtCode !== 0) {
                                  errors.numberUseExtCode = t("basics.requireField")
                              } else if (values.numberUseExtCode < 0) {
                                  errors.numberUseExtCode = t("basics.requireField")
                              }
                              break;
                          default:
                              errors.authorizationType = t("basics.requireField")
                              break;
                      }
                  }
                  if (id && values.withAuthorization && values.byPassword && !values.password && !isPasswordSet) {
                      errors.password = t("basics.requireField");
                  } else if (!id && values.withAuthorization && values.byPassword && !values.password) {
                      errors.password = t("basics.requireField");
                  }
                  if (values.dateFrom && values.dateTo) {
                      console.log(values.dateFrom);
                      console.log(values.dateTo);
                      const dateFrom = new Date(values.dateFrom);
                      const dateTo = new Date(values.dateTo);
                      if (dateFrom > dateTo) errors.dateFrom = t("basics.enterCorrectDate");
                  } else if (values.dateFrom && !values.dateTo) {
                      errors.dateTo = t("basics.setDateTo");
                  } else if (values.dateTo && !values.dateFrom) {
                      errors.dateFrom = t("basics.setDateFrom");
                  }

                  return errors;
              }
              }
              mutators={{...arrayMutators}}
              render={({
                           values,
                           handleSubmit,
                           dirty, form,
                           form: {
                               mutators: {push, update, pop, unshift}
                           },
                       }) => {
                  submit = handleSubmit;
                  return (
                      <>
                          <EnigooBreadcrumbs right={<div className={"flex flex-row"}>
                              {id && webChannelData &&
                                  <div className={"mr-1"}><EnigooButton icon={<FiLink/>} text={t("basics.open")}
                                                                        link={"/app/online/" + webChannelData.channelKey}
                                                                        newTab={true}/></div>}
                              <VerifyRights page={"webChannel"} action={id ? "updateWebChannel" : "createWebChannel"}>
                                  <EnigooButton
                                      onClick={() => submit()} icon={<FiSave/>} text={t("basics.save")}
                                      loading={saving} disabled={saving || loading}/></VerifyRights>


                          </div>}/>
                          <EnigooFormChangedAlert onReset={() => form.reset(webChannelData)} onSave={handleSubmit}
                                                  dirty={dirty}/>

                          {loading ? <EnigooLoader text={t("webChannels.loadingData")}/> :
                              <div className="flex flex-col">

                                  <div className={"flex lg:flex-row flex-col"}>
                                      <div className={"lg:w-3/4 w-full lg:mr-3"}>
                                          <Widget>
                                              <IconTabs
                                                  tabs={tabs(webChannelData, webChannelUsers, setWebChannelUsers, webChannelExternalCodes, setWebChannelExternalCodes, withAuthorization, setWithAuthorization, authorizationType, setAuthorizationType, push, viewGroups, setViewGroups, submit, update, values)}/>

                                          </Widget>
                                      </div>
                                      <div className={"lg:w-1/4 w-full"}>
                                          <Widget>
                                              <div className={"w-full"}>
                                                  <TabPhoto initImage={initImage} loading={loading}/>
                                              </div>
                                          </Widget>
                                      </div>
                                  </div>

                              </div>
                          }

                      </>
                  );
              }}
        />
    )
}

const TabPhoto = ({initImage, loading}) => {
    let [selectedImage, setSelectedImage] = useState(null);
    let [keepOriginal, setKeepOriginal] = useState(true);
    let [imageBase, setImageBase] = useState(null);
    let [loadingImage, setLoadingImage] = useState(false);
    const {t} = useTranslation()
    useEffect(() => {
        getSelectedImageBase();
    }, [selectedImage])

    const getSelectedImageBase = () => {
        if (selectedImage && selectedImage.data) {
            setImageBase(selectedImage.data);
        } else {
            setImageBase(null);
        }
    }

    const renderPhotoField = (field) => {
        if (loading) return <></>;
        if (loadingImage) return <EnigooLoader text={t("webChannels.imageLoading")}/>;
        else if (!loadingImage && selectedImage && imageBase) return (
            <>
                <div className={"form-label"}>{t("webChannels.selectedLogo")}:</div>
                <div className={"border rounded grid place-content-center p-2 mb-1"}>
                    <img className={"max-h-sm"}
                         src={imageBase}
                         alt={"img"}/>

                </div>
                <EnigooButton text={t("webChannels.selectNew")} icon={<FiX size={15}/>}
                              color={"red"}
                              onClick={() => {
                                  field.input.onChange(null);
                                  setSelectedImage(null);
                                  setImageBase(null);
                              }}
                />
            </>
        )
        else return (
                <>
                    <ChoosePhoto field={field} setSelectedImage={setSelectedImage}/>
                    {initImage && <EnigooButton text={t("webChannels.keepOriginalLogo")} icon={<FiSkipBack size={15}/>}
                                                onClick={() => {
                                                    field.input.onChange(null);
                                                    setSelectedImage(null);
                                                    setKeepOriginal(true);
                                                }}
                    />}
                </>
            )
    }
    if (initImage && keepOriginal) {
        return (
            <div className={`form-element`}>
                <>
                    <div className={"form-label"}>{t("webChannels.originalLogo")}:</div>
                    <div className={"border rounded grid place-content-center p-2 mb-1"}>
                        <img className={"max-h-sm"}
                             src={initImage}
                             alt={"img"}/>
                    </div>
                    <EnigooButton text={t("webChannels.selectOther")} icon={<FiX size={15}/>}
                                  color={"red"}
                                  onClick={() => {
                                      setKeepOriginal(false);
                                  }}
                    />
                </>
            </div>
        )
    } else {
        return (
            <div className={`form-element`}>
                <div className="form-label text-xl flex flex-row">{t("webChannels.logoToShow")}: <FiHelpCircle
                    className={"ml-2 text-red-900 mt-2"} size={18}
                    data-tip data-for={"_tooltip_wch_detail_logo"}/><EnigooToolTip content={t("webChannels.imageToolTip")} id={"_tooltip_wch_detail_logo"}/></div>
                <Field name={"image"} component={renderPhotoField}/>
            </div>
        )
    }
}

const ChoosePhoto = ({field, setSelectedImage}) => {
    const setImage = (img) => {
        field.input.onChange(img);
        setSelectedImage(img);
        return true;
    }
    return <ImageModal label={""} imageTypes={3} setImage={setImage}/>
}

const TabAuthorization = ({
                              push,
                              webChannelUsers,
                              setWebChannelUsers,
                              withAuthorization,
                              setWithAuthorization,
                              authorizationType,
                              setAuthorizationType,
                              webChannelExternalCodes,
                              setWebChannelExternalCodes,
                              submit,
                              update
                          }) => {

    let [exporting, setExporting] = useState(false);

    const {id} = useParams();
    const {t} = useTranslation();

    const createExtCode = (data, push) => {
        let exist = null;
        webChannelExternalCodes.map((item, index) => {
            if (item.code === data.code) {
                exist = item;
            }
        });
        if (!exist) {
            setWebChannelExternalCodes([...webChannelExternalCodes, {
                id: null,
                code: data.code,
                numberOfUse: data.numberOfUse ? data.numberOfUse : null
            }]);
            push("externalCode", {
                id: null,
                code: data.code,
                numberOfUse: data.numberOfUse ? data.numberOfUse : null
            })
            //submit();
            return true
        } else {
            return false;
        }

    }

    const getErrorMessage = (errors) => {
        return <div className={"text-center"}><b>{t("webChannels.externalCodeNotImorted")}:</b>
            {errors.map((item, index) => {
                return <p>{item}</p>
            })}</div>;
    }
    let fieldsValues;
    const importCodes = (file, clear, values, push, update) => {
        let errors = [];
        let newCodes = [];
        let codes = file.content.split("\n");
        codes.forEach((item) => {
            let code = "";
            let numberOfUse = null;
            let value = item.trim();
            if (value.includes(";")) {
                let valArr = value.split(";");
                if (valArr.length > 0) code = valArr[0];
                if (valArr.length >= 2) numberOfUse = valArr[1];
            } else {
                code = value;
            }
            code = code.replace(";", "")
            if (numberOfUse === "") {
                numberOfUse = null;
            }
            if (code.length > 0) {
                let exist = null;
                values && values.map((item, index) => {
                    if (item.code === code) {
                        push("externalCode", {
                            id: item.id,
                            code: code,
                            numberOfUse: numberOfUse
                        })
                    }
                });
                if (!exist) {
                    newCodes.map((item, index) => {
                        if (item.code === code) {
                            exist = item;
                        }
                    })
                    if (!exist) {
                        push("externalCode", {
                            id: null,
                            code: code,
                            numberOfUse: numberOfUse
                        })
                    }
                } else {
                    errors.push(value);
                }
            }
        })
        if (errors.length > 0) {
            CogoToast.warn(getErrorMessage(errors));

        } else {
            CogoToast.info(t("webChannels.externalCodeImported"))
        }
        setWebChannelExternalCodes([...webChannelExternalCodes, ...newCodes]);
        clear();
    }

    const exportData = (data) => {
        const url = window.URL.createObjectURL(new Blob([data.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', data.fileName); //or any other extension
        document.body.appendChild(link);
        link.click();
        setExporting(false);
    }

    const exportCodes = () => {
        if (id) {
            setExporting(true);
            apiExportWebChannelExternalCodes(id, (data) => {
                exportData(data);
            }, (err) => {
                CogoToast.error(t("webChannels.cannotExport"));
                setExporting(false);
            })
        }

    }

    const updateUser = (index, data) => {
        webChannelUsers.map((item, itemIndex) => {
            if (itemIndex === index) {
                item.login = data.login;
                item.password = data.password;
                item.active = data.active;
            }
        })
        return true;
    }

    const createUser = (data) => {
        let exist = null;
        webChannelUsers.map((item, index) => {
            if (item.login === data.login) {
                exist = item;
            }
        });
        if (!exist) {
            setWebChannelUsers([...webChannelUsers, {
                id: null,
                login: data.login,
                password: data.password,
                active: data.active
            }]);
            return true
        } else {
            return false;
        }
    }

    const removeUser = (index) => {
        webChannelUsers.splice(index, 1);
        setWebChannelUsers([...webChannelUsers]);
    }

    const authorizationTypes = [
        {name: t("webChannels.authByPassword"), id: 1},
        {name: t("webChannels.authByLogin"), id: 2},
        {name: t("webChannels.authByExternalCode"), id: 3}
    ];

    const toolTip =
        "<p><b>Pro import je nutné vybrat soubor .csv</b></p><p><b>vybrané kódy budou v prvním sloupci. V druhém sloupci pak může být hodnota</b></p><p><b>označující počet možných použití kódu, pokud není vyplněna, platí hodnota uvedená výše.</b></p><p>Počet použití značí kolikrát lze pomocí kódu vytvořit novou akreditaci,</p><p>0 pak znamená neomezený počet použití.</p>";

    const _renderAuthorizationFields = () => {
        if (!withAuthorization) return <></>;
        switch (authorizationType) {
            case 1:
                return <EnigooTextField
                    label={t("basics.password") + ":"} name={"password"}
                    placeHolder={t("placeHolder.password")} type={"password"}/>
            case 2:
                return <>
                    <div className={"form-label font-bold"}>{t("users.plural")}:</div>
                    <div className="flex flex-col mt-1 mb-2 lg:flex-row w-full lg:space-x-2 form-input">
                        <WebChannelUserModal key={"00"} saveUser={createUser}/>
                        {webChannelUsers.map((item, index) => (
                            <WebChannelUserModal item={item} index={index} key={index.toString()}
                                                 saveUser={updateUser} removeUser={removeUser}/>
                        ))}
                    </div>
                </>;
            case 3:
                return <>
                    <div className={"form-label font-bold"}>{t("externalCode.plural")}:</div>
                    <EnigooTextField name={"numberUseExtCode"} type={"number"}
                                     label={t("externalCode.numberOfUse") + ": *"}
                                     placeHolder={"Zadejte kladné číslo..."}/>

                    <FieldArray name={"externalCode"}>
                        {({fields}) => {
                            return (
                                <><EnigooFieldsGroup items={[
                                    <WebExternalCodeModal key={"00"} saveCode={(data) => {
                                        return createExtCode(data, push)
                                    }}/>,
                                    <WebChannelExternalCodeImport
                                        importCodes={importCodes} values={fields.value} push={push} update={update}/>,
                                    <>{id &&
                                        <VerifyRights page={"webChannel"} action={"exportExternalCode"}>
                                            <div className={"form-element"}><EnigooButton visible={id}
                                                                                          disabled={exporting}
                                                                                          text={t("basics.export")}
                                                                                          icon={<FaFileExport/>}
                                                                                          loading={exporting}
                                                                                          onClick={exportCodes}/></div>
                                            <span><FiHelpCircle data-for={"wch-detail-auth-extcode"} className={"ml-2 mt-3 text-red-900"} size={18}
                                                                data-tip/><EnigooToolTip content={t("webChannels.toolTipImportExternalCode")} id={"wch-detail-auth-extcode"} place={"right"} class={"enigoo-tooltip"} effect={"solid"}/>
                                            </span></VerifyRights>}</>
                                ]}/>
                                    <WebChannelExternalCodeTable data={fields} onUpdate={(index, data) => {
                                        let exist = fields.value.filter((i,idx) => (i.code === data.code && idx!==index))
                                        if (exist.length > 0) {
                                            return false;
                                        } else {
                                            update("externalCode", index, data);
                                            return true;
                                        }
                                    }} onDelete={(index) => {
                                        if (fields.value[index].log.length <= 0) {
                                            fields.remove(index);
                                        }
                                    }}/></>
                            )
                        }}
                    </FieldArray>

                </>
            default:
                return <></>
        }
    }

    return <>
        <EnigooFieldsGroup items={[
            <EnigooSwitch setChecked={setWithAuthorization} label={t("basics.auth") + ": *"}
                          name={"withAuthorization"}
                          outsideClassName={"w-full lg:w-1/2"} key={"1"}/>,
            withAuthorization ?
                <EnigooSelect onValueChange={(e) => {
                    setAuthorizationType(e ? e.value : null);
                }} items={authorizationTypes} outsideClassName={"w-full lg:w-1/2"} key={"2"}
                              label={t("basics.typeOfAuth") + ": *"} name={"authorizationType"}/> : <></>
        ]}/>
        {withAuthorization &&
            <EnigooMultiLanguageField key={"3"} type={"html"} name={"authorizationText"}
                                      label={t("webChannels.descOfAuth") + ":"}
                                      toolTip={t("placeHolder.multiLangToolTip")}/>}
        {_renderAuthorizationFields()}
    </>
}

const TabBasicsInfo = ({
                           webChannelData,
                           push,
                           viewGroups,
                           setViewGroups,
                           values
                       }) => {
    const {t} = useTranslation()


    return (
        <>
            <EnigooTextField label={t("basics.name") + ": *"} name={"name"}
                             placeHolder={t("placeHolder.name")}/>
            <ActionSelectField label={t("action.single") + ": * "} name={"actionId"} isMulti={false}/>
            <WebChannelActionFields values={values}/>
            <div className={"grid lg:grid-cols-2 gap-2"}>
                <EnigooSwitch name={"viewGroups"} label={t("groups.select") + ": *"} setChecked={setViewGroups}
                              toolTip={t("webChannels.groupSelectToolTip")}/>
                {viewGroups ?
                    <EnigooMultiLanguageField placeHolder={t("placeHolder.text")} type={"text"}
                                              name={"groupsSelectName"} label={t("groups.labelForGroup") + ":"} key={2}
                                              toolTip={t("webChannels.groupLabelForGroupToolTip")}/> : <></>}
                {!viewGroups ?
                    <GroupsSelectField forAction={values ? values.actionId : null}
                                       toolTip={t("webChannels.automaticallyAssignToolTip")}
                                       label={t("webChannels.automaticallyAssignTo") + ":"} name={"groupsId"}
                                       placeHolder={t("placeHolder.select")} isMulti={false}/> : <></>
                }
            </div>
            <EnigooNote>
                <div className={"flex flex-col space-y-2"}>
                    <div><span className={"text-enigoo-500 font-bold"}>{t("adminUser.active")}: </span></div>
                    <div className={"flex flex-col space-y-2"}
                         dangerouslySetInnerHTML={{__html: t("webChannels.activeNote")}}></div>
                    <div><span className={"text-enigoo-500 font-bold"}>{t("webChannels.openFrom")}: </span></div>
                    <div className={"flex flex-col space-y-2"}
                         dangerouslySetInnerHTML={{__html: t("webChannels.openFromNote")}}></div>
                    <div><span className={"text-enigoo-500 font-bold"}>{t("webChannels.openTo")}: </span></div>
                    <div className={"flex flex-col space-y-2"}
                         dangerouslySetInnerHTML={{__html: t("webChannels.openToNote")}}></div>
                </div>
            </EnigooNote>
            <div className={"grid grid-cols-3 gap-2"}>
                <EnigooSwitch name={"active"} label={t("adminUser.active") + ": *"}/>
                <EnigooDateTimeFieldv2 name={"dateFrom"} label={t("webChannels.openFrom") + ": "} showTimeSelect={true}
                                     type={"from"}/>
                <EnigooDateTimeFieldv2 name={"dateTo"} label={t("webChannels.openTo") + ": "} showTimeSelect={true}
                                     type={"to"}/>
            </div>

            <div className={"grid grid-cols-3 gap-2"}>
                <EnigooMultiLanguageField placeHolder={t("placeHolder.text")} type={"text"} name={"customNameFirstname"}
                                          label={t("webChannels.customFirstnameLabel") + ":"}
                                          toolTip={t("placeHolder.multiLangToolTip")}/>
                <EnigooMultiLanguageField placeHolder={t("placeHolder.text")} type={"text"} name={"customNameLastname"}
                                          label={t("webChannels.customLastnameLabel") + ":"}
                                          toolTip={t("placeHolder.multiLangToolTip")}/>
                <EnigooMultiLanguageField placeHolder={t("placeHolder.text")} type={"text"} name={"customNameEmail"}
                                          label={t("webChannels.customEmailLabel") + ":"}
                                          toolTip={t("placeHolder.multiLangToolTip")}/>
            </div>
            <EnigooSelect label={t("webChannels.afterAdd") + ":"} name={"afterAdd.action"}
                          placeHolder={t("placeHolder.select")}
                          items={[{value: 1, label: t("webChannels.buttonAddNext")}, {
                              value: 2,
                              label: t("webChannels.nothing")
                          }]} defaultValue={["value", "label"]} toolTip={t("webChannels.afterAddToolTip")}/>
            <TemplateSelectField isMulti={false} name={"mailTemplateId"} label={t("action.mailAfterAdd") + ":"}
                                 toolTip={t("webChannels.mailAfterAddToolTip")} type={0} mailTemplateType={1}/>
            <CustomFieldsAssign type={"webChannel"}
                                defaultSelectValues={!webChannelData ? [] : webChannelData.customField}
                                fieldName={"customField"} label={t("userFields.plural") + ":"} doPush={push}/>

        </>
    )
}


const tabs = (webChannelData, webChannelUsers, setWebChannelUsers, webChannelExternalCodes,
              setWebChannelExternalCodes, withAuthorization, setWithAuthorization, authorizationType,
              setAuthorizationType, push, viewGroups, setViewGroups, submit, update, values) => [
    {
        index: 0,
        content: <TabBasicsInfo push={push} webChannelData={webChannelData}
                                viewGroups={viewGroups} setViewGroups={setViewGroups} values={values}/>,
        title: (<><FiInfo size={18} className={"stroke-current"}/><span
            className={"ml-2"}><Trans i18nKey={"basics.info"}>Základní informace</Trans> </span> </>)
    },
    {
        index: 1,
        content: <TabAuthorization push={push} update={update} setAuthorizationType={setAuthorizationType}
                                   authorizationType={authorizationType}
                                   setWithAuthorization={setWithAuthorization}
                                   withAuthorization={withAuthorization} setWebChannelUsers={setWebChannelUsers}
                                   webChannelUsers={webChannelUsers} webChannelData={webChannelData}
                                   webChannelExternalCodes={webChannelExternalCodes}
                                   setWebChannelExternalCodes={setWebChannelExternalCodes}
                                   viewGroups={viewGroups} setViewGroups={setViewGroups}
                                   submit={submit}/>,
        title: (<><FiLock size={18} className={"stroke-current"}/><span
            className={"ml-2"}><Trans i18nKey={"basics.auth"}>Autorizace</Trans></span> </>)
    }
];

export default WebChannelForm;
