import React, {useEffect, useState} from 'react';
import {Form} from "react-final-form";
import {useHistory, useParams} from "react-router";
import CogoToast from "cogo-toast";
import EnigooButton from "../../../MyComponent/EnigooButton";
import {FiInfo, FiSave} from "react-icons/all";
import Widget from "../../../components/widget";
import {EnigooLoader} from "../../../MyComponent/EnigooLoader";
import {apiCreateMailTemplate, apiGetMailTemplateById, apiUpdateMailTemplate} from "./Actions";
import {EnigooFieldsGroup} from "../../../MyComponent/EnigooFieldsGroup";
import {EnigooTextField} from "../../../MyComponent/EnigooTextField";
import {TemplateTypeSelectField} from "../../../MyComponent/Api/Admin/TemplateTypeSelectField";
import {IconTabs} from "../../../components/tabs";
import EnigooBreadcrumbs from "../../../MyComponent/EnigooBreadcrumbs";
import {FiPlus} from "react-icons/fi";
import {FieldArray} from "react-final-form-arrays";
import {EnigooEmailField} from "../../../MyComponent/EnigooEmailField";
import arrayMutators from "final-form-arrays";
import VerifyRights from "../../VerifyRights";
import {EnigooSwitch} from "../../../MyComponent/EnigooSwitch";
import {Trans, useTranslation} from "react-i18next";
import EnigooJsonView from "../../../MyComponent/JsonAttributes/EnigooJsonView";
import EnigooFormChangedAlert from "../../../MyComponent/EnigooFormChangedAlert";

const MailTemplateForm = () => {
    let [templateData, setTemplateDate] = useState(null);
    let [loading, setLoading] = useState(false);
    let [saving, setSaving] = useState(false);
    let submit;
    const history = useHistory();
    const {t} = useTranslation()
    const {id} = useParams();


    useEffect(() => {
        const handleKeyDown = (event) => {
            if ((event.ctrlKey || event.metaKey) && event.key === 's') {
                event.preventDefault();
                submit();
                console.log('Ctrl/Command + S was pressed');
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    useEffect(() => {
        init();
    }, []);

    const defaultTemlate = "<!doctype html>\n" +
        "<html lang=\"cs\">\n" +
        "<head>\n" +
        "    <meta name=\"viewport\" content=\"width=device-width\"/>\n" +
        "    <meta http-equiv=\"Content-Type\" content=\"text/html; charset=UTF-8\"/>\n" +
        "    <title>Akreditace</title>\n" +
        "</head>\n" +
        "<body style=\"background-color: rgb(246, 246, 246); font-family: sans-serif; line-height: 1.4; margin: 0; padding: 0; font-size: 12pt\">\n" +
        "<center>\n" +
        "    <div style=\"width: 600px; margin: 0 auto; align-content: center\">\n" +
        "        <center>\n" +
        "            <table style=\"padding-top: 20px; text-align: left; width: 600px;\" width=\"600\">\n" +
        "                <tr>\n" +
        "                    <td>\n" +
        "                        Dobrý den,<br/><br/>\n" +
        "                        děkujeme za registraci ........<br/>\n" +
        "                        <br/>\n" +
        "                        Přejeme příjemný zážitek\n" +
        "                        <br/>\n" +
        "                        <br/>\n" +
        "                        <br/>\n" +
        "                    </td>\n" +
        "                </tr>\n" +
        "            </table>\n" +
        "        </center>\n" +
        "        <div>\n" +
        "            <p>Kontakt v případě dotazů:<br/>\n" +
        "                <strong>461 049 232</strong><br/>\n" +
        "                <a href=mailto:info@enigoo.cz>info@enigoo.cz</a></p>\n" +
        "        </div>\n" +
        "        <div>\n" +
        "            ENIGOO s.r.o., Hlaváčova 207, Pardubice <br/>\n" +
        "            <a href=http://www.enigoo.cz\n" +
        "               style=\"font-size:12px; color:#999999; text-decoration:underline\">www.enigoo.cz</a> - +420 461 049 232\n" +
        "            -\n" +
        "            <a href=mailto:info@enigoo.cz\n" +
        "               style=\"box-sizing:border-box;font-size:12px; color:#999999; text-decoration:underline\">info@enigoo.cz\n" +
        "            </a>\n" +
        "        </div>\n" +
        "    </div>\n" +
        "</center>\n" +
        "</body>\n" +
        "</html>";


    const init = () => {
        if (id) {
            setLoading(true);
            apiGetMailTemplateById(id, (data) => {
                setTemplateDate(data);
                setLoading(false);
            }, (error) => {
                setSaving(false);
                CogoToast.error(t("template.loadingDataError"))
            })
        } else {
            setTemplateDate({html: defaultTemlate});
        }
    }

    const onSubmitSuccess = (data, values) => {
        setTemplateDate(values)
        CogoToast.success(t("template.saved"));
        setSaving(false);
        history.push("/mail-template/detail/" + data.id);
    }

    const onSubmitError = (error) => {
        CogoToast.error(t("template.saveError"));
        setSaving(false);
    }

    const onSubmit = (values) => {
        setSaving(true);
        if (id) {
            apiUpdateMailTemplate(id, values, (data) => onSubmitSuccess(data, values), onSubmitError)
        } else {
            apiCreateMailTemplate(values, (data) => onSubmitSuccess(data, values), onSubmitError)
        }
    }

    return (
        <Form onSubmit={onSubmit} initialValues={templateData}
              validate={values => {
                  let errors = {};
                  if (!values.subjectFrom) {
                      errors.subjectFrom = t("basics.requireField");
                  }
                  return errors;
              }
              }
              mutators={{...arrayMutators}}
              render={({
                           values,
                           handleSubmit,
                           dirty, form,
                           form: {
                               mutators: {push, pop, unshift}
                           }
                       }) => {
                  submit = handleSubmit;
                  return (
                      <>
                          <EnigooBreadcrumbs
                              right={<div className={"flex flex-row"}>
                                  <VerifyRights page={"mailTemplate"}
                                                action={id ? "updateMailTemplate" : "createMailTemplate"}><EnigooButton
                                      onClick={() => submit()} icon={<FiSave/>}
                                      text={t("basics.save")}
                                      loading={saving} disabled={saving || loading}/></VerifyRights>
                              </div>}/>
                          <EnigooFormChangedAlert onReset={() => form.reset(templateData)} onSave={handleSubmit}
                                                  dirty={dirty}/>
                          <Widget>
                              {loading ? <EnigooLoader text={t("template.loadingData")}/> :
                                  <div className={"flex flex-col"}>

                                      <IconTabs tabs={tabs(push, null, values)}/>

                                  </div>}
                          </Widget>
                      </>
                  );
              }}/>
    )
}
export default MailTemplateForm;

const TabBasicsInfo = ({doPush, values}) => {
    let [code, setCode] = useState(null);
    const {t} = useTranslation()

    return (
        <div className={"w-full"}>
            <EnigooFieldsGroup items={[
                <EnigooTextField label={t("basics.name") + ": *"} name={"name"} placeHolder={t("placeHolder.name")}
                                 outsideClassName={"w-full lg:w-1/5"} key={"0"}/>,
                <TemplateTypeSelectField label={t("basics.type") + ":"} name={"type"} isMulti={false}
                                         outsideClassName={"w-full lg:w-1/5"} key={"1"}/>,
                <EnigooTextField label={t("mailTemplate.subject") + ": *"} name={"subjectFrom"}
                                 outsideClassName={"w-full lg:w-2/5"} key={"2"}/>,
                <EnigooSwitch name={"withAccreditation"} label={t("mailTemplate.withAccreditation") + ":"} key={"3"}
                              outsideClassName={"w-full lg:w-1/5"}/>

            ]}/>
            <>

                <div className={"form-label"}>Kopie emailu:</div>
                <EnigooButton text={t("mailTemplate.addMailToCopy")} icon={<FiPlus size={10}/>} onClick={() => {
                    doPush("mailCopy", undefined);
                }}/>
                <div className={"form-element"}>
                    <FieldArray name={"mailCopy"}>
                        {({fields}) => {
                            return (
                                <div className={"w-full grid lg:grid-cols-5 grid-cols-3 gap-2 mt-2"}>
                                    {fields.map((name, index) => {
                                        return (
                                            <div className={"mt-1 mr-1 w-full text-sm form-input"} key={name}>
                                                <div className={'flex flex-row items-center w-full'}>
                                                    <EnigooEmailField placeholder={t("placeHolder.email")}
                                                                      name={`${name}`}
                                                                      label={`#${index + 1}`} mandatory={false}/>
                                                    <div
                                                        className={"ml-2 w-1/12"}
                                                        onClick={() => {
                                                            fields.remove(index)
                                                        }
                                                        }
                                                        style={{cursor: "pointer"}}>❌
                                                    </div>
                                                </div>
                                            </div>)
                                    })}
                                </div>
                            )
                        }
                        }
                    </FieldArray>
                </div>
            </>
            <EnigooJsonView label={t("templateJsonAttributes.label") + ":"} values={values} name={"html"}
                            type={"mail-template"}/>

        </div>
    )
}

const tabs = (doPush, initHtml, values) => [
    {
        index: 0,
        content: <TabBasicsInfo doPush={doPush} initHtml={initHtml} values={values}/>,
        title: (<><FiInfo size={18} className={"stroke-current"}/><span
            className={"ml-2"}><Trans i18nKey={"basics.info"}>Základní informace</Trans></span> </>)
    }
];
