import React, {useEffect, useRef, useState} from "react";
import {FiEdit} from "react-icons/fi";
import EnigooModal from "../../../../MyComponent/Modal/EnigooModal";
import EnigooRadioField from "../../../../MyComponent/EnigooRadioField";
import {Form} from "react-final-form";
import {RiParkingBoxLine} from "react-icons/all";
import {
    apiGetActionExtension,
    apiGetGroupsExtensionByGroupsId,
    apiGetUserExtensionByUserId, apiPutAssignGroupsExtension,
    apiPutAssignUserExtension
} from "../Actions";
import {EnigooLoader} from "../../../../MyComponent/EnigooLoader";
import ParkingChooseForm from "./ParkingChooseForm";
import {useTranslation} from "react-i18next";
import CogoToast from "cogo-toast";
import {useParams} from "react-router-dom";

const ParkingAssignModal = ({
                                usersIds,
                                days,
                                actionId,
                                icon = <RiParkingBoxLine/>,
                                text = null,
                                type = "user",
                                values = null
                            }) => {

    const {t, i18n} = useTranslation()

    let [open, setOpen] = useState(false)
    let [data, setData] = useState(null);
    let [loading, setLoading] = useState(false);
    let [saving, setSaving] = useState(false);
    let [formData, setFormData] = useState(null);

    let {id} = useParams();
    let submit = useRef();

    useEffect(() => {
        if (open && actionId) {
            setLoading(true);
            apiGetActionExtension(2, actionId, (data) => {
                prepareFormData(data);

            }, (err) => {
                if(err?.response?.data?.error){
                    switch (err.response.data.error){
                        case "ACTION_DOESNT_HAVE_EXTENSION":
                            CogoToast.error(t("parkingExtension.actionDoestHaveExtension"));
                            setLoading(false);
                            setOpen(false);
                            break;
                        default:
                            CogoToast.error(t("parkingExtension.loadOptionsError"));
                            setLoading(false);
                            setOpen(false);
                            break;

                    }
                }

            })
        }
    }, [open]);

    const prepareFormData = (data) => {
        setData(data);
        setLoading(false);
        switch (type) {
            case "groups":
                prepareGroupData();
                break;
            case "user":
                prepareUserData();
                break;
            default:
                break;
        }
    }

    const prepareGroupData = () => {
        if (id && type === "groups") {
            setLoading(true);
            apiGetGroupsExtensionByGroupsId(2, actionId, id, (data) => {
                if (data.items) {
                    setFormData({items: data.items});
                }
                setLoading(false);
            }, (err) => {
                setLoading(false);
            })
        } else {
        }
    }

    const prepareUserData = () => {
        if (usersIds.length === 1) {
            setLoading(true);
            apiGetUserExtensionByUserId(actionId, usersIds[0], 2, (data) => {
                if (data.items) {
                    setFormData({items: data.items});
                }
                setLoading(false);

            }, (err) => {

                setLoading(false);
            })
        } else {
        }
    }

    const onClose = () => {
        setOpen(false);
    }

    const onOpen = () => {
        setOpen(true);
    }

    const onSuccessSave = (data) => {
        CogoToast.success(t("parkingExtension.assigned"));
        setSaving(false);
        onClose()
    }

    const onErrorSave = (err) => {
        setSaving(false);
        CogoToast.error(t("parkingExtension.assignedError"));
    }


    const onSave = (values) => {
        switch (type) {
            case "groups":
                apiPutAssignGroupsExtension(2, actionId, id, {
                    ...values,
                    actionId: actionId
                }, onSuccessSave, onErrorSave);
                break;
            case "user":
                apiPutAssignUserExtension(2, actionId, {
                    ...values,
                    usersIds: usersIds,
                    actionId: actionId
                }, onSuccessSave, onErrorSave);
                break;
        }
    }

    const _renderBody = () => {
        if (open) {
            if (loading) return <EnigooLoader text={t("mealExtension.loadingDetail")}/>
            else if (!data) return <div> NO DATA </div>
            else return <ParkingChooseForm name={"items"} type={type} initData={formData} onSave={onSave} submitRef={submit}
                                           days={days} extensionItems={data?.extension?.items?data.extension.items:[]} isMultiUser={usersIds && usersIds.length>1}/>
        } else {
            return <></>
        }
    }
    if (type === "groups") {
        return <>
            {values && values.assignDefaultParking &&
                <EnigooModal buttonIcon={icon} onSave={() => submit.current()}
                             buttonText={text !== null ? text : t("basics.detail")}
                             onOpen={onOpen} open={open}
                             onClose={onClose} disabled={!actionId}
                             title={t("parkingExtension.assign")}>
                    <div className={"p-2"}>
                        {_renderBody()}
                    </div>
                </EnigooModal>

            }
        </>
    } else return <EnigooModal buttonIcon={icon} onSave={() => submit.current()}
                               buttonText={text !== null ? text : t("basics.detail")}
                               onOpen={onOpen} open={open}
                               onClose={onClose} disabled={!usersIds || usersIds.length < 1 || !days}
                               title={t("parkingExtension.assign")}>
        <div className={"p-2"}>
            {_renderBody()}
        </div>
    </EnigooModal>

}
ParkingAssignModal.propTypes = {}
export default ParkingAssignModal;
