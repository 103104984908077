import React, {useEffect, useState} from 'react';
import {Form} from "react-final-form";
import {useHistory, useParams} from "react-router";
import CogoToast from "cogo-toast";
import EnigooButton from "../../../MyComponent/EnigooButton";
import {FiInfo, FiSave} from "react-icons/all";
import Widget from "../../../components/widget";
import {EnigooLoader} from "../../../MyComponent/EnigooLoader";
import {apiCreateTemplate, apiGetTemplateById, apiUpdateTemplate} from "./Actions";
import {IconTabs} from "../../../components/tabs";
import {EnigooTextField} from "../../../MyComponent/EnigooTextField";
import EnigooBreadcrumbs from "../../../MyComponent/EnigooBreadcrumbs";
import VerifyRights from "../../VerifyRights";
import {EnigooSelect} from "../../../MyComponent/EnigooSelect";
import {Trans, useTranslation} from "react-i18next";
import EnigooJsonView from "../../../MyComponent/JsonAttributes/EnigooJsonView";
import EnigooFormChangedAlert from "../../../MyComponent/EnigooFormChangedAlert";

const TemplateForm = () => {
    let [templateData, setTemplateDate] = useState(null);
    let [loading, setLoading] = useState(false);
    let [saving, setSaving] = useState(false);
    let submit;
    const history = useHistory();

    const {id} = useParams();
    const {t} = useTranslation()

    useEffect(() => {
        const handleKeyDown = (event) => {
            if ((event.ctrlKey || event.metaKey) && event.key === 's') {
                event.preventDefault();
                submit();
                console.log('Ctrl/Command + S was pressed');
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    useEffect(() => {
        init();
    }, []);

    const init = () => {
        if (id) {
            setLoading(true);
            apiGetTemplateById(id, (data) => {
                setTemplateDate(data);
                setLoading(false);
            }, (error) => {
                setSaving(false);
                CogoToast.error(t("template.loadingDataError"))
            })
        }
    }

    const onSubmitSuccess = (data,values) => {
        setTemplateDate(values)
        CogoToast.success(t("template.saved"));
        setSaving(false);
        history.push("/template/detail/" + data.id);
    };
    const onSubmitError = (error) => {
        CogoToast.error(t("template.saveError"))
        setSaving(false);
    }

    const onSubmit = (values) => {
        setSaving(true);
        if (id) {
            apiUpdateTemplate(id, values, (data)=>onSubmitSuccess(data,values), onSubmitError)
        } else {
            apiCreateTemplate(values, (data)=>onSubmitSuccess(data,values), onSubmitError)
        }
    }

    return (
        <Form onSubmit={onSubmit} initialValues={templateData}
              validate={values => {
                  let errors = {};
                  if (!values.name) {
                      errors.name = t("basics.requireField");
                  }
                  return errors;
              }
              } render={({handleSubmit, values,form,dirty}) => {
            submit = handleSubmit;
            return (
                <>
                    <EnigooBreadcrumbs
                        right={<VerifyRights page={"template"} action={id ? "updateTemplate" : "createTemplate"}>
                            <EnigooButton onClick={() => submit()} icon={<FiSave/>} text={t("basics.save")}
                                          loading={saving} disabled={saving || loading}/></VerifyRights>}/>
                    <EnigooFormChangedAlert onReset={() => form.reset(templateData)} onSave={handleSubmit}
                                            dirty={dirty}/>
                    <Widget>
                        {loading ? <EnigooLoader text={t("template.loadingData")}/> :
                            <div className={"flex flex-col"}>

                                <IconTabs tabs={tabs(values, submit, saving)}/>

                            </div>}
                    </Widget>
                </>
            );
        }
        }/>
    )
}
export default TemplateForm;

const TabBasicsInfo = ({values, submit, saving}) => {
    const {t} = useTranslation()
    const {id} = useParams();
    return (
        <div className={"w-full"}>
            <EnigooTextField label={t("basics.name") + ": *"} name={"name"} placeHolder={t("placeHolder.name")}/>
            <EnigooSelect label={t("template.pageSize") + ": "} name={"pageSize"} placeHolder={t("placeHolder.select")}
                          items={[
                              {label: "A4", value: "A4"},
                              {label: "A4-LandScape", value: "A4-L"},
                              {label: "A5", value: "A5"},
                              {label: "A5-LandScape", value: "A5-L"},
                              {label: "A6", value: "A6"},
                              {label: "A6-LandScape", value: "A6-L"}
                          ]} defaultValue={["value", "label"]}/>
            <EnigooJsonView label={t("templateJsonAttributes.label") + ":"} values={values}/>
            <VerifyRights page={"template"} action={id ? "updateTemplate" : "createTemplate"}> <EnigooButton
                extraStyle={"w-full mt-5"} onClick={() => submit()} icon={<FiSave/>} text={t("basics.save")}
                loading={saving} disabled={saving}/></VerifyRights>

        </div>
    );
}

const tabs = (values, submit, saving) => [
    {
        index: 0,
        content: <TabBasicsInfo values={values} submit={submit} saving={saving}/>,
        title: (<><FiInfo size={18} className={"stroke-current"}/><span
            className={"ml-2"}><Trans i18nKey={"basics.info"}>Základní informace</Trans></span> </>)
    }
];
