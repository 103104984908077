import React, {useEffect, useRef, useState} from "react";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import Portal from "../../../../components/portal";
import {FiX} from "react-icons/fi";
import EnigooButton from "../../../../MyComponent/EnigooButton";
import {FiSave} from "react-icons/all";
import CogoToast from "cogo-toast";
import {apiCopyUserToAction} from "../Actions";
import {Form} from "react-final-form";
import {ActionSelectField} from "../../../../MyComponent/Api/Admin/ActionSelectField";
import {useTranslation} from "react-i18next";

export const CopyUserToActionModal = ({
                                          setOpen,
                                          open,
                                          selectedIds,
                                          selectAll
                                      }) => {
    let [saving, setSaving] = useState(false);
    const dispatch = useDispatch();
    let [selectedAction, setSelectedAction] = useState(null);
    const {palettes, actualAction, filterUser} = useSelector(
        (state) => ({
            palettes: state.reducerAdmin.palettes,
            actualAction: state.reducerAdmin.actualAction,
            filterUser: state.reducerAdmin.filterUser
        }),
        shallowEqual
    )
    const {t} = useTranslation();
    let {background} = {
        ...palettes
    }
    let {filterData} = {
        ...filterUser
    }
    const modalRef = useRef(null)

    const hide = () => {
        setOpen(false)
    }

    const send = () => {
        if (selectedAction) {
            setSaving(true);
            let data = {};
            data.userId = selectedIds;
            data.actualAction = actualAction.actualAction;
            data.actionId = selectedAction;
            data.filterData = filterData;
            data.all = selectAll;
            apiCopyUserToAction(data, (data) => {
                CogoToast.success(t("accreditation.copyToActionComplete"));
                hide();
                setSaving(false);
                dispatch({
                    type: 'SET_RELOAD_ACCREDITATION',
                    reloadUserData: true
                })
            }, (error) => {
                CogoToast.error(t("accreditation.copyToActionError"));
                setSaving(false);
            })
        } else {
            CogoToast.error(t("accreditation.chooseGroupError"));
        }
    }

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (!modalRef || !modalRef.current) return false
            if (!open || modalRef.current.contains(event.target)) {
                return false
            }
            setOpen(!open)
        }
        document.addEventListener('mousedown', handleClickOutside)

        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [open, modalRef])

    return (
        <>
            {open && (
                <Portal selector="#portal">
                    <div className="modal-backdrop fade-in"></div>
                    <div className={`modal show ${background === 'dark' ? 'dark-mode' : ''}`}
                         data-background={background}>
                        <div className="relative min-w-sm w-1/3 mx-auto lg:max-w-5xl" ref={modalRef}>
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h3 className="text-xl font-semibold">{t("action.select")}</h3>
                                    <button
                                        className="modal-close btn btn-transparent"
                                        onClick={hide}>
                                        <FiX size={18} className="stroke-current"/>
                                    </button>
                                </div>
                                <div className={"max-h-sm overflow-y-scroll p-2 pb-10"}>
                                <Form onSubmit={() => {
                                }} render={() => (
                                    <div className={"m-2"}>
                                        <ActionSelectField type={"modal"}
                                                           onChange={(event) => setSelectedAction(event.value)}
                                                           name={"actionId"} label={t("action.single")+": *"} isMulti={false}/>
                                    </div>
                                )}/>
                                </div>

                                <div className="modal-footer space-x-2">
                                    <EnigooButton disabled={saving} loading={saving} onClick={hide} text={t("basics.cancel")}
                                                  icon={<FiX/>}/>
                                    <EnigooButton disabled={saving} loading={saving} onClick={() => send()}
                                                  text={t("basics.save")} icon={<FiSave/>}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </Portal>
            )}
        </>
    )
}
