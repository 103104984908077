import React from 'react'
import PropTypes from 'prop-types'
import {Field} from 'react-final-form'

export const  TextInput = ({
  inline = false,
  label = 'Label',
  name = 'name',
  type = 'text',
  placeholder = 'Enter something...',
  message = 'This is a hint',
    disabled = false,
    onKeyDown = null
}) => (
  <div className={`form-element ${inline ? 'form-element-inline' : ''}`}>
      <Field name={name}>
          {({input,meta})=>(
              <>
              <div className="form-label">{label}</div>
              <input
                  {...input}
                  onKeyDown={onKeyDown}
              name={name}
              type={type}
              className="form-input"
              placeholder={placeholder}
                  disabled={disabled}
              />
                  {meta.error && meta.touched && <div className="form-error">{meta.error}</div>}
              </>
          )}
      </Field>

  </div>
)
TextInput.propTypes = {
  inline: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  message: PropTypes.string
}

export const InvalidTextInput = ({
  inline = false,
  label = 'Label',
  name = 'name',
  type = 'text',
  placeholder = 'Enter something...',
  message = 'This is an error'
}) => (
  <div className={`form-element ${inline ? 'form-element-inline' : ''}`}>
      <Field name={name}>
          {({input,meta})=>(
              <>
                <div className="form-label">{label}</div>
                <input
                    {...input}
                  name={name}
                  type={type}
                  className="form-input form-input-invalid"
                  placeholder={placeholder}
                />
                {meta.error && meta.touched && <div className="form-error">{message}</div>}
              </>)}
      </Field>
  </div>

)
InvalidTextInput.propTypes = {
  inline: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  message: PropTypes.string
}

export const ValidTextInput = ({
  inline = false,
  label = 'Label',
  name = 'name',
  type = 'text',
  placeholder = 'Enter something...',
  message = 'This is ok'
}) => (
  <div className={`form-element ${inline ? 'form-element-inline' : ''}`}>
      <Field name={name}>
          {({input,meta})=>(
              <>
            <div className="form-label">{label}</div>
            <input
                {...input}
              name={name}
              type={type}
              className="form-input form-input-valid"
              placeholder={placeholder}
            />
                {meta.error && meta.touched && <div className="form-error">{message}</div>}
          </>)}
        </Field>
  </div>
)
InvalidTextInput.propTypes = {
  inline: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  message: PropTypes.string
}

