import React, {useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router";
import {apiPutAssignExtension} from "./Actions";
import CogoToast from "cogo-toast";
import MealChooseForm from "../MyComponent/MealChooseForm";
import EnigooButton from "../MyComponent/EnigooButton";
import {FiSave} from "react-icons/all";
import ParkingChooseForm from "../Admin/pages/ActionExtension/ParkingExtension/ParkingChooseForm";

const PreviewParking = ({allowSelect = true, userDays, parkingItems, userParking}) =>{
    let [saving,setSaving] = useState(false);

    const {t, i18n} = useTranslation()

    const {subjectKey} = useParams();
    const {bc} = useParams();

    let submit = useRef();


    const onSubmit = (values) => {
        if (bc && subjectKey) {
            setSaving(true);
            apiPutAssignExtension(2,subjectKey, bc, values,
                (data) => {
                    CogoToast.success(t("parkingExtension.assigned"),{hideAfter:1}).then(() => {

                        window.location.reload();
                    });
                    setSaving(false);

                }, (err) => {
                    CogoToast.error(t("parkingExtension.assignedError"));
                    setSaving(false);
                });
        }
    }

    return (<div className={"flex flex-col text-left"}>
                <div className={"w-full"}>{t("preview.selectedParking")}:</div>
            <ParkingChooseForm disabled={!allowSelect} name={"items"} onSave={onSubmit} submitRef={submit} days={userDays}
                            extensionItems={parkingItems} initData={userParking} tabs={userDays.length>1}/>
            <EnigooButton icon={<FiSave/>} text={t("preview.updateParking")} onClick={() => submit.current()}
                          disabled={!allowSelect || saving} loading={saving} visible={allowSelect}/>
        </div>

    )
}
PreviewParking.propTypes={

}
export default PreviewParking;
