import {axios} from "../axiosConfig";

const url = 'api/preview/v1';

export function apiPreviewGetUser(subjectKey,bc,callback,error){
    axios.get(url+"/user/"+subjectKey+"/"+bc)
        .then(response => callback(response.data.data))
        .catch(err=>error(err));
}


export const apiPutAssignExtension= (type, subjectKey, bc, data, callback, error)=>{
    axios.put(`${url}/user/${subjectKey}/${bc}/extension/${type}`,data)
        .then(response => callback(response.data.data))
        .catch(err=>error(err));
}
