import {axios} from "../../../axiosConfig";

const url = "api/admin/v1/action";

export function apiGetAction(callback, error) {
    axios.get(url)
        .then(response => callback(response.data.data))
        .catch(err => error(err))
}

export function apiGetActionById(id, callback, error) {
    axios.get(url + "/" + id)
        .then(response => callback(response.data.data))
        .catch(err => error(err))
}

export function apiCreateAction(data, callback, error) {
    axios.post(url,data)
        .then(response => callback(response.data.data))
        .catch(err => error(err));
}

export function apiUpdateAction(data,id,callback,error){
    axios.put(url+"/"+id,data)
        .then((response)=>{callback(response.data.data)})
        .catch((err)=>error(err))
}