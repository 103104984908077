import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import EnigooButton from "../../../MyComponent/EnigooButton";
import Widget from "../../../components/widget";
import {FiEdit, FiPlus} from "react-icons/fi";
import {EnigooLoader} from "../../../MyComponent/EnigooLoader";
import {apiGetAction} from "./Actions";
import CogoToast from "cogo-toast";
import EnigooBreadcrumbs from "../../../MyComponent/EnigooBreadcrumbs";
import VerifyRights from "../../VerifyRights";
import {useTranslation} from "react-i18next";
import EnigooTablev2 from "../../../MyComponent/EnigooTablev2";
import EnigooActiveField from "../../../MyComponent/EnigooActiveField";

const Action = (props) => {
    const history = useHistory();
    let [action, setAction] = useState([]);
    let [loading, setLoading] = useState(false);
    const {t} = useTranslation()
    useEffect(() => {
        getAction();
    }, []);

    const getAction = () => {
        setLoading(true);
        apiGetAction((data) => {
            setAction(data);
            setLoading(false);
        }, (error) => {
            CogoToast.error("Nepodařilo se načíst akce");
            setLoading(false);
            history.push("/");
        })
    }

    return (
        <>
            <EnigooBreadcrumbs
                right={<VerifyRights page={"action"} action={"createAction"}> <EnigooButton link={"/action/create"} icon={<FiPlus/>} text={t("basics.add")}/></VerifyRights>}/>
            <Widget>
                {loading ? <EnigooLoader text={t("basics.loadingData")}/> : <Table action={action}/>}
            </Widget>
        </>
    )
}

const Table = (props) => {
    const {t,i18n} = useTranslation()
    const columns =  React.useMemo(()=> [
        {header: t('basics.name'), accessorKey: 'name',id:"name"},
        {header: t('action.startOfAction'),id:"dateFrom",
            cell:({row})=>{
                return <>{formatDateTime(row.original.dateFrom)}</>
            }
            },
        { header: t('action.endOfAction'),id:"dateTo",
            cell:({row})=>{
                return <>{formatDateTime(row.original.dateTo)}</>
            }
            },
        {
          header: t("action.dividedIntoDays"),id:"divideIntoDays",
            cell:({row})=>{
                return <EnigooActiveField value={row.original.multiDay?1:0}/>
            }
        },
        {
            header: t('basics.options'),
            id: 'options',
            cell:({row})=>{
                return (
                    <EnigooButton link={"/action/detail/" + row.original.id} icon={<FiEdit/>}/>
                )
            }
        }
    ],[i18n.language]);
    const data = React.useMemo(()=>props.action,[]);
    return (
        <EnigooTablev2 selectable={false} paginated={true} data={data} columns={columns}/>
    )
}

const formatDateTime = (dateTimeInString) => {
    if (dateTimeInString) {
        let date = new Date(dateTimeInString);
        return date.toLocaleDateString() + " " + date.toLocaleTimeString();
    }
}

export default Action;
