import React from "react";
import {useTranslation} from "react-i18next";
import EnigooTablev2 from "../../../MyComponent/EnigooTablev2";

const UserCreditTransactionTable = ({data})=>{
    const {t,i18n} = useTranslation()

    const columns = React.useMemo(()=>[
        {header:t('device.single'),id:"device" ,cell:({row})=>(row.original.device?row.original.device:"-")},
        {header:t('adminUser.admin'),id:"admin", cell:({row})=>(row.original.adminUser?row.original.adminUser:"-")},
        {header:t('basics.date'),id:"date", accessorKey:'date'},
        {header:t('deviceReports.transactionType'),id:"transactionType", accessorKey:'transactionType'},
        {header:t('deviceReports.cost'),id:"price", accessorKey:'price'},
        {header:t('accreditation.single'), id:"user",accessorKey:'user'},
        {header:'BC',id:"bc", accessorKey:'bc'},
        {header:t('groups.single'),id:"groups", accessorKey:'groups'},
        {header:t('action.single'),id:"action", accessorKey:'action'},
    ],[i18n.language]);

    return <EnigooTablev2 columns={columns} data={data} selectable={false} />
}
export default UserCreditTransactionTable;
