import React, {useEffect, useState} from "react";
import {EnigooSelectMulti} from "../../EnigooSelectMulti";
import {EnigooSelect} from "../../EnigooSelect";
import PropTypes from "prop-types";
import {apiManageGetSubject} from "../../../Manage/pages/Subject/Actions";

export const ManageSubjectSelectField = ({
                                           label = "Subjekt: ",
                                           name,
                                           isMulti = false,
                                           outsideClassName = "w-full",
                                           onValueChange = null,
                                           required = false
                                       }) => {

    let [data, setData] = useState(null);
    let [loading, setLoading] = useState(false);

    useEffect(() => {
        get();
    }, [])

    const get = () => {
        setLoading(true);
        apiManageGetSubject((data) => {
            setData(data);
            setLoading(false);
        }, (error) => {
            setLoading(false);
        })
    }

    return (isMulti ?
        <EnigooSelectMulti label={label} name={name} disabled={loading} items={data}
                           outsideClassName={outsideClassName} onValueChange={onValueChange}
                           defaultValue={["id", "name"]} required={required}/> :
        <EnigooSelect label={label} name={name} disabled={loading} items={data}
                      outsideClassName={outsideClassName} onValueChange={onValueChange}
                      defaultValue={["id", "name"]} required={required}/>);

}

ManageSubjectSelectField.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    isMulti: PropTypes.bool.isRequired,
    outsideClassName: PropTypes.string,
    onValueChange: PropTypes.func
}
