import {axios} from "../../../axiosConfig";

const url = "api/admin/v1/groups";

export function apiGetGroup(callback, error){
    axios.get(url)
        .then(response=>callback(response.data.data))
        .catch(err=>error(err));
}

export function apiGetGroupById(id, callback, error){
    axios.get(url+"/"+id)
        .then(response=>callback(response.data.data))
        .catch(err=>error(err));
}

export function apiUpdateGroup(id, data, callback, error){
    axios.put(url+"/"+id,data)
        .then(response=>callback(response.data.data))
        .catch(err=>error(err));
}

export function apiCreateGroup(data, callback, error){
    axios.post(url,data)
        .then(response=>callback(response.data.data))
        .catch(err=>error(err));
}