import {axios} from "../../../axiosConfig";

const url = "api/admin/v1/subject";

export function apiGetSubject(callback, error){
    axios.get(url)
        .then(response=>callback(response.data.data))
        .catch(err=>error(err));
}

export function apiUpdateSubject(data,callback,error){
    axios.put(url,data)
        .then(response=>callback(response.data.data))
        .catch(err=>error(err));
}

export function apiGetAllSubject(callback,error){
    axios.get(url+"/all")
        .then(response=>callback(response.data.data))
        .catch(err=>error(err))
}
