import React from "react";
import {FiPlus} from "react-icons/fi";
import {FieldArray} from "react-final-form-arrays";
import DeviceTemplateCustomRuleValuesAssignField from "./DeviceTemplateCustomRuleValuesAssignField";
import {useTranslation} from "react-i18next";

const DeviceTemplateCustomRuleValues = ({doPush,name}) => {
    const {t} = useTranslation()

    const addValue = () => {
        doPush(`${name}.values`, {})
    }

    const ButtonAdd = () => (
        <div className={"form-element"}>
            <button
                onClick={() => {
                    addValue();
                }}
                className={"btn btn-default btn-rounded btn-icon hover:bg-enigoo-600 bg-enigoo-500 text-white"}>
                {t("basics.add")} <FiPlus size={10} className={"ml-2"}/>
            </button>
        </div>
    )

    return (
        <>
            <div className="flex flex-col mt-1 mb-2 w-full lg:space-x-2 text-sm">
                <div className={"form-label"}>Hodnoty:</div>
                <ButtonAdd/>
            </div>
            <FieldArray name={`${name}.values`}>
                {({fields}) => {
                    return (
                        <div
                            className={`grid text-sm mt-5 grid-cols-1 gap-2`}>
                            {fields.map((name, index) => (
                                <div key={name}>
                                    <DeviceTemplateCustomRuleValuesAssignField fields={fields} name={name} index={index}/>
                                </div>
                            ))}
                        </div>
                    )
                }
                }
            </FieldArray>
        </>
    )

}
export default DeviceTemplateCustomRuleValues;
