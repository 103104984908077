import React from "react";
import {useTranslation} from "react-i18next";
import {EnigooTextField} from "../../../../MyComponent/EnigooTextField";
import {FieldArray} from "react-final-form-arrays";
import {EnigooSelect} from "../../../../MyComponent/EnigooSelect";
import {Form} from "react-final-form";
import arrayMutators from "final-form-arrays";
import UserParkingExtensionRadioField from "./UserParkingExtensionRadioField";
import ParkingArrayRadioField from "./ParkingArrayRadioField";
import {IconTabs} from "../../../../components/tabs";
import EnigooTable from "../../../../MyComponent/EnigooTable";

const ParkingChooseForm = ({
                               extensionItems,
                               name,
                               days,
                               onSave,
                               initData,
                               submitRef,
                               disabled = false,
                               tabs = false,
                               newForm = true,
                               parentFormValues = null,
                               type = "user",
                                isMultiUser = false
                           }) => {

    const {t, i18n} = useTranslation();
    const getTranslate = (item) => {
        if (item[i18n.language]) {
            return item[i18n.language];
        } else {
            return item["cs"];
        }
    }

    const _renderItem = (item, dayIndex, itemIndex, fields, values) => {
        let selectedId = (values.items && values.items[dayIndex] && values.items[dayIndex].itemId) ? values.items[dayIndex].itemId : null;

        return <div className={"form element flex flex-col space-y-2"}>
            <div className={"hidden"}><EnigooTextField label={""}
                                                       name={`${name}[${dayIndex}].items[${itemIndex}].id`}
                                                       visible={true}
                                                       initValue={item.id}/></div>
            <div className={"hidden"}><EnigooTextField label={""}
                                                       name={`${name}[${dayIndex}].items[${itemIndex}].dateUsed`}
                                                       visible={true}/></div>

            {/*_renderOptions(item.options, dayIndex, itemIndex, item.label, fields)*/}
        </div>


    }

    const _renderOptions = (options, dayIndex, itemIndex, label, fields) => {
        let dateUsed = fields.value && fields.value[itemIndex] ? fields.value[itemIndex].dateUsed : null;
        let option = fields.value && fields.value[itemIndex] ? fields.value[itemIndex].option : null;

        return <>
            {dateUsed ? <div className={"flex flex-col space-y-2"}>
                    <div className={""}>{`${getTranslate(label)}:`}</div>
                    <div><b>{t("mealExtension.used") + ":"}</b> {dateUsed}</div>
                </div> :
                <UserParkingExtensionRadioField initValue={option} disabled={dateUsed || disabled}
                                                name={`${name}[${dayIndex}].items[${itemIndex}].option`}
                                                options={options}
                                                label={"Vyberte parkovací místo:"}/>}</>

    }

    const prepareItemsForSelect = (items) => {
        let array = [];
        if (items) {
            items.forEach((item, index) => {
                array.push({
                    id: item.id,
                    label: getTranslate(item.label)
                })
            })
        }

        return array;
    }

    const _renderDay = (day, dayIndex, values) => {
        if (!days || days.includes(day.day)) {
            let dateUsed = values[name] && values[name][dayIndex] && values[name][dayIndex].dateUsed ? values[name][dayIndex].dateUsed : null;
            let option = values[name] && values[name][dayIndex] && values[name][dayIndex].itemId ? values[name][dayIndex].itemId : null;
            return <div className={`form-element flex flex-row w-full rounded border p-2`} key={dayIndex}>
                {(day.day !== 0 && !tabs) &&
                    <div className={"form-label font-bold"}>{`${getTranslate(day.name)} (${day.day})`} </div>}
                <FieldArray name={`${name}[${dayIndex}].items`}>
                    {({fields}) => {
                        return <div className={"flex flex-col w-full space-y-2"}>
                            <div className={"hidden"}><EnigooTextField label={""} name={`${name}[${dayIndex}].day`}
                                                                       initValue={day.day}/></div>
                            <ParkingArrayRadioField name={`${name}[${dayIndex}].itemId`}
                                                    label={t("parkingExtension.parkArray") + ":"} options={day.items}
                                                    initValue={option} disabled={dateUsed || disabled}/>
                            {(option && type === "user" && !isMultiUser) &&
                                <EnigooTextField label={"SPZ:"} name={`${name}[${dayIndex}].data`} disabled={dateUsed || disabled}/>}
                            {day.items.map((item, itemIndex) => _renderItem(item, dayIndex, itemIndex, fields, values))}
                        </div>
                    }
                    }
                </FieldArray>
            </div>
        } else {
            return <></>
        }
    }

    const _renderTabs = (tabsValues) => {
        let tabs = [];
        extensionItems.forEach((item, index) => {
            if(!days || days.includes(item.day)) {
                tabs.push({
                    index: index,
                    title: `${getTranslate(item.name)} (${item.day})`,
                    content: _renderDay(item, index, tabsValues)
                })
            }
        })

        return <IconTabs tabs={tabs}/>
    }

    if (newForm) {

        return (
            <Form onSubmit={onSave ? onSave : () => {
            }} initialValues={initData}
                  mutators={{...arrayMutators}}
                  validate={(values) => {
                      let error = {};
                      if (values[name]) {
                          error.items = [];
                          values[name].forEach((item, index) => {
                              if (days && days.includes(item.day)) {
                                  error.items[index] = {};
                                  /*if (item.itemId && !item.data && type === "user" && !isMultiUser) {
                                      error.items[index].data = t("basics.requireField")
                                  }*/
                              }
                          })
                      }
                      return error;
                  }}
                  render={({handleSubmit, values}) => {
                      if (submitRef) submitRef.current = handleSubmit;

                      return <>
                          <div className={"flex flex-col space-y-2 p-2 text-sm"}><FieldArray name={name}>
                              {({fields}) => {
                                  if (tabs) {
                                      return _renderTabs(values);
                                  } else {
                                      return extensionItems.map((item, index) => _renderDay(item, index, values))
                                  }

                              }}
                          </FieldArray></div>
                      </>
                  }}/>
        )
    } else {
        return (
            <div className={"flex flex-col space-y-2 p-2 text-sm"}><FieldArray name={name}>
                {({fields}) => {
                    if (tabs) {
                        return _renderTabs(parentFormValues);
                    } else {
                        return extensionItems.map((item, index) => _renderDay(item, index, parentFormValues))
                    }

                }}
            </FieldArray></div>
        )
    }

}
ParkingChooseForm.propTypes = {}
export default ParkingChooseForm;
