import React from 'react'
import {useSelector, shallowEqual} from 'react-redux'
import Item from './item'
import Logo from './logo'
import '../../css/components/left-sidebar-1.css'
import VerifyRights from "../../Admin/VerifyRights";
import {useTranslation} from "react-i18next";
import {FiCalendar, FiCamera, FiDatabase, FiFileText, FiHome, FiSettings, FiUser, FiUsers} from "react-icons/fi";

const menuItems = [
    {
        title: 'Menu',
        items: [
            {
                url: '/',
                icon: <FiHome size={20}/>,
                title: 'basics.dashboard',
                items: [],
            },
            {
                url: '/',
                icon: <FiUser size={20}/>,
                title: 'accreditation.plural',
                page: 'user',
                action: ['getUser', 'checkUser'],
                items: [
                    {
                        url: '/accreditation',
                        title: 'accreditation.list',
                        page: 'user',
                        action: 'getUser',
                        items: []
                    },
                    {
                        url: '/accreditation/check',
                        title: 'accreditation.check',
                        page: 'user',
                        action: 'checkUser',
                        items: []
                    }
                ]
            },
            {
                url: '/',
                icon: <FiCalendar size={20}/>,
                title: 'action.plural',
                page: ['action', 'actionExtension'],
                action: ['getAction', 'getActionExtension'],
                items: [
                    {
                        url: '/action',
                        title: 'action.list',
                        page: 'action',
                        action: 'getAction',
                        items: []
                    },
                    {
                        url: '/action-extension',
                        title: 'actionExtension.pluralAction',
                        page: ['actionExtension','actionExtension'],
                        action: ['getActionExtension', 'getActionExtensionReport'],
                        onlyDisable:true,
                        extension: [1, 2],
                        items: []
                    }
                ]
            },
            {
                url: '/gallery',
                icon: <FiCamera size={20}/>,
                title: 'photogallery.single',
                page: 'image',
                action: 'getImage',
                items: []
            },
            {
                url: '/',
                icon: <FiUsers size={20}/>,
                title: 'groupsZones.plural',
                page: ['groups', 'zone'],
                action: ["getGroups", "getZone"],
                items: [
                    {
                        url: '/groups',
                        title: 'groups.plural',
                        page: 'groups',
                        action: 'getGroups',
                        items: []
                    },
                    {
                        url: '/zone',
                        title: 'zones.plural',
                        page: 'zone',
                        action: 'getZone',
                        items: []
                    }
                ]
            },
            {
                url: '/custom-field',
                icon: <FiFileText size={20}/>,
                title: 'userFields.plural',
                page: 'customField',
                action: 'getCustomField',
                items: []
            },
            {
                url: '/',
                icon: <FiDatabase size={20}/>,
                title: 'device.plural',
                page: ['device', 'device', 'deviceTemplate'],
                action: ['getDeviceKey', 'getDevice', 'getDeviceTemplate'],
                items: [
                    {
                        url: '/device/settings',
                        title: 'settings.single',
                        page: 'device',
                        action: 'getDeviceKey',
                        items: []
                    },
                    {
                        url: '/device',
                        title: 'device.plural',
                        page: 'device',
                        action: 'getDevice',
                        items: []
                    },
                    {
                        url: '/device/rules',
                        title: 'device.rules',
                        page: 'device',
                        action: 'getDeviceTemplate',
                        items: []
                    },
                    {
                        url: '/device/report',
                        title: "device.reports",
                        page: 'report',
                        action: "getReport",
                        items: []
                    }
                ]
            },
            {
                url: '/',
                icon: <FiSettings size={20}/>,
                title: 'settings.single',
                page: ['subject', 'adminUser', 'webChannel', 'template', 'mailTemplate'],
                action: ["getSubject", "getAdminUser", "getWebChannel", "getTemplate", 'getMailTemplate'],
                items: [
                    {
                        url: '/subject',
                        title: 'settings.subject',
                        page: 'subject',
                        action: 'getSubject',
                        items: []
                    },
                    {
                        url: '/admin-user',
                        title: 'users.usersSystem',
                        page: 'adminUser',
                        action: 'getAdminUser',
                        items: []
                    },
                    {
                        url: '/web-channel',
                        title: 'webChannels.plural',
                        page: 'webChannel',
                        action: 'getWebChannel',
                        items: []
                    },
                    {
                        url: '/template',
                        title: 'template.plural',
                        page: ['template', 'mailTemplate'],
                        action: ['getTemplate', 'getMailTemplate'],
                        items: []
                    }
                ]
            }
        ]
    }
];
const Sidebar = () => {
    const {t} = useTranslation();

    const {config, palettes} = useSelector(
        (state) => ({
            config: state.reducerAdmin.config,
            palettes: state.reducerAdmin.palettes
        }),
        shallowEqual
    )
    const {layout, collapsed} = {...config}

    return (
        <div className={`left-sidebar left-sidebar-1 fixed left-0 top-0 h-screen w-64 z-10 
        md:${!collapsed?"overflow-y-auto":""} lg:${!collapsed?"overflow-y-auto":""} xl:${!collapsed?"overflow-y-auto":""}`}>
            <Logo/>
            {menuItems.map((menu, i) => (
                <div key={i}>
                    <div className="left-sidebar-title">
                        <span>{t("menu.single")}</span>
                    </div>
                    <ul>
                        {menu.items.map((l0, a) => (
                            <li key={a} className="l0">
                                {!l0.action && !l0.page ? <Item {...l0}/> :
                                    <VerifyRights page={l0.page} action={l0.action} extension={l0.extension}><Item {...l0}/></VerifyRights>}
                                <ul>
                                    {l0.items.map((l1, b) => (
                                        <li key={b} className="l1">
                                            {!l1.action && !l1.page ? <Item {...l1}/> : <VerifyRights page={l1.page} onNoRights={l1.onlyDisable?<Item disabled={true} {...l1}/>:null}
                                                                                                      action={l1.action} extension={l1.extension}><Item {...l1}/></VerifyRights>}
                                            <ul>
                                                {l1.items.map((l2, c) => (
                                                    <li key={c} className="l2">
                                                        {!l2.action && !l2.page ? <Item {...l2}/> :
                                                            <VerifyRights page={l2.page} onNoRights={l2.onlyDisable?<Item disabled={true} {...l2}/>:null}
                                                                          action={l2.action} extension={l2.extension}><Item {...l2}/></VerifyRights>}
                                                        <ul>
                                                            {l2.items.map((l3, d) => (
                                                                <li key={d} className="l3">
                                                                    {!l3.action && !l3.page ? <Item {...l3}/> :
                                                                        <VerifyRights onNoRights={l3.onlyDisable?<Item disabled={true} {...l3}/>:null} page={l3.page} action={l3.action} extension={l3.extension}><Item {...l3}/></VerifyRights>}
                                                                    <ul>
                                                                        {l3.items.map((l4, e) => (
                                                                            <li key={e} className="l4">
                                                                                {!l4.action && !l4.page ?
                                                                                    <Item {...l4}/> :
                                                                                    <VerifyRights page={l4.page} onNoRights={l4.onlyDisable?<Item disabled={true} {...l4}/>:null}
                                                                                                  action={l4.action} extension={l4.extension}><Item {...l4}/></VerifyRights>}
                                                                            </li>
                                                                        ))}
                                                                    </ul>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </li>
                                                ))}
                                            </ul>
                                        </li>
                                    ))}
                                </ul>
                            </li>
                        ))}
                    </ul>
                </div>
            ))}
        </div>
    )
}

export default Sidebar
