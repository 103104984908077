import React, {useEffect, useState} from "react";
import {apiGetAction} from "../../../Admin/pages/Action/Actions";
import {EnigooSelectMulti} from "../../EnigooSelectMulti";
import {EnigooSelect} from "../../EnigooSelect";
import PropTypes from "prop-types";
import {shallowEqual, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";

export const ActionSelectField = ({
                                      label,
                                      name,
                                      isMulti = true,
                                      outsideClassName = "w-full",
                                        onChange = null,
                                      type="form"
                                  }) => {
    let [data, setData] = useState(null);
    let [loading, setLoading] = useState(false);
    //data z databaze
    useEffect(() => {
        get()
    }, [])

    const {actualAction} = useSelector(
        state => (state.reducerAdmin.actualAction),
        shallowEqual
    )
    const {t} = useTranslation()
    const get = () => {
        setLoading(true);
        apiGetAction((data) => {
            if(type==="modal"){
                let array = [];
                data.map((item,index)=>{
                    if(item.id!==actualAction){
                        array.push(item);
                    }
                })
                setData(array);
            }else{
                setData(data);
            }

            setLoading(false);
        }, (error) => {
            setLoading(false);
        })
    }
    return (isMulti ? <EnigooSelectMulti inModal={type==="modal"} placeHolder={t("placeHolder.action")} onValueChange={onChange} outsideClassName={outsideClassName} label={label} disabled={loading} name={name} items={data}/> :
        <EnigooSelect inModal={type==="modal"} placeHolder={t("placeHolder.action")} onValueChange={onChange} outsideClassName={outsideClassName} label={label} name={name} disabled={loading} items={data}/>);

}
ActionSelectField.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    isMulti: PropTypes.bool

}
