import React, {useEffect, useState} from 'react'
import {EnigooLoader} from "../MyComponent/EnigooLoader";
import {apiOnlineAuthorizateWebChannel} from "./pages/Registration/Actions";
import LayoutOnline from "../layouts/online";
import {OnlineLoginForm} from "./pages/Registration/OnlineLoginForm";
import {axios} from "../axiosConfig";
import {useParams} from "react-router";
import Routes from "./Routes";
import {useDispatch} from "react-redux";
import {useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";

const AppOnline = () => {
    let [loading, setLoading] = useState(true);
    let [token, setToken] = useState(null);
    let [errorMessage, setErrorMessage] = useState(null);

    const history = useHistory();
    const dispatch = useDispatch();
    const {id} = useParams();
    const {t,i18n} = useTranslation()

    useEffect(() => {
        authorize();
    }, [])

    const authorize = () => {
        if (!id) {
            setErrorMessage("Zadali jste nekorektní adresu");
        }
        let token = localStorage.getItem("ath-acc-en-on");
        if (!token) {
            setLoading(false);
        } else {
            axios.defaults.headers.common['authorization-online'] = token;
            apiOnlineAuthorizateWebChannel(id, (data) => {
                setToken(data.token);
                dispatch({type: 'SET_ONLINE_ERROR', value: null});
                setLoading(false);
            }, (error) => {
                i18n.changeLanguage("cs");
                if (error.response && error.response.data) {
                    switch (error.response.data.error) {
                        case "WEB_CHANNEL_NOT_ACTIVE":
                            dispatch({type: 'SET_ONLINE_ERROR', value: t("online.error.channelNotActive")});
                            setLoading(false);
                            break;
                        case "WEB_CHANNEL_NOT_ACTIVE_YET":
                            dispatch({type: 'SET_ONLINE_ERROR', value: t("online.error.channelNotActiveYet")});
                            setLoading(false);
                            break;
                        case "WEB_CHANNEL_IS_CLOSED":
                            dispatch({type: 'SET_ONLINE_ERROR', value: t("online.error.channelClosed")});
                            setLoading(false);
                            break;
                        case "AUTH_INVALID":
                        case "TOKEN_EXPIRED":
                        case "USED_WEB_CHANNEL_CODE":
                        case "TOKEN_INVALID":
                            axios.defaults.headers.common['authorization-online'] = null;
                            setToken(null);
                            localStorage.removeItem("ath-acc-en-on");
                            window.location.reload();
                            setLoading(false);
                            break;
                        case "WEB_CHANNEL_DOESNT_EXIST":
                            dispatch({type: 'SET_ONLINE_ERROR', value: t("online.error.checkChannelCode")});
                            setLoading(false);
                            break;
                        default:
                            dispatch({type: 'SET_ONLINE_ERROR', value: t("online.error.noData")});
                            setLoading(false);
                            break;
                    }
                }else{
                    localStorage.removeItem('ath-acc-en-on');
                    window.location.reload();
                    setLoading(false);
                }

            })
        }
    }
    if (errorMessage) return <LayoutOnline>
        <div className={"text-lg"}>{errorMessage}</div>
    </LayoutOnline>
    if (loading) return <LayoutOnline>
        <div className="min-h-screen w-full p-4"><EnigooLoader text={t("basics.loadingPage")}/></div>
    </LayoutOnline>
    if (!token) return <LayoutOnline><OnlineLoginForm setToken={setToken}/></LayoutOnline>;
    if (token) return <LayoutOnline><Routes/></LayoutOnline>;

}
export default AppOnline;
