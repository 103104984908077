export default function filterUser(
    state = {
        openFilter: true,
        filterData: null,
        filtered: false,
        reloadUserData: false
    },
    action
) {
    switch (action.type) {
        case "SET_FILTER_USER_ALL":
            return {...state, openFilter: action.openFilter, filterData: action.filterData, filtered: action.filtered}
        case "SET_OPEN_FILTER":
            return {...state, openFilter: action.openFilter}
        case "SET_FILTER_DATA":
            return {...state, filterData: action.filterData}
        case "SET_FILTERED":
            return {...state, filtered: action.filtered}
        case "SET_RELOAD_ACCREDITATION":
            return {...state, reloadUserData: action.reloadUserData}
        case "RESET_FILTER_USER":
        case "RESET":
            return {
                openFilter: true,
                filterData: null,
                filtered: false,
                reloadUserData: false
            }
        default:
            return state;
    }
}
