import React, {useEffect, useState} from 'react';
import {Form} from "react-final-form";
import {useHistory, useParams} from "react-router";
import CogoToast from "cogo-toast";
import EnigooButton from "../../../../MyComponent/EnigooButton";
import {FiCreditCard, FiInfo, FiSave, RiArrowGoBackLine} from "react-icons/all";
import Widget from "../../../../components/widget";
import {EnigooLoader} from "../../../../MyComponent/EnigooLoader";
import {IconTabs} from "../../../../components/tabs";
import {apiCreateUser, apiGetUserById, apiUpdateUser} from "../Actions";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import EnigooBreadcrumbs from "../../../../MyComponent/EnigooBreadcrumbs";
import {apiGetActionById} from "../../Action/Actions";
import {FiActivity, FiDelete, FiPrinter} from "react-icons/fi";
import {apiGetZone} from "../../Zone/Actions";
import {apiGetGroupById} from "../../Groups/Actions";
import {print, userPrint} from "../CollectiveOperations/UserPrint";
import TransactionTab from "../../UserCreditTransaction/TransactionTab";
import VerifyRights from "../../../VerifyRights";
import {UserPrintErrorModal} from "../CollectiveOperations/UserPrintErrorModal";
import {UserDeleteModal} from "../UserDeleteModal";
import {Trans, useTranslation} from "react-i18next";
import {apiCheckUser} from "../../UserCheck/Actions";
import TabImage from "./TabImage";
import TabBasicsInfo from "./TabBasicsInfo";
import EnigooFormChangedAlert from "../../../../MyComponent/EnigooFormChangedAlert";

const UserForm = () => {
    let [userData, setUserData] = useState({});
    let [loading, setLoading] = useState(false);
    let [printing, setPrinting] = useState(false);
    let [saving, setSaving] = useState(false);
    let [customFields, setCustomFields] = useState(null);
    let [zones, setZones] = useState(null);
    let [selectedGroup, setSelectedGroup] = useState(null);
    let [initImage, setInitImage] = useState(null);
    let [isCredit, setIsCredit] = useState(false);
    let [printError, setPrintError] = useState(null);
    let [openDeleteModal, setOpenDeleteModal] = useState(false);

    let setFormValue = null;

    const {actualAction} = useSelector(
        state => ({customField: state.reducerAdmin.customField, actualAction: state.reducerAdmin.actualAction}),
        shallowEqual
    )

    const {adminRights} = useSelector(
        state => ({adminRights: state.reducerAdmin.adminRights}),
        shallowEqual
    )
    const {t} = useTranslation()

    const dispatch = useDispatch();

    const history = useHistory();
    let submit;

    const {id} = useParams();
    const {bc} = useParams();

    useEffect(() => {
        const handleKeyDown = (event) => {
            if ((event.ctrlKey || event.metaKey) && event.key === 'p' && id) {
                event.preventDefault();
                userPrint([Number(id)], setPrinting, actualAction, setPrintError, "print")
                console.log('Ctrl/Command + P was pressed');
            }
            if ((event.ctrlKey || event.metaKey) && event.key === 's') {
                event.preventDefault();
                submit();
                console.log('Ctrl/Command + S was pressed');
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    const onSelectChange = (event) => {
        if (!event || !event.value) {
            setSelectedGroup(null);
        } else {
            apiGetGroupById(event.value, (data) => {
                zones.map((item, index) => {
                    setFormValue(`z-${item.id}`, false);
                })
                data.zones.map((item, index) => {
                    setFormValue(`z-${item.id}`, true);
                })
                setSelectedGroup(event.value);
            }, (error) => {
                setSelectedGroup(null);
            });
        }
    }


    useEffect(() => {
        setLoading(true);
        init();
        if (!bc) {
            getActionDetails();
        }
        getZones();
        setLoading(false);
    }, [id]);

    useEffect(() => {
        if (zones && userData) {
            let userDataCopy = userData;
            zones.forEach((item, index) => {
                if (!userDataCopy[`z-${item.id}`]) {
                    userDataCopy[`z-${item.id}`] = false;
                }
            })
            setUserData(userDataCopy);
        }

    }, [zones, userData])

    useEffect(() => {
        if (customFields && userData) {
            let userDataCopy = userData;
            customFields.forEach((item, index) => {
                if (item.customFieldType.type === "BOOL" && !userDataCopy[`cf-${item.id}`]) {
                    userDataCopy[`cf-${item.id}`] = false;
                }
            })
            setUserData(userDataCopy);
        }
    }, [customFields, userData])

    const onGetSuccess = (data) => {
        if (bc) {
            dispatch({
                type: "CHANGE_ACTION",
                actualAction: data.action.id
            });
            setCustomFields(data.action.customField);
            setIsCredit(data.action.isCredit);
        }
        if (!actualAction) {
            dispatch({
                type: "CHANGE_ACTION",
                actualAction: data.actionId
            });
        }

        const user = data;
        data.groups && setSelectedGroup(data.groups.id);
        user.group = data.groups ? data.groups.id : null;
        data.customField && data.customField.map((item, index) => {
            user[`cf-${item.id}`] = item.value;
        });
        data.zones && data.zones.map((item, index) => {
            user[`z-${item.id}`] = true;
        });

        setInitImage(data.image ? data.image : null);
        setUserData(user);
    }

    const init = () => {
        if (bc) {
            apiCheckUser(bc, onGetSuccess, (error) => {
                CogoToast.error(t("user.bcNotExist"), {backgroundColor: "#424242"})
                history.push("/accreditation/check");
            })
        } else if (id) {
            apiGetUserById(id, actualAction.actualAction, onGetSuccess, (error) => {
                CogoToast.error(t("user.cannotLoad"))
                history.push("/accreditation");
            })
        }
    }

    const getActionDetails = () => {
        actualAction.actualAction && apiGetActionById(actualAction.actualAction, (actionData) => {
            if (!bc && !id && actionData.customField) {
                actionData.customField.forEach((item, index) => {
                    if (item.customFieldType.type === "BOOL") {
                        setUserData({...userData, [`cf-${item.id}`]: false})
                    }
                })
            }
            setCustomFields(actionData.customField);
            setIsCredit(actionData.isCredit);
        }, (error) => {
            setCustomFields(null);
            dispatch({
                type: 'SET_ACTION',
                actualAction: null,
            })
        })
    }

    const getZones = () => {
        apiGetZone((zoneData) => {
            let array = [];
            zoneData.map((item, index) => {
                if (item.id === actualAction.actualAction || item.id === null) {
                    array = [...array, ...item.items];
                    if (!id && !bc) {
                        item.items.forEach((it, idx) => {
                            //s setUserData({...userData,[`z-${it.id}`]:false})
                        })
                    }
                }
            })
            setZones(array);
        }, (error) => {
            setZones(null);
        })
    }

    const onSubmit = (values) => {
        setSaving(true);
        let array = [];
        customFields.map((item, index) => {
            array.push({
                "id": item.id,
                "value": (values[`cf-${item.id}`] !== undefined || values[`cf-${item.id}`] !== null) ? values[`cf-${item.id}`] : null
            });
            //values[`cf-${item.id}`]=undefined;
        })
        values.customField = array;
        array = [];
        zones.map((item, index) => {
            if (values[`z-${item.id}`] === true) array.push(item.id);
        })
        values.zones = array;
        values.actionId = actualAction.actualAction;
        if (id || bc) {
            let updateValues = values;
            updateValues.imageId = updateValues.image ? updateValues.image.id : null;
            if (!updateValues.imageId) {
                updateValues.imageData = updateValues.image ? updateValues.image.data : null;
            }

            updateValues.image = undefined;
            let userId = id;
            if (bc) {
                userId = userData.id;
            }

            apiUpdateUser(userId, updateValues,
                (data) => {
                    CogoToast.success(t("accreditation.editComplete"));
                    setSaving(false);
                    if (bc) {
                        history.push("/accreditation/check/" + bc)
                    } else {
                        history.push("/accreditation/detail/" + data.id);
                    }
                    init()
                }, (error) => {
                    let msg = t("accreditation.editError");
                    if (error?.response?.data?.error) {
                        if (error.response.data.error === "EMAIL_NOT_VALID") {
                            msg += "\n" + t("accreditation.enterCorrectEmail")
                        }
                        if (error.response.data.error === "EMAIL_EXIST") msg += "\n" + t("accreditation.emailExist");
                    } else CogoToast.error(msg)
                    setSaving(false);
                })
        } else {
            let newValues = values;
            newValues.imageId = newValues.image ? newValues.image.id : null;
            if (!newValues.imageId) {
                newValues.imageData = newValues.image ? newValues.image.data : null;
            }

            newValues.image = undefined;
            apiCreateUser(newValues, (data) => {
                CogoToast.success(t("accreditation.created"));
                setSaving(false);
                history.push("/accreditation/detail/" + data.id);
                setUserData(newValues)
            }, (error) => {
                let msg = t("accreditation.createError");
                if (error?.response?.data?.error) {
                    if (error.response.data.error === "EMAIL_NOT_VALID") {
                        msg += "\n" + t("accreditation.enterCorrectEmail")
                    }
                    if (error.response.data.error === "EMAIL_EXIST") msg += "\n" + t("accreditation.emailExist");
                } else CogoToast.error(msg);
                setSaving(false);
            })
        }
    }

    const _renderTabs = (values) => {
        let array = [
            {
                index: 0,
                content: <TabBasicsInfo customFields={customFields} isCredit={isCredit} onSelectChange={onSelectChange}
                                        zones={zones} values={values}/>,
                title: (<><FiInfo size={18} className={"stroke-current"}/><span
                    className={"ml-2"}><Trans i18nKey={"basics.info"}>Základní informace</Trans></span> </>)
            }
        ];
        if (isCredit && (id)) {
            array.push({
                index: 1,
                content: <TransactionTab userId={id}/>,
                title: (<><FiCreditCard size={18} className={"stroke-current"}/><span className={"ml-2"}><Trans
                    i18nKey={"deviceReports.credit"}>Kredit</Trans></span></>)
            })
        }

        return array;
    }

    const _renderBody = (values) => {
        if (loading || !customFields || !actualAction) return <Widget><EnigooLoader
            text={t("basics.loadingData")}/></Widget>
        else return <div className={"flex flex-col"}>
            <>
                <div className={"lg:flex lg:flex-row"}>
                    <div className={"lg:w-2/3 w-full flex-col"}>
                        <Widget>
                            <IconTabs
                                tabs={_renderTabs(values)}/>
                        </Widget>
                    </div>
                    <TabImage initImage={initImage}/>
                </div>
            </>

        </div>
    }

    const _renderButtons = () => {
        if (id) {
            return <div className={"flex flex-row lg:flex-no-wrap flex-wrap justify-end space-x-1"}>
                <VerifyRights onlyForManageUser={true} page={"user"} action={"goToPreview"}>
                    <EnigooButton link={"/preview/" + adminRights.subject.key + "/cid/" + userData.bc}
                                  icon={<FiActivity/>} toolTip={t("action.showPreview")} newTab={true}
                                  text={"Preview"}/>
                </VerifyRights>
                <VerifyRights page={"user"} action={"deleteUser"}>
                    <EnigooButton color={"red"} onClick={() => setOpenDeleteModal(true)} icon={<FiDelete/>}
                                  text={t("basics.delete")}
                                  disabled={saving || loading}/>
                </VerifyRights>
                <VerifyRights page={"user"} action={"getUserPrintData"}><EnigooButton
                    onClick={() => userPrint([Number(id)], setPrinting, actualAction, setPrintError, "print")}
                    text={t("basics.print")} icon={<FiPrinter/>}
                    disabled={printing || loading} loading={printing}/></VerifyRights>
                <VerifyRights page={"user"} action={id ? "updateUser" : "createUser"}>
                    <EnigooButton onClick={() => submit()} icon={<FiSave/>} text={t("basics.save")}
                                  loading={saving} disabled={saving || loading}/>
                </VerifyRights>
            </div>
        } else if (bc) {
            return <>
                <EnigooButton onClick={() => {
                    history.push("/accreditation/check");
                }} color={"red"} icon={<RiArrowGoBackLine/>} text={t("basics.back")}/>
                <VerifyRights page={"user"} action={id ? "updateUser" : "createUser"}>
                    <EnigooButton onClick={() => {
                        submit()
                        history.push("/accreditation/check")
                    }
                    } icon={<FiSave/>} text={t("basics.save")}
                                  loading={saving} disabled={saving || loading}/>
                </VerifyRights>
                <VerifyRights page={"user"} action={"getUserPrintData"}><EnigooButton
                    onClick={() => {
                        submit()
                        userPrint([Number(userData.id)], setPrinting, actualAction, setPrintError, "print")
                        history.push("/accreditation/check")
                    }}
                    text={t("basics.print")} icon={<FiPrinter/>}
                    disabled={printing || loading} loading={printing}/></VerifyRights>
            </>

        } else {
            return <>
                <VerifyRights page={"user"} action={id ? "updateUser" : "createUser"}>
                    <EnigooButton onClick={() => submit()} icon={<FiSave/>} text={t("basics.save")}
                                  loading={saving} disabled={saving || loading}/>
                </VerifyRights>
            </>
        }
    }


    return (
        <>
            <Form onSubmit={onSubmit} initialValues={userData}
                  validate={values => {
                      let errors = {};
                      if (!values.firstname) {
                          errors.firstname = t("basics.requireField");
                      }
                      if (!values.lastname) {
                          errors.lastname = t("basics.requireField");
                      }
                      if (!values.email) {
                          errors.email = t("basics.requireField");
                      }
                      customFields && customFields.map((item, index) => {
                          if (item.isMandatory && (values[`cf-${item.id}`] === undefined || values[`cf-${item.id}`] === null)) {
                              errors[`cf-${item.id}`] = t("basics.requireField")
                          }
                      })
                      return errors;
                  }
                  }
                  mutators={{
                      setValue: ([field, value], state, {changeValue}) => {
                          changeValue(state, field, () => value);
                      }
                  }}
                  render={({form, handleSubmit, values, dirty}) => {
                      setFormValue = form.mutators.setValue;
                      submit = handleSubmit;
                      return (<>
                          <EnigooBreadcrumbs right={
                              <div className={"flex flex-row flex-wrap space-x-2"}>
                                  {_renderButtons()}
                              </div>
                          }/>
                          <EnigooFormChangedAlert dirty={dirty} onSave={handleSubmit}
                                                  onReset={() => form.reset(userData)}/>
                          <UserPrintErrorModal setError={setPrintError} error={printError}/>
                          <UserDeleteModal setOpen={setOpenDeleteModal} open={openDeleteModal}/>
                          {_renderBody(values)}

                      </>);
                  }
                  }/>
        </>

    )
}
export default UserForm;





