import React, {useEffect} from "react";
import withBreadcrumbs from 'react-router-breadcrumbs-hoc';
import {NavLink} from "react-router-dom";
import {FiChevronsRight} from "react-icons/fi";
import Widget from "../components/widget";
import {useTranslation} from "react-i18next";
import {shallowEqual, useSelector} from "react-redux";


const routes = [
    {path: "/", breadcrumb: 'basics.home'},
    {path: "/admin-user", breadcrumb: 'users.usersSystem'},
    {path: "/admin-user/create", breadcrumb: 'basics.newMale'},
    {path: "/admin-user/detail/:id", breadcrumb: 'basics.detail'},
    {path: "/custom-field", breadcrumb: 'userFields.plural'},
    {path: "/custom-field/create", breadcrumb: 'basics.newNeutral'},
    {path: "/custom-field/detail/:id", breadcrumb: 'basics.detail'},
    {path: "/action", breadcrumb: 'action.single'},
    {path: "/action/create", breadcrumb: 'basics.newFemale'},
    {path: "/action/detail/:id", breadcrumb: 'basics.detail'},
    {path: "/action-extension", breadcrumb: 'actionExtension.pluralAction'},
    {path: "/action-extension/report", breadcrumb: 'device.reports'},
    {path: "/action-extension/meal", breadcrumb: 'mealExtension.plural'},
    {path: "/action-extension/parking", breadcrumb: 'parkingExtension.plural'},
    {path: "/action-extension/meal/:actionId/users", breadcrumb: 'users.plural'},
    {path: "/action-extension/parking/:actionId/users", breadcrumb: 'users.plural'},
    {path: "/action-extension/meal/detail/:actionId", breadcrumb: 'basics.detail'},
    {path: "/action-extension/parking/detail/:actionId", breadcrumb: 'basics.detail'},
    {path: "/action-extension/create", breadcrumb: 'basics.newNeutral'},
    {path: "/action-extension/detail/:id", breadcrumb: 'basics.detail'},
    {path: "/groups", breadcrumb: 'groups.plural'},
    {path: "/groups/create", breadcrumb: 'basics.newFemale'},
    {path: "/groups/detail/:id", breadcrumb: 'basics.detail'},
    {path: "/template", breadcrumb: 'template.plural'},
    {path: "/template/create", breadcrumb: 'basics.newFemale'},
    {path: "/template/detail/:id", breadcrumb: 'basics.detail'},
    {path: "/mail-template", breadcrumb: 'mailTemplate.plural'},
    {path: "/mail-template/create", breadcrumb: 'basics.newFemale'},
    {path: "/mail-template/detail/:id", breadcrumb: 'basics.detail'},
    {path: "/zone", breadcrumb: 'zones.plural'},
    {path: "/zone/create", breadcrumb: 'basics.newFemale'},
    {path: "/zone/detail/:id", breadcrumb: 'basics.detail'},
    {path: "/web-channel", breadcrumb: 'webChannels.plural'},
    {path: "/web-channel/create", breadcrumb: 'basics.newMale'},
    {path: "/web-channel/detail/:id", breadcrumb: 'basics.detail'},
    {path: "/accreditation", breadcrumb: 'basics.accreditation'},
    {path: "/accreditation/create", breadcrumb: 'basics.newFemale'},
    {path: "/accreditation/import", breadcrumb: 'basics.import'},
    {path: "/accreditation/check", breadcrumb: 'accreditation.check'},
    {path: "/accreditation/check/:bc", breadcrumb: 'basics.detail'},
    {path: "/accreditation/detail/:id", breadcrumb: 'basics.detail'},
    {path: "/subject", breadcrumb: 'settings.subject'},
    {path: "/gallery", breadcrumb: 'photogallery.single'},
    {path: "/gallery/detail/:id", breadcrumb: 'photo.detail'},
    {path: "/gallery/add", breadcrumb: 'basics.newFemale'},
    {path: "/user-profile", breadcrumb: 'basics.profile'},
    {path: "/device", breadcrumb: 'device.plural'},
    {path: "/device/settings", breadcrumb: 'settings.plural'},
    {path: "/device/report", breadcrumb: 'device.reports'},
    {path: "/device/detail/:id", breadcrumb: 'basics.detail'},
    {path: "/device/rules", breadcrumb: 'device.rules'},
    {path: "/device/rules/detail/:id", breadcrumb: 'basics.detail'},
    {path: "/device/rules/create", breadcrumb: 'basics.newNeutral'},
];

// map, render, and wrap your breadcrumb components however you want.
const EnigooBreadcrumbs = ({breadcrumbs, right}) => {
    let actualPath = window.location.pathname;

    const {config, palettes} = useSelector(
        (state) => ({
            config: state.reducerAdmin.config,
            palettes: state.reducerAdmin.palettes
        }),
        shallowEqual
    )
    const {layout, collapsed} = {...config}
    useEffect(()=>{

    },[])

    const {t} = useTranslation();

    return (
        <div className={`fixed top-0 right-0 left-0 pt-20 z-40 lg:px-3 px-1 bg-grey-50 md:${!collapsed?"ml-64":"ml-16"} lg:${!collapsed?"ml-64":"ml-16"}`} style={{zIndex:1}}>
            <Widget border={"border-grey-300 shadow"}>
                <div className="flex flex-row items-center justify-between">
                    <div className="flex flex-row flex-wrap">
                        {breadcrumbs.map(({
                                              match,
                                              breadcrumb
                                          }) => (
                            <span key={match.url} className={"flex flex-row items-center justify-start"}>
                            <div className={actualPath === match.url ? "font-bold text-xl" : ""}>
                            <NavLink to={match.url==="/mail-template"?"/template":match.url}>{t(breadcrumb.props.children)}</NavLink></div>
                                {actualPath !== match.url ?
                                    <FiChevronsRight className="h-3 w-3 mr-2 stroke-current"/> : <></>}
                            </span>
                        ))}
                    </div>
                    <span className="ml-auto"></span>
                    {right}
                </div>
            </Widget>
        </div>
    )
};

export default withBreadcrumbs(routes, {disableDefaults: true})(EnigooBreadcrumbs);
