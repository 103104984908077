import {Field} from "react-final-form";
import PropTypes from "prop-types";
import React from "react";
import Prism from 'prismjs';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-javascript';
import "prismjs/themes/prism.css";
import ReactCodeMirror from "@uiw/react-codemirror";
import {html} from "@codemirror/lang-html";
import {quietlight} from "@uiw/codemirror-theme-quietlight"
import pretty from 'pretty'
import EnigooButton from "./EnigooButton";
import {useTranslation} from "react-i18next";
import {CgFormatJustify} from "react-icons/all";

export const EnigooHtmlTextArray = ({
                                        inline = false,
                                        label,
                                        name,
                                        placeHolder = 'Zadejte text...',
                                        disabled = false,
                                        outsideClassName = "w-full",
                                        onChange = null,
                                        visible = true
                                    }) => {
    const {t} = useTranslation();
    return(
        <div className={`form-element ${inline ? 'form-element-inline' : ''}`}>
            <Field name={name}>
                {(properties) => {
                    onChange && onChange(properties.input.value);
                    return (visible &&
                        <>
                            <div className="form-label">{label}</div>

                            <div
                                className={properties.meta.error && properties.meta.touched ? "form-input form-input-invalid" : "form-input"}>
                                <div className={"flex flex-row space-x-2 mb-2 w-full border-b-2"}><EnigooButton icon={<CgFormatJustify/>} text={t("templateJsonAttributes.formatCode")}
                                                                                              onClick={() => {
                                                                                                  let code = pretty(properties.input.value)
                                                                                                  properties.input.onChange(code);
                                                                                                  onChange && onChange(code);
                                                                                              }}/></div>
                                <ReactCodeMirror value={properties.input.value} extensions={[html({autoCloseTags: true})]}
                                                 onChange={code => {
                                                     properties.input.onChange(code);
                                                     onChange && onChange(code);
                                                 }} theme={quietlight} maxHeight={"500px"}/>
                            </div>
                            {properties.meta.error && properties.meta.touched &&
                                <div className="form-error">{properties.meta.error}</div>}
                        </>
                    )
                }}
            </Field>
        </div>
    )
}
EnigooHtmlTextArray.propTypes = {
    inline: PropTypes.bool,
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    placeHolder: PropTypes.string,
    disabled: PropTypes.bool,
    outsideClassName: PropTypes.string
}
