import {Trans, useTranslation} from "react-i18next";
import {EnigooTextField} from "../../../../MyComponent/EnigooTextField";
import {EnigooEmailField} from "../../../../MyComponent/EnigooEmailField";
import {EnigooCustomField} from "../../../../MyComponent/EnigooCustomField";
import VerifyRights from "../../../VerifyRights";
import EnigooCreditPanel from "../../../../MyComponent/EnigooCreditPanel";
import {IconTabs} from "../../../../components/tabs";
import React from "react";
import PropTypes from "prop-types";
import {FiCircle} from "react-icons/fi";
import TabZone from "./TabZone";
import {shallowEqual, useSelector} from "react-redux";
import ActionDaysSelect from "./ActionDaysSelect";

const TabBasicsInfo = ({customFields, isCredit, zones, onSelectChange,values}) => {
    const {t} = useTranslation()
    const {actualAction} = useSelector(
        state => ({customField: state.reducerAdmin.customField, actualAction: state.reducerAdmin.actualAction}),
        shallowEqual
    )
    return (
        <>
            <div className={"grid lg:grid-cols-3 grid-cols-1 gap-2"}>
                <EnigooTextField label={t("basics.firstname") + ": *"} name={"firstname"}
                                 placeHolder={t("placeHolder.firstname")}/>
                <EnigooTextField label={t("basics.lastname") + ": *"} name={"lastname"}
                                 placeHolder={t("placeHolder.lastname")}/>
                <EnigooEmailField label={t("basics.email") + ": *"} name={"email"}
                                  placeholder={t("placeHolder.email")} mandatory={true}/>
            </div>

            <div className="grid lg:grid-cols-3 lg:gap-2">
                {customFields && customFields.map((item, index) => (
                    <EnigooCustomField customField={item} key={index.toString()} outsideClassName={"w-full"}/>
                ))}
            </div>
            <ActionDaysSelect actionId={actualAction.actualAction} toolTip={t("action.daysToolTip")} values={values}/>

            <VerifyRights page={"userCreditTransaction"} action={"getUserCreditTransaction"}>
                {isCredit && <EnigooCreditPanel name={"credit"}/>}
            </VerifyRights>
            <div style={{"marginLeft": '-1rem', "marginRight": '-1rem'}}><IconTabs
                tabs={tabsZone(zones, onSelectChange)}/></div>

        </>
    );
}
const tabsZone = (zones, onSelectChange) => [
    {
        index: 0,
        content: <TabZone zones={zones} onSelectChange={onSelectChange}/>,
        title: (<><FiCircle size={18} className={"stroke-current"}/><span
            className={"ml-2"}><Trans i18nKey={"zones.accreditation"}>Akreditační zény</Trans></span> </>)
    }
]
TabBasicsInfo.propTypes={
    customFields:PropTypes.array,
    isCredit:PropTypes.bool,
    zones:PropTypes.array,
    onSelectChange:PropTypes.func,
    values: PropTypes.any
}
export default TabBasicsInfo;
