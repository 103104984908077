import React, {useEffect, useState} from "react";
import EnigooModal from "../../../../MyComponent/Modal/EnigooModal";
import {useTranslation} from "react-i18next";
import PropTypes from "prop-types";
import {apiGetActionById} from "../../Action/Actions";
import {Form} from "react-final-form";
import Widget from "../../../../components/widget";
import ActionDaysSelect from "../Form/ActionDaysSelect";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {apiUserAssignDays} from "../Actions";
import CogoToast from "cogo-toast";
import {MdFormatListBulleted} from "react-icons/all";

const UserAssignDaysModal = ({selectedRows, actionId,
                                 selectAll}) => {
    const {t} = useTranslation();
    let [saving, setSaving] = useState(false);
    let [loading, setLoading] = useState(false);
    let [action, setAction] = useState(null);
    let [open, setOpen] = useState(false);
    const dispatch = useDispatch();

    let submit = null;

    useEffect(() => {
        setLoading(true);
        apiGetActionById(actionId, (data) => {
            setAction(data);
            setLoading(false);
        }, (err) => {
            setAction(null);
            setLoading(false);
        })
    }, [actionId]);

    const {palettes, actualAction, filterUser} = useSelector(
        (state) => ({
            palettes: state.reducerAdmin.palettes,
            actualAction: state.reducerAdmin.actualAction,
            filterUser: state.reducerAdmin.filterUser
        }),
        shallowEqual
    )
    let {filterData} = {
        ...filterUser
    }

    const onSave = (values) => {
        setSaving(true);
        let data = {};
        data.userId = selectedRows;
        data.actualAction = actualAction.actualAction;
        data.actionId = actionId;
        data.filterData = filterData;
        data.all = selectAll;
        data.days = values.days?values.days:[];
        console.log(data);
        apiUserAssignDays(data,(data)=>{
            CogoToast.success(t("accreditation.assignDaysSuccess"));
            onClose();
            dispatch({
                type: 'SET_RELOAD_ACCREDITATION',
                reloadUserData: true
            })
            setSaving(false);
        },(err)=>{
            CogoToast.error(t("accreditation.assignDaysError"));
            setSaving(false);
        })
    }

    const onClose = () =>{
        setOpen(false);
    }
    const onOpen = () => {
        setOpen(true);
    }

    if (action && action.multiDay) {
        return (
            <EnigooModal open={open} onOpen={onOpen} onClose={onClose} buttonText={t("accreditation.assignDays")}
                         title={t("accreditation.assignDays")}
                         disabled={!selectedRows || selectedRows.length === 0 || loading || saving}
                         buttonIcon={<MdFormatListBulleted/>} type={"dropdown"}
                         onSave={()=>submit()}>
                <Widget>
                    <div className="flex flex-col">
                        <Form onSubmit={onSave}
                              render={({handleSubmit}) => {
                                  submit = handleSubmit;
                                  return (
                                      <>
                                          <ActionDaysSelect inModal={true} actionId={actionId} name={"days"}
                                                            label={t("action.days") + ": *"} type={"modal"}/>
                                      </>
                                  );
                              }}
                        />
                    </div>

                </Widget>
            </EnigooModal>
        )
    } else {
        return <></>
    }
}
UserAssignDaysModal.propTypes = {
    selectedRows: PropTypes.array,
    actionId: PropTypes.number
}
export default UserAssignDaysModal;
