import React from "react";
import {CustomFieldSelectField} from "../../../MyComponent/Api/Admin/CustomFieldSelectField";
import {EnigooSelect} from "../../../MyComponent/EnigooSelect";
import DeviceTemplateCustomRuleValues from "./DeviceTemplateCustomRuleValues";
import {FiCopy, FiHelpCircle} from "react-icons/fi";
import {useTranslation} from "react-i18next";
import {shallowEqual, useSelector} from "react-redux";
import EnigooToolTip from "../../../MyComponent/EnigooToolTip";

const DeviceTemplateCustomRuleAssignField = ({fields, name, index, doPush}) => {
    const {t} = useTranslation()
    const toolTip =
        "<p><b>Typ vlastního pravidla:</b></p><ul> <li><b>Datum: </b>Hodnota pro ověření se porovnává s dnešním datem a musí být zadána ve formátu YYYY-MM-DD</li> </ul>";

    const {palettes} = useSelector(
        (state) => ({
            palettes: state.reducerAdmin.palettes
        }),
        shallowEqual
    )

    const {background,leftSidebar,navbar} = palettes;

    let isDark = React.useMemo(()=>{
        return background==="dark" && leftSidebar==="dark" && navbar==="dark";
    },[background,leftSidebar,navbar])

    return (
        <div className={`text-sm form-input ${isDark?"bg-grey-800":""}`}>
            <div className={"grid grid-cols-2"}>
                <div className={"flex flex-row"}>
                    <div className={"form-label"}>{t("deviceTemplate.customRule")} #{index + 1}:</div>
                    <div><FiHelpCircle className={"ml-2 text-red-900"} size={18}
                                       data-tip data-for={"_tooltip_device_template_assign"}/><EnigooToolTip id={"_tooltip_device_template_assign"} place={"right"} content={t("deviceTemplate.customRuleToolTip")}/></div>
                </div>
                <div className={"flex flex-row space-x-2 justify-end w-full"}>
                    <div
                        className={"text-right"}
                        style={{cursor: "pointer"}}>
                        <div onClick={() => {
                            if(fields.value&&fields.value[index]) {
                                fields.push(fields.value[index])
                            }
                        }}> <FiCopy size={15}/>
                        </div>
                    </div>
                    <div
                        className={"text-right"}
                        style={{cursor: "pointer"}}>
                        <div onClick={() => {
                            fields.remove(index)
                        }}> ❌
                        </div>
                    </div>
                </div>
            </div>
            <div className={"grid grid-cols-2 text-sm mt-5 gap-2"}>
                <CustomFieldSelectField isMulti={false} name={`${name}.customField`} label={t("userFields.singleShortCut")+":"}/>
                <EnigooSelect placeHolder={t("placeHolder.select")} label={t("basics.type")+": "} name={`${name}.type`} items={[{"label": t("basics.date"), "value": "date"}]}
                              defaultValue={["value", "label"]} data-tip={toolTip}/>
            </div>
            <DeviceTemplateCustomRuleValues doPush={doPush} name={name}/>
        </div>
    )
}
export default DeviceTemplateCustomRuleAssignField;
