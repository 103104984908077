import PropTypes from "prop-types";
import React from "react";
import {EnigooTextField} from "./EnigooTextField";
import {EnigooSelectMulti} from "./EnigooSelectMulti";
import {EnigooEmailField} from "./EnigooEmailField";
import {EnigooSwitch} from "./EnigooSwitch";
import {EnigooPhoneField} from "./EnigooPhoneField";
import {EnigooSelect} from "./EnigooSelect";
import EnigooAddressField from "./EnigooAddressField";
import {useTranslation} from "react-i18next";
import {shallowEqual, useSelector} from "react-redux";

export const EnigooCustomField = ({
                                      inline = false,
                                      disabled = false,
                                      outsideClassName = "w-full",
                                      visible = true,
                                      customField,
                                      filter = false,
                                      name = null,
                                      onKeyDown = null,
                                      type = "admin",
                                      label = null,
                                  }) => {
    const {t, i18n} = useTranslation()

    const {onlineConfig} = useSelector(
        (state) => ({
            onlineConfig: state.reducerOnline.onlineConfig
        }),
        shallowEqual
    )
    const getPlaceHolder = () => {
        let cfPlaceHolder = "";
        switch (customField.customFieldType.type) {
            case "TEXT":
            case "NUMBER":
            case "SELECT_MULTI":
            case "SELECT":
            case "ADDRESS":
            case "EMAIL":
            case "BOOL":
            case "PHONE":
            default:
                return ""

        }
    }

    const getOptionsArray = (customField) => {
        let array = [];
        customField.options.map((item, index) => {
            array.push({
                "id": item.id,
                "name": item.value
            })
        })
        return array;
    }

    const getLabel = () => {
        if (label) {
            return label;
        } else {
            let cfLabel = customField.isMandatory && !filter ? customField.label + ": *" : customField.label + ":";
            if (customField.label && customField.label[i18n.language]) {
                cfLabel = customField.label[i18n.language]
            } else {
                cfLabel = customField.label.cs;
            }
            cfLabel += customField.isMandatory ? ": *" : ":";
            return cfLabel;
        }
    }
    if (customField.customFieldType) {
        let cfLabel = getLabel();
        let cfPlaceHolder = getPlaceHolder();
        let cfName = name ? name : `cf-${customField.id}`;

        switch (customField.customFieldType.type) {
            case "TEXT":
                return <EnigooTextField label={cfLabel} name={cfName} inline={inline}
                                        outsideClassName={outsideClassName}
                                        disabled={disabled} type={'text'} placeHolder={t("placeHolder.text")}
                                        visible={visible} onKeyDown={onKeyDown}/>;
            case "NUMBER":
                return <EnigooTextField label={cfLabel} name={cfName} inline={inline}
                                        outsideClassName={outsideClassName}
                                        disabled={disabled} type={'number'} placeHolder={t("placeHolder.number")}
                                        visible={visible} onKeyDown={onKeyDown}/>;
            case "SELECT_MULTI":
                return <EnigooSelectMulti label={cfLabel} name={cfName}
                                          inline={inline} outsideClassName={outsideClassName}
                                          items={customField.options} defaultValue={["value", "value"]}
                                          placeHolder={t("placeHolder.selectMulti")} onKeyDown={onKeyDown}/>;
            case "SELECT":
                return <EnigooSelect label={cfLabel} name={cfName} inline={inline}
                                     outsideClassName={outsideClassName}
                                     items={customField.options} defaultValue={["value", "value"]}
                                     placeHolder={t("placeHolder.select")} onKeyDown={onKeyDown}/>;
            case "ADDRESS":
                if (filter) {
                    return <EnigooTextField label={cfLabel} name={cfName} inline={inline}
                                            outsideClassName={outsideClassName}
                                            disabled={disabled} type={'text'} placeHolder={t("placeHolder.text")}
                                            visible={visible} onKeyDown={onKeyDown}/>
                } else {
                    return <EnigooAddressField label={cfLabel} name={cfName} isMandatory={customField.isMandatory} onKeyDown={onKeyDown}/>
                }
            case "EMAIL":
                if (filter) {
                    return <EnigooTextField label={cfLabel} name={cfName} inline={inline}
                                            outsideClassName={outsideClassName}
                                            disabled={disabled} type={'text'} placeHolder={t("placeHolder.text")}
                                            visible={visible} onKeyDown={onKeyDown}/>
                } else {
                    return <EnigooEmailField label={cfLabel} name={cfName}
                                             inline={inline} outsideClassName={outsideClassName}
                                             placeholder={t("placeHolder.email")} mandatory={customField.isMandatory} onKeyDown={onKeyDown}/>;
                }

            case "BOOL":
                if (filter)
                    return <EnigooSelect label={cfLabel} name={cfName} inline={inline}
                                         outsideClassName={outsideClassName}
                                         items={[{value: 1, label: "ANO"}, {value: -1, label: "NE"}]}
                                         defaultValue={["value", "label"]} onKeyDown={onKeyDown}/>;
                else return <EnigooSwitch label={cfLabel} name={cfName} inline={inline}
                                          outsideClassName={outsideClassName} onKeyDown={onKeyDown}/>;
            case "PHONE":
                return <EnigooPhoneField label={cfLabel} name={cfName} limitMaxLength={true} mandatory={customField.isMandatory}
                                         inline={inline} outsideClassName={outsideClassName}
                                         placeHolder={t("placeHolder.phone")} onKeyDown={onKeyDown}/>;
            default:
                return <></>

        }
    } else {
        return (<></>);
    }
}
EnigooTextField.propTypes = {
    inline: PropTypes.bool,
    disabled: PropTypes.bool,
    outsideClassName: PropTypes.string,
    visible: PropTypes.bool,
    customField: PropTypes.any,
    filter: PropTypes.bool
}
