import React, {useEffect, useState} from "react";
import Dropdown from "../../../components/dropdowns/dropdown-1";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {apiGetAllSubject} from "../../../Admin/pages/Subject/Actions";
import {apiPostChangeAdminUserSubject} from "../../../Admin/pages/AdminUser/Actions";
import CogoToast from "cogo-toast";
import {useHistory} from "react-router";
import {useTranslation} from "react-i18next";

const EnigooSubjectDropDown = ({}) => {

    let [loading, setLoading] = useState(false);
    let [subjects, setSubjects] = useState(null);
    let [changing, setChanging] = useState(false);

    const dispatch = useDispatch();
    const history = useHistory();
    const {t,i18n} = useTranslation()

    const {adminRights} = useSelector(
        state => ({adminRights: state.reducerAdmin.adminRights}),
        shallowEqual
    )
    const {palettes} = useSelector(
        (state) => ({
            palettes: state.reducerAdmin.palettes
        }),
        shallowEqual
    )

    const {background,leftSidebar,navbar} = palettes;

    let isDark = React.useMemo(()=>{
        return background==="dark" && leftSidebar==="dark" && navbar==="dark";
    },[background,leftSidebar,navbar])

    useEffect(() => {
        getSubjects();
    }, [])

    const getSubjectName = () => {
        if (adminRights && adminRights.subject && adminRights.subject.name) {
            return adminRights.subject.name;
        } else {
            return "-";
        }
    }

    const getSubjects = () => {
        if (!adminRights.manageuser) {
            setSubjects([]);
        } else {
            setLoading(true);
            apiGetAllSubject((data) => {
                setSubjects(data);
                setLoading(false);
            }, (err) => {
                setSubjects(null);
                setLoading(false);
            })
        }
    }

    const changeSubject = (subject) => {
        setChanging(true);

        //odešli na api
        apiPostChangeAdminUserSubject({subjectId: subject.id}, (data) => {
            if(!data.multiLanguage){
                i18n.changeLanguage("cs");
            }
            setChanging(false);
            CogoToast.success(t("subject.changedSubject"));
            history.push("/");
            dispatch({
                type: "SET_SUBJECT",
                subject: subject
            });
            dispatch({
                type: "RESET_ACTUAL_ACTION"
            })
            dispatch({
                type: "RESET_FILTER_USER"
            })

        }, (error) => {
            CogoToast.error(t("subject.errorChangeSubject"));
            setChanging(false);
        })
    }

    if (!adminRights.manageuser) return <></>;

    if (loading) return <></>;
    if (!subjects) return <></>;
    if (subjects && subjects.length === 0) return <></>;
    if (subjects) {
        return <>
            <>
                <div className="flex flex-row">
                    <div className={"min-w-xxs"}>
                        <Dropdown
                            disabled={changing}
                            width="w-64"
                            placement={`bottom-start`}
                            title={getSubjectName()}
                            dropdownToggleClass={`w-full btn btn-default border btn-rounded ${isDark?"bg-grey-800 text-grey-50 border-grey-50":"bg-white text-enigoo-500 border-enigoo-500"} btn-icon hover:bg-enigoo-600 bg-enigoo-500 hover:text-white space-x-1 mt-1 mr`}>
                            <>
                                <div className="flex flex-col text-center">
                                    {subjects.map((item, i) => (
                                        <div
                                            key={i}
                                            className="items-center flex flex-wrap justify-center h-8 space-y-1 dropdown-item">
                                            <button onClick={() => {
                                                changeSubject(item);
                                            }}
                                                    className="btn text-xs w-full btn-rounded h-full hover:bg-enigoo-500 hover:text-white">{item.name}</button>
                                        </div>
                                    ))}
                                </div>
                            </>
                        </Dropdown>
                    </div>
                </div>
            </>
        </>
    }


}
export default EnigooSubjectDropDown;
