import React, {useState} from "react";
import {apiGetReport} from "./Actions";
import CogoToast from "cogo-toast";
import EnigooBreadcrumbs from "../../../MyComponent/EnigooBreadcrumbs";
import Widget from "../../../components/widget";
import {Form} from "react-final-form";
import {ActionSelectField} from "../../../MyComponent/Api/Admin/ActionSelectField";
import {DeviceSelectField} from "../../../MyComponent/Api/Admin/DeviceSelectField";
import EnigooButton from "../../../MyComponent/EnigooButton";
import {FiSearch} from "react-icons/fi";
import {EnigooSelect} from "../../../MyComponent/EnigooSelect";
import {FiDownload} from "react-icons/all";
import {EnigooLoader} from "../../../MyComponent/EnigooLoader";
import UserCreditTransactionTable from "./UserCreditTransactionTable";
import EntranceTable from "./EntraceTable";
import {useTranslation} from "react-i18next";
import {EnigooDateTimeFieldv2} from "../../../MyComponent/EnigooDateTimeFieldv2";


const DeviceReport = () => {
    let [loading, setLoading] = useState(false);
    let [data, setData] = useState(null);
    let responseType = null;
    let submit;
    const {t} = useTranslation()


    const getReport = (values) => {
        setLoading(true);
        apiGetReport({...values, responseType: responseType}, (data) => {
            if (responseType === "xls") {
                const url = window.URL.createObjectURL(new Blob([data.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', data.fileName); //or any other extension
                document.body.appendChild(link);
                link.click();
            } else {
                setData({data:data,type:values.type});
            }
            responseType = null;
            setLoading(false);
        }, (error) => {
            CogoToast.error(t("deviceReports.cannotCreate"))
            setLoading(false);
        })
    }

    const _renderTable = () => {
        if (loading && responseType) return <Widget><EnigooLoader text={t("basics.loadingData")}/></Widget>;
        if (data) {
            return <Widget><ReportTable data={data}/></Widget>;
        }
        if (!data) return <Widget><div>{t("basics.noData")}</div></Widget>;
    }

    return (
        <>
            <EnigooBreadcrumbs right={<div className={"flex flex-row"}>
                <div className={"mr-2"}><EnigooButton onClick={() => {
                    responseType = "json";
                    submit();
                }} text={t("basics.search")}
                                                      icon={<FiSearch className={"mr-2"}/>}
                                                      loading={loading}
                                                      disabled={loading}/>
                </div>
                <EnigooButton onClick={() => {
                    responseType ="xls";
                    submit()
                }} text={t("basics.download")}
                              icon={<FiDownload className={"mr-2"}/>} loading={loading}
                              disabled={loading}/>
            </div>}/>
            <div className={"w-full flex-col"}>
                <Widget>
                    <Form onSubmit={getReport}
                          validate={values => {
                              let error = {};
                              if (!values.type) {
                                  error.type = t("basics.requireField");
                              }
                              return error;
                          }}
                          render={({handleSubmit}) => {
                              submit = handleSubmit;
                              return (

                                  <>
                                      <EnigooSelect label={t("deviceReports.typeOfReport")+": *"} name={"type"}
                                                    items={[{"id": "credit", "name": t("deviceReports.credit")}, {
                                                        "id": "entrance",
                                                        "name": t("deviceReports.entrances")
                                                    }]}/>
                                      <div className={"grid grid-cols-2 gap-2"}>
                                          <ActionSelectField name={"actionId"} label={t("action.plural")+": "}/>
                                          <DeviceSelectField name={"deviceId"} label={t("device.plural")+": "}/>
                                          <EnigooDateTimeFieldv2 name={"dateFrom"} label={t("basics.from")+": "} type={"from"}
                                                               showTimeSelect={true}/>
                                          <EnigooDateTimeFieldv2 name={"dateTo"} label={t("basics.to")+": "} type={"to"}
                                                               showTimeSelect={true}/>
                                      </div>

                                  </>
                              )
                          }}
                    />
                </Widget>
                {_renderTable()}

            </div>
        </>
    )

}
const ReportTable = ({data})=>{
    if(data.type==="credit"){
        return <UserCreditTransactionTable data={data.data}/>
    }else if(data.type==="entrance"){
        return <EntranceTable data={data.data}/>
    }
}
export default DeviceReport;
