import React, {useState} from "react";
import Flag from "react-flagkit";

export const MultiLanguageTabs = ({tabs}) =>{
    const [openTab,setOpenTab] = useState(0);
    const langImgs = [{
        id: "cs",
        img: <div className={"text-center"}><Flag country={"CZ"} size={20} className={"border"}/></div>
    },{
        id: "sk",
        img: <div className={"text-center"}><Flag country={"SK"} size={20} className={"border"}/></div>
    },
        {
            id: "en",
            img: <div className={"text-center"}><Flag country={"GB"} size={20} className={"border"}/></div>
        },
        {
            id: "de",
            img: <div className={"text-center"}><Flag country={"DE"} size={20} className={"border"}/></div>
        }]
    return (
        <div className="flex flex-wrap flex-col w-full tabs">
            <div className="flex lg:flex-wrap flex-row lg:space-x-2">
                {langImgs.map((img,index)=>(
                    <div key={index} className="flex-none">
                        <button
                            onClick={() => {
                                setOpenTab(index)
                            }}
                            className={`tab tab-underline ${
                                openTab === index ? 'tab-active' : 'border'
                            }`}
                            type="button">
                            {img.img}
                        </button>
                    </div>
                ))}
            </div>
            {tabs.map((tab, key) => (
                <div key={key}
                    className={`tab-content mt-0 pt-0 ${
                        openTab !== tab.index ? 'hidden' : 'block'
                    }`}>
                    {tab.content}
                </div>
            ))}
        </div>
    )
}
