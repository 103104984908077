import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import {chartColors} from "./chartColors";

export const AccreditationLineChart = (data) => {
    let [colors, setColors] = useState([]);
    let [chartData, setChartData] = useState([]);
    const {t} = useTranslation();

    function stringToColor(str) {
        let hash = 0;
        for (let i = 0; i < str.length; i++) {
            hash = str.charCodeAt(i) + ((hash << 5) - hash);
        }

        let color = '#';
        for (let i = 0; i < 3; i++) {
            const value = (hash >> (i * 8)) & 0xFF;
            color += ('00' + value.toString(16)).slice(-2);
        }
        return color;
    }

    useEffect(() => {
        let newData = [];
        if (data && data.data) {
            data.data.forEach((item, i) => {
                let newObject = {};
                Object.entries(item).forEach(([key, value]) => {
                    if (key !== "name") {
                        let translatedKey = t(key)
                        newObject[translatedKey] = value;
                    } else {
                        newObject.name = value;
                    }
                })
                newData.push(newObject)
            })
        }


        setChartData(newData);
    }, [data])

    useEffect(() => {
        let colors = [];
        if (chartData.length > 0) {
            Object.entries(chartData[0]).forEach(([key, value],index) => {
                if (key !== "name") {
                    colors.push({
                        dataKey: key,
                        stroke: chartColors[index % chartColors.length]
                    })
                }
            })
        }
        setColors(colors)
    }, [chartData])


    return (
        <div style={{width: '100%', height: 500}}>
            <ResponsiveContainer>
                <LineChart
                    data={chartData}
                    margin={{
                        top: 10,
                        right: 10,
                        left: 10,
                        bottom: 10
                    }}>
                    <Tooltip/>
                    <CartesianGrid strokeDasharray="3 3"/>
                    <XAxis dataKey="name" axisLine={false} tickLine={false}/>
                    <YAxis axisLine={false} tickLine={false} width={30}/>
                    <Legend verticalAlign="top" height={36}/>
                    {colors.map((color, i) => (
                        <Line
                            type="monotone"
                            dataKey={color.dataKey}
                            stroke={color.stroke}
                            strokeWidth={2}
                            activeDot={{r: 8}}
                        />
                    ))}
                </LineChart>
            </ResponsiveContainer>
        </div>
    )
}
