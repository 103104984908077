import React, {useState} from "react";
import {apiGetEntranceReport} from "./Actions";
import CogoToast from "cogo-toast";
import EnigooBreadcrumbs from "../../../MyComponent/EnigooBreadcrumbs";
import Widget from "../../../components/widget";
import {Form} from "react-final-form";
import {ActionSelectField} from "../../../MyComponent/Api/Admin/ActionSelectField";
import {DeviceSelectField} from "../../../MyComponent/Api/Admin/DeviceSelectField";
import EnigooButton from "../../../MyComponent/EnigooButton";
import {FiSend} from "react-icons/fi";
import {EnigooDateTimeFieldv2} from "../../../MyComponent/EnigooDateTimeFieldv2";


const EntranceReport = () => {
    let [loading, setLoading] = useState(false);

    const getReport = (values) => {
        setLoading(true);
        apiGetEntranceReport(values, (data) => {
            const url = window.URL.createObjectURL(new Blob([data.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', data.fileName); //or any other extension
            document.body.appendChild(link);
            link.click();
            setLoading(false);
        }, (error) => {
            CogoToast.error("Nepodařilo se vytvořit sestavu průchodů.")
            setLoading(false);
        })
    }

    return (
        <>
            <EnigooBreadcrumbs/>
            <div className={"w-full flex-col"}>
                <Widget>
                    <Form onSubmit={getReport}
                          render={({handleSubmit})=>(
                              <>
                                  <div className={"grid grid-cols-2 gap-2"}>
                                      <ActionSelectField name={"actionId"} label={"Akce: "}/>
                                      <DeviceSelectField name={"deviceId"} label={"Zařízení: "}/>
                                      <EnigooDateTimeFieldv2 name={"dateFrom"} label={"Od: "} type={"from"} showTimeSelect={true}/>
                                      <EnigooDateTimeFieldv2 name={"dateTo"} label={"Do: "} type={"to"} showTimeSelect={true}/>
                                  </div>
                                  <EnigooButton onClick={handleSubmit} text={"Odeslat"} icon={<FiSend className={"mr-2"}/>} loading={loading} disabled={loading}/>
                              </>
                          )}
                    />
                </Widget>

            </div>
        </>
    )

}
export default EntranceReport;
