import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import moment from "moment/moment";
import {FieldArray} from "react-final-form-arrays";
import {EnigooSelect} from "../../../../MyComponent/EnigooSelect";
import {FiPlus} from "react-icons/fi";
import {EnigooTextField} from "../../../../MyComponent/EnigooTextField";
import {useTranslation} from "react-i18next";
import EnigooMultiLanguageField from "../../../../MyComponent/EnigooMultiLanguageField/EnigooMultiLanguageField";

const ActionDaySelect = ({values, doPush}) => {
    let [days, setDays] = useState([]);
    let [dataToSelect, setDataToSelect] = useState([]);

    const {t} = useTranslation()
    let actualFields = null;

    useEffect(() => {
        prepareDays();
    }, []);

    useEffect(() => {
        if (days) {
            let array = [];
            days.forEach((item, index) => {
                if (!values.days || !values.days.some((i) => i.id === item)) {
                    array.push({id: item});
                }
            })
            setDataToSelect(array);
            values.selectedDay = undefined;
        }

    }, [values.days]);


    useEffect(() => {
        prepareDays();
    }, [values.dateFrom, values.dateTo]);

    const prepareDays = () => {
        let daysArray = [];
        if (values.dateFrom && values.dateTo) {
            const dayFrom = moment(values.dateFrom);
            const dayTo = moment(values.dateTo);
            while (dayFrom.isSameOrBefore(dayTo, 'day')) {
                daysArray.push(dayFrom.format('YYYY-MM-DD'));
                dayFrom.add(1, 'day');
            }
            setDays(daysArray);
        } else {
            setDays([]);
        }
        if (values.days) {
            let toRemove = []
            values.days.map((item, index) => {
                if (!daysArray.some((i) => i === item.id)) {
                    toRemove.push(index);
                }
            })
            toRemove.forEach((item) => {
                actualFields.remove(item);
            })
        }
        if (days) {
            let array = [];

            daysArray.map((item, index) => {
                if (!values.days || !values.days.some((i) => i.id === item)) {
                    array.push({id: item});
                }
            })
            setDataToSelect(array);
            values.selectedDay = undefined;
        }

    }

    if (values.multiDay && days && days.length > 0) {
        return (
            <div className={"flex flex-col space-y-2"}>

                <div className={"flex flex-row space-x-2"}>
                    <EnigooSelect label={t("action.selectedDay")+":"} name={"selectedDay"} items={dataToSelect}
                                  defaultValue={["id", "id"]} placeHolder={t("placeHolder.select")}/>
                    <div className={"form-element"}>
                        <div className={"form-label"}>{t("webChannels.assign")}</div>
                        <button disabled={!values.selectedDay}
                                onClick={() => {
                                    doPush("days", {id: values.selectedDay})
                                    //setSelectedDays([...selectedDays, selectedDay])
                                    values.selectedDay = undefined;
                                    //setSelectedDay(null);

                                }}
                                className={"btn btn-default btn-rounded btn-icon hover:bg-enigoo-600 bg-enigoo-500 text-white"}>
                            <FiPlus size={10}/>
                        </button>
                    </div>

                </div>

                <FieldArray name={"days"}>
                    {({fields}) => {
                        actualFields = fields;
                        return (
                            <div className={"grid lg:grid-cols-2 grid-cols-1 gap-2"}>
                                {fields.map((name, index) => (
                                    <div className={`text-sm form-input`} key={name}>
                                        <div className={"grid grid-cols-2 gap-2"}>
                                            <div className={"font-bold"}>{fields.value[index].id}:</div>
                                            <div className={"flex place-self-end"}>
                                                <div
                                                    className={"text-right"}
                                                    onClick={() => {
                                                        fields.remove(index)
                                                    }
                                                    }
                                                    style={{cursor: "pointer"}}>❌
                                                </div>
                                            </div>
                                        </div>
                                        <div className={"grid grid-cols-2 text-sm mt-5 gap-2"}>
                                            <EnigooTextField label={""} name={`${name}.id`} visible={false}/>
                                            <EnigooMultiLanguageField name={`${name}.name`} label={t("basics.name")+": *"}/>
                                            <EnigooTextField label={t("zones.nameOnAccreditation")+": *"}
                                                             name={`${name}.onAccreditation`} visible={true}/>

                                        </div>
                                    </div>
                                ))}
                            </div>
                        )
                    }
                    }
                </FieldArray>
            </div>
        )
    } else {
        return <></>
    }


}
ActionDaySelect.propTypes = {
    values: PropTypes.object.isRequired,
    doPush: PropTypes.func.isRequired
}
export default ActionDaySelect;
