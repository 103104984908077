import {combineReducers} from 'redux'
import config from './config'
import leftSidebar from './left-sidebar'
import palettes from './palettes'
import actualAction from "./actualAction";
import filterUser from "./filterUser";
import adminRights from "./adminRights";
import image from "./image";
import checkUserCam from "./checkUserCam";

const rootReducer = combineReducers({
  config,
  leftSidebar,
  palettes,
  actualAction,
  filterUser,
  adminRights,
  image,
  checkUserCam
})

export default rootReducer
