import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import CogoToast from "cogo-toast";
import {Form} from "react-final-form";
import {FiCircle, FiSend} from "react-icons/fi";
import EnigooModal from "../../../MyComponent/Modal/EnigooModal";
import Widget from "../../../components/widget";
import {FaQuestion} from "react-icons/all";
import {EnigooTextField} from "../../../MyComponent/EnigooTextField";
import {apiPostForgotPassword} from "./Actions";
import {EnigooEmailField} from "../../../MyComponent/EnigooEmailField";

const ForgotPasswordModal = ({}) => {
    const {t,i18n} = useTranslation();
    let [saving, setSaving] = useState(false);
    let [loading, setLoading] = useState(false);
    let [open, setOpen] = useState(false);

    let submit = null;

    const onSave = (values) => {
        setSaving(true);
        values.language = i18n.language;
        console.log(values);
        apiPostForgotPassword(values,(data)=>{
            CogoToast.success(t("basics.forgotPasswordEmailSend"))
            onClose();
        },(err)=>{
            CogoToast.error(t("basics.forgotPasswordInvalidEmail"))
        })


    }

    const onClose = () => {
        setOpen(false);
    }
    const onOpen = () => {
        setOpen(true);
    }

    return (
        <EnigooModal open={open} onOpen={onOpen} onClose={onClose} buttonText={t("basics.forgotPassword")}
                     title={t("basics.forgotPassword")} buttonColor={"white"} buttonTextColor={"enigoo-500"} buttonExtraStyle={"underline items-start text-left"}
                     buttonIcon={<FaQuestion/>} type={"button"} saveText={t("basics.send")} cancelText={t("basics.close")} saveIcon={<FiSend/>}
                     onSave={() => submit()}>
            <Widget>
                <div className="flex flex-col ">
                    <Form onSubmit={onSave}
                          render={({handleSubmit}) => {
                              submit = handleSubmit;
                              return (
                                  <div className={"flex flex-col space-y-2 items-center"}>
                                      <div className={"text-xl"}>
                                          {t("basics.forgotPasswordLabel")}
                                      </div>
                                        <EnigooEmailField label={t("basics.email")+": *"} mandatory={true} name={"login"} placeHolder={"example@enigoo.cz"}/>
                                  </div>
                              );
                          }}
                    />
                </div>

            </Widget>
        </EnigooModal>
    )
}
ForgotPasswordModal.propTypes = {}
export default ForgotPasswordModal;
