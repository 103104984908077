import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import Dropdown from "../../components/dropdowns/dropdown-1";
import {HiOutlineGlobeAlt} from "react-icons/all";
import {shallowEqual, useSelector} from "react-redux";
import Flag from "react-flagkit";

const EnigooLanguageDropDown = ({type = "admin"}) => {

    const {i18n} = useTranslation()
    const languages = [
        {
            id: "cs",
            label: "CZ",
            img: <Flag country={"CZ"} size={20} className={"border mr-2"}/>
        },{
            id: "sk",
            label: "SK",
            img: <Flag country={"SK"} size={20} className={"border mr-2"}/>
        }
        , {id: "en", label: "EN", img: <Flag country={"GB"} size={20} className={"border mr-2"}/>}, {
            id: "de",
            label: "DE",
            img: <Flag country={"DE"} size={20} className={"border mr-2"}/>
        }];
    const {onlineConfig, adminRights} = useSelector(
        (state) => ({
            onlineConfig: state.reducerOnline.onlineConfig,
            adminRights: state.reducerAdmin.adminRights
        }),
        shallowEqual
    )

    useEffect(() => {
        getLanguage()
    }, [onlineConfig.multiLanguage]);


    const getLanguage = () => {
        languages.forEach((item) => {
            if (item.id === i18n.language) {
                setLang(item)
            }
        })
    }

    const getActualLang = () => {
        let actual = null;
        languages.forEach((item) => {
            if (item.id === i18n.language) {
                actual = item;
            }
        })
        if (actual == null) {
            actual = languages[0];
        }
        return actual;
    }

    const {palettes} = useSelector(
        (state) => ({
            palettes: state.reducerAdmin.palettes
        }),
        shallowEqual
    )

    const {background, leftSidebar, navbar} = palettes;

    let isDark = React.useMemo(() => {
        return background === "dark" && leftSidebar === "dark" && navbar === "dark";
    }, [background, leftSidebar, navbar])

    let [lang, setLang] = useState();
    const changeLanguage = (lang) => {
        i18n.changeLanguage(lang.id);
        setLang(lang)
    }

    const getTitle = () => {
        let title = "-";
        languages.forEach((item) => {
            if (item.id === lang.id) {
                title = item.label
            }
        })
        return title;
    }

    const _renderDropDown = () => (<div>
            <Dropdown
                iconOpen={<HiOutlineGlobeAlt className={"mr-2"} size={20}/>}
                iconHidden={getActualLang().img}
                width="w-16"
                placement={`bottom-start`}
                title={getActualLang().label}
                dropdownToggleClass={`w-16 btn btn-default border btn-rounded ${isDark ? "bg-grey-800 text-grey-50 border-grey-50" : "bg-white text-enigoo-500 border-enigoo-500"} btn-icon hover:bg-enigoo-600 bg-enigoo-500 hover:text-white space-x-1 mt-1 mr`}>
                <>
                    <div className="flex flex-col text-center">
                        {languages.map((item, i) => (
                            <div
                                key={i}
                                className="items-center flex flex-wrap justify-center h-8 space-y-1 dropdown-item">
                                <button onClick={() => {
                                    changeLanguage(item);
                                }}
                                        className="btn text-xs w-full btn-rounded h-full hover:bg-enigoo-500 hover:text-white">
                                    <div
                                        className={"flex flex-row items-center justify-start m-2"}>{item.img}<span> {item.label}</span>
                                    </div>
                                </button>
                            </div>
                        ))}
                    </div>
                </>
            </Dropdown>
        </div>
    )


    switch (type) {
        case "login":
        case "preview":
            return _renderDropDown();
        case "online":
            if (onlineConfig.multiLanguage) {
                return _renderDropDown()
            } else {
                return <></>
            }
        case "admin":
            if (adminRights.subject && adminRights.subject.multiLanguage) {
                return _renderDropDown();
            } else {
                return <></>
            }
        default:
            return <></>
    }


}
export default EnigooLanguageDropDown;
