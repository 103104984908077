import PropTypes from "prop-types";
import React, {useEffect} from "react";
import Switch from "react-switch";
import {Field} from "react-final-form";
import {getColor} from "../functions/colors";
import {FiHelpCircle} from "react-icons/fi";
import ReactDOM from "react-dom";
import EnigooToolTip from "./EnigooToolTip";

export const EnigooSwitch = ({
                                 color = "enigoo-500",
                                 label,
                                 inline = false,
                                 name,
                                 outsideClassName = "w-full",
                                 setChecked = null,
                                 visible = true,
                                 toolTip,
                                 disabled = false,
                                 toolTipPosition = "right"
                             }) => {
    useEffect(() => {

    }, []);

    const renderSwitchButton = (field) => {

        field.input.value === "" ? field.input.onChange(false) : field.input.onChange(field.input.value);
        return (<div className={"flex- flex-col"}>
                <Switch disabled={disabled}
                        checked={field.input.value === "" ? false : field.input.value} onChange={(value) => {
                    field.input.onChange(value);
                    if (setChecked) {
                        setChecked(value);
                    }
                }} uncheckedIcon={false} checkedIcon={false} onColor={getColor(color)}/>
                {field.meta.error && field.meta.touched && <div className="form-error">{field.meta.error}</div>}
            </div>
        )
    }
    if (visible) {
        return (
            <div className={outsideClassName}>
                <div className={`form-element mb-4 ${inline ? 'form-element-inline' : ''}`}>
                    {label !== "" && <div className={"form-label flex flex-row"}>{label}
                        {toolTip && <>
                            <FiHelpCircle data-tip data-for={"_tooltip_" + name} key={name}
                                          className={"ml-2 text-red-900"} size={18}/>
                            <EnigooToolTip content={toolTip} id={"_tooltip_" + name} place={toolTipPosition}/>

                        </>}</div>}
                    <Field name={name} component={renderSwitchButton}/>
                </div>
            </div>
        )
    } else {
        return <></>
    }
}
EnigooSwitch.propTypes = {
    color: PropTypes.string,
    label: PropTypes.string.isRequired,
    inline: PropTypes.bool,
    name: PropTypes.string.isRequired,
    outsideClassName: PropTypes.string,
    disabled: PropTypes.bool
}
