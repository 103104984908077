import React from 'react';
import {Route, Switch} from 'react-router-dom';
import {OnlineForm} from "./pages/Registration/OnlineForm";


const Routes = () => {
    return (
        <Switch>
            <Route exact path={"/app/online"} component={OnlineForm}/>
            <Route exact path={"/app/online/:id"} component={OnlineForm}/>
        </Switch>
    )
}
export default Routes
