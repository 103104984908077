import React, {useEffect, useState} from "react";
import {EnigooSelectMulti} from "../../EnigooSelectMulti";
import {EnigooSelect} from "../../EnigooSelect";
import PropTypes from "prop-types";
import {apiGetCustomFieldType} from "../../../Admin/pages/CustomField/Actions";
import {useTranslation} from "react-i18next";

export const CustomFieldTypeSelectField = ({
                                               label = "Typ uživatelského pole: ",
                                               name,
                                               isMulti = true,
                                               outsideClassName = "w-full",
                                               onValueChange = null,
                                               disabled = false
                                           }) => {
    let [data, setData] = useState(null);
    let [options, setOptions] = useState(null);
    let [loading, setLoading] = useState(false);
    const {t, i18n} = useTranslation()

    useEffect(() => {
        get();
    }, []);

    const confOptions = () => {
        let options = [];
        data.map((item) => {
            options.push({
                id: item.id,
                name: item.name[i18n.language]
            })
        })
        setOptions(options)
    }

    const get = () => {
        setLoading(true);
        apiGetCustomFieldType((data) => {
            let options = [];
            data.map((item) => {
                options.push({
                    id: item.id,
                    name: item.name[i18n.language]
                })
            })
            setData(options);
            setLoading(false);
        }, (error) => {
            setLoading(false);
        })
    }

    return (isMulti ?
        <EnigooSelectMulti placeHolder={t("placeHolder.selectMulti")} loading={loading} label={label} name={name} disabled={disabled} items={data}
                           outsideClassName={outsideClassName} onValueChange={onValueChange}/> :
        <EnigooSelect placeHolder={t("placeHolder.select")} loading={loading} label={label} name={name} disabled={disabled} items={data}
                      outsideClassName={outsideClassName} onValueChange={onValueChange}/>);


}

CustomFieldTypeSelectField.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    isMulti: PropTypes.bool
}
