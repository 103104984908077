import {axios} from "../../../axiosConfig";

const url = "api/admin/v1/device-template";

export const apiGetDeviceTemplate=(callback,error)=>{
    axios.get(url)
        .then(response=>callback(response.data.data))
        .catch(err=>error(err));
}

export const apiGetDeviceTemplateById = (id,callback,error)=>{
    axios.get(url+"/"+id)
        .then(response=>callback(response.data.data))
        .catch(err=>error(err))
}

export const apiCreateDeviceTemplate = (data,callback,error)=>{
    axios.post(url,data)
        .then(response=>callback(response.data.data))
        .catch(err=>error(err));
}

export const apiUpdateDeviceTemplate = (id,data,callback,error)=>{
    axios.put(url+"/"+id,data)
        .then(response=>callback(response.data.data))
        .catch(err=>error(err))
}
