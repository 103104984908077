import {axios} from "../../../axiosConfig";

const url = "api/admin/v1/admin-user";

export function apiGetUserAdmin(callback, error) {
    axios.get(url)
        .then(response => callback(response.data.data))
        .catch((err) => error(err))
}

export function apiGetUserAdminById(id, callback, error) {
    axios.get(url + "/" + id)
        .then(response => callback(response.data.data))
        .catch((err) => error(err))
}

export function apiUpdateUserAdmin(id, data, callback, error) {
    axios.put(url + "/" + id, data)
        .then(response => callback(response.data.data))
        .catch(err => error(err));
}

export function apiDeleteUserAdmin(id, callback, error) {
    axios.delete(url + "/" + id)
        .then(response => callback(response.data.data))
        .catch(err => error(err));
}

export function apiCreateUserAdmin(data, callback, error) {
    axios.post(url, data)
        .then(response => callback(response.data.data))
        .catch(err => error(err));
}

export function apiGetActualAdminUser(callback, error) {
    axios.get(url + "/actual")
        .then(response => callback(response.data.data))
        .catch((err) => error(err));
}

export function apiPostChangeAdminUserSubject(data, callback, error) {
    axios.post(url + "/actual/subject", data)
        .then(response => callback(response.data.data))
        .catch(err => error(err))
}

export function apiGetSendChangePasswordRequest(id,callback,error){
    axios.get(url +"/forgot-password"+ "/"+id)
        .then(response => callback(response.data.data))
        .catch((err) => error(err));
}
