import React, {useEffect, useState} from "react";
import {useParams} from "react-router";
import {useTranslation} from "react-i18next";
import {apiGetUserExtension} from "../Actions";
import {isArray} from "@craco/craco/lib/utils";
import MealAssignModal from "../MealExtensionUsers/MealAssignModal";
import {FiEdit} from "react-icons/fi";
import UserExtensionTable from "./UserExtensionTable";
import {IconTabs} from "../../../../components/tabs";
import {EnigooLoader} from "../../../../MyComponent/EnigooLoader";
import EnigooBreadcrumbs from "../../../../MyComponent/EnigooBreadcrumbs";
import Widget from "../../../../components/widget";
import PropTypes from "prop-types";
import ParkingAssignModal from "../ParkingExtension/ParkingAssignModal";

const UserExtension = ({type=1}) =>{
    const {actionId} = useParams();
    const {t, i18n} = useTranslation();
    let [loading, setLoading] = useState(false);
    let [data, setData] = useState(null);
    let [selectedTab, setSelectedTab] = useState(0);
    let [selectedRows, setSelectedRows] = useState([]);
    let [modalOpen, setModalOpen] = useState(false);

    const _setSelectedTab = (index) => {
        setSelectedTab(index);
        setSelectedRows([]);
    }

    useEffect(() => {
        if (actionId) {
            setLoading(false);
            apiGetUserExtension(type,actionId, (data) => {
                if(data.users && isArray(data.users)){
                    setSelectedTab(data.users[0].day===""?0:data.users[0].day);
                }
                setData(data);
                setLoading(false);
            }, (err) => {
                setData(null);
                setLoading(false);
            })
        }
    }, []);


    const getTranslate = (item) => {
        if (item[i18n.language]) {
            return item[i18n.language];
        } else {
            return item["cs"];
        }
    }

    const _renderOptionsButton = (id) =>{
        switch (type){
            case 1:
                return <MealAssignModal actionId={actionId} usersIds={[id]} days={[selectedTab]} icon={<FiEdit/>} text={""}/>
            case 2:
                return <ParkingAssignModal actionId={actionId} usersIds={[id]} days={[selectedTab]} icon={<FiEdit/>} text={""}/>
        }

    }

    const _renderModal = () =>{
        switch (type){
            case 1:
                return <div className={"flex flex-row space-x-2"}>
                    <MealAssignModal actionId={actionId} extensionId={data?.extension?.id} usersIds={selectedRows} days={[selectedTab]} setOpen={setModalOpen} isOpen={modalOpen}/>
                </div>
            case 2:
                return <div className={"flex flex-row space-x-2"}>
                    <ParkingAssignModal actionId={actionId} usersIds={selectedRows} days={[selectedTab]}/>
                </div>
        }
    }

    const _renderTabs = () => {
        let tabs = [];
        if (data && data.action.multiDay) {


            if (data && data.users) {
                let initTab = 0;
                data.users.forEach((item, index) => {
                    if(index===0){
                        initTab = item.day;
                    }
                    tabs.push({
                        index: item.day,
                        title: getTranslate(item.name) + (item.day ? ' (' + item.day + ')' : ""),
                        content: <UserExtensionTable items={item.users} setSelectedRows={setSelectedRows} renderOptionsButton={_renderOptionsButton}/>,
                        day:item.day
                    })
                })
                return <IconTabs tabs={tabs} setOpenedTab={_setSelectedTab} initOpenTab={initTab} disableNonActiveTab={true}/>
            }

        } else {
            return <UserExtensionTable items={data.users[0].users} setSelectedRows={setSelectedRows} renderOptionsButton={_renderOptionsButton}/>
        }


    }

    const _renderBody = () => {
        if (loading) return <EnigooLoader text={t("mealExtension.loadingAccreditation")}/>
        if (!data) return <>No data...</>
        else {
            return _renderTabs();
        }
    }

    return <>

        <EnigooBreadcrumbs right={_renderModal() }/>
        <Widget>
        </Widget>
        <Widget>
            {_renderBody()}
        </Widget>
    </>
}
UserExtension.propTypes={
    type:PropTypes.oneOf([1,2])
}
export default UserExtension;
