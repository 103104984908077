import {useTranslation} from "react-i18next";
import Widget from "../../../../components/widget";
import EnigooImageField from "../../../../MyComponent/EnigooImageField";
import React from "react";
import PropTypes from "prop-types";

const TabImage = ({initImage}) => {
    const {t} = useTranslation()

    return (
        <div className={"lg:w-1/3 w-full ml-2"}>
            <Widget>
                <EnigooImageField initImage={initImage} name={"image"} label={t("photo.single") + ":"}/>
            </Widget>
        </div>
    )
}
TabImage.propTypes={
    initImage: PropTypes.any
}
export default TabImage;
