import React from "react";
import {Field} from "react-final-form";
import PropTypes from "prop-types";

const EnigooCreditPanel = ({name}) =>{

    return (
    <div className={"w-full border rounded p-3 mb-3"}>
        <div className={"form-label text-xl"}>Aktuální výše kreditu:</div>
        <Field name={name}>
            {({input, meta}) => (
                <div className={"bold"}>{input.value?input.value:"-"} Kč</div>
            )}
        </Field>
    </div>
    )
}
EnigooCreditPanel.propTypes={
    name:PropTypes.string
}
export default EnigooCreditPanel;