import React, {useEffect, useState} from "react";
import {EnigooSelectMulti} from "../../EnigooSelectMulti";
import {EnigooSelect} from "../../EnigooSelect";
import PropTypes from "prop-types";
import {apiGetTemplate} from "../../../Admin/pages/Template/Actions";
import {useTranslation} from "react-i18next";

export const TemplateSelectField = ({
                                        label,
                                        name,
                                        isMulti = true,
                                        outsideClassName = "w-full",
                                        type = 1,
                                        mailTemplateType = null,
                                        onValueChange = null,
    inModal =false,
        toolTip=null
                                    }) => {
    let [data, setData] = useState(null);
    let [loading, setLoading] = useState(false);
    const {t} = useTranslation()
    //data z databaze
    useEffect(() => {
        get()
    }, [])

    const get = () => {
        setLoading(true);
        apiGetTemplate((data) => {
            if(type===0 && mailTemplateType){
                let filteredData = [];
                data[type].items.map((item,index)=>{
                    if(item.type===mailTemplateType){
                        filteredData.push(item);
                    }
                })
                setData(filteredData);
            }else{
                setData(data[type].items);
            }
            setLoading(false);
        }, (error) => {
            setLoading(false);
        })
    }

    return (isMulti ?
        <EnigooSelectMulti inModal={inModal} toolTip={toolTip} onValueChange={onValueChange} placeHolder={t("placeHolder.template")} outsideClassName={outsideClassName} label={label} disabled={loading} name={name} items={loading?[]:data}/> :
        <EnigooSelect toolTip={toolTip} inModal={inModal} onValueChange={onValueChange} placeHolder={t("placeHolder.template")} outsideClassName={outsideClassName} label={label} name={name} disabled={loading} items={loading?[]:data}/>);

}
TemplateSelectField.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    isMulti: PropTypes.bool,
    type: PropTypes.number,
}
