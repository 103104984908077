import React from 'react';
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";

const EnigooActiveField = ({
    value
})=>{
    const {t} = useTranslation();
    if(value){
        return (
            <div className={`py-1 bg-green-100 text-green-900 text-center font-bold w-1/3 rounded border-green-900`}>
                {t('basics.yes')}
            </div>
        )
    } else {
        return (
            <div className={`py-1 bg-red-100 text-red-900 text-center font-bold w-1/3 rounded border-red-900`}>
                {t('basics.no')}
            </div>
        )
    }

}

EnigooActiveField.propTypes = {
    value: PropTypes.number.isRequired
}
export default EnigooActiveField;
