import React from 'react'
import {useTranslation} from "react-i18next";

const Centered = ({heading=false, children}) => {
    const {t} = useTranslation();

    return (
        <div
            data-layout="centered"
            className="w-full h-screen flex items-center justify-center bg-grey-50">
            <div className={"flex flex-col w-full max-w-xl"}>
                {heading &&  <div className={"flex flex-col space-y-2 items-center justify-center pb-6"}>
                    <h2 className={"uppercase text-enigoo-500 font-bold"}>{t("basics.accreditationSystem")}</h2>
                    <img src={"/logo-enigoo.png"} alt={"ENIGOO.cz"}/>
                </div>}
                {children}
            </div>
        </div>
    )
}

export default Centered
