import React, {useEffect, useRef, useState} from "react";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import Portal from "../../../../components/portal";
import {FiX} from "react-icons/fi";
import EnigooButton from "../../../../MyComponent/EnigooButton";
import {FiSave} from "react-icons/all";
import CogoToast from "cogo-toast";
import {apiAssignUserToGroups} from "../Actions";
import {Form} from "react-final-form";
import GroupsSelectField from "../../../../MyComponent/Api/Admin/GroupsSelectField";
import {useTranslation} from "react-i18next";
import EnigooNote from "../../../../MyComponent/EnigooNote";

export const AssignUserToGroupModal = ({
                                           setOpen,
                                           open,
                                           selectedIds,
                                           selectAll
                                       }) => {
    let [saving, setSaving] = useState(false);
    let [selectedGroup, setSelectedGroup] = useState(null);
    const dispatch = useDispatch();

    const {palettes, actualAction, adminRights, filterUser} = useSelector(
        (state) => ({
            palettes: state.reducerAdmin.palettes,
            actualAction: state.reducerAdmin.actualAction,
            adminRights: state.reducerAdmin.adminRights,
            filterUser: state.reducerAdmin.filterUser
        }),
        shallowEqual
    )
    const {t} = useTranslation()
    let {background} = {
        ...palettes
    }
    let {filterData} = {...filterUser};
    const modalRef = useRef(null)

    const hide = () => {
        setOpen(false)
    }

    const send = () => {
        if (selectedGroup) {
            setSaving(true);
            let data = {};
            data.userId = selectedIds;
            data.groupsId = selectedGroup;
            data.actionId = actualAction.actualAction;
            data.filterData = filterData;
            data.all = selectAll;
            apiAssignUserToGroups(data, (data) => {
                CogoToast.success(t("accreditation.assignToGroupComplete"));
                hide();
                setSaving(false);
                dispatch({
                    type: 'SET_RELOAD_ACCREDITATION',
                    reloadUserData: true
                })
            }, (error) => {
                CogoToast.error(t("accreditation.assignToGroupError"));
                setSaving(false);
            })
        } else {
            CogoToast.error(t("accreditation.assignGroupChooseGroup"));
        }
    }

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (!modalRef || !modalRef.current) return false
            if (!open || modalRef.current.contains(event.target)) {
                return false
            }
            setOpen(!open)
        }
        document.addEventListener('mousedown', handleClickOutside)

        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [open, modalRef])

    return (
        <>
            {open && (
                <Portal selector="#portal">
                    <div className="modal-backdrop fade-in"></div>
                    <div className={`modal show ${background === 'dark' ? 'dark-mode' : ''}`}
                         data-background={background}>
                        <div className="relative min-w-sm w-1/3 mx-auto lg:max-w-5xl" ref={modalRef}>
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h3 className="text-xl font-semibold">{t("groups.select")}</h3>
                                    <button
                                        className="modal-close btn btn-transparent"
                                        onClick={hide}>
                                        <FiX size={18} className="stroke-current"/>
                                    </button>
                                </div>
                                <div className={"max-h-sm overflow-y-scroll p-2 pb-10"}>
                                    <Form onSubmit={() => {
                                    }} render={() => (
                                        <div className={"flex flex-col space-y-2"}>
                                            {adminRights.subject.extensions.length >= 1 &&
                                                <EnigooNote color={"enigoo"}
                                                            visible={true}>
                                                    <div className={"flex flex-row space-x-2"}>
                                                        <div className={"flex flex-col space-y-2"}><span
                                                            className={"font-bold text-enigoo-500"}>{t("groups.select")} </span>
                                                            {adminRights.subject.extensions.includes(1) &&
                                                            <div
                                                                dangerouslySetInnerHTML={{__html: t("groups.userAssignNoteMeal")}}></div>}
                                                            {adminRights.subject.extensions.includes(2) &&
                                                                <div
                                                                    dangerouslySetInnerHTML={{__html: t("groups.userAssignNoteParking")}}></div>}
                                                        </div>
                                                    </div>
                                                </EnigooNote>}
                                            <div className={"m-2"}>
                                                <GroupsSelectField onChange={(event) => setSelectedGroup(event.value)}
                                                                   name={"groupId"} inModal={true}
                                                                   label={t("groups.single") + ": *"} isMulti={false}/>
                                            </div>
                                        </div>

                                    )}/>
                                </div>
                                <div className="modal-footer space-x-2">
                                    <EnigooButton disabled={saving} loading={saving} onClick={hide}
                                                  text={t("basics.cancel")}
                                                  icon={<FiX/>}/>
                                    <EnigooButton disabled={saving} loading={saving} onClick={() => send()}
                                                  text={t("basics.save")} icon={<FiSave/>}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </Portal>
            )}
        </>
    )
}
