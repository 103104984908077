import React, {useState} from 'react'
import {Link, useLocation} from 'react-router-dom'
import {FiChevronRight,} from 'react-icons/fi'
import {useTranslation} from "react-i18next";
import EnigooToolTip from "../../MyComponent/EnigooToolTip";

const Item = ({url, icon, title, badge, items, disabled = false}) => {
    const [hidden, setHidden] = useState(true)
    const {t} = useTranslation()
    let location = useLocation()
    let {pathname} = {...location}
    let active = pathname === url ? true : false
    if (pathname === '/' && url === '/dashboard') {
        active = true
    }
    if (pathname === '/' && url !== '/dashboard') {
        active = false
    }
    if (items.length === 0) {
        if (disabled) {
            return <>
                <div data-tip data-for={"_tooltip_menu_item_disabled_" + url}
                     key={"_tooltip_menu_item_disabled_" + url}
                    className={`left-sidebar-item ${disabled ? 'disabled' : ''} ${active ? 'active' : ''} text-grey-500`}>
                    {icon}
                    <span className="title">{t(title)}</span>

                </div>
                <EnigooToolTip id={"_tooltip_menu_item_disabled_" + url}
                               content={t("basics.thisItemNotAllowed")}/>
            </>
        } else {
            return (
                <Link to={url} className={`left-sidebar-item ${disabled ? 'disabled' : ''} ${active ? 'active' : ''}`}>
                    {icon}
                    <span className="title">{t(title)}</span>
                </Link>
            )
        }

    }
    return (
        <button disabled={disabled}
                onClick={() => setHidden(!hidden)}
                className={`left-sidebar-item ${active ? 'active' : ''} ${
                    hidden ? 'hidden-sibling' : 'open-sibling'
                }`}>
            {icon}
            <span className="title">{t(title)}</span>
            <FiChevronRight className="ml-auto arrow"/>
        </button>
    )
}

export default Item
