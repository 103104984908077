import {axios} from "../../../axiosConfig";


const url = "api/manage/v1/subject";

export function apiManageCreateSubject(data,callback,error){
    axios.post(url,data)
        .then(response=>callback(response.data.data))
        .catch(err=>error(err));
}

export function apiManageGetSubjectById(id,callback,error){
    axios.get(url+"/"+id)
        .then(response=>callback(response.data.data))
        .catch(err=>error(err));
}

export function apiManageGetSubject(callback,error){
    axios.get(url)
        .then(response=>callback(response.data.data))
        .catch(err=>error(err));
}

export function apiManageUpdateSubject(data,id,callback,error){
    axios.put(url+"/"+id,data)
        .then(response=>callback(response.data.data))
        .catch(err=>error(err));
}

export function apiManageGetWebChannelForSubject(subjectId,callback,error){
    axios.get("api/manage/v1/web-channel/"+subjectId)
        .then(response=>callback(response.data.data))
        .catch(err=>error(err))
}